import { Box } from '@chakra-ui/react';

const BrowserFrame = ({ src }) => {
    return (
      <Box w="100%" h="500px">
        <iframe
          src={src}
          title="Embedded Browser"
          width="100%"
          height="100%"
          frameBorder="0"
          scrolling="auto"
        ></iframe>
      </Box>
    );
  };

  export default BrowserFrame;
  