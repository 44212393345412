import React, { useState, useEffect } from 'react';
import { Popper, Paper, Typography, Box, Button, TextField } from '@mui/material';
import Draggable from 'react-draggable';

interface AddApexTagProps {
    open: boolean;
    anchorEl: HTMLElement | null;
    onClose: () => void;
    onSave: (tagData: any) => void;
    pageNum: number | null;
    ecwDocId: number | null | undefined;
}

function AddApexTagComponent(props: any) {
    return (
        <Draggable handle="#draggable-popper-title">
            <Paper {...props} />
        </Draggable>
    );
}

const AddApexTag: React.FC<AddApexTagProps> = ({ open, anchorEl, onClose, onSave, pageNum, ecwDocId }) => {
    const [tag, setTag] = useState('');
    const [onEntityType, setOnEntityType] = useState('');
    const [onEntityKey, setOnEntityKey] = useState<number | string>('');
    const [linkEntityType, setLinkEntityType] = useState('');
    const [linkEntityKey, setLinkEntityKey] = useState<number | string>('');
    const [tagDesc, setTagDesc] = useState('');
    const [tagPage, setTagPage] = useState('');
    const [tagDate, setTagDate] = useState('');
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        setOnEntityType('ecwdoc');
        setOnEntityKey(`${ecwDocId}`);
        setTagPage(`${pageNum}`);
    },[pageNum]);

    useEffect(() => {
        setTagPage(`${pageNum}`);
    },[pageNum]);

    const handleFormSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        const tagData = {
            tag,
            onEntityType,
            onEntityKey,
            linkEntityType,
            linkEntityKey,
            tagDesc,
            tagPage,
            tagDate,
        };
        onSave(tagData);
    };

    return (
        <Popper open={open} anchorEl={anchorEl} placement="left-start" disablePortal>
            <AddApexTagComponent>
                <Box p={2} display="flex" justifyContent="space-between" alignItems="center" style={{ cursor: 'move' }}>
                    <Typography variant="h6" id="draggable-popper-title">Add New Tag</Typography>
                    <Button size="small" onClick={onClose}>X</Button>
                </Box>
                <Box p={2}>
                    <form id="add-tag-form" onSubmit={handleFormSubmit}>
                        <Box display="flex" flexDirection="column" gap={2}>
                            <TextField
                                label="Tag"
                                variant="outlined"
                                fullWidth
                                value={tag}
                                onChange={(e) => setTag(e.target.value)}
                                required
                            />
                            <Box display="flex" gap={2}>
                                <TextField
                                    label="On Entity Type"
                                    variant="outlined"
                                    fullWidth
                                    value={onEntityType}
                                    onChange={(e) => setOnEntityType(e.target.value)}
                                    required
                                />
                                <TextField
                                    label="On Entity Key"
                                    variant="outlined"
                                    fullWidth
                                    type="number"
                                    value={onEntityKey}
                                    onChange={(e) => setOnEntityKey(Number(e.target.value))}
                                    required
                                />
                            </Box>
                            <Box display="flex" gap={2}>
                                <TextField
                                    label="Link Entity Type"
                                    variant="outlined"
                                    fullWidth
                                    value={linkEntityType}
                                    onChange={(e) => setLinkEntityType(e.target.value)}
                                />
                                <TextField
                                    label="Link Entity Key"
                                    variant="outlined"
                                    fullWidth
                                    type="number"
                                    value={linkEntityKey}
                                    onChange={(e) => setLinkEntityKey(Number(e.target.value))}
                                />
                            </Box>
                            <TextField
                                label="Tag Description"
                                variant="outlined"
                                fullWidth
                                value={tagDesc}
                                onChange={(e) => setTagDesc(e.target.value)}
                            />
                            <TextField
                                label="Tag Page"
                                variant="outlined"
                                fullWidth
                                value={tagPage}
                                onChange={(e) => setTagPage(e.target.value)}
                            />
                            <TextField
                                label="Tag Date"
                                variant="outlined"
                                fullWidth
                                type="date"
                                InputLabelProps={{ shrink: true }}
                                value={tagDate}
                                onChange={(e) => setTagDate(e.target.value)}
                            />
                        </Box>
                        {error && (
                            <Typography color="error" variant="body2">
                                {error}
                            </Typography>
                        )}
                    </form>
                </Box>
                <Box display="flex" justifyContent="flex-end" p={2}>
                    <Typography color="error" variant="body2">
                        { pageNum}
                    </Typography>

                    <Button onClick={onClose} color="primary">
                        Cancel
                    </Button>
                    <Button type="submit" form="add-tag-form" color="primary">
                        Submit
                    </Button>
                </Box>
            </AddApexTagComponent>
        </Popper>
    );
};

export default AddApexTag;
