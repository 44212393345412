import React, { useState, useEffect } from 'react';
import { Popper, Paper, Typography, Box, Button, TextField } from '@mui/material';
import Draggable from 'react-draggable';

interface TagRowData {
    tagid: number;
    tag: string;
    onentitytype: string;
    onentitykey?: number;
    docpath?: string;
    docpage?: number;
    docname?: string;
    linkentitytype?: string;
    linkentitykey?: number;
    ecwdoc_id?: number;
    filename?: string;
    patient?: number;
    patid?: number;
    tagpage: number;
    tagdesc?: string;
    tagdate?: string;
    deleteflag?: number;
    created_at?: string;
    updated_at?: string;
    deleted_at?: string;
}

interface EditApexTagProps {
    open: boolean;
    anchorEl: HTMLElement | null;
    onClose: () => void;
    tagData: TagRowData | null;
    onSave: (updatedTag: TagRowData) => void;
    onDelete: (tagId: number) => void;
    pageNum: number | undefined ;
    ecwDocId: number | null | undefined;
}

function DraggablePaperComponent(props: any) {
    return (
        <Draggable handle="#draggable-popper-title">
            <Paper elevation={3} {...props} />
        </Draggable>
    );
}

const EditApexTag: React.FC<EditApexTagProps> = ({ open, anchorEl, onClose, tagData, onSave, onDelete, pageNum, ecwDocId }) => {
    const [formData, setFormData] = useState<TagRowData>({
        tagid: 0,
        tag: '',
        onentitytype: '',
        tagpage: 0
    });

    useEffect(() => {
        setFormData(tagData || {
            tagid: 0,
            tag: '',
            onentitytype: '',
            tagpage: 0
        });
    }, [tagData]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSave = () => {
        onSave(formData);
        onClose();
    };

    const handleDelete = () => {
        if (formData.tagid) {
            onDelete(formData.tagid);
            onClose();
        }
    };

    return (
        <Popper open={open} anchorEl={anchorEl} placement="top" disablePortal style={{ zIndex: 1300 }}>
            <DraggablePaperComponent style={{ width: 850, position: 'fixed', top: 10, left: '20%' }}>
                <Box p={2} display="flex" justifyContent="space-between" alignItems="center" style={{ cursor: 'move' }}>
                    <Typography variant="h6" id="draggable-popper-title">Edit Tag</Typography>
                    <Button size="small" onClick={onClose}>X</Button>
                </Box>
                <Box p={2}>
                    <form id="edit-tag-form" style={{ width: '100%' }}>
                        <Box display="flex" flexDirection="row" flexWrap="wrap" gap={2}>
                            {Object.keys(formData).map((key) => (
                                <Box key={key} flexBasis="calc(33.3333% - 16px)">
                                    <TextField
                                        label={key}
                                        name={key}
                                        variant="outlined"
                                        fullWidth
                                        value={(formData as any)[key] || ''}
                                        onChange={handleChange}
                                        required={key === 'tagid' || key === 'tag' || key === 'onentitytype' || key === 'tagpage'}
                                    />
                                </Box>
                            ))}
                        </Box>
                    </form>
                </Box>
                <Box display="flex" justifyContent="flex-end" p={2}>
                    <Button onClick={onClose} color="primary">Cancel</Button>
                    <Button onClick={handleSave} color="primary">Save</Button>
                    {formData.tagid && <Button onClick={handleDelete} color="secondary">Delete</Button>}
                </Box>
            </DraggablePaperComponent>
        </Popper>
    );
};

export default EditApexTag;
