import React, { Component } from 'react';
import { Box } from '@mui/material';

interface IFrameViewerProps {
    url: string;
}

class IFrameViewer extends Component<IFrameViewerProps> {
    render() {
        const { url } = this.props;

        return (
            <Box
                component="div"
                sx={{
                    width: '100%',
                    height: '100vh',
                    overflow: 'auto',
                }}
            >
                <iframe
                    src={url}
                    title="Iframe Content"
                    style={{ width: '100%', height: '100%' }}
                    frameBorder="0"
                />
            </Box>
        );
    }
}

export default IFrameViewer;
