export default class DataViewManager {

  constructor() {

    this._data = [];
    this._columnInfo = [];
    this._currentSortColumn = "";
    this._currentSortDirection = "";
    this._currentFilterExpression = {};
    this._filteredView = [];

  }

  _filterData() {

    var hideRow = false;
    this._filteredView = [...this._data];

    for (var x = 0; x < this._filteredView.length; x++) {

      const row = this._filteredView[x];
      row._isFiltered = row._isSelected ? false : this.testRow(row, this._currentFilterExpression);

      /*

      if (row._isSelected) {
        row._isFiltered = false;
      }
      else {
        row._isFiltered = this.testRow(row, this._currentFilterExpression)
      }

      hideRow = false;

      const keys = Object.keys(this._currentFilterExpression);

      for (var f = 0; f < keys.length; f++) {

        const key = keys[f];

        const filterExpr = this._currentFilterExpression[key];

        if (!filterExpr || filterExpr == "")
          continue;

        if (typeof filterExpr === 'function') {

          // callback function for filtering. should return
          // true if row should be hidden, or false if row can be
          // shown

          hideRow = filterExpr(row[key]);

        }
        else {

          const targetColumn = this._columnInfo.find(x => x.field == key);
          const filterHint = targetColumn ? targetColumn.filterHint : null;

          switch (filterHint) {

            case 'date':
              if (filterExpr.startDate || filterExpr.endDate) {
                if (row[key] == null) {
                  hideRow = true;
                }
                else {
                  const rowDate = new Date(row[key]);
                  if (filterExpr.startDate && rowDate < new Date(filterExpr.startDate)) {
                    hideRow = true;
                  }
                  if (filterExpr.endDate && rowDate > new Date(filterExpr.endDate)) {
                    hideRow = true;
                  }
                }
              }
              break;

            case 'numeric':

              if (row[key] == null || row[key] != filterExpr) {

                hideRow = true;

              }

              break;

            case 'freetext':

              if (row[key] == null || row[key].toLowerCase().includes(filterExpr.toLowerCase()) == false) {

                // alert("key "+key+", row value : "+newRows[x][key]+", filter exp "+newFilters[key]+", doesn't match");

                hideRow = true;   // don't filter out rows that are selected

              }

              break;

            case null:
            case undefined:
            case 'list':

              if (filterExpr) {

                var valuArray = Array.isArray(filterExpr) ? filterExpr : filterExpr.split(',');

                if (row[key] == null) {

                  if (valuArray.includes("[Empty]") == false) {

                    hideRow = true;

                    break;

                  }

                }
                else {

                  if (valuArray.includes(row[key]) == false) {

                    hideRow = true;
                    break;

                  }

                }

              }

              break;
            default:

              if (filterExpr) {

                // alert('filterhint unknown');

              }
              break;
          }

        }

        if (hideRow)
          break;

      }

      row._isFiltered = hideRow;
      */

    }

    this._filteredView = this._filteredView.filter((x) => !x._isFiltered);

  }

  _sortData() {

    if (this._currentSortColumn == "")
      return;

    const colInfo = this._columnInfo.find((key) => key.field == this._currentSortColumn);
    const sortMode = colInfo && colInfo.sortHint ? colInfo.sortHint : '';

    const arg = colInfo.field;

    switch (sortMode) {
      case 'numeric':
        this._filteredView.sort((a, b) => {
          if (this._currentSortDirection == 'A') {
            if (a[arg] == null) return -1;
            if (b[arg] == null) return 1;

            return a[arg] - b[arg];
          } else {
            // descending
            if (a[arg] == null) return 1;
            if (b[arg] == null) return -1;

            return b[arg] - a[arg];
          }
        });
        break;
      case 'date':
      case 'datetime':
        this._filteredView.sort((a, b) => {
          if (this._currentSortDirection == 'A') {
            if (a[arg] == null) return -1;
            if (b[arg] == null) return 1;

            return new Date(a[arg]) - new Date(b[arg]);
          } else {
            // descending
            if (a[arg] == null) return 1;
            if (b[arg] == null) return -1;

            return new Date(b[arg]) - new Date(a[arg]);
          }
        });
        break;
      default:
        this._filteredView.sort((a, b) => {
          if (this._currentSortDirection == 'A') {
            if (a[arg] == null) return -1;
            if (b[arg] == null) return 1;

            if (a[arg] > b[arg]) return 1;
            if (a[arg] < b[arg]) return -1;

            return 0;
          } else {
            // descending
            if (a[arg] == null) return 1;
            if (b[arg] == null) return -1;

            if (a[arg] > b[arg]) return -1;
            if (a[arg] < b[arg]) return 1;

            return 0;
          }
        });
        break;
    }

  }

  addFilter(column, value) {

    if (value == null || value == "") {
      if (this._currentFilterExpression[column])
        delete this._currentFilterExpression[column]
    }
    else
      this._currentFilterExpression[column] = value;

    // filter here
    this._filterData();

    this._sortData();

  }

  // if selection has changed externally,
  // call this to re-filter

  adjustFilteredView() {
    this._filterData();
  }

  clearFilters() {

    this._currentFilterExpression = {};
    this._filteredView = [...this._data];
    this._sortData();
  }

  getCurrentView() {

    return {
      currentFilters: this._currentFilterExpression,
      currentSortColumn: this._currentSortColumn,
      currentSortDirection: this._currentSortDirection,
      filteredRowCount: this._data.length - this._filteredView.length,
      filteredView: this._filteredView,
      visibleRowCount: this._filteredView.length,
    }

  }

  getRawData() {
    return [...this._data];
  }

  getUniqueValues(columnName) {

    const uniqValues = [];
    this._data.forEach((row) => {
      if (row[columnName] && !uniqValues.includes(row[columnName]))
        uniqValues.push(row[columnName]);
    })

    uniqValues.sort();

    return uniqValues;

  }

  getSelectedAccessions() {
    return this.getSelectedRows().map((x) => x.epicaccn);
  }

  getSelectedRows() {
    return this._data.filter((row) => row._isSelected);
  }

  /**
   * Tests a row against the given filter expression and returns
   *    - TRUE if the row should be hidden (failed filter test and should be filtered out)
   *    - FALSE if the row should be visible
   *
   * Filter fields must match the supplied column definitions for this to work
   *
   * @param {*} row The row to test
   * @param {*} filterExpression filterExpression, a JSON object in the format { column_name : ['filtervalue1', 'filterValue2'], ...}
   * @returns
   */
  testRow(row, filterExpression) {

    var hideRow = false;

    const keys = Object.keys(filterExpression);

    for (var f = 0; f < keys.length; f++) {

      const key = keys[f];

      const filterExpr = filterExpression[key];

      if (!filterExpr || filterExpr == "")
        continue;

      if (typeof filterExpr === 'function') {

        // callback function for filtering. should return
        // true if row should be hidden, or false if row can be
        // shown

        hideRow = filterExpr(key == "*" ? row : row[key]);

      }
      else {

        const targetColumn = this._columnInfo.find(x => x.field == key);
        const filterHint = targetColumn ? targetColumn.filterHint : null;

        switch (filterHint) {

          case 'date':
            if (filterExpr.startDate || filterExpr.endDate) {
              if (row[key] == null) {
                hideRow = true;
              }
              else {
                const rowDate = new Date(row[key]);
                if (filterExpr.startDate && rowDate < new Date(filterExpr.startDate)) {
                  hideRow = true;
                }
                if (filterExpr.endDate && rowDate > new Date(filterExpr.endDate)) {
                  hideRow = true;
                }
              }
            }
            break;

          case 'numeric':

            if (row[key] == null || row[key] != filterExpr) {

              hideRow = true;

            }

            break;

          case 'freetext':

            if (row[key] == null || row[key].toLowerCase().includes(filterExpr.toLowerCase()) == false) {

              // alert("key "+key+", row value : "+newRows[x][key]+", filter exp "+newFilters[key]+", doesn't match");

              hideRow = true;   // don't filter out rows that are selected

            }

            break;

          case null:
          case undefined:
          case 'list':

            if (filterExpr) {

              var valuArray = Array.isArray(filterExpr) ? filterExpr : filterExpr.split(',');

              if (row[key] == null) {

                if (valuArray.includes("[Empty]") == false) {

                  hideRow = true;

                  break;

                }

              }
              else {

                if (valuArray.includes(row[key]) == false) {

                  hideRow = true;
                  break;

                }

              }

            }

            break;
          default:

            if (filterExpr) {

              // alert('filterhint unknown');

            }
            break;
        }

      }

      if (hideRow)
        break;

    }

    return hideRow;

  }

  /**
   * Marks a row as selected and highlighted
   *
   * @param {*} row
   */
  selectRow(row) {
    row._isSelected = row._isHighlighted = true;
  }

  setData(dataArray, columnInfo) {

    this._columnInfo = columnInfo
    this._currentSortColumn = "";
    this._currentSortDirection = "";
    this._currentFilterExpression = {};
    this._data = dataArray;
    this._filteredView = [...dataArray];

  }

  /**
   * Replaces all filters and sets a new one
   *
   * @param {*} column
   * @param {*} value
   */
  setFilter(column, value) {

    this._currentFilterExpression = {};
    this.addFilter(column, value);

  }

  setFilterExpression(expression) {

    if (!expression)
      this._currentFilterExpression = {};
    else
      this._currentFilterExpression = expression;

    // filter here
    this._filterData();

    this._sortData();
  }

  sort(columnName, sortDirection) {

    if (columnName && sortDirection) {
      this._currentSortColumn = columnName;
      this._currentSortDirection = sortDirection;
    }
    else if (this._currentSortColumn == columnName) {
      this._currentSortDirection = this._currentSortDirection == "A" ? "D" : "A";
    }
    else {
      this._currentSortColumn = columnName;
      this._currentSortDirection = "A";
    }

    // sort here
    this._sortData();

  }

  /**
   * Unhighlights all rows that are unselected
   */
  unhighlightRows() {

    if (this._data) {
      this._data.forEach((row) => row._isHighlighted = row._isSelected);
    }

  }

  /**
   * Unselects a selected row, but leaves it highlighted
   * @param {*} row
   */
  unselectRow(row) {
    row._isSelected = false;
  }

}
