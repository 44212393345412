import React, { useState, useEffect } from 'react';
import { Popper, Paper, Typography, Box, Button, TextField } from '@mui/material';
import Draggable from 'react-draggable';

interface RowData {
    [key: string]: any;
    tran_id?: string;
    status?: string;
    arg_op?: string;
    importsource?: string;
    importsourceref?: string;
    importnotes?: string;
    sourceAccount?: string;
    destAccount?: string;
    sourceAccount_id?: number;
    destAccount_id?: number;
    trangrpparent_id?: number;
    trantype?: number;
    trandate?: string;
    tranamount?: number;
    debit_credit?: string;
    description?: string;
    trannotes?: string;
    currencyname?: string;
    reconcilestatus?: string;
    trancompleted?: string;
    ecwpayment_id?: number;
    payer_desc?: string;
    ecwpayer_id?: number;
    vendor_desc?: string;
    transource?: string;
    transource_id?: number;
    fireflyref?: string;
    fireflyreftj_id?: number;
}

interface EditBankTransactionProps {
    open: boolean;
    anchorEl: HTMLElement | null;
    onClose: () => void;
    rowData: RowData | null;
    onSave: (updatedRow: RowData) => void;
    onDelete: (rowId: string) => void;
}

function DraggablePaperComponent(props: any) {
    return (
        <Draggable handle="#draggable-popper-title">
            <Paper elevation={3} {...props} />
        </Draggable>
    );
}

const EditBankTransaction: React.FC<EditBankTransactionProps> = ({ open, anchorEl, onClose, rowData, onSave, onDelete }) => {
    const [formData, setFormData] = useState<RowData>(rowData || {});

    useEffect(() => {
        setFormData(rowData || {});
    }, [rowData]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSave = () => {
        onSave(formData);
        onClose();
    };

    const handleDelete = () => {
        if (formData.tran_id) {
            onDelete(formData.tran_id);
            onClose();
        }
    };

    return (
        <Popper open={open} anchorEl={anchorEl} placement="top" disablePortal style={{ zIndex: 1300 }}>
            <DraggablePaperComponent style={{ width: '75%', position: 'fixed', top: '25%', left: '20%', transform: 'translate(-50%, -50%)' }}>
                <Box p={2} display="flex" justifyContent="space-between" alignItems="center" style={{ cursor: 'move' }}>
                    <Typography variant="h6" id="draggable-popper-title">Edit Bank Transaction</Typography>
                    <Button size="small" onClick={onClose}>X</Button>
                </Box>
                <Box p={2}>
                    <form id="edit-bank-transaction-form" style={{ width: '100%' }}>
                        <Box display="flex" flexDirection="row" flexWrap="wrap" gap={2}>
                            {rowData && Object.keys(rowData).map((key) => (
                                <Box key={key} flexBasis="calc(33.3333% - 16px)">
                                    <TextField
                                        label={key}
                                        name={key}
                                        variant="outlined"
                                        fullWidth
                                        value={formData[key] || ''}
                                        onChange={handleChange}
                                        required
                                    />
                                </Box>
                            ))}
                        </Box>
                    </form>
                </Box>
                <Box display="flex" justifyContent="flex-end" p={2}>
                    <Button onClick={onClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSave} color="primary">
                        Save
                    </Button>
                    <Button onClick={handleDelete} color="secondary">
                        Delete
                    </Button>
                </Box>
            </DraggablePaperComponent>
        </Popper>
    );
};

export default EditBankTransaction;
