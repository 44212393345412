// DocumentContext.js
import React, { useState, useContext } from 'react';

const DocumentContext = React.createContext();

const DocumentProvider = ({ children }) => {
  const [docinfo, setDocinfo] = useState({
    ecwdocid : null,
    apexdocid : null,
    documentpath : null,
    documentpage : null,
    pagecount : 1
  });

  const updateDocinfo = (newDocinfo) => {
    setDocinfo(newDocinfo);
  };

  const updateDocPageinfo = (documentpage) => {
    setDocinfo({...docinfo, documentpage: documentpage});
  };

  return (
    <DocumentContext.Provider value={{ docinfo, updateDocinfo, updateDocPageinfo }}>
      {children}
    </DocumentContext.Provider>
  );
};

const useDocument = () => {
  const context = useContext(DocumentContext);
  if (!context) {
    throw new Error('useDocument must be used within a DocumentProvider');
  }
  return context;
};

export { DocumentProvider, useDocument };
