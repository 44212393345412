import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import {
  Box,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ApexPdfjsViewer from "../Components/ApexPdfjsViewer";

import CreateTask from "../Modals/CreateTask";
import { AttachDocument } from "../Components/AttachDocument";
import Draggable from "react-draggable";
import apiConfig from "../apiConfig";

import {
  addSubTasks,
  addTag,
  deleteSubTask,
  deleteTag,
  deleteTasks,
  getTagsList,
  getTasks,
  updateSubtasksList,
  updateTasks,
} from "../Redux/AppReducer/action";
import { UPDATE_TASK_SUCCESS } from "../Redux/AppReducer/actionTypes";


const date = new Date().toLocaleDateString();

const DocumentViewerpage = () => {

  let apiURL = apiConfig.REACT_APEX_BASE_API;

  let data = {
    fileName: '',
    fileData: null
  }


  const [documentURL, setdocumentURL] = useState('')
  const [startingPageNum, setstartingPageNum] = useState(1)
  const [pdfToDisplayURL, setpdfToDisplayURL] = useState('')
  const [currentPageNum, setCurrentPageNum] = useState(1);


  const [rowDocsData, setRowDocsData] = useState();

  const pageParams = useParams(); // props.match.params.docpath;

  const filePath = pageParams.docpath;
  const pageNum = pageParams.pagenum;
  console.log("Location filepath:", filePath);
  useEffect(() => {
    console.log("Detected change of Location filepath:", filePath, pageNum);

    retrieveDocument(filePath);
    setstartingPageNum(pageNum);

  },
    [filePath, pageNum]);

  const retrieveDocument = (docURL) => {


    console.log("Received request to retrieve:", docURL);
    if (docURL) {

      if (docURL.includes('pat-')) {
        let patid = docURL.substring(4);

        //docURL = `${apiURL}/getecwstatement?patid=${patid}&incqrcode=portal`;
        docURL = `${apiURL}/getecwstatement?patid=${patid}&incqrcode=portal&startdos=2016-01-01`;
        console.log("Derived URL:", docURL);
      } else {
        docURL = docURL.replace('/', '\\');
        docURL = `${apiURL}/downloadFile/${docURL.replaceAll("\\", "~").replaceAll("/", "~")}`;
        console.log("Derived URL:", docURL);
      }
    }

    setRowDocsData({
      fileName: docURL,
      fileData: null
    });
    console.log('Starting to get File', docURL);

    fetch(
      encodeURI(docURL), {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    }
    )
      // .then(this.handleErrors)
      .then((r) => r.blob())
      .then((blob) => {
        console.log("Received Document Blob :", blob);

        let url = URL.createObjectURL(blob);
        console.log("Made Blob url Document Blob :", url);
        setpdfToDisplayURL(url);
        // pdfViewerRef.current.UI.loadDocument(url, {
        //   extension: 'pdf'
        // });

      });

  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleAgDocument = (data) => {

    console.log("DOC Viewer received uploaded file data: ", data);
    setRowDocsData(data);
    const reader = new FileReader();

    reader.addEventListener(
      "load",
      () => {
        // convert image file to base64 string
        //preview.src = reader.result;

        console.log("WILL Display url:", reader.result);
        setpdfToDisplayURL(reader.result);


      },
      false
    );

    if (data.fileData) {
      reader.readAsDataURL(data.fileData);
      data = {
        fileName: data.name,
        fileData: data.fileData
      }
    }
  }

  const onSetCurrentPageNum = pageNum => {
    setCurrentPageNum(pageNum);
    console.log("Received in DocuMgr page num = ", pageNum);
  }
  console.log("DocumentViewer Current page:", currentPageNum);

  return (

    <>
      <Box flex='1' bg='green.500' >
        <ApexPdfjsViewer pdfUrl={pdfToDisplayURL} startingPageNum={startingPageNum} setCurrentPageNum={onSetCurrentPageNum} onOpenFile={handleAgDocument} />
      </Box>
      <Box width='400px' bg='green.500'>
      </Box>
    </>
  );
};

export default DocumentViewerpage;
