import { createSlice, createAsyncThunk, createEntityAdapter} from '@reduxjs/toolkit'
import { getLocalData, saveLocalData } from "../../utils/localStorage";
import axios from "axios";
import apiConfig from "../../apiConfig";

const tasksAdapter = createEntityAdapter();

const initialState = tasksAdapter.getInitialState({
    tasks: [],
    tags: [],
    isLoading: false,
    isError: false,
  });

//  THUNKS
let authToken = getLocalData("token") ;
const Headers = {
  'Content-Type': `application/json`,
  "Access-Control-Allow-Origin": "*",
  'Authorization': `Bearer ${authToken ? authToken : "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c"}`,
};

let apiURL=apiConfig.REACT_APP_BASE_API;
export const getTasks = createAsyncThunk ('task/getTasks', async () => {
  return axios
    .get(`${apiURL}/tasks`)
    .then((r) => {
      return  r.data ;
    });
});

export const createTask = createAsyncThunk ('task/createTask', async (payload) => {
    return axios
      .post(`${apiURL}/tasks`, payload)
      .then((r) => {
        return r; 
      })
});

export const updateTasks = createAsyncThunk ('task/updateTasks', async (payload) => {
    return axios
      .patch(`${apiURL}/tasks/${payload.id}`, payload, { headers: {
        "Access-Control-Allow-Origin": "*",
      },
    })
    .then((r) => {
        return r.data;
      })
});

export const deleteTasks = createAsyncThunk ('task/deleteTasks', async (id) => {
    return axios.delete(`${apiURL}/tasks/${id}`).then(() => {
        return;
      })
});
  
export const updateSubtasksList = createAsyncThunk ('task/updateSubtasksList', async (payload) => {
    return axios
      .patch(`${apiURL}/tasks/${payload.id}`, payload, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((r) => {
        return r
       } )
});

export const addSubTasks = createAsyncThunk ('task/addSubTasks', async (payload) => {
    return axios
      .patch(`${apiURL}/tasks/${payload.id}`, payload)
      .then((r) => {
        return r ;
      })
});
  
export const deleteSubTask = createAsyncThunk ('task/deleteSubTask', async (payload) => {
    return axios
      .patch(`${apiURL}/tasks/${payload.id}`, payload)
      .then((r) => {
        return r;
      })
});
    
export const addTag = createAsyncThunk ('task/addTag', async (tag) => {
    return axios
    .post(`${apiURL}/tagList`, { tag })
    .then((r) => {
      return r.data ;
    })
});

export const deleteTag = createAsyncThunk ('task/deleteTag', async (id) => {
    return axios.delete(`${apiURL}/tagList/${id}`).then(() => {
        return;
      })
});

export const getTagsList = createAsyncThunk ('task/getTagsList', async () => {
    return axios
      .get(`${apiURL}/tagList`)
      .then((r) => {
        return r.data;
      })
});
  
const tasksSlice = createSlice({
  name: 'task',
  initialState,
  reducers: {
    GET_TASKS_REQUEST(state, action) {
      state.isLoading = true; 
    },
    GET_TASKS_SUCCESS(state, action) {
      state.isLoading =  false;
      state.tasks = action.payload; 
    },
    GET_TASKS_FAILURE(state, action) {
        state.isLoading =  false;
        state.isError = true;
    },
    GET_TAG_REQUEST(state, action) {
        return { ...state };
    },
    GET_TAG_SUCCESS(state, action) {
        return { ...state, tags: action.payload };
    },
    GET_TAG_FAILURE(state, action) {
        return { ...state };
    },    
  },
  extraReducers: builder => {
    builder
      .addCase(getTasks.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(getTasks.fulfilled, (state, action) => {
        state.tasks = action.payload;
        state.status = 'idle'
      })
      .addCase(createTask.pending, (state, action) => {
      })
      .addCase(createTask.fulfilled, (state, action) => {
      })
  }
})

export const { GET_TASKS_REQUEST, GET_TASKS_SUCCESS, GET_TASKS_FAILURE,  GET_TAG_REQUEST, GET_TAG_SUCCESS, GET_TAG_FAILURE} = tasksSlice.actions

export default tasksSlice.reducer