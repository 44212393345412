import { Box, Heading, HStack, Text, useDisclosure } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import {
  useColorMode,
  Switch,
  Flex,
  Spacer,
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Avatar,
  Tooltip ,
  Icon ,
  Divider
} from '@chakra-ui/react'

import { MdHelpOutline as HelpIcon,
    MdDocumentScanner as DocumentsIcon ,
    MdAccountBalance,
    MdPendingActions,
    MdAddTask,
    MdAttachFile,
    MdDashboard,
    MdOutlinePriceCheck,
    MdDatasetLinked,
    MdCalculate,
    MdOutlinePaid,
    MdOutlineQueue,
    MdOutlineFileOpen,
    MdSpatialAudio
} from 'react-icons/md';


import {  
    EmailIcon,
    SearchIcon,
    SettingsIcon,
    QuestionIcon,
    PhoneIcon, 
    AddIcon, 
    WarningIcon ,
    ViewIcon,
    Search2Icon
} from '@chakra-ui/icons'
import { Link } from '@chakra-ui/react'
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getTagsList } from "../Redux/ReduxSlices/AppReducerSlice";
import { UserProvider, useUserContext } from '../Contexts/UserContext';
import Profile from "./Profile";
import CreateTask from "../Modals/CreateTask";

const Menubar = (props) => {
  const dispatch = useDispatch();
  const { setUser, isLoggedIn, loginUserName, loginUserEmail, logout } = useUserContext();

    const tagLists = useSelector((state) => state.task.tags);
  const tasks = useSelector((state) => state.task.tasks);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedTags, setSelectedTags] = useState(
    searchParams.getAll("tags") || []
  );
  const navigate = useNavigate();

  const { colorMode, toggleColorMode } = useColorMode()
  const isDark = colorMode === 'dark'
  const [display, changeDisplay] = useState('none')

  const {
    currentState,
    currentUser,
    callbackFunction,
    externalCommand,
    localViewer,
    lookupHistory,
    onSearch,
    openFileFunction,
    lastWarningID,    // last warning that i have seen
    warningInfo } = props;

  // const logoutHandler = () => {
  //   new Promise((res, rej) => {
  //     res(localStorage.removeItem("token"));
  //   }).then(() => {
  //     navigate("/login");
  //   });
  // };
  const logoutHandler = () => {
    new Promise((res, rej) => {
        logout();
      res(localStorage.removeItem("token"));
      res(localStorage.removeItem("userCredentials"));
    }).then(() => {
      navigate("/login");
    });
  };

  const handleTagChange = (value) => {
    //if the tag is selected remove it, else add it.
    let newTags = [...selectedTags];
    if (selectedTags.includes(value)) {
      newTags.splice(newTags.indexOf(value), 1);
    } else {
      newTags.push(value);
    }
    setSelectedTags(newTags);
    setSearchParams({ tags: newTags });
  };

  const handleLogout = () => {}

  useEffect(() => {
    if (tagLists.length === 0) {
      dispatch(getTagsList());
    }
  }, [dispatch, tagLists.length]);

  const onShowHelp = function () {
    window.open("documentation/index.html", "blank");
  }

    const onShowClickUp = function () {
        window.open("https://app.clickup.com/9017191238/v/l/6-901702458046-1", "tasksTab");
    }


    const onFacesheetsEnter = function () {
        window.open("https://app.apexheartcare.com:8044/facesheets_entry", "fsEntryTab");
    }

    const onFacesheetsNeed = function () {
        window.open("https://app.apexheartcare.com:8044/facesheets_need", "blank");
    }

  const onShowProjects = function () {
    window.open("https://app.apexheartcare.com", "opTab");
  }

    const onShowApexEcw = function () {
        window.open("https://emr.apexheartcare.com/mobiledoc/jsp/webemr/login/newLogin.jsp", "ecwTab");
    }

  const onGoHome = function () {
    navigate("/");
  }

  let clickUpURL= "https://app.clickup.com/9017191238/v/l/6-901702458046-1"
  const userEmail = loginUserEmail();
  console.log("In menu component", userEmail);
  return (
    <Flex as="nav" bg="gray.800" p="10px" alignItems="center">

        <Text as="a" bg="red.300" colorScheme="purple"
            onClick={() => { onGoHome(); }}>Apex</Text>
        <Spacer />
        <HStack spacing="20px" >

            {/*<Tooltip label="Claim Payments" >*/}
            {/*    <IconButton*/}
            {/*        variant='outline'*/}
            {/*        colorScheme='teal'*/}
            {/*        aria-label='Claim Payments'*/}
            {/*        icon={ <Icon as={MdOutlineQueue} /> }*/}
            {/*        onClick={() => navigate("/claimpayments")}*/}
            {/*    />*/}
            {/*</Tooltip>*/}

        {/*<Tooltip label="Claims Queue" >*/}
        {/*        <IconButton  */}
        {/*          variant='outline'*/}
        {/*          colorScheme='teal'*/}
        {/*          aria-label='Claims Queue'*/}
        {/*          icon={ <Icon as={MdOutlineQueue} /> }*/}
        {/*          onClick={() => navigate("/claimsqueue")} */}
        {/*        />*/}
        {/*    </Tooltip>*/}

            {/*<Tooltip label="Claims Review Page" >*/}
            {/*    <IconButton*/}
            {/*        variant='outline'*/}
            {/*        colorScheme='teal'*/}
            {/*        aria-label='Claims Review Page'*/}
            {/*        icon={ <Icon as={MdOutlineQueue} /> }*/}
            {/*        onClick={() => navigate("/claimrqueuespage")}*/}
            {/*    />*/}
            {/*</Tooltip>*/}

                <Box display="flex" flexDirection="column" alignItems="center" onClick={() => navigate("/claimrqueuespage")} cursor="pointer">
                    <IconButton
                        variant='outline'
                        colorScheme='teal'
                        aria-label='Claims Dashboard'
                        icon={ <Icon as={MdOutlineQueue} /> }
                    />
                    <Text mt={2} color="white">Claims Dashboard</Text>
                </Box>

                {/*<Box display="flex" flexDirection="column" alignItems="center" onClick={() => navigate("/hospclaims")} cursor="pointer">*/}
                {/*    <IconButton*/}
                {/*        variant='outline'*/}
                {/*        colorScheme='teal'*/}
                {/*        aria-label='Claims'*/}
                {/*        icon={ <Icon as={MdDatasetLinked} /> }*/}
                {/*        onClick={() => navigate("/hospclaims")}*/}
                {/*    />*/}
                {/*    <Text mt={2} color="white">To eCW</Text>*/}
                {/*</Box>*/}

                <Box display="flex" flexDirection="column" alignItems="center" onClick={() => navigate("/hospsheets")} cursor="pointer">
                    <IconButton
                        variant='outline'
                        colorScheme='teal'
                        size={'md'}
                        aria-label='Hospital Sheets'
                        icon={ <Icon as={ MdOutlineFileOpen } /> }
                        onClick={() => navigate("/hospsheets")}
                    />
                    <Text mt={2} color="white">Hosp Entry</Text>
                </Box>

                <Box display="flex" flexDirection="column" alignItems="center" onClick={() => navigate("/hospsheetsreview")} cursor="pointer">
                    <IconButton
                        variant='outline'
                        colorScheme='teal'
                        size={'md'}
                        aria-label='Reivew Hosp'
                        icon={ <Icon as={ MdOutlineFileOpen } /> }
                        onClick={() => navigate("/hospsheetsreview")}
                    />
                    <Text mt={2} color="white">Review Claims</Text>
                </Box>


            <Box display="flex" flexDirection="column" alignItems="center" onClick={() => navigate("/account")} cursor="pointer">
                <IconButton
                    variant='outline'
                    colorScheme='teal'
                    aria-label='Patient Documents'
                    icon={ <Icon as={MdAccountBalance} /> }
                    onClick={() => navigate("/account")}
                />
                <Text mt={2} color="white"> Documents</Text>
            </Box>

            <Box display="flex" flexDirection="column" alignItems="center" onClick={() => navigate("/documgr/attach")} cursor="pointer">
                <IconButton
                    variant='outline'
                    colorScheme='teal'
                    aria-label='Attach Documents'
                    icon={ <Icon as={MdAttachFile} /> }
                    onClick={() => navigate("/documgr/attach")}
                />
                <Text mt={2} color="white">Attach Docs</Text>
            </Box>

            <Box display="flex" flexDirection="column" alignItems="center" onClick={() => navigate("/echos")} cursor="pointer">
                <IconButton
                    variant='outline'
                    colorScheme='teal'
                    aria-label='Echos'
                    icon={ <Icon as={MdSpatialAudio} /> }
                    onClick={() => navigate("/echos")}
                />
                <Text mt={2} color="white">Echos</Text>
            </Box>



            {/*<Box display="flex" flexDirection="column" alignItems="center" onClick={() => navigate("/deposits")} cursor="pointer">*/}
            {/*    <IconButton*/}
            {/*        variant='outline'*/}
            {/*        colorScheme='teal'*/}
            {/*        aria-label='Deposits'*/}
            {/*        icon={ <Icon as={MdOutlinePriceCheck} /> }*/}
            {/*        onClick={() => navigate("/deposits")}*/}
            {/*    />*/}
            {/*    <Text mt={2} color="white">Deposits</Text>*/}
            {/*</Box>*/}

        {/* <Tooltip label="Finance" >
            <IconButton  
              variant='outline'
              colorScheme='teal'
              aria-label='Finance'
              icon={ <Icon as={MdOutlinePaid} /> }
              onClick={() => navigate("/perfin")} 
            />
        </Tooltip> */}




            {/*<Tooltip label="Actions" >*/}
            {/*    <IconButton  */}
            {/*      variant='outline'*/}
            {/*      colorScheme='teal'*/}
            {/*      aria-label='Action List'*/}
            {/*      icon={ <Icon as={MdDashboard} /> }*/}
            {/*      onClick={() => navigate("/action")} */}
            {/*    />*/}
            {/*</Tooltip>*/}


            {/*<Tooltip label="DocActions" >*/}
            {/*    <IconButton  */}
            {/*      variant='outline'*/}
            {/*      colorScheme='teal'*/}
            {/*      aria-label='Create Actions'*/}
            {/*      icon={ <Icon as={MdAddTask} /> }*/}
            {/*      onClick={() => navigate("/documgr/action")}  */}
            {/*    />*/}
            {/*</Tooltip>*/}


            {/* <Tooltip label="Settings" >
                <IconButton  
                  variant='outline'
                  colorScheme='teal'
                  aria-label='Send email'
                  icon={<SettingsIcon />}
                  onClick={() => callbackFunction('settings')} 
                />
            </Tooltip> */}

            {/*{isLoggedIn() ? (*/}
            {/*    <>*/}
            {/*        {userEmail && userEmail.includes('korlakunta') &&*/}
            {/*            <Tooltip label="Projects" >*/}
            {/*            <IconButton*/}
            {/*                variant='outline'*/}
            {/*                colorScheme='teal'*/}
            {/*                aria-label='Action List'*/}
            {/*                icon={ <Icon as={MdPendingActions} /> }*/}
            {/*                onClick={() => onShowProjects()}*/}
            {/*            />*/}
            {/*        </Tooltip>}*/}
            {/*    </>*/}
            {/*) : (*/}
            {/*    <div>User is not logged in</div>*/}
            {/*)}*/}


            {/* <Tooltip label="Help" >
                <IconButton  
                  variant='outline'
                  colorScheme='teal'
                  aria-label='Send email'
                  icon={ <Icon as={HelpIcon} /> }
                  onClick={() => onShowHelp()}
                />
            </Tooltip> */}

            <Menu placement="bottom-end" >
            <Tooltip label={`logged in as ${userEmail}`} aria-label='A tooltip'>
                <MenuButton>
                     <Avatar name={userEmail} src={""} />
                </MenuButton>
                </Tooltip>
                <MenuList zIndex="100">
                    <MenuItem>
                        <Link to="/profile">Profile</Link>
                    </MenuItem>
                    {isLoggedIn() ? (
                        <>
                            {userEmail && userEmail.includes('korlakunta') &&
                                <>
                                    <Divider/>

                                    <MenuItem
                                    icon={<Icon as={HelpIcon}/>}
                                    onClick={() => {
                                        onShowClickUp();
                                    }}>Tasks
                                    </MenuItem>
                                    <MenuItem
                                        icon={<Icon as={HelpIcon}/>}
                                        onClick={() => navigate("/hospclaims")}>Claims To eCW
                                    </MenuItem>
                                    <MenuItem
                                        icon={<Icon as={HelpIcon}/>}
                                        onClick={() => navigate("/scannedpayments")}>Scanned EOB
                                    </MenuItem>
                                    <MenuItem
                                        icon={<Icon as={HelpIcon}/>}
                                        onClick={() => navigate("/deposits")}>Deposits
                                    </MenuItem>
                                    <MenuItem
                                        icon={<Icon as={HelpIcon}/>}
                                        onClick={() => navigate("/paymentspage")}>Payments
                                    </MenuItem>
                                    <MenuItem
                                        icon={<Icon as={HelpIcon}/>}
                                        onClick={() => {
                                            onShowProjects();
                                        }}>Projects
                                    </MenuItem>

                                    <Divider/>
                                </>}
                        </>
                    ) : (
                        <div>User is not logged in</div>
                    )}

                    <MenuItem
                        icon={ <Icon as={ViewIcon} /> }
                        onClick={() => { onShowApexEcw(); }}>ApexEcw
                    </MenuItem>
                    <Divider />
                    <MenuItem 
                        icon={ <Icon as={ViewIcon} /> }
                        onClick={() => { onFacesheetsEnter(); }}>Facesheets
                    </MenuItem>
                    <MenuItem
                        icon={ <Icon as={Search2Icon} /> }
                        onClick={() => { onFacesheetsNeed(); }}>Lookup Pts
                    </MenuItem>
                    <Divider />
                    <MenuItem 
                        icon={ <Icon as={HelpIcon} /> } 
                        onClick={() => { onShowHelp(); }}>Help
                        </MenuItem>
                    <Divider />
                    <MenuItem onClick={logoutHandler}>Logout</MenuItem>
                </MenuList>
            </Menu>

          
        </HStack>
    </Flex>
  );
};

export default Menubar;
