import React, { useState } from 'react';

const CPTdropDownRenderer = ({ value, data, colDef, onCellValueChanged }) => {
  const [selectedValue, setSelectedValue] = useState(value);

  const options = colDef.dropdownOptions;

  const handleDropdownChange = (e) => {
    setSelectedValue(e.target.value);
    if (onCellValueChanged) {
      onCellValueChanged({ ...data, [colDef.field]: e.target.value });
    }
  };

  return (
    <select value={selectedValue} onChange={handleDropdownChange}>
      {options.map((option, index) => (
        <option key={index} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
};

export default CPTdropDownRenderer;
