import React, { useState, useEffect } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import { Button, Input,Flex, HStack, VStack, Tabs, TabList, Tab,  TabPanels, TabPanel, Text, Box, Checkbox} from '@chakra-ui/react';

import apiConfig from '../apiConfig';
import axios from "axios";
import ApexPdfjsViewer from '../Components/ApexPdfjsViewer';
import EcwPatientEncounter from '../Modals/SelectEcwPatientEncounter';
import { useDocument } from '../Contexts/DocumentContext';
import ManageBillingTempClaimCpts from '../Features/billing/ManageBillingTempClaimCpts';
import { handleEcwDocument, handleFolderDocument, handleAgDocument } from '../utils/ecw/hadleDocuments';
import CheckboxRenderer from '../Components/CheckboxRenderer';
import EditClaimDx from './EditClaimDx';
import PatientBrowser from './PatientBrowser';
import SplitPanel from '../Components/splitpage/splitpanel';
import PatientManagementComponent from '../Modals/PatientManagementComponent';


const TempClaimsViwer = ({ initialRowData }) => {

    let apiURL = apiConfig.REACT_APEX_BASE_API;
    const { documentContext, updateDocinfo, updateDocPageinfo } = useDocument();
    //const removeGridRef = React.useRef();
    const claimsGridRef = React.useRef();
    const claimsCPTGridRef = React.useRef();
    const initFocusRef = React.useRef();

    const [rowData, setRowData] = useState(initialRowData);
    const [claimsRowData, setClaimsRowData] = useState(initialRowData);

    const [filteredClaims, setFilteredClaims] = useState(initialRowData);

    const [quickFilter, setQuickFilter] = useState('');

    const [selectedPatientId, setSelectedPatientId] = useState();

    const [docToDisplay, setDocToDisplay] = useState();
    const [startingPageNum, setstartingPageNum] = useState(1)
    const [pdfToDisplayURL, setpdfToDisplayURL] = useState('')
    const [currentPageNum, setCurrentPageNum] = useState(1);
    const [currentClaimID, setCurrentClaimId] = useState();
    const[claimDocumentLinks, setClaimDocumentLinks] = useState();
    const [claimCptRowdata, setClaimCptRowdata] = useState();
    const [showEncounters, setShowEncounters] = useState();
    const [showOOVRReady, setShowOOVReady] = useState();

    //{ headerName: 'Enc', field: 'apex_encounter_id', sortable: true, filter: true ,width: 100},
    //{ headerName: 'L', field: 'claim_locked', sortable: true, filter: true ,width: 80, 'cellRender' : CheckboxRenderer},

    const claimRowIndex = (params) => {
        //console.log("ROw index", params.node);
        return params.node.rowIndex + 1;
    };

    const claimColumnDefs = [

        {
            headerName: 'Nr',
            field : "i",
            width : 70,
            lockPosition: true,
            valueGetter: claimRowIndex,
        },
        { headerName: 'TCL', field: 'tclaim_id', sortable: true, filter: true ,width: 100},
        { headerName: 'dch', field: 'discharge_date', sortable: true, filter: true , width: 80},
        { headerName: 'C', field: 'cptcount', sortable: true, filter: true ,width: 80,},
        { headerName: 'Ins', field: 'patins', tooltipField: 'patins', sortable: true, filter: true ,width: 80},
        { headerName: 'PatID', field: 'patientid', sortable: true, filter: true ,width: 90},
        { headerName: 'Apex Patient', field: 'apex_patient', sortable: true, filter: true,width: 150 },
        { headerName: 'Facility', field: 'facility', sortable: true, filter: true,width: 90 },
        { headerName: 'Claim DOS', field: 'claim_dos', sortable: true, filter: true,width: 130 },
        { headerName: 'Claim DX', field: 'claim_dx', sortable: true, filter: true ,width: 130},

        { headerName: 'TCPT', field: 'claim_cpt_info', sortable: true, filter: true },
        { headerName: 'Status', field: 'claim_status', sortable: true, filter: true , width: 100},
        { headerName: 'Ready', field: 'claim_ready_for_oov', sortable: true, filter: true ,width: 80},
        // Add more columns as needed
    ];

    const claimCPTColumnDefs = [
        { headerName: 'Ready', field: 'cpt_status', width: 90, sortable: true,   editable: false},
        { headerName: 'tcpt_id', field: 'tcpt_id', width: 90, sortable: true,  },
        { headerName: 'facility', field: 'facility', width: 80, sortable: true,},
        { headerName: 'sdos', field: 'sdos' ,width: 130, sortable: true,},
        { headerName: 'cptcode', field: 'cptcode',width: 80, sortable: true, },
        { headerName: 'cptdesc', field: 'cptdesc'  ,width: 150,},
        { headerName: 'modifiers', field: 'modifiers',width: 80, sortable: true, },
        { headerName: 'tclaim_id', field: 'tclaim_id',width: 90, sortable: true, },
        { headerName: 'patientId', field: 'patientId',width: 90, sortable: true, },
        { headerName: 'cpt_dx', field: 'cpt_dx' },

        { headerName: 'apex_patient', field: 'apex_patient',width: 200, sortable: true,  },

        { headerName: 'claim_dx', field: 'claim_dx' },
        { headerName: 'new_hosp_patient', field: 'new_hosp_patient' ,width: 200, sortable: true, },
    ];


    const columnDefs = [
        { headerName: 'Ready', field: 'cpt_status', width: 90, sortable: true,   editable: false},
        { headerName: 'tcpt_id', field: 'tcpt_id', width: 90, sortable: true,  },
        { headerName: 'tclaim_id', field: 'tclaim_id',width: 90, sortable: true, },
        { headerName: 'patientId', field: 'patientId',width: 90, sortable: true, },
        { headerName: 'facility', field: 'facility', width: 80, sortable: true,},
        { headerName: 'sdos', field: 'sdos' ,width: 130, sortable: true,},
        { headerName: 'cptcode', field: 'cptcode',width: 80, sortable: true, },
        { headerName: 'modifiers', field: 'modifiers',width: 80, sortable: true, },
        { headerName: 'apex_patient', field: 'apex_patient',width: 200, sortable: true,  },
        { headerName: 'cptdesc', field: 'cptdesc' },
        { headerName: 'cpt_dx', field: 'cpt_dx' },
        { headerName: 'claim_dx', field: 'claim_dx' },
        { headerName: 'new_hosp_patient', field: 'new_hosp_patient' ,width: 200, sortable: true, },
    ];




    const getClaimDocumentLinks = async (claimid)  =>{

        console.log("Will get document reference links for claim :", claimid);

        let authHeader = {};

        const authH = JSON.parse(localStorage.getItem('authHeader'));
        if (authH) {
            authHeader =  authH;
        }


        let urlPrefix = apiURL + '/exsql?dbserver=';
        let dataURL = `apex&sqltype=customSQL&sqltext=select docpagenum, document_path, min(entity_type) as entity_type from rc.billing_doc_links  where tclaim_id = ${claimid}  group by document_path, docpagenum   `;


        console.log("SQL to execute : ", dataURL)
        const res2 = await axios.get(urlPrefix + dataURL, authHeader);
        if (res2.data) {
            console.log("GOT result from add document refs lookup:", res2.data);
            let dframe = res2.data['frame0'];
            //console.log(dframe);
            if (dframe) {
                let myObj = JSON.parse(dframe);
                console.log(myObj);
                let gridData = myObj['rows'];
                if (gridData) {
                    setClaimDocumentLinks(gridData);
                }
                console.log("Claim Document Links Grid", gridData);
            }
        }


    }


    const refreshCptData = async (claimid) => {
        let authHeader = {};

        const authH = JSON.parse(localStorage.getItem('authHeader'));
        if (authH) {
            authHeader =  authH;
        }

        if (!claimid) return;

        let urlPrefix = apiURL + '/exsql?dbserver=';
        let dataURL = `apex&sqltype=customSQL&sqltext=set rowcount 0  select * from rc.billing_temp_claims tcpt
    where tclaim_id = ${claimid}
    order by tcpt.claim_dos ,  tcpt.tcpt_id  `;

        console.log("Exec query:", dataURL);
        const res2 = await axios.get(urlPrefix + dataURL, authHeader);
        if (res2.data) {
            console.log("GOT CPT data for Claim:", res2.data);

            try {
                let dframe = res2.data['frame0'];
                //console.log(dframe);
                let myObj = JSON.parse(dframe);
                console.log(myObj);
                let gridData = myObj['rows'];

                setClaimCptRowdata(gridData);
            }
            catch(err) {
                console.log("Error:", err);
            }


        }

    };

    const refreshClaimsData = async () => {
        let authHeader = {};

        const authH = JSON.parse(localStorage.getItem('authHeader'));
        if (authH) {
            authHeader =  authH;
        }

        let urlPrefix = apiURL + '/exsql?dbserver=';
        let dataURL = `apex&sqltype=customSQL&sqltext=set rowcount 0  select distinct discharge_date, tclaim_id, patientid, apex_patient, facility,  claim_dx, claim_dos, claim_status , apex.dbo.TClaimCptInfo(tclaim_id) as claim_cpt_info  ,
	  isnull(tcpt.apex_encounter_id,0) as apex_encounter_id, (select count(*) from rc.billing_tcpt tcpt2 where tcpt2.tclaim_id = tcpt.tclaim_id) as cptcount, isnull(tcpt.claim_locked, 0) as claim_locked, isnull(tcpt.claim_ready_for_oov, 0) as claim_ready_for_oov, tcpt.claim_modifiers_checked, tcpt.claim_icds_checked, tcpt.claim_cpt_checked,
	  isnull(apex.dbo.PatientInsurance(patientid),'') as patins
      from rc.billing_temp_claims tcpt
      where  1 = 1  and isnull(apex_encounter_id, 0) = 0 order by claim_dos ,  apex_patient   `;

        const res2 = await axios.get(urlPrefix + dataURL, authHeader);
        if (res2.data) {
            console.log("GOT CPT Info :", res2.data);
            let dframe = res2.data['frame0'];
            //console.log(dframe);
            let myObj = JSON.parse(dframe);
            console.log(myObj);
            let gridData = myObj['rows'];
            console.log("TEMP Claims to display", gridData);
            setClaimsRowData(gridData);
        }

    };


    const refreshData = async () => {
        let authHeader = {};

        const authH = JSON.parse(localStorage.getItem('authHeader'));
        if (authH) {
            authHeader =  authH;
        }

        // --where  tcpt.cptcode not in('93272', '93228')
        let urlPrefix = apiURL + '/exsql?dbserver=';
        let dataURL = `apex&sqltype=customSQL&sqltext=set rowcount 0  select * from rc.billing_temp_claims tcpt
        where 1 = 1 and isnull(apex_encounter_id, 0) = 0
        order by tcpt.claim_dos ,  tcpt.tcpt_id  `;

        console.log("Executing SQL:", dataURL);
        const res2 = await axios.get(urlPrefix + dataURL, authHeader);
        if (res2.data) {
            console.log("GOT Billing Files:", res2.data);
            try {
                let dframe = res2.data['frame0'];
                console.log(dframe);
                let myObj = JSON.parse(dframe);
                console.log(myObj);
                let gridData = myObj['rows'];

                setRowData(gridData);
                setFilteredClaims(getFilteredClaims(gridData, showEncounters, showOOVRReady));
            }
            catch(err) {
                console.log("Error:", err);
            }

        }

    };

    useEffect(()=> {
        refreshData();
        refreshClaimsData();
    }, []);

    // useEffect(()=> {

    // }, [docToDisplay]);

    const handleQuickFilter = (event) => {
        //console.log("Quick Filter:",event.target, event.target.value, removeGridRef.current);
        setQuickFilter(event.target.value );
        //removeGridRef.current.api.setQuickFilter(event.target.value);
        claimsGridRef.current.api.setQuickFilter(event.target.value);
    };


    const onSetCurrentPageNum = (pagenum) => {
        console.log("Currnet Page Numnber Changed", docToDisplay, documentContext, pagenum);
        setCurrentPageNum(pagenum);

        if (docToDisplay) {
            updateDocinfo({
                ecwdocid: null,
                apexdocid: null,
                documentpath : `${docToDisplay.dirpath}/${docToDisplay.filename}`,
                documentpage: currentPageNum,
                pagecount : 1
            });
        }else {
            updateDocPageinfo(currentPageNum);
        }
    }


    const handleClaimGridRowSelected = (event) => {

        const selectedNodes = claimsGridRef.current.api
            .getSelectedNodes()
            .filter((node) => node.selected);

        const datarow = selectedNodes[0].data;
        console.log('AG Claim Row selected', datarow);
        setCurrentClaimId(datarow.tclaim_id);
        getClaimDocumentLinks(datarow.tclaim_id);
        refreshCptData(datarow.tclaim_id);

    };


    const handleGridRowSelected = (event) => {

        const selectedNodes = event.api
            .getSelectedNodes()
            .filter((node) => node.selected);

        const datarow = selectedNodes[0].data;
        console.log('AG Row selected', datarow);
        setCurrentClaimId(datarow.tclaim_id);
        setstartingPageNum(datarow.docpagenum);
        setDocToDisplay({
            document_path : datarow.document_path,
            docpagenum : datarow.docpagenum
        })

        setSelectedPatientId(datarow.patientId);
        console.log("Patient:", datarow.patientId );

        handleAgDocument({
            filename : datarow.document_path,
            dirpath: '',
            startpage: datarow.docpagenum
        }, apiURL, onSetpdfToDisplayURL, setstartingPageNum);

        //console.log('Document to Display', docToDisplay);

        //setSelectedBillingSheetFile(datarow);
        //setQuickFilenameFilter(datarow.CustomName);

    };

    const onSetpdfToDisplayURL = (args) => {
        console.log("PDFURL changed:", args);
        setpdfToDisplayURL(args);
    }

    // let claimCptRowdata =  currentClaimID && rowData ? (rowData.filter(row=>{return row.tclaim_id === currentClaimID })) : [];

    const ShowDocument = (pagenum, docpath) =>{

        handleAgDocument({
            filename : docpath,
            dirpath: '',
            startpage:pagenum
        }, apiURL, onSetpdfToDisplayURL, setstartingPageNum);

    }

    const getFilteredClaims = (filteredTempClaims, showEncounters, showOOVRReady) => {


        //let filteredTempClaims = claimsRowData;
        //console.log(showEncounters, showOOVRReady, claimsRowData);
        //console.log(filteredTempClaims);

        if (filteredTempClaims && filteredTempClaims.length > 0 )
        {
            filteredTempClaims = filteredTempClaims.length > 0 ?  filteredTempClaims.filter(row=> {return (row.apex_encounter_id === (showEncounters ? 1 : 0)) }) : filteredTempClaims;

            //console.log(filteredTempClaims);

            filteredTempClaims = filteredTempClaims.length > 0 ? filteredTempClaims.filter(row=> {return (row.claim_ready_for_oov === (showOOVRReady ? 1 : 0)) }) : filteredTempClaims;

            console.log(filteredTempClaims);
        }

        return filteredTempClaims;

    }

    const onIncludeOOVReadyClaims = () => {

        setShowOOVReady(!showOOVRReady);
        if (showOOVRReady) {

            const instance = claimsGridRef.current.api.getFilterInstance('claim_ready_for_oov');
            instance.setModel({
                type: "equals",
                filter: true
            });

            claimsGridRef.current.api.onFilterChanged();

        } else {

            const instance = claimsGridRef.current.api.getFilterInstance('claim_ready_for_oov');
            instance.setModel({
                type: "equals",
                filter: false
            });

            claimsGridRef.current.api.onFilterChanged();

        }


    }

    const onIncludeClaimswithEncounters = () => {

        setShowEncounters(!showEncounters);
        if (showEncounters) {

            const instance = claimsGridRef.current.api.getFilterInstance('apex_encounter_id');
            instance.setModel({
                type: "equals",
                filter: 0
            });

            claimsGridRef.current.api.onFilterChanged();

        } else {

            const instance = claimsGridRef.current.api.getFilterInstance('apex_encounter_id');
            instance.setModel({
                type: "greaterThan",
                filter: 0
            });

            claimsGridRef.current.api.onFilterChanged();

        }

    }



    return (


        <SplitPanel split={"horizontal"}>

            <VStack>
                <div  style={{ width: '100%', marginLeft: '25px',  marginTop: '5px'}}>
                    <HStack>
                        <ManageBillingTempClaimCpts isEdit={true} claimID={currentClaimID}/>
                        <Button onClick={refreshData} colorScheme="blue">Refresh</Button>
                        <Input as='input' value={quickFilter} onChange={handleQuickFilter} width={'200px'}
                               ref={initFocusRef}
                               placeholder="filter..." variant="filled" fontSize='sm' aria-multiline/>
                        <EditClaimDx claimID={currentClaimID}/>
                        <PatientBrowser claimID={currentClaimID}/>

                        <Checkbox
                            isChecked={showEncounters}
                            onChange={onIncludeClaimswithEncounters}
                            colorScheme={showEncounters ? 'green' : 'gray'}
                        >
                            Show Enc
                        </Checkbox>
                        <Text>{claimsGridRef.current && claimsGridRef.current.api.getDisplayedRowCount()}</Text>
                    </HStack>
                </div>
                <div className="ag-theme-alpine"
                     style={{height: '20vw', width: '100%', marginTop: '5px', marginBottom: '30px'}}>
                    <AgGridReact
                        ref={claimsGridRef}
                        columnDefs={claimColumnDefs}
                        rowData={claimsRowData}
                        rowSelection="single"
                        applyColumnDefOrder={true}
                        onSelectionChanged={handleClaimGridRowSelected}
                        frameworkComponents={{
                            checkboxRenderer: CheckboxRenderer
                        }}

                    />
                </div>

                <div className="ag-theme-alpine" style={{height: '20vw', width: '100%', marginTop: '-10px'}}>
                    <AgGridReact
                        ref={claimsCPTGridRef}
                        columnDefs={claimCPTColumnDefs}
                        rowData={claimCptRowdata}
                        rowSelection="single"
                        applyColumnDefOrder={true}
                        onSelectionChanged={handleGridRowSelected}
                        frameworkComponents={{
                            checkboxRenderer: CheckboxRenderer
                        }}

                    />
                </div>
            </VStack>


            <VStack>

                <div style={{width: '100%'}}>
                    <VStack>

                        <EcwPatientEncounter patientid={selectedPatientId}/>
                        <div style={{width: '100%', marginLeft: '25px', marginTop: '5px'}}>
                            <HStack>
                                {claimDocumentLinks && claimDocumentLinks.map((row, indx) => {
                                    return (<Button
                                        onClick={() => ShowDocument(row.docpagenum, row.document_path)}>docuPage{row.docpagenum}</Button>)
                                })}
                            </HStack>
                        </div>

                    </VStack>
                </div>
                <ApexPdfjsViewer pdfUrl={pdfToDisplayURL} startingPageNum={startingPageNum} setCurrentPageNum={onSetCurrentPageNum}/>
            </VStack>

        </SplitPanel>


    );
};

export default TempClaimsViwer;
