import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LeftIcon from '@mui/icons-material/ChevronLeft';
import RightIcon from '@mui/icons-material/ChevronRight';
import UpIcon from '@mui/icons-material/KeyboardArrowUp';
import DownIcon from '@mui/icons-material/KeyboardArrowDown';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

// set pixel size in parent container
// and then all splitter children should have 100% height and 100% width specified

interface SplitPanelProps {
  id?: string;
  callback?: (params: { id: string | undefined; source: string; collapseState: string; sizes: number[] }) => void;
  hidePanel1?: boolean;
  hidePanel2?: boolean;
  showExpandButton?: boolean;
  disablePanel1Collapse?: boolean;
  disablePanel2Collapse?: boolean;
  split: 'vertical' | 'horizontal';
  initialSize?: number;
  secondPaneButton?: 'close' | 'fullscreen' | 'fullscreenexit';
  secondPaneButtonClickCallback?: (params: { id: string | undefined }) => void;
  doubleClickCallback?: (params: { id: string | undefined; paneIndex: number }) => void;
  children: React.ReactNode;
}

interface SplitPanelState {
  paneSizes: number[];
  prevPaneSizes: number[];
  collapseState: string;
  left: number;
  top: number;
  down: boolean;
  offset: number;
  containerHeight: number;
  containerWidth: number;
  resizeSource: string;
}

class SplitPanel extends Component<SplitPanelProps, SplitPanelState> {
  divContainer: HTMLDivElement | null = null;
  splitter: HTMLDivElement | null = null;

  constructor(props: SplitPanelProps) {
    super(props);

    const initialSizes = props.children ? new Array(React.Children.count(props.children)).fill(100 / React.Children.count(props.children)) : [];

    this.state = {
      paneSizes: initialSizes,
      prevPaneSizes: [],
      collapseState: '',
      left: 0,
      top: 0,
      down: false,
      offset: 0,
      containerHeight: 0,
      containerWidth: 0,
      resizeSource: 'auto',
    };

    this.mouseUp = this.mouseUp.bind(this);
    this.mouseDown = this.mouseDown.bind(this);
    this.mouseMove = this.mouseMove.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousemove', this.mouseMove);
    document.addEventListener('mouseup', this.mouseUp);
   // document.addEventListener('mousedown', this.mouseDown);

    const { initialSize, hidePanel2 } = this.props;

    if (initialSize && initialSize !== 0) {
      const newArray = [initialSize, 100 - initialSize];
      this.setState({ paneSizes: newArray });
    }

    if (hidePanel2 === true) {
      this.collapseRight(0, 'auto');
      this.setState({ prevPaneSizes: this.state.paneSizes, resizeSource: 'auto' });
    }
  }

  componentDidUpdate(prevProps: SplitPanelProps) {
    if (this.props.hidePanel1 !== prevProps.hidePanel1 && this.props.hidePanel1 === true) {
      if (this.state.collapseState === '') {
        this.collapseLeft(0, 'auto');
      }
    } else if (this.state.collapseState === 'left') {
      this.collapseLeft(0, 'auto');
    }

    if (this.props.hidePanel2 !== prevProps.hidePanel2 && this.props.hidePanel2 === true) {
      if (this.state.collapseState === '') {
        this.collapseRight(0, 'auto');
      }
    } else if (this.state.collapseState === 'right') {
      this.collapseRight(0, 'auto');
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousemove', this.mouseMove);
    document.removeEventListener('mouseup', this.mouseUp);
    //document.removeEventListener('mousedown', this.mouseDown);
  }

  mouseDown(e: React.MouseEvent<HTMLDivElement>) {
    if (this.state.collapseState === '') {
      let offset = 0;
      if (this.splitter) {
        offset = this.props.split === 'vertical' ? e.pageY - this.splitter.getBoundingClientRect().top : e.pageX - this.splitter.getBoundingClientRect().left;
      }

      this.setState({
        down: true,
        left: e.pageX - offset,
        top: e.pageY - offset,
        offset: offset,
        containerHeight: this.divContainer ? this.divContainer.clientHeight : 0,
        containerWidth: this.divContainer ? this.divContainer.clientWidth : 0,
      });
    }
  }

  mouseUp(e: MouseEvent) {
    if (this.state.down) {
      let nPerc = 50;
      const newArray: number[] = [];

      if (this.props.split === 'vertical') {
        nPerc = Math.floor(((e.pageY - this.state.offset - (this.divContainer ? this.divContainer.getBoundingClientRect().top : 0)) / this.state.containerHeight) * 100);
      } else {
        nPerc = Math.floor(((e.pageX - this.state.offset - (this.divContainer ? this.divContainer.getBoundingClientRect().left : 0)) / this.state.containerWidth) * 100);
      }

      if (nPerc <= 5 && !this.props.disablePanel1Collapse) {
        this.collapseLeft(0, 'user');
      } else if (nPerc >= 95 && !this.props.disablePanel2Collapse) {
        this.collapseRight(0, 'user');
      } else {
        nPerc = Math.min(90, Math.max(10, nPerc));
        newArray[0] = nPerc;
        newArray[1] = 100 - nPerc;

        this.setState({ paneSizes: newArray, prevPaneSizes: [], resizeSource: 'user' });

        if (this.props.callback) {
          this.props.callback({ id: this.props.id, source: 'user', collapseState: '', sizes: newArray });
        }
      }
    }
    this.setState({ down: false });
  }

  mouseMove(e: MouseEvent) {
    if (this.state.down) {
      if (this.props.split === 'vertical') {
        this.setState({
          left: e.pageX,
          top: Math.min(Math.max(e.pageY - this.state.offset, this.divContainer ? this.divContainer.getBoundingClientRect().top : 0), (this.state.containerHeight + (this.divContainer ? this.divContainer.getBoundingClientRect().top : 0)) - 11),
        });
      } else {
        this.setState({
          left: Math.min(Math.max(e.pageX - this.state.offset, this.divContainer ? this.divContainer.getBoundingClientRect().left : 0), (this.state.containerWidth + (this.divContainer ? this.divContainer.getBoundingClientRect().left : 0)) - 11),
          top: e.pageY,
        });
      }
    }
  }

  doubleClick(index: number) {
    if (this.props.doubleClickCallback) {
      this.props.doubleClickCallback({ id: this.props.id, paneIndex: index });
    }
  }

  secondPaneButtonClick(index: number) {
    if (this.props.secondPaneButtonClickCallback) {
      this.props.secondPaneButtonClickCallback({ id: this.props.id });
    }
  }

  collapseLeft(index: number, src: string) {
    this._toggleInner(index, index + 1, 'left', src);
  }

  collapseRight(index: number, src: string) {
    this._toggleInner(index + 1, index, 'right', src);
  }

  _toggleInner(src: number, dest: number, newState: string, eventSource: string) {
    const { paneSizes, prevPaneSizes } = this.state;

    let arrOldWidths: number[];

    if (prevPaneSizes.length === 0) {
      const arrNewWidths = [...paneSizes];
      arrOldWidths = [...paneSizes];
      const nRightWidth = arrNewWidths[src];
      arrNewWidths[src] = 0;
      arrNewWidths[dest] += nRightWidth;

      this.setState({ paneSizes: arrNewWidths, prevPaneSizes: arrOldWidths, collapseState: newState });

      if (this.props.callback) {
        this.props.callback({ id: this.props.id, source: eventSource, collapseState: newState, sizes: arrNewWidths });
      }
    } else {
      arrOldWidths = [...prevPaneSizes];
      this.setState({ paneSizes: arrOldWidths, prevPaneSizes: [], collapseState: '' });

      if (this.props.callback) {
        this.props.callback({ id: this.props.id, source: eventSource, collapseState: '', sizes: arrOldWidths });
      }
    }
  }

  render() {
    const { split, secondPaneButton, children } = this.props;
    const { paneSizes, down, collapseState, left, top, containerHeight, containerWidth } = this.state;

    return (
        <div
            style={{ display: 'flex', height: '100%', width: '100%', flexDirection: split === 'vertical' ? 'column' : 'row' }}
            ref={(divContainer) => {
              this.divContainer = divContainer;
            }}
        >
          {React.Children.map(children, (x, i) => (
              <React.Fragment key={i}>
                <div
                    className="noselect"
                    style={{
                      width: split === 'vertical' ? '100%' : `${paneSizes[i]}%`,
                      height: split === 'vertical' ? `${paneSizes[i]}%` : '100%',
                      transition: 'width .20s ease',
                      overflow: 'clip',
                      display: paneSizes[i] === 0 ? 'none' : '',
                    }}
                    key={`child${i}`}
                    onDoubleClick={() => this.doubleClick(i)}
                >
                  {secondPaneButton && i > 0 && (
                      <Tooltip title={secondPaneButton === 'close' ? 'Close this panel' : ''}>
                  <span>
                    <IconButton className="iconButton" style={{ position: 'fixed', color: 'white' }} onClick={() => this.secondPaneButtonClick(i)}>
                      {secondPaneButton === 'fullscreen' && <FullscreenIcon style={{ fontSize: '30pt' }} />}
                      {secondPaneButton === 'close' && <CloseIcon />}
                      {secondPaneButton === 'fullscreenexit' && <FullscreenExitIcon style={{ fontSize: '30pt' }} />}
                    </IconButton>
                  </span>
                      </Tooltip>
                  )}
                  {x}
                </div>
                {i < React.Children.count(children) - 1 && (
                    <React.Fragment key={`a${i}`}>
                      <div
                          className={split === 'vertical' ? 'move-handle-horz' : 'move-handle'}
                          style={{
                            left: split === 'vertical' ? '' : left,
                            top: split === 'vertical' ? top : '',
                            display: down ? '' : 'none',
                            height: split === 'vertical' ? '11px' : `${containerHeight}px`,
                            width: split === 'vertical' ? `${containerWidth}px` : '11px',
                          }}
                      ></div>
                      {(collapseState === '' || this.props.showExpandButton !== false) && (
                          <div
                              className={collapseState === '' ? (split === 'vertical' ? 'splitter-bar-horz noselect' : 'splitter-bar noselect') : split === 'vertical' ? 'splitter-bar-collapsed-horz' : 'splitter-bar-collapsed'}
                              key={`splitter${i}`}
                              ref={(splitter) => {
                                this.splitter = splitter;
                              }}
                              onMouseDown={collapseState === '' ? this.mouseDown : undefined}
                              onClick={collapseState === '' ? undefined : () => this.collapseLeft(i, 'user')}
                          >
                            &nbsp;
                            {split === 'vertical' ? (
                                <>
                                  {collapseState === 'right' && (
                                      <Tooltip title={collapseState === 'right' ? 'Click to restore top panel' : 'Collapse all the way to the top'}>
                                        <div className="splitter-bar-toggle-button-horz" onClick={() => this.collapseLeft(i, 'user')}>
                                          <UpIcon className="little-arrow" />
                                        </div>
                                      </Tooltip>
                                  )}
                                  {collapseState === 'left' && (
                                      <Tooltip title={collapseState === 'left' ? 'Click to restore top panel' : 'Collapse all the way to the bottom'}>
                                        <div className="splitter-bar-toggle-button-horz" onClick={() => this.collapseRight(i, 'user')}>
                                          <DownIcon className="little-arrow" />
                                        </div>
                                      </Tooltip>
                                  )}
                                </>
                            ) : (
                                <>
                                  {collapseState === 'right' && (
                                      <Tooltip title={collapseState === 'right' ? 'Click to restore left panel' : 'Collapse all the way to the left'}>
                                        <div className="splitter-bar-toggle-button" onClick={() => this.collapseLeft(i, 'user')}>
                                          <LeftIcon className="little-arrow" />
                                        </div>
                                      </Tooltip>
                                  )}
                                  {collapseState === 'left' && (
                                      <Tooltip title={collapseState === 'left' ? 'Click to restore right panel' : 'Collapse all the way to the right'}>
                                        <div className="splitter-bar-toggle-button" onClick={() => this.collapseRight(i, 'user')}>
                                          <RightIcon className="little-arrow" />
                                        </div>
                                      </Tooltip>
                                  )}
                                </>
                            )}
                          </div>
                      )}
                    </React.Fragment>
                )}
              </React.Fragment>
          ))}
        </div>
    );
  }
}

// SplitPanel.propTypes = {
//   id: PropTypes.string,
//   callback: PropTypes.func,
//   hidePanel1: PropTypes.bool,
//   hidePanel2: PropTypes.bool,
//   showExpandButton: PropTypes.bool,
//   disablePanel1Collapse: PropTypes.bool,
//   disablePanel2Collapse: PropTypes.bool,
//   split: PropTypes.oneOf(['vertical', 'horizontal']).isRequired,
//   initialSize: PropTypes.number,
//   secondPaneButton: PropTypes.oneOf(['close', 'fullscreen', 'fullscreenexit']),
//   secondPaneButtonClickCallback: PropTypes.func,
//   doubleClickCallback: PropTypes.func,
//   children: PropTypes.node.isRequired,
// };

export default SplitPanel;
