import React from 'react';
import SupportSysAdminProps from  '../Components/supportadmin/sysadmin';
//import { ChakraProvider, Box, Text } from '@chakra-ui/react';

const ClaimsReviewPage = () => {
    return (
        <SupportSysAdminProps />
    );
};

export default ClaimsReviewPage;
