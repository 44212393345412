import React from 'react';
//import { makeStyles } from '@mui/styles';
//import { withStyles } from '@mui/styles';

import {
  Grid,
  Text,
  Box,
  Button,
  Menu,
  MenuItem,
  MenuButton,
  MenuList,
  useOutsideClick ,
  Image,
  HStack,
  Portal,
  VStack
 }  from '@chakra-ui/react';

 import {
  ChevronDownIcon
 } from '@chakra-ui/icons'

 import { MdHelpOutline as HelpIcon,
  MdDocumentScanner as DocumentsIcon ,
  MdAccountBalance,
  MdPendingActions,
  MdAddTask,
  MdAttachFile,
  MdDashboard,
  MdOutlinePriceCheck,
  MdImportantDevices,
} from 'react-icons/md';

 import { Card, CardHeader, CardBody, CardFooter } from '@chakra-ui/card'

 import {
  Popover
 } from '@chakra-ui/popover' ;




// import { MdHelpOutline as HelpIcon,
//   MdDocumentScanner as DocumentsIcon ,
//   MdAccountBalance,
//   MdPendingActions,
//   MdAddTask,
//   MdAttachFile,
//   MdDashboard,
//   MdOutlinePriceCheck
// } from 'react-icons/md';

import axios from 'axios';

import  apiConfig, { useLocalStorage }  from '../../apiConfig'

// axios.interceptors.request.use(function (config) {

//   const authH = JSON.parse(localStorage.getItem('authHeader'));
//     if (authH) {
//       const token = authH.headers["Authorization"];
//       console.log("Setting axios header", token);
//       config.headers.Authorization =  token;
//     }

//   return config;
// });

// const useStyles = makeStyles({
//   root: {
//     maxWidth: 350,
//     border: '1px',
//     'border-color' : 'red'
//   },
// });


const onShowTransactionsClick = id => {
    this.props.getTransactions(id, "view");
  };


const  onGetPlaidTransactionsClick = id => {
    this.props.getTransactions(id, "refresh");
  };

const  onGetAllPlaidTransactionsClick = id => {
    this.props.getTransactions(id, "gethx");
  };

const  onUpdateAccountsClick = id => {
    this.props.getTransactions(id, "ua");
  };

  const onUploadFromFileClick = id => {
    this.props.onUploadFromFileClick(id);
  }
  

const  onUpdateTokenClick = id => {
    axios
    .post(apiConfig.API_URL +"/get_update_link_token", {'id': id})
    .then(res => {
      let linkToken_forupdate = res.data.link_token;
      this.setState({updateAccessToken : linkToken_forupdate});
    })
    .catch(err =>
      console.log(err)
    );
};

export default function ImgMediaCard(props) {

  const [authHeader, setauthHeader] = useLocalStorage("authHeader", {});
  //const classes = useStyles();
  const institutionName = props.account.institutionName;
  const account = props.account
  const account_id = props.account.itemId;
  let recent_date = ''
  if (props.account.recent_date){
    recent_date  = new Date( props.account.recent_date).getMonth() + 1 + "-" + new Date( props.account.recent_date).getDate();
  }else {
    recent_date = ''
  }

  console.log(institutionName, recent_date);
  return (
    <Card  bg="lightgreen" minW="150" maxH="100">

    <VStack>
      <HStack >
      <Button onClick={() => props.onView(account_id)} size="small" color="primary">
          View
        </Button>
        <Menu>
  <MenuButton as={Button} minH='10px' h='12px' rightIcon={<ChevronDownIcon />}>
  </MenuButton>
  <Portal>
  <MenuList >
    <MenuItem minH='30px' onClick={() => props.onRetrieve100(account_id)}>
      <Image
        boxSize='2rem'
        borderRadius='full'
        src='https://placekitten.com/100/100'
        alt='Fluffybuns the destroyer'
        mr='12px'
      />
      <span>Get-100</span>
    </MenuItem>
    <MenuItem minH='30px' onClick={() => props.onUpdateAccounts(account_id)}>
      <Image
        boxSize='2rem'
        borderRadius='full'
        src='https://placekitten.com/120/120'
        alt='Simon the pensive'
        mr='12px'
      />
      <span>Update Accounts</span>
    </MenuItem>
    <MenuItem minH='30px' onClick={() => props.onRetrieveAll(account_id)}>
    <Image
      boxSize='2rem'
      borderRadius='full'
      src='https://placekitten.com/120/120'
      alt='Simon the pensive'
      mr='12px'
    />
    <span>Get-Hx</span>
  </MenuItem>
  <MenuItem minH='30px' onClick={() => props.onUpdateToken(account_id)}>
  <Image
    boxSize='2rem'
    borderRadius='full'
    src='https://placekitten.com/120/120'
    alt='Simon the pensive'
    mr='12px'
  />
  <span>Update Token</span>
</MenuItem>
<MenuItem minH='30px' onClick={() => props.onUploadFile(account_id)}>
<Image
  boxSize='2rem'
  borderRadius='full'
  src='https://placekitten.com/120/120'
  alt='Simon the pensive'
  mr='12px'
/>
<span>Upload File</span>
</MenuItem>
  </MenuList>
  </Portal>
</Menu>

</HStack>
<Text  >
{institutionName} {' '}
{recent_date}
</Text>
</VStack>


      

        {/* <MenuListComposition key = {account_id} account = {account}  account_id = {account_id}
      onUpdateAccounts = {props.onUpdateAccounts}
      onRetrieve100 = {props.onRetrieve100}
      onRetrieveAll = {props.onRetrieveAll}
      onUpdateToken = {props.onUpdateToken}
      /> */}

        {/* <Button onClick={() => props.onRetrieveAll(account_id)} size="small" color="primary">
          R-All
        </Button>
                <Button onClick={() => props.onUpdateAccounts(account_id)} size="small" color="primary">
          uA
        </Button>
        <Button onClick={() => props.onView(account_id)} size="small" color="primary">
          V
        </Button>
        <Button onClick={() => props.onRetrieve100(account_id)} size="small" color="primary">
          R100
        </Button>
        <Button onClick={() => props.onUpdateToken(account_id)} size="small" color="primary">
          uT
        </Button>   */}
    </Card>
  );

}


// const useMenuStyles = makeStyles((theme) => ({
//   root: {
//     display: 'flex',
//   },
//   paper: {
//     marginRight: theme.spacing(2),
//   },
// }));

const MenuListComposition = (props) => {
  //const classes = useMenuStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  //console.log("Menu Props:", props);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
    console.log("Menu Props:", props);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div >
      <div>
        <Button
          innerRef={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          Action
        </Button>
        <Popover open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Box
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Text>
                <useOutsideClick  onClickAway={handleClose}>
                  <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown} style={{  'z-index' : 15, opacity: 1.0,
    'background-color': 'lightblue' }}>
                    <MenuItem onClick={() => {
                      console.log("Calling OnRetrieve100 : " + props.account_id);
                      props.onRetrieve100(props.account_id)
                    }}>R-100</MenuItem>
                    <MenuItem onClick={() =>props.onUpdateAccounts(props.account_id)}>Upd Acc</MenuItem>
                    <MenuItem onClick={() => props.onRetrieveAll(props.account_id)}>R-All</MenuItem>
                    <MenuItem onClick={() =>props.onUpdateToken(props.account_id)}>Upd Tkn</MenuItem>
                  </MenuList>
                </useOutsideClick >
              </Text>
            </Box>
          )}
        </Popover>
      </div>
    </div>
  );
}


const CustomizedMenus = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        color="primary"
        onClick={handleClick}
      >
        Action
      </Button>
      <Menu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => {
          console.log("Calling OnRetrieve100 : " + props.account_id);
          props.onRetrieve100(props.account_id)
        }}>
        
        </MenuItem>

        
      </Menu>
    </div>
  );
}

// const StyledMenu = withStyles({
//   paper: {
//     border: '1px solid #d3d4d5',
//   },
// })((props) => (
//   <Menu
//     elevation={0}
//     getcontentanchorel={null}
//     anchorOrigin={{
//       vertical: 'bottom',
//       horizontal: 'center',
//     }}
//     transformOrigin={{
//       vertical: 'top',
//       horizontal: 'center',
//     }}
//     {...props}
//   />
// ));

// const StyledMenuItem = withStyles((theme) => ({
//   root: {
//     '&:focus': { 
//       backgroundColor: '#ffffff',   //theme.palette.primary.main
//       '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
//         color: '#333', //theme.palette.common.white,
//       },
//     },
//   },
// }))(MenuItem);

