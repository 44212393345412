import React from 'react';

const ReviewByPatient: React.FC = () => {
    return (
        <div>
            {/* Placeholder for reviewing by patient */}
            <h3>Review By Patient</h3>
        </div>
    );
};

export default ReviewByPatient;
