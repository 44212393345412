import React, { useState, useEffect, useRef } from 'react';
import { Button, Box, FormLabel } from '@mui/material';
import axios from 'axios';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import Page from '../../Components/splitpage/page';
import SplitPanel from '../../Components/splitpage/splitpanel';
import EditScannedPayment from './EditScannedPayment';
import AddNewScannedPayment from './AddNewScannedPayment';
import apiConfig from "../../apiConfig";
import { SelectDepositsFile } from '../../Modals/SelectApexDepositsFile';
import EditApexTag from "./EditApexTag";
import ApexPdfjsViewer from "../../Components/ApexPdfjsViewer";
import { useDocument } from "../../Contexts/DocumentContext";
import useStateRef from "react-usestateref";

import { handleEcwDocument, handleFolderDocument, handleAgDocument } from "../../utils/ecw/hadleDocuments";
import {Row} from "jspdf-autotable";
import AddApexTag from "./AddApexTag";

interface RowData {
    spmt_id?: string;
    ecwdoc_id?: number;
    docpath?:string;
    docpage?: number,
    docname?:string;
    pageno?: number;
    checknumber?: string;
    checkamount?: number;
    checkdate?: string;
    cardamount?: number;
    otheramount?: number;
    otheramount2?: number;
    ecwpayment_id?: string;
    bankdeposit_id?: string;
    pmtpatientid?: string;
    pmtpatientname?: string;
    reconcilestatus?: string;
    customFilename?: string;
    ecwFileName?: string;
    depositbatch?: string;
    ispaymentcached?: number;
    expiredpayment?: number;
    replacementremarks?: string;
    paymentnotes?: string;
    reference1?: string;
    reference2?: string;
    enteredby?: string;
    deleteflag?: number;
    status?: string;
}

interface TagRowData {
    tagid: number;
    tag: string;
    onentitytype: string;
    onentitykey?: number;
    docpath?:string;
    docpage?: number,
    docname?:string;
    linkentitytype?: string;
    linkentitykey?: number;
    ecwdoc_id?: number;
    filename?: string;
    patient?: number;
    patid?: number;
    tagpage: number;
    tagdesc?: string;
    tagdate?: string;
    deleteflag?: number;
    created_at?: string;
    updated_at?: string;
    deleted_at?: string;
}

interface ResultRowData {
    [key: string]: any;
}

interface ParsedData {
    rows: RowData[];
}

interface ParsedTagData {
    rows: TagRowData[];
}



const ScannedPaymentPage: React.FC = () => {
    const [rowData, setRowData] = useState<RowData[]>([]);
    const [tagRowData, setTagRowData] = useState<TagRowData[]>([]);
    const [selectedRow, setSelectedRow] = useState<RowData | null>(null);
    const [selectedTagRow, setSelectedTagRow] = useState<TagRowData | null>(null);
    const [openEdit, setOpenEdit] = useState(false);
    const [openAdd, setOpenAdd] = useState(false);


    const gridRef = useRef<any>(null);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [pdfToDisplayURL, setpdfToDisplayURL] = useState('');
    const [selectedDepositsFile, setSelectedDepositsFile] = useState<any>();
    const [startingPageNum, setStartingPageNum] = useState(1);
    const [currentPageNum, setCurrentPageNum] = useState<number>();
    const [currentDocName, setCurrentDocName] = useState<string>();
    const { documentContext, updateDocinfo, updateDocPageinfo } = useDocument();
    const [documentInfo, setDocumentInfo, documentInfoRef] = useStateRef({
        id: -1,
        name: '',
        account: ''
    });

    const apiURL = apiConfig.REACT_APEX_BASE_API;
    const [anchorElTagForm, setAnchorElTagForm] = useState<HTMLElement | null>(null);
    const [openTagForm, setOpenTagForm] = useState(false);
    const [openAddTagForm, setOpenAddTagForm] = useState(false);
    const [openEditTagForm, setOpenEditTagForm] = useState(false);

    const handleOpenAddTagForm = (event: React.MouseEvent<HTMLElement>) => {
        setOpenAddTagForm(true);
        setAnchorElTagForm(event.currentTarget);
    };

    const handleCloseAddTagForm = () => {
        setOpenAddTagForm(false);
        setAnchorElTagForm(null);
    };


    const handleOpenEditTagForm = (event: React.MouseEvent<HTMLElement>) => {
        setOpenEditTagForm(true);
        setAnchorElTagForm(event.currentTarget);
    };

    const handleCloseEditTagForm = () => {
        setOpenEditTagForm(false);
        setAnchorElTagForm(null);
    };

    // const handleSave = (updatedRow: RowData) => {
    //     handleSaveNewScanPayment(updatedRow);
    //     setRowData(prevRows => prevRows.map(row => (row.spmt_id === updatedRow.spmt_id ? updatedRow : row)));
    // };

    const handleSubmitTag = async (tagData:TagRowData) => {

        console.log("Got called handleSubmitTag", tagData);

        const authHeader = JSON.parse(localStorage.getItem('authHeader') || '{}');
        try {
            const urlPrefix = `${apiURL}/exsql?dbserver=`;
            const dataURL = `apex&sqltype=customSQL&sqltext=exec apex.rc.apexsp_mnt_tags @argop = 'add', @tagid = '${tagData.tagid ? tagData.tagid : null}',
            @tag = '${tagData.tag}',
            @onEntityType = '${tagData.onentitytype}',
            @onEntityKey = ${tagData.onentitykey},
            @linkEntityType = '${tagData.linkentitytype}',
            @linkEntityKey = ${tagData.linkentitykey},
            @tagDesc = '${tagData.tagdesc}',
            @tagPage = '${tagData.tagpage}',
            @tagDate = '${tagData.tagdate}';`;
            const res2 = await axios.get(urlPrefix + dataURL, { headers: authHeader });
            if (res2.data) {
                handleCloseEditTagForm();
                handleCloseAddTagForm();
            }
        } catch (error) {
            console.error('Error adding tag:', error);
        }
    };

    const handleSaveNewScanPayment = async (newRow: RowData) => {

        const filteredData = Object.fromEntries(
            Object.entries(newRow).filter(([_, v]) => v !== null && v !== undefined)
        );

        const authHeader = JSON.parse(localStorage.getItem('authHeader') || '{}');
        const urlPrefix = `${apiConfig.REACT_APEX_BASE_API}/exsql?dbserver=`;
        let sqlText = `apex&sqltype=customSQL&sqltext=exec rc.mnt_scannedpayment @arg_op='add'`;

        Object.entries(filteredData).forEach(([key, value]) => {
            sqlText += `, @${key}=${typeof value === 'string' ? `'${value}'` : value}`;
        });

        console.log("Got called handleSavePayment", newRow, `${urlPrefix}${sqlText}`);
        try {
            const res = await axios.get(`${urlPrefix}${sqlText}`, { headers: authHeader });
            if (res.data) {
                setRowData([...rowData, newRow]);
            }
        } catch (error) {
            console.error('Error saving data:', error);
        }
    };

    const fetchPaymentData = async (sqlText: string) => {
        const authHeader = JSON.parse(localStorage.getItem('authHeader') || '{}');
        const urlPrefix = `${apiURL}/exsql?dbserver=apex&sqltype=customSQL&sqltext=`;

        const response = await axios.get(`${urlPrefix}${sqlText}`, { headers: authHeader });

        if (response.data) {
            const dframe: string = response.data.frame0;
            const myObj: ParsedData = JSON.parse(dframe);
            const gridData: ResultRowData[] = myObj.rows;
            setRowData(gridData);
        }
    };

    const fetchTagData = async (sqlText: string) => {
        const authHeader = JSON.parse(localStorage.getItem('authHeader') || '{}');
        const urlPrefix = `${apiURL}/exsql?dbserver=apex&sqltype=customSQL&sqltext=`;

        const response = await axios.get(`${urlPrefix}${sqlText}`, { headers: authHeader });

        if (response.data) {
            const dframe: string = response.data.frame0;
            const myObj: ParsedTagData = JSON.parse(dframe);
            const gridData: TagRowData[] = myObj.rows;
            setTagRowData(gridData);
        }
    };

    useEffect(() => {
        fetchPaymentData('select top 2000 apex.dbo.EcwDocumentPath(ecwdoc_id) as docpath,pageno as docpage, apex.dbo.EcwDocumentName(ecwdoc_id) as docname, * from rc.ApexScannedPayment order by spmt_id desc');
        fetchTagData('select top 2000 apex.dbo.EcwDocumentPath(ecwdoc_id) as docpath,tagpage as docpage, apex.dbo.EcwDocumentName(ecwdoc_id) as docname,* from apex.rc.apex_tags_view order by tagid desc');
    }, []);

    const handleRefresh = () => {
        fetchPaymentData('select top 2000 apex.dbo.EcwDocumentPath(ecwdoc_id) as docpath, pageno as docpage, apex.dbo.EcwDocumentName(ecwdoc_id) as docname,* from rc.ApexScannedPayment order by spmt_id desc');
        fetchTagData('select top 2000 apex.dbo.EcwDocumentPath(ecwdoc_id) as docpath, tagpage as docpage, apex.dbo.EcwDocumentName(ecwdoc_id) as docname, * from apex.rc.apex_tags_view order by tagid desc');
    };

    const handleSelectDocument = (docId: string) => {
        fetchPaymentData(`select * from rc.ApexScannedPayment where ecwdoc_id = ${docId}`);
        fetchTagData(`select * from apex.rc.apex_tags_view where ecwdoc_id = ${docId} order by tagid desc`);
    };

    const handleEdit = (row: RowData) => {
        setSelectedRow(row);
        setAnchorEl(document.body);
        setOpenEdit(true);
    };

    const handleTagEdit = (row: TagRowData) => {
        setSelectedTagRow(row);
        setAnchorEl(document.body);
        setOpenEditTagForm(true);
    };

    const handleAdd = () => {
        setAnchorEl(document.body);
        setOpenAdd(true);
    };

    const handleSave = (updatedRow: RowData) => {
        handleSaveNewScanPayment(updatedRow);
        setRowData(prevRows => prevRows.map(row => (row.spmt_id === updatedRow.spmt_id ? updatedRow : row)));
    };

    const handleDelete = (rowId: string) => {
        setRowData(prevRows => prevRows.filter(row => row.spmt_id !== rowId));
    };


    const handleTagDelete = (rowId: number) => {
        setTagRowData(prevRows => prevRows.filter(row => row.tagid !== rowId));
    };


    const dateFormatter = (params: { value: string }) => {
        const date = new Date(params.value);
        return date.toISOString().split('T')[0];
    };

    const onSetpdfToDisplayURL = (args: React.SetStateAction<string>) => {
        setpdfToDisplayURL(args);
    };

    const onSelectedDepositsFile = (data: any) => {
        setSelectedDepositsFile(data);
        updateDocinfo({
            ecwdocid: data.docID,
            apexdocid: null,
            documentpath: `${data.dirpath}/${data.filename}`,
            documentpage: currentPageNum,
            pagecount: 1
        });

        setDocumentInfo({
            id: data.docID,
            name: data.filename,
            account: data.account
        });
        setCurrentDocName(data.docname);

        handleAgDocument({
            filename: `${data.dirpath}/${data.filename}`,
            dirpath: '',
            startpage: data.docpagenum
        }, apiURL, onSetpdfToDisplayURL, setStartingPageNum);
    };

    const onSetCurrentPageNum = (pageNum: number) => {
        setCurrentPageNum(pageNum);

        if (selectedDepositsFile) {
            updateDocinfo({
                ecwdocid: null,
                apexdocid: null,
                documentpath: `${selectedDepositsFile.dirpath}/${selectedDepositsFile.filename}`,
                documentpage: pageNum,
                pagecount: 1
            });
        } else {
            updateDocPageinfo(pageNum);
        }
    };

    const handleViewPage = (row: RowData) => {
        console.log("Selected row", row);
        ShowDocument(row.docpage ? row.docpage : 1, row.docpath? row.docpath : '');
    }
    const ShowDocument = (pagenum: number, docpath: string) =>{
        handleAgDocument({
            filename : docpath,
            dirpath: '',
            startpage:pagenum
        }, apiURL, onSetpdfToDisplayURL, setCurrentPageNum);
    }

    let documentCustomName = selectedRow && selectedRow.docname;
    console.log(selectedRow && selectedRow.docname);
    return (
        <>
            <Page
                title="Scanned Payments"
                actionButtons={
                    <>
                        <Button variant="contained" onClick={handleRefresh}>Refresh</Button>
                        <Button variant="contained" onClick={() => handleSelectDocument('some_doc_id')}>Select Document</Button>
                        <Button variant="contained" onClick={handleAdd}>Add Payment</Button>
                    </>
                }
                toolButtons={
                    <>

                        <Button variant="contained" onClick={handleRefresh}>Refresh</Button>
                        <Button variant="contained" onClick={handleAdd}>Add Payment</Button>
                        <SelectDepositsFile selectedBillingSheetFile={selectedDepositsFile} setSelectedBillingSheetFile={onSelectedDepositsFile} />
                        <Button variant="contained" color="primary" onClick={handleOpenAddTagForm}>Add New Tag</Button>
                    </>
                }
                content={
                    <SplitPanel split="horizontal">
                        <SplitPanel split="vertical">
                            <div className="ag-theme-alpine" style={{height: '42vh',}}>
                                <AgGridReact
                                    ref={gridRef}
                                    rowData={rowData}
                                    rowSelection={'single'}
                                    enableBrowserTooltips
                                    defaultColDef={{sortable: true, filter: true,  resizable: true}}
                                    columnDefs={[
                                        {
                                            headerName: 'Actions',
                                            width: 200,
                                            cellRenderer: (params: any) => (
                                                <>
                                                <Button onClick={() => handleEdit(params.data)}>Edit</Button>
                                                <Button onClick={() => handleViewPage(params.data)}>View</Button>
                                                </>
                                            ),
                                        },
                                        {field: 'spmt_id', headerName: 'Pmt ID', width: 100},
                                        {field: 'ecwdoc_id', headerName: 'Doc', width: 100, tooltipField: 'customFilename'},
                                        { field: 'docname', headerName: 'Name', width: 180, tooltipField: 'docname'  },
                                        {field: 'pageno', headerName: 'Page No', width: 100},
                                        {
                                            field: 'checkdate',
                                            headerName: 'Check Date',
                                            width: 140,
                                            valueFormatter: dateFormatter
                                        },
                                        {field: 'checknumber', headerName: 'Check Number', width: 120},
                                        {field: 'checkamount', headerName: 'Check Amt', width: 120},
                                        {field: 'cardamount', headerName: 'Card Amt', width: 120},
                                        {field: 'otheramount', headerName: 'Other Amt', width: 120},
                                    ]}
                                />
                            </div>

                            <div className="ag-theme-alpine" style={{height: '42vh',}}>
                                    <AgGridReact
                                        ref={gridRef}
                                        rowData={tagRowData}
                                        enableBrowserTooltips
                                        rowSelection={'single'}
                                        defaultColDef={{sortable: true, filter: true, floatingFilter: true, resizable: true}}
                                        columnDefs={[
                                            {
                                                headerName: 'Actions',
                                                width: 200,
                                                cellRenderer: (params: any) => (
                                                    <>
                                                        <Button onClick={() => handleTagEdit(params.data)}>Edit</Button>
                                                        <Button onClick={() => handleViewPage(params.data)}>View</Button>
                                                    </>
                                                ),
                                            },
                                            { field: 'tagid', headerName: 'Tag ID', width: 80 },
                                            { field: 'tag', headerName: 'Tag', width: 110 },
                                            { field: 'ecwdoc_id', headerName: 'Doc', width: 100, tooltipField: 'filename'  },
                                            { field: 'filename', headerName: 'Name', width: 180, tooltipField: 'filename'  },
                                            { field: 'patid', headerName: 'PatID', width: 100 },
                                            { field: 'patient', headerName: 'Patient', width: 100,  tooltipField: 'patient'  },
                                            { field: 'tagpage', headerName: 'Page', width: 100},
                                            { field: 'tagdate', headerName: 'Tag Date', width: 140, valueFormatter: dateFormatter },
                                            {field: 'tagdesc', headerName: 'Tag Description', width: 200, tooltipField: 'tagdesc' },

                                            { field: 'deleteflag', headerName: 'Delete Flag', width: 100 },

                                            { field: 'onentitytype', headerName: 'On Entity Type', width: 100 },
                                            { field: 'onentitykey', headerName: 'On Entity Key', width: 100 },
                                            { field: 'linkentitytype', headerName: 'Link Entity Type', width: 100 },
                                            { field: 'linkentitykey', headerName: 'Link Entity Key', width: 100 },

                                            { field: 'created_at', headerName: 'Created At', width: 140, valueFormatter: dateFormatter },
                                            { field: 'updated_at', headerName: 'Updated At', width: 140, valueFormatter: dateFormatter },
                                            { field: 'deleted_at', headerName: 'Deleted At', width: 140, valueFormatter: dateFormatter },
                                        ]}
                                    />
                                </div>
                        </SplitPanel>
                        <div style={{ width: '100%' }}>
                            <Box display="flex" flexDirection="column">
                                <FormLabel>{selectedRow && selectedRow.docname}</FormLabel>
                                <ApexPdfjsViewer pdfUrl={pdfToDisplayURL} startingPageNum={currentPageNum} setCurrentPageNum={onSetCurrentPageNum} />
                            </Box>
                        </div>
                    </SplitPanel>
                }
            />
            <>
                <EditApexTag
                    open={openEditTagForm}
                    onClose={handleCloseEditTagForm}
                    onSave={handleSubmitTag}
                    anchorEl={anchorElTagForm}
                    pageNum={currentPageNum}
                    tagData={selectedTagRow}
                    onDelete={handleTagDelete}
                    ecwDocId={selectedDepositsFile ? selectedDepositsFile.docID : 0}
                />
                <AddApexTag
                    open={openAddTagForm}
                    onClose={handleCloseAddTagForm}
                    onSave={handleSubmitTag}
                    anchorEl={anchorElTagForm}
                    pageNum={currentPageNum ? currentPageNum : 0}
                    ecwDocId={selectedDepositsFile ? selectedDepositsFile.docID : 0}
                />
                <EditScannedPayment
                    open={openEdit}
                    anchorEl={anchorEl}
                    onClose={() => setOpenEdit(false)}
                    rowData={selectedRow}
                    onSave={handleSave}
                    onDelete={handleDelete}
                    pageNum={currentPageNum ? currentPageNum : 0}
                    ecwDocId={selectedDepositsFile ? selectedDepositsFile.docID : 0}
                />
                <AddNewScannedPayment
                    open={openAdd}
                    anchorEl={anchorEl}
                    onClose={() => setOpenAdd(false)}
                    onSave={handleSaveNewScanPayment}
                    pageNum={currentPageNum ? currentPageNum : 0}
                    ecwDocId={selectedDepositsFile ? selectedDepositsFile.docID : 0}
                />
            </>
        </>
    );
};

export default ScannedPaymentPage;
