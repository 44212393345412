import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getLocalData, saveLocalData } from "../../utils/localStorage";
import axios from "axios";
import { setToast } from "../../utils/Authenticate";
import apiConfig from "../../apiConfig";


const initialState = {
  isAuth: getLocalData("token") ? true : false,
  token: getLocalData("token") || "",
  profileData: [],
  isLoading: false,
  isError: false,
};

let authToken = getLocalData("token") ;
const Headers = {
  'Content-Type': `application/json`,
  "Access-Control-Allow-Origin": "*",
  'Authorization': `Bearer ${authToken ? authToken : "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c"}`,
};

let apiURL=apiConfig.REACT_APP_AUTHENTICATION;

// axios.defaults.headers.common.PlaidEnvironment = `${payload.plaidenv}`;

export const register = createAsyncThunk ('auth/register', async (payload) => {
  return axios
    .post(`${apiURL}/auth/signup`, payload)
    .then((r) => {
      return r.data ;
    })
    .catch((e) => {
      setToast(payload.toast, e.response.data.msg, "error");
    });
});

export const login = createAsyncThunk ('auth/login', async (payload) => {

  
  //console.log("Call to thunk auth/login:", payload, Headers);
  return axios.post
  (`${apiURL}/auth/login`, payload, { headers: Headers} )
  .then((r) => {
    console.log("Returing success frm login", r);
    return r.data ;
  })
  .catch((e) => {
    console.log("Returing failure frm login", e);
    if (e.includes("Authori")) {
      //return { 'token': 'it is ok'};
    }
    setToast(payload.toast, e.response.data.msg, "error");
  });
});

export const profile = createAsyncThunk ('auth/profile', async (payload) => {
  const options = {
    method: "GET",
    url: `${apiURL}/${payload.email}`,
    headers: { Authorization: `Bearer ${payload.token}` },
  };
  return axios(options)
    .then((r) => {
      console.log("RECEIVED from Profile Call: ", r);
      return  r.data;
    })
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    REGISTER_REQUEST(state, action) {
      return { ...state, isLoading: true };
    },
    REGISTER_SUCCESS(state, action) {
      return { ...state, isLoading: false, RegisterUser: action.payload };
    },
    REGISTER_FAILURE(state, action) {
      return { ...state, isLoading: false, isError: true };
    },
    LOGIN_REQUEST(state, action) {
      return { ...state, isLoading: true };
    },
    LOGIN_SUCCESS(state, action) {
      saveLocalData("token", action.payload.token);
      return { ...state, isLoading: false, isAuth: true, token: action.payload.token };
    },
    LOGIN_FAILURE(state, action) {
      return {
        ...state,
        isLoading: false,
        isError: true,
        isAuth: false,
        token: "",
      };
    },
    PROFILE_SUCCESS(state, action) {
      console.log("PROFILE_DATA_RECEIEVED", action.payload);
      return {
        ...state,
        profileData: action.payload,
      };
    },   
  },
  extraReducers: builder => {
    builder
      .addCase(register.pending, (state, action) => {
      })
      .addCase(register.fulfilled, (state, action) => {
      })
      .addCase(login.pending, (state, action) => {
      })
      .addCase(login.fulfilled, (state, action) => {
        console.log("LOGIN FULFILLED", action);
        saveLocalData("token", action.payload.token);
      })
      .addCase(profile.pending, (state, action) => {
      })
      .addCase(profile.fulfilled, (state, action) => {
      })
  }
})

export const { REGISTER_REQUEST, REGISTER_SUCCESS, REGISTER_FAILURE, LOGIN_REQUEST,  LOGIN_SUCCESS, LOGIN_FAILURE, PROFILE_SUCCESS} = authSlice.actions

export default authSlice.reducer