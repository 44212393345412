import React, { useState, useEffect } from 'react';
import { Box, Button, Container, HStack, VStack } from '@chakra-ui/react';
import CommentCard from './CommentCard'; // Ensure the path is correct
import axios from 'axios';
import apiConfig from "../../apiConfig";

interface Comment {
    detailId: number;
    patid: number;
    claimid: number;
    note: string;
    notePlainText: string;
    noteType: number;
    assignedToName: string;
    assignedByName: string;
    enteredbyName: string;
    modifieddate: string;
}

interface CommentsSectionProps {
    patientId: number;
    claimId: number;
}

// const getClaimNotes = async (claim_id) => {
//     let authHeader = {};
//
//     const authH = JSON.parse(localStorage.getItem('authHeader'));
//     if (authH) {
//         authHeader =  authH;
//     }
//
//     let urlPrefix = apiURL + '/exsql?dbserver=';
//     let dataURL = `apex&sqltype=customSQL&sqltext=set rowcount 0 select  * from rc.claim_followup_logs where claim_id = ${claim_id}    `;
//
//     console.log("geClaim NotesSQL :", dataURL);
//     const res2 = await axios.get(urlPrefix + dataURL, authHeader);
//     if (res2.data) {
//         console.log("GOT claim followup log Data:", res2.data);
//         let dframe = res2.data['frame0'];
//         console.log("Next Frame:", dframe);
//         let myObj = JSON.parse(dframe);
//         console.log(myObj);
//         let gridData = myObj['rows'];
//         console.log("Got claim notes:", gridData);
//
//         setClaimFollowups(gridData);
//     }
// }

const CommentsSection: React.FC<CommentsSectionProps> = ({ patientId, claimId }) => {
    const [comments, setComments] = useState<Comment[]>([]);
    const [isAdding, setIsAdding] = useState(false);
    const [newCommentType, setNewCommentType] = useState(0);
    let apiURL = apiConfig.REACT_APEX_BASE_API;

    useEffect(() => {
        // Fetch comments from API
        const fetchComments = async () => {

            try {
            let authHeader = {};

            // @ts-ignore
                if (!claimId) return;
            // @ts-ignore
                const authH = JSON.parse(localStorage.getItem('authHeader'));
            if (authH) {
                authHeader =  authH;
            }

            let urlPrefix = apiURL + '/exsql?dbserver=';
            let dataURL = `apex&sqltype=customSQL&sqltext=set rowcount 0 select distinct  * from rc.claim_followup_logs where claim_id = ${claimId}    `;

            console.log("geClaim NotesSQL :", dataURL);
            const res2 = await axios.get(urlPrefix + dataURL, authHeader);
            if (res2.data) {
                console.log("GOT claim followup log Data:", res2.data);
                let dframe = res2.data['frame0'];
                console.log("Next Frame:", dframe);
                let myObj = JSON.parse(dframe);
                console.log(myObj);
                let gridData = myObj['rows'];
                console.log("Got claim notes:", gridData);

                //setClaimFollowups(gridData);
                let formatComments = gridData.map((row: {
                    patid: number;
                    claimid: number;
                    modifieddate: any;
                    assignedToName: string;
                    assignedByName: string;
                    enteredbyName: string;
                    detailId: any; action_notes: any; noteType: any; }) => ({
                    patid: row.patid,
                    claimid: row.claimid,
                    detailId: row.detailId,
                    note: row.action_notes,
                    notePlainText: row.action_notes,
                    noteType: row.noteType ? row.noteType : 0,
                    enteredbyName : row.enteredbyName,
                    assignedByName : row.assignedByName,
                    assignedToName: row.assignedToName,
                    modifieddate: row.modifieddate,
                }));
                setComments(formatComments);
            }
            } catch (error) {
                console.error('Error fetching comments:', error);
            }
        };
        fetchComments();
    }, [patientId, claimId]);

    const handleSaveComment = (newComment: Comment) => {
        setComments((prevComments) => {
            const existingCommentIndex = prevComments.findIndex((comment) => comment.detailId === newComment.detailId);
            if (existingCommentIndex !== -1) {
                // Update existing comment
                const updatedComments = [...prevComments];
                updatedComments[existingCommentIndex] = newComment;
                return updatedComments;
            } else {
                // Add new comment
                return [...prevComments, newComment];
            }
        });
        setIsAdding(false);
    };

    const handleCancel = () => {
        setIsAdding(false);
    };

    return (
        <Container maxW="100%" mt={4}>
            <VStack spacing={4}>
                {comments.map((comment) => (
                    <CommentCard
                        key={comment.detailId}
                        comment={comment}
                        onSave={handleSaveComment}
                        onCancel={handleCancel}
                    />
                ))}
                {isAdding && (
                    <CommentCard
                        isNew={true}
                        detailId={0}
                        note=""
                        noteType={newCommentType}
                        onSave={handleSaveComment}
                        onCancel={handleCancel}
                    />
                )}
                <HStack spacing={2} mt={1}>
                    <Button onClick={() => { setNewCommentType(0); setIsAdding(true); }} colorScheme="teal">
                        Claim Followup
                    </Button>
                    <Button onClick={() => { setNewCommentType(1); setIsAdding(true); }} colorScheme="teal">
                        Patient Comment
                    </Button>
                    <Button onClick={() => { setNewCommentType(2); setIsAdding(true); }} colorScheme="teal">
                        Payment Followup
                    </Button>
                </HStack>
            </VStack>
        </Container>
    );
};

export default CommentsSection;
