import React, { useState } from 'react';
import ApexPdfjsViewer from '../Components/ApexPdfjsViewer';
import { SelectBillingSheetFile } from './SelectBillingSheetFile';

import {
    RadioGroup,
    Stack,
    Radio,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Flex,
    useDisclosure,
    Text,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverArrow,
    PopoverCloseButton,
    PopoverBody

} from '@chakra-ui/react';

function ViewDocumentModal(props) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [pdfToDisplayURL, setpdfToDisplayURL] = useState(props.pdfToDisplayURL);
    const [startingPageNum, setStartingPageNum] = useState(props.startingPageNum);
    const [currentPageNum, setCurrentPageNum] = useState();
    const [selectedBillingSheetFile, setselectedBillingSheetFile] = useState();

    const onSelectedBillingSheetFile = (data) => {
        setselectedBillingSheetFile(data);
    }

    const onSetCurrentPageNum = (pagenum) => {
        console.log("Viewing now page: ", pagenum);
        setCurrentPageNum(pagenum);
    }
    console.log("Inside view document as modal", pdfToDisplayURL, startingPageNum);
    //     <SelectBillingSheetFile selectedBillingSheetFile={selectedBillingSheetFile} setSelectedBillingSheetFile={onSelectedBillingSheetFile} />
    const btnRef = React.useRef()
    return (
        <Popover isLazy placement='left-end'>
        <PopoverTrigger>
        <Button colorScheme="blue" mr={3} >CPT</Button>
        </PopoverTrigger>
        <PopoverContent bg='tomato' color='white' w='800px'>
          <PopoverHeader fontWeight='semibold'>Claim Create / Edit </PopoverHeader>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody>
          <ApexPdfjsViewer pdfUrl={pdfToDisplayURL} startingPageNum={startingPageNum} setCurrentPageNum={onSetCurrentPageNum} />
          </PopoverBody>
        </PopoverContent>
      </Popover>
    )
}

// <ApexPdfjsViewer pdfUrl={pdfToDisplayURL} startingPageNum={startingPageNum} setCurrentPageNum={onSetCurrentPageNum} />
export default ViewDocumentModal;