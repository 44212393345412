import React from "react";
import { OutlinedInput, OutlinedInputProps } from "@mui/material";

interface StyledInputProps extends OutlinedInputProps {
    width?: string;
}

const StyledInput: React.FC<StyledInputProps> = (props) => {
    const { width, ...rest } = props;

    return (
        <div style={{ display: 'inline' }}>
            <OutlinedInput
                style={{ fontSize: '11pt', height: '30px', width: width || '300px' }}
                {...rest}
            />
        </div>
    );
}

export default StyledInput;
