import React, { useState } from 'react';
import SplitPage from '../splitpage/splitpage';
import SplitPanel from '../splitpage/splitpanel';
import SampleDataGrid from './samplegridusage';

interface SupportSysAdminProps {
  currentUser: any;
  modalService: any;
}

const tabs = ['Access Audit', 'Cache Monitor', 'Job Agents', 'Receivers', 'Servers', 'Summary'];

const SupportSysAdmin: React.FC<SupportSysAdminProps> = (props) => {
  const [currentTab, setCurrentTab] = useState<string>(tabs[0]);

  function getLeftPanelContent() {
    return (
        <table style={{ width: '100%' }}>
          <thead>
          <tr>
            <th>Select a System</th>
          </tr>
          </thead>
          <tbody>
          {tabs.map((tab, ndx) => (
              <tr
                  key={`tab${ndx}`}
                  className={tab === currentTab ? 'selected' : ''}
                  onClick={() => setCurrentTab(tab)}
              >
                <td>{tab}</td>
              </tr>
          ))}
          </tbody>
        </table>
    );
  }

  function getRightPanelContent() {
    const { currentUser, modalService } = props;

    switch (currentTab) {
      case 'Access Audit':
        return <div>Access Audit Panel</div>;
      case 'Cache Monitor':
        return (
            <SampleDataGrid />
        );
      case 'Receivers':
        return <div>Receiver  Panel</div>;
      default:
        return (
            <SplitPanel
                id="first"
                split="vertical"
                hidePanel1={false}
                hidePanel2={false}
                showExpandButton={false}
                disablePanel2Collapse={true}
                initialSize={30}
            >
              <SplitPage
                  actionButtons={<></>}
                  isLoading={false}
                  leftPanelContent={getLeftPanelContent()}
                  loadingMsg={'Loading'}
                  rightPanelContent={<div>Right panel content</div>}
                  title={`System Top Panel`}
                  toolButtons={<></>}
              />
              <SplitPage
                  actionButtons={<></>}
                  isLoading={false}
                  leftPanelContent={getLeftPanelContent()}
                  loadingMsg={'Loading'}
                  rightPanelContent={<div>Right panel2 content</div>}
                  title={`System Bottom Panel` }
                  toolButtons={<></>}
              />
            </SplitPanel>
        );
    }
  }

  return (
      <SplitPage
          actionButtons={<></>}
          isLoading={false}
          leftPanelContent={getLeftPanelContent()}
          loadingMsg={'Loading'}
          rightPanelContent={getRightPanelContent()}
          title={`System Administration`}
          toolButtons={<></>}
      />
  );
}

export default SupportSysAdmin;
