import React, { useState } from 'react';
import { Box, RadioGroup, Radio, VStack, HStack , Button, Input, Text , Checkbox} from '@chakra-ui/react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import axios from 'axios';
import apiConfig from "../apiConfig";
import { format } from 'date-fns'

const ClaimListReview = () => {

  let apiURL = apiConfig.REACT_APEX_BASE_API;
  const claimsGridRef = React.useRef();
  const claimsFollowupLogGridRef = React.useRef();
  const claimsBllingLogGridRef = React.useRef();
  const initFocusRef= React.useRef();

  const [quickFilter, setQuickFilter] = useState('');
  const [filterValue, setFilterValue] = useState('all');
  const [claimsData, setClaimsData] = useState([]);
  const [claimFolloupLogData, setClaimFolloupLogData] = useState([]);
  const [claimBillingLogData, setClaimBillingLogData] = useState([]);
  const [currentText, setcurrentText] = useState();
  
  const [withClaimIdZero, setWithClaimIdZero] = useState(false);

  const handleFilterChange = (value) => {
    setFilterValue(value);
    console.log("Filter to values", value);

    const instance = claimsGridRef.current.api.getFilterInstance('claim_queue');
    instance.setModel({
      filterType: 'text',
      type: 'equals',
      filter: value
      });

    claimsGridRef.current.api.onFilterChanged();


    // Apply your filtering logic here based on the selected filter value
    // You can use the Ag-Grid API to filter rows
  };

  const refreshClaimsData = async () => {
    let authHeader = {};

    const authH = JSON.parse(localStorage.getItem('authHeader'));
    if (authH) {
      authHeader =  authH;
    }

    let urlPrefix = apiURL + '/exsql?dbserver=';
    let dataURL = `apex&sqltype=customSQL&sqltext=set rowcount 0   select * from rc.billing_apex_enc_queue_current order by visit_dos  `;

    const res2 = await axios.get(urlPrefix + dataURL, authHeader);
    if (res2.data) {
      console.log("GOT Billing Files:", res2.data);
      let dframe = res2.data['frame0'];
      //console.log(dframe);
      let myObj = JSON.parse(dframe);
      console.log(myObj);
      let gridData = myObj['rows'];

      setClaimsData(gridData);
    }

};

const refreshClaimFollowupLog = async (idtype,claimid) => {
  let authHeader = {};

  const authH = JSON.parse(localStorage.getItem('authHeader'));
  if (authH) {
    authHeader =  authH;
  }

  if (!claimid) return;

  let urlPrefix = apiURL + '/exsql?dbserver=';
  let dataURL = `apex&sqltype=customSQL&sqltext=set rowcount 0 select apex.dbo.username(enteredby) as username, * from rc.billing_claim_followup_log where claim_id =  ${claimid}  order by modifieddate desc
   `;

  const res2 = await axios.get(urlPrefix + dataURL, authHeader);
  console.log("SQL:", dataURL);
  if (res2.data) {
    console.log("GOT Billing Files:", res2.data);
    if (res2.data) {
    let dframe = res2.data['frame0'];
    //console.log(dframe);
    let myObj = JSON.parse(dframe);
    console.log(myObj);
    let gridData = myObj['rows'];

    console.log("Claim Followup Logs:", gridData);

    setClaimFolloupLogData(gridData);
  }
  }

};




const loadRecentPostingData = async () => {

  let authHeader = {};

  const authH = JSON.parse(localStorage.getItem('authHeader'));
  if (authH) {
    authHeader =  authH;
  }

  let urlPrefix = apiURL + '/exsql?dbserver=';
  let dataURL = `apex&sqltype=customSQL&sqltext=set rowcount 0 exec rc.billsp_get_recently_posted  `;
  console.log("SQL:", dataURL);
  const res2 = await axios.get(urlPrefix + dataURL, authHeader);
  if (res2.data) {
    console.log("GOT Billing Files:", res2.data);
    let dframe = res2.data['frame0'];
    //console.log(dframe);
    let myObj = JSON.parse(dframe);
    console.log(myObj);
    let gridData = myObj['rows'];
    console.log("Claim Billing Logs:", gridData);
    setClaimsData(gridData);
  }

}

const loadRecentFollowup = async () => {

  let authHeader = {};

  const authH = JSON.parse(localStorage.getItem('authHeader'));
  if (authH) {
    authHeader =  authH;
  }

  let urlPrefix = apiURL + '/exsql?dbserver=';
  let dataURL = `apex&sqltype=customSQL&sqltext=set rowcount 0 select    flog.modifieddate, apex.dbo.username(assignedbyid) as assignedBy, apex.dbo.username(assignedtoid) as assignedTo, 
  flog.id as flog_id, flog.isactive as flog_active, flog.claim_id as flog_claim_id, apex.dbo.username(enteredby) as enteredby, convert(varchar,notes) as flog_notes,
  bq.*
  from rc.billing_claim_followup_log flog ,  rc.billing_apex_enc_queue_current bq 
  where flog.modifieddate between dateadd(dd, -30, getdate()) and getdate()
  and flog.claim_id = bq.claim_id
  order by flog.modifieddate desc `;
  console.log("SQL:", dataURL);
  const res2 = await axios.get(urlPrefix + dataURL, authHeader);
  if (res2.data) {
    console.log("GOT Billing Files:", res2.data);
    let dframe = res2.data['frame0'];
    //console.log(dframe);
    let myObj = JSON.parse(dframe);
    console.log(myObj);
    let gridData = myObj['rows'];
    console.log("Claim Billing Logs:", gridData);
    setClaimsData(gridData);
  }

}


const loadRecentPaymentPosting = async () => {

  let authHeader = {};

  const authH = JSON.parse(localStorage.getItem('authHeader'));
  if (authH) {
    authHeader =  authH;
  }

  let urlPrefix = apiURL + '/exsql?dbserver=';
  let dataURL = `apex&sqltype=customSQL&sqltext=set rowcount 0 exec rc.billsp_get_recently_posted `;
  console.log("SQL:", dataURL);
  const res2 = await axios.get(urlPrefix + dataURL, authHeader);
  if (res2.data) {
    console.log("GOT Billing Files:", res2.data);
    let dframe = res2.data['frame0'];
    //console.log(dframe);
    let myObj = JSON.parse(dframe);
    console.log(myObj);
    let gridData = myObj['rows'];
    console.log("Claim Billing Logs:", gridData);
    setClaimsData(gridData);
  }

}


const loadRecentDemogUpdate = async () => {

  let authHeader = {};

  const authH = JSON.parse(localStorage.getItem('authHeader'));
  if (authH) {
    authHeader =  authH;
  }

  let urlPrefix = apiURL + '/exsql?dbserver=';
  let dataURL = `apex&sqltype=customSQL&sqltext=set rowcount 0 exec rc.billsp_get_recently_updated_demog `;
  console.log("SQL:", dataURL);
  const res2 = await axios.get(urlPrefix + dataURL, authHeader);
  if (res2.data) {
    console.log("GOT Billing Files:", res2.data);
    let dframe = res2.data['frame0'];
    //console.log(dframe);
    let myObj = JSON.parse(dframe);
    console.log(myObj);
    let gridData = myObj['rows'];
    console.log("Claim Billing Logs:", gridData);
    setClaimsData(gridData);
  }

}

const refreshClaimBillingLog = async (idtype, claimid) => {
  let authHeader = {};

  const authH = JSON.parse(localStorage.getItem('authHeader'));
  if (authH) {
    authHeader =  authH;
  }

  if (!idtype || !claimid) return;
  let urlPrefix = apiURL + '/exsql?dbserver=';
  let dataURL = `apex&sqltype=customSQL&sqltext=set rowcount 0 select  apex.dbo.username(userid) as username, * from   rc.billing_claim_log where entitytype = ${idtype} and claim_id =  ${claimid}  order by logdate desc `;
  console.log("SQL:", dataURL);
  const res2 = await axios.get(urlPrefix + dataURL, authHeader);
  if (res2.data) {
    console.log("GOT Billing Files:", res2.data);
    let dframe = res2.data['frame0'];
    //console.log(dframe);
    let myObj = JSON.parse(dframe);
    console.log(myObj);
    let gridData = myObj['rows'];
    console.log("Claim Billing Logs:", gridData);
    setClaimBillingLogData(gridData);
  }

};

React.useEffect(() => {
  refreshClaimsData();
}, []);

const handleClaimsRowSelected = () => {
  console.log("Claims row selected ");

  
  const selectedNodes = claimsGridRef.current && claimsGridRef.current.api
  .getSelectedNodes()
  .filter((node) => node.selected);
  
if (selectedNodes && selectedNodes.length) {
const datarow = selectedNodes[0].data;
console.log('AG Claim Row selected,', datarow);
if (datarow.flog_ent_type ===2) {
  setClaimBillingLogData([]);
  setClaimFolloupLogData([]);
  refreshClaimBillingLog(2,datarow.patientid);

}else {
  setClaimBillingLogData([]);
  setClaimFolloupLogData([]);
  refreshClaimFollowupLog(1,datarow.claim_id);
  refreshClaimBillingLog(1,datarow.claim_id);
}
}


}

const handleQuickFilter = (event) => {
  //console.log("Quick Filter:",event.target, event.target.value, claimsGridRef.current);
  setQuickFilter(event.target.value );
  claimsGridRef.current.api.setQuickFilter(event.target.value);
};

const onClaimsWithNoClaimID = () => {

  console.log("onClaims with No Claim ID", !withClaimIdZero);
  const instance = claimsGridRef.current.api.getFilterInstance('claim_id');
  if (!withClaimIdZero) {
      instance.setModel({
        filterType: 'number',
        type: 'greaterThan',
        filter: 0
        });
        claimsGridRef.current.api.onFilterChanged();
  }else {
    claimsGridRef.current.api.setFilterModel(null);
  }

  setWithClaimIdZero(!withClaimIdZero);
}

const handleClaimsFollowupLogRowSelected = () => {

  console.log("Claims Followup row selected ");

  
  const selectedNodes = claimsFollowupLogGridRef.current && claimsFollowupLogGridRef.current.api
  .getSelectedNodes()
  .filter((node) => node.selected);
  
if (selectedNodes && selectedNodes.length) {
const datarow = selectedNodes[0].data;
console.log('AG Claim Followup Row selected,', datarow);
setcurrentText(datarow.notes);

}

}

//


const handleClaimsBillingLogRowSelected = () => {
  
  console.log("Claims Billinglog row selected ");

  
  const selectedNodes = claimsBllingLogGridRef.current && claimsBllingLogGridRef.current.api
  .getSelectedNodes()
  .filter((node) => node.selected);
  
if (selectedNodes && selectedNodes.length) {
const datarow = selectedNodes[0].data;
console.log('AG Claim Billinglog Row selected,', datarow);
setcurrentText(datarow.lognotes);

}

}

  return (
    <Box>
      <HStack>
            <RadioGroup
              value={filterValue}
              onChange={handleFilterChange}
              colorScheme="teal"
              defaultValue="all"
              isInline
            >
              <Radio value="all">All</Radio>
              <Radio value="tofile">ToFile</Radio>
              <Radio value="fileopen">FileOpen</Radio>
              <Radio value="denials">Denials</Radio>
            </RadioGroup>

            <Button onClick={refreshClaimsData} colorScheme="blue">Refresh</Button>
            <Input as='input' value={quickFilter}  onChange={handleQuickFilter} width={'200px'} ref={initFocusRef} 
                placeholder="filter..." variant="filled" fontSize='sm' aria-multiline />
            <Text>{claimsGridRef.current && claimsGridRef.current.api.getDisplayedRowCount()}</Text>
            <Checkbox  isChecked={withClaimIdZero}  onChange={onClaimsWithNoClaimID}   colorScheme={withClaimIdZero ? 'green' : 'gray'}> 
                claim_id=0
            </Checkbox>
            <Button onClick={loadRecentFollowup} colorScheme="blue">Recent Followup</Button>
            <Button onClick={loadRecentPaymentPosting} colorScheme="blue">Recent Posting EOB</Button>
            <Button onClick={loadRecentDemogUpdate} colorScheme="blue">Recent Patient Info</Button>
            
      </HStack>


      <div className="ag-theme-alpine" style={{ height: '40vh', width: '95vw', marginTop: '10px', marginLeft: '-1px' }}>
        <AgGridReact 
        ref={claimsGridRef}
        columnDefs={[
          { headerName: 'modifieddate', field: 'modifieddate', width: 120, resizable: true,
          cellStyle: { fontSize: '12px' },
          valueFormatter: (params) => {
            // Check if visit_dos is a valid date
            const date = new Date(params.value);
            if (!isNaN(date.getTime())) {
              // Format the date as yyyy-mm-dd
              return date.toISOString().split(' ')[0];
            }
            // If it's not a valid date, return the original value
            return params.value;
          },
        },
        { headerName: 'entryby', field: 'enteredby', width: 100, resizable: true, },
          { headerName: 'visit_dos', field: 'visit_dos', width: 120, 
            cellStyle: { fontSize: '12px' },
            valueFormatter: (params) => {
              // Check if visit_dos is a valid date
              const date = new Date(params.value);
              if (!isNaN(date.getTime())) {
                // Format the date as yyyy-mm-dd
                return date.toISOString().split('T')[0];
              }
              // If it's not a valid date, return the original value
              return params.value;
            },
          },
          { headerName: 'claim_id', field: 'claim_id' , filter: true, width: 120,},
          { headerName: 'patient', field: 'patient', width: 180,  cellStyle: { fontSize: '12px' }, },
          { headerName: 'idtype', field: 'flog_ent_type' , filter: true, width: 60,},
          { headerName: 'patientid', field: 'patientid' , filter: true, width: 120,},

          { headerName: 'visittype', field: 'visittype', width: 120,  },
          { headerName: 'facility', field: 'facility', width: 120,  },
          { headerName: 'pos', field: 'invpos', width: 60,  },
          { headerName: 'claim_queue', field: 'claim_queue', filter: true, width: 120, },
          { headerName: 'claim_status', field: 'claim_status' , filter: true , width: 120,},
          { headerName: '#', field: 'cptcount' , width: 80, },
          { headerName: 'status', field: 'status', width: 120,  },
          { headerName: 'L', field: 'enclock', width: 60,  },
          { headerName: 'PatientInsurances', field: 'PatientInsurances' },
          { headerName: 'reason', field: 'reason' },
          { headerName: 'encounterid', field: 'encounterid' , filter: true, width: 120, },
       



          { headerName: 'claim_status_code', field: 'claim_status_code' , filter: true},

          { headerName: 'InvoiceAmount', field: 'InvoiceAmount' },
          { headerName: 'allowedfee', field: 'allowedfee' },
          { headerName: 'payment', field: 'payment' },
          { headerName: 'netpayment', field: 'netpayment' },
          { headerName: 'ptbalance', field: 'ptbalance' },
          { headerName: 'Balance', field: 'Balance' },
          { headerName: 'pinsname', field: 'pinsname' },
          { headerName: 'sinsname', field: 'sinsname' },
          { headerName: 'tinsname', field: 'tinsname' },
          { headerName: 'billedto', field: 'billedto' },
          { headerName: 'billedtoname', field: 'billedtoname' },
      ]}
       defaultColDef={{cellStyle: { fontSize: '12px' },}}
       rowData={claimsData}
       rowSelection="single"
       applyColumnDefOrder={true}
       onSelectionChanged={handleClaimsRowSelected}

        />
      </div>
      <HStack>
      <div className="ag-theme-alpine" style={{ height: '40vh', width: '47vw', marginTop: '10px', marginLeft: '-1px' }}>
        <AgGridReact 
        ref={claimsFollowupLogGridRef}
        columnDefs={[
          { headerName: 'modifieddate', field: 'modifieddate', width: 110, 
          cellStyle: { fontSize: '12px' },
          valueFormatter: (params) => {
            // Check if visit_dos is a valid date
            const date = new Date(params.value);
            if (!isNaN(date.getTime())) {
              // Format the date as yyyy-mm-dd
              return date.toISOString().split('T')[0];
            }
            // If it's not a valid date, return the original value
            return params.value;
          },
        },
        { headerName: 'username', field: 'username', width: 140,  cellStyle: { fontSize: '12px' }, },

        { headerName: 'notes', field: 'notes', width: 280,  },
        { headerName: 'userid', field: 'userid', width: 120,  },        
      ]}
       defaultColDef={{cellStyle: { fontSize: '12px' },}}
       rowData={claimFolloupLogData}
       rowSelection="single"
       applyColumnDefOrder={true}
       onSelectionChanged={handleClaimsFollowupLogRowSelected}

        />
      </div>
          <div className="ag-theme-alpine" style={{ height: '40vh', width: '47vw', marginTop: '10px', marginLeft: '-1px' }}>
        <AgGridReact 
        ref={claimsBllingLogGridRef}
        columnDefs={[
          { headerName: 'logdate', field: 'logdate', width: 120, 
            cellStyle: { fontSize: '12px' },
            valueFormatter: (params) => {
              // Check if visit_dos is a valid date
              const date = new Date(params.value);
              if (!isNaN(date.getTime())) {
                // Format the date as yyyy-mm-dd
                return date.toISOString().split('T')[0];
              }
              // If it's not a valid date, return the original value
              return params.value;
            },
          },
          { headerName: 'username', field: 'username', width: 140,  cellStyle: { fontSize: '12px' }, },
          { headerName: 'notes', field: 'lognotes', width: 160,  },
          { headerName: 'userid', field: 'userid', width: 120,  },
         
      ]}
       defaultColDef={{cellStyle: { fontSize: '12px' },}}
       rowData={claimBillingLogData}
       rowSelection="single"
       applyColumnDefOrder={true}
       onSelectionChanged={handleClaimsBillingLogRowSelected}

        />
      </div>
      
      </HStack>
      <Text>{currentText}</Text>
    </Box>
  );
};

export default ClaimListReview;
