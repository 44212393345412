import React from 'react';
import useState from 'react-usestateref';
import { AgGridReact } from 'ag-grid-react';
import axios from 'axios';
import { format } from 'date-fns'
import apiConfig from '../../apiConfig';
import { useDocument } from '../../Contexts/DocumentContext';
import CheckboxRenderer from '../../Components/CheckboxRenderer';
import CreateBillingTempClaim from '../../Modals/CreateBillingTempClaim';
import Draggable, { DraggableData, DraggableEvent }  from 'react-draggable';

import {
  Box,
  Button,
  Editable,
  EditablePreview,
  EditableTextarea,
  FormControl,
  FormLabel,
  Input,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Flex,
  HStack,
  VStack,
  Grid,
  GridItem,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  Text,
  Portal,
  Checkbox
} from "@chakra-ui/react";

import { SelectEcwPatient } from '../../Modals/SelectApexPatient';
import { SelectEcwBillingClaim } from '../../Modals/SelectBillingClaim';
import { SelectEcwCPT } from '../../Modals/SelectApexCPT';
import { SingleDatepicker } from "chakra-dayzed-datepicker";

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

const ManageBillingTempClaimCpts = (props) => {

  const documentContext = useDocument();
  const gridRef = React.useRef();
  const [popoverPlacementPosition, setPopoverPlacementPosition] = useState(1)
  const [popoverPlacement, setPopoverPlacement] = useState('top-start')
  const [rowData, setRowData] = useState([]);
  const [isClaimReadyChecked, setIsClaimReadyChecked] = useState(false);
  const [isClaimCptReadyChecked, setIsClaimCptReadyChecked] = useState(false);


  const [formClaimCPTData, setFormClaimCPTData] = useState();

  console.log("ManageBillingTempClassCpts component, props = ", props);
  let curDocumentPath=props? props.currentDocumentPath : '';
  let curDocumentPage=props? props.currentPageNum : null;

  const [newItem, setNewItem, newItemRef ] = useState({
    tcpt_id: '',
    tclaim_id: '',
    cptcode: '',
    cptdesc: '',
    modifiers: '',
    dxcodes: '',
    units: 1,
    comment: '',
    sdos: new Date(),
  });
  //const [selectedItem, setSelectedItem] = useState(null);
  const [selectedEcwBillingClaim, setSelectedEcwBillingClaim] = useState();
  const [currentClaimData, setCurrentClaimData] = useState();
  const [selectedClaimPatient, setSelectedClaimPatient] = useState();
  const [selectedEcwCPT, setselectedEcwCPT] = useState();
  const [currentClaimID, setcurrentClaimID] = useState();
  const [currentClaimCptID, setcurrentClaimCptID] = useState();
  const [isCPTItemSelected, setisCPTItemSelected] = useState(false);
  const [isNewClaimModeOn, setIsNewClaimModeOn] = useState(false);
  const [isEditExistingClaimModeOn, setisEditExistingClaimModeOn] = useState(false);
  const [isEditExistingClaimCPTsModeOn, setisEditExistingClaimCPTsModeOn] = useState(false);
  const [isPageLinkedToCPT, setIsPageLinkedToCPT] = useState(false);

  const [windowPosition, setWindowPosition] = useState ({
    posX : 100,
    posY : 100
  });

  const onDrag = (e, data) => {
    console.log(`Moving to new position : ${data.lastX} ${data.lastY} `)
    setWindowPosition({ poxX: data.lastX, posY: data.lastY });
  };


  const columnDefs = [


    { headerName: 'Start DOS', field: 'sdos', sortable: true, filter: false, width: 130, resizable: true },
    { headerName: 'CPT', field: 'cptcode', sortable: true, filter: false, editable: false, width: 90, resizable: true },
    { headerName: 'U', field: 'units', sortable: true, filter: false, width: 50, resizable: true },
    { headerName: 'Ready', field: 'cpt_status', width: 90, sortable: false, },
    { headerName: 'Desc', field: 'cptdesc', sortable: true, filter: false, editable: false, width: 180, resizable: true },
    { headerName: 'Mod', field: 'modifiers', sortable: true, filter: false, resizable: true, width: 120 },
    { headerName: 'ID', field: 'claimcpt_id', sortable: true, filter: false, checkboxSelection: true, width: 120 },
    { headerName: 'Dx', field: 'dxcodes', sortable: true, filter: false, resizable: true, width: 120 },
    { headerName: 'cmt', field: 'comment', sortable: true, filter: false, resizable: true, width: 120 },
    { headerName: 'TClaim ID', field: 'tclaim_id', sortable: true, filter: true },
    // Add more columns as needed
  ];

  const onNextPopoverPlacement = () => {

    console.log("Trying to move to next position", popoverPlacementPosition);
    switch (popoverPlacementPosition ? popoverPlacementPosition + 1 : 1) {
      case 1 : //setPopoverPlacement('right-end');
               setPopoverPlacementPosition(popoverPlacementPosition + 1);
               setWindowPosition({
                posX: windowPosition.posX + 100
               })
               console.log("Setting to top-start");
               break;
      case 2 : //setPopoverPlacement('right');
      setWindowPosition({
        posX: windowPosition.posX + 100
       })
               setPopoverPlacementPosition(popoverPlacementPosition + 1);
               console.log("Setting to bottom-start");
               break;
      case 3: //setPopoverPlacement('right-start');
      setWindowPosition({
        posX: windowPosition.posX + 100
       })
               setPopoverPlacementPosition(popoverPlacementPosition + 1);
               console.log("Setting to right-start");
               break;
      case 4: //setPopoverPlacement('left-end');
      setWindowPosition({
        posX: windowPosition.posX + 100
       })
               setPopoverPlacementPosition(popoverPlacementPosition + 1);
               console.log("Setting to right-start");
               break;

      case 5: //setPopoverPlacement('left');
      setWindowPosition({
        posX: windowPosition.posX + 100
       })
               setPopoverPlacementPosition(popoverPlacementPosition + 1);
               console.log("Setting to right-start");
               break;

      case 6 : //setPopoverPlacement('left-start');
      setWindowPosition({
        posX: windowPosition.posX + 100
       })
               setPopoverPlacementPosition(popoverPlacementPosition + 1);
               console.log("Setting to bottom-start");
               break;
      case 7: //setPopoverPlacement('top-end');
      setWindowPosition({
        posX: windowPosition.posX + 100
       })
               setPopoverPlacementPosition(popoverPlacementPosition + 1);
               console.log("Setting to right-start");
               break;
      case 8: //setPopoverPlacement('top');
      setWindowPosition({
        posX: windowPosition.posX + 100
       })
               setPopoverPlacementPosition(popoverPlacementPosition + 1);
               console.log("Setting to right-start");
               break;

      case 9: //setPopoverPlacement('auto-start');
      setWindowPosition({
        posX: windowPosition.posX + 100
       })
               setPopoverPlacementPosition(popoverPlacementPosition + 1);
               console.log("Setting to right-start");
               break;

      default:
        setPopoverPlacementPosition(1);
        //setPopoverPlacement('top-start');
        setWindowPosition({
          posX: 100
         })
        console.log("Setting to bottom-start");

    }

    //setPopoverPlacement('left-start');
  }


  const linkPageToCPT = async (claimPatientId, claimCptID, documentPath, documentPage) => {

    console.log("Will Link Page from context", documentPath, documentPage, claimPatientId, claimCptID);


    if (!claimPatientId ||  !claimCptID || !documentPath || !documentPage) return ;

  let authHeader = {};

  const authH = JSON.parse(localStorage.getItem('authHeader'));
  if (authH) {
    authHeader =  authH;
  }


  let urlPrefix = apiURL + '/exsql?dbserver=';
  let dataURL = `apex&sqltype=customSQL&sqltext=exec apex.rc.billsp_manage_doculink
  @arg_op = 'E', 
  @patient_id = ${claimPatientId},
  @linkcategory = 'cptinfo',
  @docpagenum = ${documentPage},
  @document_path = '${documentPath}',
  @ecwdoc_id = NULL,
  @apexdoc_id  = NULL,
  @arg_entity_linkto = 'cpt' ,
  @arg_entity_id  = ${claimCptID} ,
  @comment = '' ,
  @delflag  = NULL

     `;

  console.log("SQL to execute : ", dataURL)

   const res2 = await axios.get(urlPrefix + dataURL, authHeader);
  if (res2.data) {
    console.log("GOT result from add temp Billing patient:", res2.data);
    let dframe = res2.data['frame0'];

    setIsPageLinkedToCPT(true);
    //console.log(dframe);
    // let myObj = JSON.parse(dframe);

    // let gridData = myObj['rows'];
    // console.log(myObj, gridData);
    // rowdata = {...rowdata , tpat_id : gridData[0].tpat_id};
    // console.log("NewRowData after insert:", rowdata);
    // //setRowData([...rowData, rowdata]);

  //refreshDocumentLinksTable(`${selectedBillingSheetFile.dirpath}/${selectedBillingSheetFile.filename}`);

  }

  }

  const handleRemoveLink = async (claimCptID, documentPath, documentPage) => {
    console.log("Removing page link from CPT", claimCptID, documentPath, documentPage);

    if (!claimCptID || !documentPath ||  !documentPage) return;

        let authHeader = {};

        const authH = JSON.parse(localStorage.getItem('authHeader'));
        if (authH) {
          authHeader =  authH;
        }

        // link_id
        // linkcategory
        // attach_id


        let urlPrefix = apiURL + '/exsql?dbserver=';
        let dataURL = `apex&sqltype=customSQL&sqltext= delete rc.billings_links_attach
        from rc.billings_links_attach atch, rc.billing_links lnk
          where lnk.link_id = atch.link_id
          and lnk.document_path = '${documentPath}'
          and lnk.docpagenum = ${documentPage}
          and atch.entity_type = 'cpt'
          and atch.entity_id =${claimCptID} `;

        console.log("SQL to execute : ", dataURL)

        const res2 = await axios.get(urlPrefix + dataURL, authHeader);
            if (res2.data) {
                console.log("GOT result from add temp Billing patient:", res2.data);
                let dframe = res2.data['frame0'];

                setIsPageLinkedToCPT(false);

             // console.log("Will refresh table for the document", `${selectedBillingSheetFile.dirpath}/${selectedBillingSheetFile.filename}`);

             // refreshDocumentLinksTable(`${selectedBillingSheetFile.dirpath}/${selectedBillingSheetFile.filename}`);

            }

}

  React.useEffect(() => {

    console.log("Checking page link existance:", curDocumentPath, curDocumentPage, currentClaimCptID);
    if (curDocumentPage && currentClaimCptID && curDocumentPath )  isPageLinkedtoCPT(currentClaimCptID, curDocumentPath, curDocumentPage);

  }, [curDocumentPath, curDocumentPage, currentClaimCptID]);

  const getBillingClaimCPTs = async (claim_id) => {

    console.log("Will get CPTs :", claim_id);

    let authHeader = {};

    const authH = JSON.parse(localStorage.getItem('authHeader'));
    if (authH) {
      authHeader = authH;
    }



    let urlPrefix = apiURL + '/exsql?dbserver=';
    let dataURL = `apex&sqltype=customSQL&sqltext=select * from apex.rc.billing_claim_cpts where claim_id = ${claim_id}  `;


    console.log("SQL to execute : ", dataURL)
    const res2 = await axios.get(urlPrefix + dataURL, authHeader);
    if (res2.data) {
      console.log("GOT result from add temp Billing claim:", res2.data);
      let dframe = res2.data['frame0'];
      //console.log(dframe);
      let myObj = JSON.parse(dframe);
      console.log(myObj);
      let gridData = myObj['rows'];
      if (gridData) {
        if (gridData[0]) {
          setIsClaimReadyChecked(gridData[0].claim_status === 1 ? true : false);
        }
      }
      console.log("CPT Grid", gridData);
      setFormClaimCPTData(gridData);
      setisCPTItemSelected(isItemSelected());
    }


  }

  const handleRefresh = () => {
    handleClear();
    if (currentClaimID > 0) {
      console.log("Attempting refresh:", currentClaimID);
      getBillingClaimCPTs(currentClaimID);
    }
  }

  const saveBillingTClaimCPT = async (rowdata) => {

    console.log("Will save current CPT row:", rowdata, documentContext, selectedEcwBillingClaim);

    console.log("DOCUMENT CONTEXT", documentContext);
    let docpagenum = documentContext.docinfo.documentpage;
    let docpath = documentContext.docinfo.documentpath;
    console.log("DOC CONTXT", docpagenum, docpath);

    let authHeader = {};

    const authH = JSON.parse(localStorage.getItem('authHeader'));
    if (authH) {
      authHeader = authH;
    }

    let urlPrefix = apiURL + '/exsql?dbserver=';

    let claim_id_to_use = currentClaimID;
    if (!claim_id_to_use) {
      if (selectedEcwBillingClaim) {
        claim_id_to_use = selectedEcwBillingClaim.claim_id;
        if (!claim_id_to_use) claim_id_to_use = selectedEcwBillingClaim.claim_id;
      }
      if (!claim_id_to_use && currentClaimData) claim_id_to_use = currentClaimData.tclaim_id;


      if (!claim_id_to_use) {
        console.log("Failed to establish claim ID context:", claim_id_to_use, selectedEcwBillingClaim, currentClaimData);
        return;

      }
    }

    console.log("Ready to exe proc billsp_manage_tcpt", rowdata);
    if (curDocumentPage === undefined) {
      curDocumentPage='null'
    }
    let dataURL = `apex&sqltype=customSQL&sqltext=exec apex.rc.billsp_manage_tcpt
        @arg_op = 'E'  ,
        @tcpt_id  = ${rowdata.claimcpt_id} ,
        @tclaim_id  = ${claim_id_to_use},
        @cptcode = '${rowdata.cptcode}',
        @cptdesc = '${encodeURIComponent(rowdata.cptdesc)}',
        @tcpt_status = ${rowdata.cpt_status ? 1 : 0},
        @modifiers = '${rowdata.modifiers}',
        @dxcodes= '${rowdata.dxcodes}',
        @units = ${rowdata.units},
        @sdos = '${format(rowdata.sdos, 'yyyy-MM-dd')}',
        @docpagenum  = ${curDocumentPage},
        @document_path = '${curDocumentPath}',
        @ecwdoc_id  = NULL,
        @apexdoc_id  = NULL,
        @comment = '${rowdata.comment}' 

       `;


    console.log("SQL to execute : ", dataURL)
    const res2 = await axios.get(urlPrefix + dataURL, authHeader);
    if (res2.data) {
      console.log("GOT result from add temp Billing claim:", res2.data);
      // let dframe = res2.data['frame0'];
      // //console.log(dframe);
      // let myObj = JSON.parse(dframe);
      // console.log(myObj);
      // let gridData = myObj['rows'];
      // rowdata = {...rowdata , tcpt_id : gridData[0].tcpt_id};
      // console.log("NewRowData after insert:", rowdata);
      getBillingClaimCPTs(claim_id_to_use);
    }


  }

  const updateBillingTClaimCPT000 = async (rowdata) => {

    console.log("Will update current CPT row:", rowdata, documentContext, selectedEcwBillingClaim);

    console.log("DOCUMENT CONTEXT", documentContext);
    let docpagenum = documentContext.docinfo.documentpage;
    let docpath = documentContext.docinfo.documentpath;
    console.log("DOC CONTXT", docpagenum, docpath);

    let authHeader = {};

    const authH = JSON.parse(localStorage.getItem('authHeader'));
    if (authH) {
      authHeader = authH;
    }

    let urlPrefix = apiURL + '/exsql?dbserver=';

    let claim_id_to_use = currentClaimID;
    if (!claim_id_to_use) {
      if (selectedEcwBillingClaim) {
        claim_id_to_use = selectedEcwBillingClaim.claim_id;
        if (!claim_id_to_use) claim_id_to_use = selectedEcwBillingClaim.claim_id;
      }
      if (!claim_id_to_use && currentClaimData) claim_id_to_use = currentClaimData.tclaim_id;


      if (!claim_id_to_use) {
        console.log("Failed to establish claim ID context:", claim_id_to_use, selectedEcwBillingClaim, currentClaimData);
        return;

      }
    }


    console.log("Ready to exe proc billsp_manage_tcpt", rowdata);
    let dataURL = `apex&sqltype=customSQL&sqltext=exec apex.rc.billsp_manage_tcpt
        @arg_op = 'E'  ,
        @tcpt_id  = NULL,
        @tclaim_id  = ${claim_id_to_use},
        @cptcode = '${rowdata.cptcode}',
        @cptdesc = '${encodeURIComponent(rowdata.cptdesc)}',
        @tcpt_status = ${rowdata.cpt_status ? 1 : 0},
        @modifiers = '${rowdata.modifiers}',
        @dxcodes= '${rowdata.dxcodes}',
        @units = ${rowdata.units},
        @sdos = '${format(rowdata.sdos, 'yyyy-MM-dd')}',

        @docpagenum  = ${curDocumentPage},
        @document_path = '${curDocumentPath}',
        @ecwdoc_id  = NULL,
        @apexdoc_id  = NULL,
        @comment = '${rowdata.comment}'

       `;


    console.log("SQL to execute : ", dataURL)
    const res2 = await axios.get(urlPrefix + dataURL, authHeader);
    if (res2.data) {
      console.log("GOT result from add temp Billing claim:", res2.data);
      let dframe = res2.data['frame0'];
      //console.log(dframe);
      let myObj = JSON.parse(dframe);
      console.log(myObj);
      let gridData = myObj['rows'];
      rowdata = { ...rowdata, tcpt_id: gridData[0].tcpt_id };
      console.log("NewRowData after insert:", rowdata);
      getBillingClaimCPTs(rowdata.tclaim_id);
    }


  }



  /// CPT ROWS

  const onSetNewItem = (row) => {
    console.log("SetNewItem Called", row);
    setNewItem(row);
  }


  const handleAdd = () => {
    console.log("Add row called", newItemRef.current, selectedEcwCPT, selectedEcwBillingClaim);

    let claim_id_to_use = currentClaimID;
    if (!claim_id_to_use) {
      if (selectedEcwBillingClaim) {
        claim_id_to_use = selectedEcwBillingClaim.claim_id;
        if (!claim_id_to_use) claim_id_to_use = selectedEcwBillingClaim.claim_id;
      }
      if (!claim_id_to_use && currentClaimData) claim_id_to_use = currentClaimData.tclaim_id;


      if (!claim_id_to_use) {
        console.log("Failed to establish claim ID context:", claim_id_to_use, selectedEcwBillingClaim, currentClaimData);
        return;

      }
    }

    let rowdata = {
      claimcpt_id: null,
      tclaim_id: claim_id_to_use,
      cptcode: selectedEcwCPT.cptcode,
      cptdesc: selectedEcwCPT.cptdesc,
      sdos: newItemRef.current.sdos,
      comment: newItemRef.current.comment,
      units: newItemRef.current.units >= 0 ? newItemRef.current.units : 1,
      modifiers: newItemRef.current.modifiers,
      dxcodes: newItemRef.current.dxcodes
    }

    saveBillingTClaimCPT(rowdata);
    //setRowData([...rowData, newItem]);
    setNewItem({
      tcpt_id: '',
      tclaim_id: '',
      cptcode: '',
      cptdesc: '',
      cpt_status: false,
      modifiers: '',
      dxcodes: '',
      units: 1,
      sdos: new Date(),
      comment: ''
    });
  };



  const handleUpdate = () => {
    console.log("Update row called");

    const selectedNodes = gridRef.current.api
      .getSelectedNodes()
      .filter((node) => node.selected);
    if (!selectedNodes || selectedNodes.length === 0) {
      console.log("UPdate Called, no row selected");
      setisCPTItemSelected(isItemSelected());
      return;
    }
    console.log("Selected Nodes", selectedNodes);
    const selectedItem = selectedNodes[0].data;

    if (selectedItem) {
      console.log("Update row called on selected item: ", newItem, selectedItem);

      console.log("Add row called", newItemRef.current, selectedEcwCPT, selectedEcwBillingClaim);



      let rowdata = {
        claimcpt_id: selectedItem.claimcpt_id,
        tclaim_id: selectedItem.claim_id,
        cptcode: selectedItem.cptcode,
        cptdesc: selectedItem.cptdesc,
        sdos: newItemRef.current.sdos,
        cpt_status: newItemRef.current.cpt_status,
        comment: newItemRef.current.comment,
        units: newItemRef.current.units >= 0 ? newItemRef.current.units : 1,
        modifiers: newItemRef.current.modifiers,
        dxcodes: newItemRef.current.dxcodes
      }

      saveBillingTClaimCPT(rowdata);

      // const updatedData = rowData.map(item =>
      //   item.tcpt_id === selectedItem.tcpt_id ? selectedItem : item
      // );
      // setRowData(updatedData);
      //setSelectedItem(null);
    }
  };

  const handleClear = () => {
    //setSelectedItem(null);
    setNewItem({
      tcpt_id: '',
      tclaim_id: '',
      cptcode: '',
      cptdesc: '',
      modifiers: '',
      cpt_status: false,
      dxcodes: '',
      units: 1,
      sdos: new Date(),
      comment: ''
    });
    setSelectedClaimPatient();
    setSelectedEcwBillingClaim();
    setCurrentClaimData();
    setselectedEcwCPT();
    setcurrentClaimID();
    setcurrentClaimCptID();



  }



  const isItemSelected = () => {

    if (!gridRef || !gridRef.current || !gridRef.current.api) return false;

    const selectedNodes = gridRef.current.api
      .getSelectedNodes()
      .filter((node) => node.selected);

    if (!selectedNodes || selectedNodes.length === 0) return false;

    console.log("Selected Nodes", selectedNodes);
    const selectedItem = selectedNodes[0].data;

    if (selectedItem) {
      return true;
    }
    return false;
  }

  const handleDelete = async () => {
    console.log("Delete row called");

    const selectedNodes = gridRef.current.api
      .getSelectedNodes()
      .filter((node) => node.selected);

    if (!selectedNodes || selectedNodes.length === 0) {
      console.log("Delete Called, no row selected");
      setisCPTItemSelected(isItemSelected());
      return;
    }

    console.log("Selected Nodes", selectedNodes);
    const selectedItem = selectedNodes[0].data;

    if (selectedItem) {
      console.log("Delete row called on selected item: ", selectedItem);

      /////////////////


      let authHeader = {};

      const authH = JSON.parse(localStorage.getItem('authHeader'));
      if (authH) {
        authHeader = authH;
      }

      let urlPrefix = apiURL + '/exsql?dbserver=';


      let dataURL = `apex&sqltype=customSQL&sqltext=exec apex.rc.billsp_manage_tcpt
        @arg_op = 'D'  ,
        @tcpt_id  = ${selectedItem.claimcpt_id}

       `;


      console.log("SQL to execute : ", dataURL)
      const res2 = await axios.get(urlPrefix + dataURL, authHeader);
      if (res2.data) {
        console.log("GOT result from add temp Billing claim:", res2.data);
        let dframe = res2.data['frame0'];
        //console.log(dframe);
        // let myObj = JSON.parse(dframe);
        // console.log(myObj);
        // let gridData = myObj['rows'];
        // rowdata = {...rowdata , tcpt_id : gridData[0].tcpt_id};
        // console.log("NewRowData after insert:", rowdata);
        getBillingClaimCPTs(selectedItem.claim_id);
      }

      // const filteredData = rowData.filter(item => item.tcpt_id !== selectedItem.tcpt_id);
      // setRowData(filteredData);
      //setSelectedItem(null);
    }
  };

  const onSelectedClaimPatient = (data) => {
    console.log("select ptDoc patient in documanager", data);
    setSelectedClaimPatient(data);
  }



  const onsetSelectedEcwCPT = (data) => {
    console.log("select CPT documanager", data);
    setselectedEcwCPT(data);
  }


  const OnSetSelectedEcwBillingClaim = (claim) => {
    console.log("Selected Claim to work on: ", claim);
    setSelectedEcwBillingClaim(claim);
    //Lookup the CPTs in this claim and set it to  invcpt

    let tclaim_id = claim.tclaim_id;
    let claim_id = claim.claim_id;

    console.log("Chooing claim or tclaim", claim_id, tclaim_id);
    if (!claim_id) {
      claim_id = claim.tclaim_id;
    }
    console.log("Using  claim ", claim_id);
    setcurrentClaimID(claim_id);

    getBillingClaimCPTs(claim_id);

  }

  const handleClaimReadyChange = async (event) => {

    console.log("Mark Claim  ready to bill status : ", !isClaimReadyChecked, currentClaimID);

    if (!currentClaimID) return;

    let authHeader = {};

    const authH = JSON.parse(localStorage.getItem('authHeader'));
    if (authH) {
      authHeader = authH;
    }

    let urlPrefix = apiURL + '/exsql?dbserver=';

    let claim_id_to_use = currentClaimID;

    let dataURL = `apex&sqltype=customSQL&sqltext=update apex.rc.billing_tclaim set tclaim_status =  ${!isClaimReadyChecked ? 1 : 0} where tclaim_id = ${currentClaimID} if exists (select 'x' from apex.rc.billing_tclaim where tclaim_id = ${currentClaimID} and  tclaim_status = 1) update apex.rc.billing_tcpt set tcpt_status = 1 where tclaim_id = ${currentClaimID}
     
       `;


    console.log("SQL to execute : ", dataURL)
    const res2 = await axios.get(urlPrefix + dataURL, authHeader);
    if (res2.data) {
      console.log("succssfully update tclaim_status:", res2.data);

    }



    setIsClaimReadyChecked(!isClaimReadyChecked);
    //onSetNewItem({ ...newItem, edos: val });
  }

  const handleClaimCptReadyChange = (event) => {

    onSetNewItem({ ...newItemRef.current, cpt_status: !newItemRef.current.cpt_status });
    console.log("Mark Claim CPT s ready to bill", currentClaimID, event, !isClaimCptReadyChecked);
    setIsClaimCptReadyChecked(!isClaimCptReadyChecked);

  }

  //isDisabled={selectedItem? false : true}

  const isPageLinkedtoCPT = async (claimcpt_id, documentpath, documentpage) => {

      console.log("Will check if page linked to cpt :",claimcpt_id, documentpath, documentpage);

      let authHeader = {};

      const authH = JSON.parse(localStorage.getItem('authHeader'));
      if (authH) {
        authHeader = authH;
      }

      let urlPrefix = apiURL + '/exsql?dbserver=';

      let dataURL = `apex&sqltype=customSQL&sqltext=select 1 as link_exists from rc.billings_links_attach atch, rc.billing_links lnk
      where lnk.link_id = atch.link_id
      and lnk.document_path = '${documentpath}'
      and lnk.docpagenum = ${documentpage}
      and atch.entity_type = 'cpt'
      and atch.entity_id =${claimcpt_id}   `;

      console.log("SQL to execute : ", dataURL)
      const res2 = await axios.get(urlPrefix + dataURL, authHeader);
      if (res2.data) {
        console.log("GOT result from add temp Billing claim:", res2.data);
        let dframe = res2.data['frame0'];
        //console.log(dframe);
        let myObj = JSON.parse(dframe);
        console.log(myObj);
        let gridData = myObj['rows'];
        if (gridData && gridData[0] && gridData[0].link_exists) setIsPageLinkedToCPT(true);
        else setIsPageLinkedToCPT(false);
        return
      }
      setIsPageLinkedToCPT(false);
  }

  const handleClearCPTForm = () => {

    onSetNewItem({ ...newItemRef.current,
      tcpt_id: '',
      tclaim_id: '',
      cptcode: '',
      cptdesc: '',
      modifiers: '',
      dxcodes: '',
      units: 1,
      comment: '',
      sdos: new Date()
    })

  }

  const handleGridRowSelected =  (event) => {

    //console.log("handleGridRowSelected:",event);
    //setSelectedItem(event.api.getSelectedRows()[0]);

    setisCPTItemSelected(isItemSelected());

    const selectedNodes = event.api
      .getSelectedNodes()
      .filter((node) => node.selected);

    if (!selectedNodes || selectedNodes.length === 0) {
      console.log("handle row selected, but no row selected");
      setisCPTItemSelected(isItemSelected());
      return;
    }
    console.log("Selected Nodes on Claim CPT LIST", selectedNodes);
    if (selectedNodes.length == 1) {
      const datarow = selectedNodes[0].data;
      let claimcpt_id = datarow.claimcpt_id;
      let docpat = documentContext.docinfo.documentpath;
      let docpage = documentContext.docinfo.documentpage;
      console.log("CPT selected for page check", claimcpt_id, docpat, docpage, datarow);
      setcurrentClaimCptID(claimcpt_id);
      //isPageLinkedtoCPT(claimcpt_id, docpat, documentContext.docinfo.documentpage);

      setNewItem({
        tcpt_id: datarow.claim_id,
        tclaim_id: datarow.claimcpt_id,
        cptcode: datarow.cptcode,
        cptdesc: datarow.cptdesc,
        cpt_status: datarow.cpt_status === 1 ? true : false,
        modifiers: datarow.modifiers,
        dxcodes: datarow.dxcodes,
        units: datarow.units,
        sdos: Date.parse(datarow.sdos.substring(0, 10) + ' 00:00:00 GMT-0600'),
        comment: datarow.comment
      });

      console.log('AG Row selected', datarow, newItem);
    }
    // setSelectedEcwInsurance(datarow);
    // setQuickInsuranceFilter(datarow.Name);

  };

  const onNewClaimCreateMode = () => {
    setIsNewClaimModeOn(true);
    setisEditExistingClaimModeOn(false);
    setisEditExistingClaimCPTsModeOn(false);
    setcurrentClaimID(null);
    setSelectedEcwBillingClaim(null);
  }

  const onEditExistingClaimMode = () => {
    setIsNewClaimModeOn(false);
    setisEditExistingClaimModeOn(true);
    setisEditExistingClaimCPTsModeOn(false);
  }

  const onEditExistingClaimCPTsMode = () => {
    setIsNewClaimModeOn(false);
    setisEditExistingClaimModeOn(false);
    setisEditExistingClaimCPTsModeOn(true);
  }

  let apiURL = apiConfig.REACT_APEX_BASE_API;
  let formModeEdit = props.isEdit ? true : false;
  let argClaimID = props.claimID ? props.claimID : null;
  // if (argClaimID != currentClaimID) {
  //   console.log("New input received. Updaging claim id", argClaimID, currentClaimID);
  //   setcurrentClaimID(argClaimID);
  // }


  React.useEffect(() => {

    if ( selectedEcwBillingClaim) {
      if (!isEditExistingClaimCPTsModeOn) {
        setisEditExistingClaimCPTsModeOn(true);
      }
    }

    if (selectedEcwBillingClaim && selectedEcwBillingClaim.claim_id && (selectedEcwBillingClaim.claim_id !== currentClaimID)) {
      console.log("Loading CPTs for the selected claim: ", selectedEcwBillingClaim.claim_id, currentClaimID);
      setcurrentClaimID(selectedEcwBillingClaim.claim_id);
      getBillingClaimCPTs(selectedEcwBillingClaim.claim_id);
    }

  }, [ selectedEcwBillingClaim]);

  React.useEffect(() => {

    if (argClaimID > 0 ) {
      if (!isEditExistingClaimCPTsModeOn) {
        setisEditExistingClaimCPTsModeOn(true);
      }
    }

    if (argClaimID) {
      console.log("Loading CPTs for the claim: ", argClaimID, currentClaimID);
      if (currentClaimID !== argClaimID) {
        setcurrentClaimID(argClaimID);
        getBillingClaimCPTs(argClaimID);
      }
    }

  }, [argClaimID]);

  console.log("Selected cpt", selectedEcwCPT);

  return (
    <Popover isLazy minW={{ base: "50vw" }}   closeOnBlur={false} >

      <PopoverTrigger>
        <Button colorScheme="blue" mr={3} >{formModeEdit ? 'Edit Claim' : 'New Claim'}</Button>
      </PopoverTrigger>
      <Draggable handle=".modal-drag-handle"
            position={{
              x: windowPosition.posX,
              y: windowPosition.posY
            }}
            onDrag={onDrag}
      >
      <PopoverContent className='test'  bg='lightsalmon' color='black' w='750px'  style={{ position: 'relative', x: windowPosition.posX, y: windowPosition.posY}}>
        <PopoverHeader fontWeight='semibold'>

        <Box className="modal-drag-handle" cursor="grab">
          <Text> Claim Create or Edit</Text>

            </Box>


        </PopoverHeader>



        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody >

          <Box>
            {/* <HStack>
    <SelectEcwPatient selectedEcwPatient={selectedClaimPatient} setSelectedEcwPatient={onSelectedClaimPatient} />
      </HStack> */}

            <VStack>
              <SelectEcwBillingClaim currentClaimID={currentClaimID} selectedEcwBillingClaim={selectedEcwBillingClaim} setSelectedEcwBillingClaim={OnSetSelectedEcwBillingClaim} />

              <HStack>

                {/* {isNewClaimModeOn ? (<></>) : (<>  <Button
                  width='90px'
                  border='2px'
                  borderColor='green.500'
                  flex={2}
                  onClick={onNewClaimCreateMode}
                >New Claim</Button> <Button
                  width='90px'
                  border='2px'
                  borderColor='edit.500'
                  flex={2}
                  onClick={onEditExistingClaimMode}
                >Edit Claim</Button> </>)} */}


{/* {isEditExistingClaimModeOn || isEditExistingClaimCPTsModeOn ? (<> <Button
                  width='90px'
                  border='2px'
                  borderColor='green.500'
                  flex={2}
                  onClick={onNewClaimCreateMode}
                >New Claim</Button></>) : (<></>)} */}


                {isEditExistingClaimModeOn ? (<><Button
                  width='90px'
                  border='2px'
                  borderColor='green.500'
                  flex={2}
                  onClick={onEditExistingClaimCPTsMode}
                >Edit CPTs</Button></>) : (<></>)}

            {isEditExistingClaimCPTsModeOn ? (<>  <Button
                width='90px'
                border='2px'
                borderColor='edit.500'
                flex={2}
                onClick={onEditExistingClaimMode}
              >Edit Claim</Button></>) : (<></>)}

{ (!isNewClaimModeOn || !isEditExistingClaimCPTsModeOn || !isEditExistingClaimModeOn) || (isEditExistingClaimModeOn || isEditExistingClaimCPTsModeOn) ? (<> <Button
                  width='90px'
                  border='2px'
                  borderColor='green.500'
                  flex={2}
                  onClick={onNewClaimCreateMode}
                >New Claim</Button> </>) : (<></>)}


                {/*<Button colorScheme='green' onClick={handleRefresh}>*/}
                {/*  Refresh*/}
                {/*</Button>*/}
                <Checkbox
                  isChecked={isClaimReadyChecked}
                  onChange={handleClaimReadyChange}
                  colorScheme={isClaimReadyChecked ? 'green' : 'gray'}
                >
                  Ready
                </Checkbox>

              </HStack>
            </VStack>


            { (isNewClaimModeOn || isEditExistingClaimModeOn ) && (!isEditExistingClaimCPTsModeOn ) ? (

              <>
                <CreateBillingTempClaim isNewClaimModeOn={isNewClaimModeOn}  currentClaimID={currentClaimID} selectedEcwBillingClaim={selectedEcwBillingClaim} setSelectedEcwBillingClaim={OnSetSelectedEcwBillingClaim}  curDocumentPath={curDocumentPath} curDocumentPage={curDocumentPage} />
              </>

            ) : (
              <>
                <div className="ag-theme-alpine" style={{ width: '100%', marginTop: '1rem' }}>
                  <AgGridReact
                    ref={gridRef}
                    rowData={formClaimCPTData}
                    columnDefs={columnDefs}
                    domLayout='autoHeight'
                    rowSelection='single'
                    rowHeight={20}
                    animateRows={true}
                    resizable={true}
                    onSelectionChanged={handleGridRowSelected}
                    frameworkComponents={{
                      checkboxRenderer: CheckboxRenderer
                    }}
                  ></AgGridReact>
                </div>

                {/* Input fields for adding or updating */}


                {/* <HStack>
           <Text>CPT: {selectedEcwCPT ? selectedEcwCPT.cptcode : ''}  {'  '}  {selectedEcwCPT ? selectedEcwCPT.cptdesc : ''}</Text>
        </HStack> */}
                <Text>{curDocumentPath} {curDocumentPage}</Text>
                <HStack>

                  <SelectEcwCPT selectedEcwCPT={selectedEcwCPT} setSelectedEcwCPT={onsetSelectedEcwCPT} />

                  <Text>Dx</Text>
                  <Input
                    placeholder='Dx'
                    value={newItemRef.current.dxcodes}
                    onChange={e => onSetNewItem({ ...newItemRef.current, dxcodes: e.target.value })}
                  />
                  {/*<Checkbox*/}
                  {/*  isChecked={newItemRef.current.cpt_status}*/}
                  {/*  onChange={handleClaimCptReadyChange}*/}
                  {/*  colorScheme={newItemRef.current.cpt_status ? 'green' : 'gray'}*/}
                  {/*>*/}
                  {/*  Ready*/}
                  {/*</Checkbox>*/}
                  <Button colorScheme='gray' onClick={handleClearCPTForm} isDisabled={isItemSelected() ? true : false} >
                    Clear
                  </Button>
                </HStack>
                <HStack>
                  <Text>Mod</Text>
                  <Input
                    placeholder='Modifiers'
                    value={newItemRef.current.modifiers}
                    onChange={e => onSetNewItem({ ...newItemRef.current, modifiers: e.target.value })}
                  />
                  <Text>Units </Text>
                  <Input
                    placeholder='Units'
                    value={newItemRef.current.units}
                    onChange={e => onSetNewItem({ ...newItemRef.current, units: e.target.value })}
                  />
                  <Text>DOS</Text>
                  <SingleDatepicker
                    name="date-input"
                    date={newItemRef.current.sdos}
                    onDateChange={val => onSetNewItem({ ...newItemRef.current, sdos: val })}
                  />

                  {/*<Text>EDOS </Text>*/}
                  {/*<SingleDatepicker*/}
                  {/*  name="date-input"*/}
                  {/*  date={newItemRef.current.edos}*/}
                  {/*  onDateChange={val => onSetNewItem({ ...newItemRef.current, edos: val })}*/}
                  {/*/>*/}

                </HStack>
                <HStack>
                  <Text>Comment</Text>
                  <Input
                    placeholder='Comments'
                    value={newItemRef.current.comment}
                    onChange={e => onSetNewItem({ ...newItemRef.current, comment: e.target.value })}
                  />
                </HStack>

                {/* Add input fields for other columns */}

                <HStack alignItems={'right'}>
                  <Button colorScheme='green' onClick={handleAdd}>
                    Add
                  </Button>
                  <Button colorScheme='blue' onClick={handleUpdate} isDisabled={isItemSelected() ? false : true} >
                    Update
                  </Button>
                  <Button colorScheme='red' onClick={handleDelete} isDisabled={isItemSelected() ? false : true} >
                    Delete
                  </Button>

                  <Button colorScheme='gray' onClick={() => {
                     const selectedNodes = gridRef.current.api
                     .getSelectedNodes()
                     .filter((node) => node.selected);

                   if (!selectedNodes || selectedNodes.length === 0) {
                     console.log("Row is not selected, why is this triggered. ");
                     return;
                   }
                    let currentRow = selectedNodes[0]? selectedNodes[0].data: null;
                    if (!currentRow) return;
                    console.log(isPageLinkedToCPT ? `Del Link` : `Link Page`, currentRow, curDocumentPath, curDocumentPage);

                    if (isPageLinkedToCPT) handleRemoveLink(currentRow.claimcpt_id, curDocumentPath, curDocumentPage);
                    else linkPageToCPT(currentRow.patientid, currentRow.claimcpt_id, curDocumentPath, curDocumentPage);

                  }} isDisabled={!documentContext || !documentContext.docinfo? true : false} >
                    {isPageLinkedToCPT ? `Del Link` : `Link Page`}
                  </Button>
                </HStack>
              </>
            )
            }
          </Box>
        </PopoverBody>
      </PopoverContent>
      </Draggable>
    </Popover>
  );
};

export default ManageBillingTempClaimCpts;
