

import { configureStore } from '@reduxjs/toolkit'

import perfinReducer from '../Features/perfin/perfinSlice';
import AppReducer  from  "./ReduxSlices/AppReducerSlice";
import AuthReducer from "./ReduxSlices/AuthReducerSlice"; 

const store = configureStore ({
  reducer: {
    perfin: perfinReducer,
    auth: AuthReducer,
    task: AppReducer,
  }
});

export default store;
