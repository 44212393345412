import React from 'react';
import LoadingScreen from '../loadingscreen/loadingscreen';
import SplitPanel from './splitpanel';

const MOBILE_DEVICE_MEDIUM = 800;

interface SplitPageProps {
  actionButtons?: React.ReactNode;
  isLoading?: boolean;
  leftPanelContent?: React.ReactNode;
  loadingMsg?: string;
  rightPanelContent?: React.ReactNode;
  title?: string;
  toolButtons?: React.ReactNode;
  hideLeftPanel?: boolean;
}

interface SplitPageState {
  windowHeight: number;
  windowWidth: number;
}

export default class SplitPage extends React.Component<SplitPageProps, SplitPageState> {
  static defaultProps: Partial<SplitPageProps> = {
    isLoading: false,
    loadingMsg: '',
  };

  isChildWindow: boolean;

  constructor(props: SplitPageProps) {
    super(props);
    this.isChildWindow = window.parent !== window;

    this.state = {
      windowHeight: 0,
      windowWidth: 0,
    };

    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount(): void {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount(): void {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize(): void {
    this.setState({
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
    });
  }

  render() {
    const {
      actionButtons,
      isLoading,
      leftPanelContent,
      loadingMsg,
      rightPanelContent,
      title,
      toolButtons,
      hideLeftPanel,
    } = this.props;

    const { windowHeight, windowWidth } = this.state;

    return (
        <>
          {isLoading ? (
              <LoadingScreen msg={loadingMsg} />
          ) : (
              <div
                  className="omv-pageroot noselect"
                  style={{ height: windowHeight - (this.isChildWindow ? 0 : 60) }}
              >
                <div className="omv-pageheader">
                  <div>
                    <h4>{title}</h4>
                  </div>
                  <div className="md-lg" style={{ flexGrow: 2, paddingLeft: '10px', marginTop: '-1px' }}>
                    {toolButtons}
                  </div>
                  <div style={{ marginTop: '5px' }}>
                    {actionButtons}
                  </div>
                </div>

                <div style={{ height: windowHeight - 112 }}>
                  {hideLeftPanel ? (
                      <div
                          className="omv-splitpanel-right"
                          style={{ height: windowHeight - (this.isChildWindow ? 52 : 112) }}
                      >
                        {rightPanelContent}
                      </div>
                  ) : (
                      <SplitPanel
                          split="horizontal"
                          initialSize={25}
                          hidePanel1={windowWidth < MOBILE_DEVICE_MEDIUM}
                          showExpandButton={false}
                      >
                        <div
                            className="md-lg omv-splitpanel-left"
                            style={{ height: windowHeight - (this.isChildWindow ? 52 : 112) }}
                        >
                          {leftPanelContent}
                        </div>
                        <div
                            className="omv-splitpanel-right"
                            style={{ height: windowHeight - (this.isChildWindow ? 52 : 112) }}
                        >
                          {rightPanelContent}
                        </div>
                      </SplitPanel>
                  )}
                </div>
              </div>
          )}
        </>
    );
  }
}
