import React, { ChangeEvent, KeyboardEvent, MouseEvent } from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import DataViewManager from './dataviewmanager';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Cancel from '@mui/icons-material/Cancel';
import FilterAltIcon from '@mui/icons-material/FilterList';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import StyledSelect from '../ui-elements/styledselect';
import StyledInput from '../ui-elements/styledinput';

interface Column {
  field: string;
  headerName: string;
  width?: number;
  filterHint?: string;
  className?: string;
  dateformat?: string;
  renderFunction?: (value: any, row: any) => React.ReactNode;
  setHTML?: boolean;
}

interface AppDataGridProps {
  checkBoxSelection?: boolean;
  resistDoubleClick?: boolean;
  rowHeight?: number;
  showHeader?: boolean;
  useExternalDataManager?: boolean;
  columns: Column[];
  rows: any[];
  colorMap: any[];
  dataviewManager?: any;
  currentView?: any;
  lastFilterTime?: number;
  lastSelectedRow?: number;
  onAddFilter?: (field: string, expression: string) => void;
  onClearFilter?: () => void;
  onDrop?: (row: any, data: string) => void;
  onFilterExpressionChanged?: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  onFilterCheckboxSelectAllChanged?: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  onFilterCheckboxChanged?: (ev: React.ChangeEvent<HTMLInputElement>, txt: string) => void;
  onFilterDateChanged?: (ev: React.ChangeEvent<HTMLInputElement>, fieldName: string) => void;
  onFilterDateCleared?: (fieldName: string) => void;
  onRowClicked?: (row: any, event: React.MouseEvent<HTMLTableRowElement>) => boolean;
  onRowSelected?: (row: any) => void;
  onRowUnselected?: (row: any) => void;
  onSort?: (columnName: string) => void;
  selectionPrecheck?: (callback: () => void) => void;
  multiSelect?: boolean;
  draggable?: boolean;
  dragDataField?: string;
  droppable?: boolean;
  isRowSelectable?: (row: any) => boolean;
  showRememberFilter?: boolean;
  hideFooter?: boolean;
}

interface AppDataGridState {
  columnFilters: { [key: string]: any };
  currentFilterBoxColumn: number;
  currentView: any;
  filterButtonBottom: number;
  filterButtonLeft: number;
  filterCurrentExpression: any;
  filterHint: string;
  filterValues: any[];
  showFilterBox: boolean;
  pageSize: number;
  pageIndex: number;
  rememberFilter: boolean;
  sortColumn: string;
}

export const MINIMUM_SELECTION_INTERVAL = 125;

class AppDataGrid extends React.Component<AppDataGridProps, AppDataGridState> {
  static defaultProps = {
    checkBoxSelection: false,
    resistDoubleClick: true,
    rowHeight: 35,
    showHeader: true,
    useExternalDataManager: false,
  };

  private dvm: any;
  private lastRowClickedTime: number | null;
  private selectedRow: HTMLTableRowElement | null = null;
  private filterInputBox: HTMLInputElement | null = null;

  constructor(props: AppDataGridProps) {
    super(props);

    this.state = {
      columnFilters: {},
      currentFilterBoxColumn: -1,
      currentView: null,
      filterButtonBottom: 0,
      filterButtonLeft: 0,
      filterCurrentExpression: '',
      filterHint: '',
      filterValues: [],
      showFilterBox: false,
      pageSize: 50,
      pageIndex: 0,
      rememberFilter: false,
      sortColumn: '',
    };

    this.dvm = null;
    this.lastRowClickedTime = null;
    this.scrollToSelectedRow = this.scrollToSelectedRow.bind(this);
  }

  componentDidMount() {
    const { dataviewManager, useExternalDataManager } = this.props;

    if (useExternalDataManager) {
      this.dvm = dataviewManager;
    } else {
      this.dvm = new DataViewManager();
      this.setData();
    }
    this.refreshView();
  }

  componentDidUpdate(prevProps: AppDataGridProps) {
    const { lastFilterTime, lastSelectedRow, rows } = this.props;

    if (this.dvm && prevProps.rows !== rows) {
      this.setData();
      return;
    }

    if (lastFilterTime !== prevProps.lastFilterTime) {
      this.setState({
        pageIndex: 0,
      });
    }

    if (lastSelectedRow !== prevProps.lastSelectedRow) {
      setTimeout(this.scrollToSelectedRow, 300);
    }
  }

  addFilter() {
    try {
      const argColumn = this.props.columns[this.state.currentFilterBoxColumn];
      const argExpression = this.state.filterCurrentExpression;

      this.setState({
        showFilterBox: false,
      });

      if (this.props.onAddFilter) {
        this.props.onAddFilter(argColumn.field, argExpression);
      } else {
        if (this.dvm) {
          this.dvm.addFilter(argColumn.field, argExpression);
          this.refreshView();
        }
      }
    } catch (e) {}
  }

  filterButtonClicked(arg: number) {
    try {
      const currentView = this.props.currentView || this.state.currentView;

      if (this.state.currentFilterBoxColumn === arg && this.state.showFilterBox) {
        this.setState({ showFilterBox: false });
        return;
      }

      const obj = (ReactDOM.findDOMNode(this.refs[`filterButton${arg}`]) as HTMLDivElement).getBoundingClientRect();

      const colInfo = this.props.columns[arg];
      let uniqValues = [];

      if (colInfo.filterHint === undefined || colInfo.filterHint === 'list') {
        if (this.dvm) {
          uniqValues = this.dvm.getUniqueValues(colInfo.field);
        }
      }

      this.setState({
        currentFilterBoxColumn: arg,
        filterCurrentExpression: currentView?.currentFilters?.[colInfo.field] ?? '',
        filterButtonBottom: obj.bottom,
        filterButtonLeft: obj.left,
        filterHint: colInfo.filterHint ?? '',
        filterValues: uniqValues,
        showFilterBox: true,
      }, () => { if (colInfo.filterHint && this.filterInputBox) this.filterInputBox.focus(); });

    } catch (e) {}
  }

  fixDate(arg: string, argFormat: string) {
    return moment(arg, "YYYY-MM-DD HH:mm:ss").format(argFormat);
  }

  getRowColor(row: any) {
    const { colorMap } = this.props;

    for (let x = 0; x < colorMap.length; x++) {
      const key = Object.keys(colorMap[x].filter)[0];
      if (row[key] === colorMap[x].filter[key]) {
        return colorMap[x].color;
      }
    }

    return '';
  }

  getRowCountDescription(pageCount: number, pageIndex: number, pageSize: number, currentView: any) {
    // @ts-ignore
    // @ts-ignore
    // @ts-ignore
    // @ts-ignore
    let element = <><>
      {pageSize >= currentView.visibleRowCount ? (
          <>
            {currentView.visibleRowCount === 0 ? 'No' : currentView.visibleRowCount} Row{currentView.visibleRowCount === 0 || currentView.visibleRowCount > 1 ? 's' : ''}
          </>
      ) : (
          <>
            Page Size
            <StyledSelect
                //SKTODO:  Removed the property below. Need to fix this issue.
                //onChange={(e: SelectChangeEvent<string | number>, child: React.ReactNode) => this.onChangePageSize(e)}
                value={pageSize}
                style={{marginLeft: '10px', marginRight: '10px'}}
            >
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </StyledSelect>
            Showing
            rows {pageIndex * pageSize + 1}-{Math.min(pageIndex * pageSize + pageSize, currentView.visibleRowCount)} of {currentView.visibleRowCount}
          </>
      )}
      {currentView.filteredRowCount > 0 && (
          <div style={{display: 'inline'}}>
            (Plus <span style={{textDecoration: 'underline', cursor: 'pointer'}}
                        onClick={() => this.onClearFilter()}>{currentView.filteredRowCount} hidden</span>)
          </div>
      )}
    </>
    </>;
    return element;
  }

  //@ts-ignore
  onChangePageSize(e: SelectChangeEvent<string | number>): void {
    this.setState({ pageSize: Number(e.target.value) });
  }

  gotoPage(ndx: number) {
    if (ndx !== this.state.pageIndex) {
      this.setState({ pageIndex: ndx });
    }
  }

  onChangeRememberFilterSetting(e: ChangeEvent<HTMLInputElement>) {
    this.setState({ rememberFilter: e.target.checked });
  }

  onClearFilter() {
    if (this.props.onClearFilter) {
      this.props.onClearFilter();
    } else if (this.dvm) {
      this.dvm.clearFilters();
      this.refreshView();
    }
  }

  onDropped(row: any, ev: React.DragEvent<HTMLTableRowElement>) {
    ev.preventDefault();
    if (this.props.onDrop) this.props.onDrop(row, ev.dataTransfer.getData("text"));
  }

  onFilterExpressionChanged(ev: ChangeEvent<HTMLInputElement>) {
    this.setState({ filterCurrentExpression: ev.target.value });
  }

  onFilterCheckboxSelectAllChanged(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.checked) {
      this.setState({ filterCurrentExpression: '' });
    }
  }

  onFilterCheckboxChanged(e: ChangeEvent<HTMLInputElement>, txt: string) {
    let current = this.state.filterCurrentExpression;

    if (e.target.checked) {
      current = current === '' ? txt : `${current},${txt}`;
    } else {
      if (current === txt) {
        current = '';
      } else {
        const ndx = current.indexOf(txt);
        if (ndx === 0) {
          current = current.replace(`${txt},`, '');
        } else {
          current = current.replace(`,${txt}`, '');
        }
      }
    }

    this.setState({ filterCurrentExpression: current });
  }

  onFilterDateChanged(e: ChangeEvent<HTMLInputElement>, fieldName: string) {
    const { filterCurrentExpression } = this.state;

    const newExpression = !filterCurrentExpression ? {} : filterCurrentExpression;
    newExpression[fieldName] = e.target.value;

    this.setState({ filterCurrentExpression: newExpression });
  }

  onFilterDateCleared(fieldName: string) {
    const { filterCurrentExpression } = this.state;

    const newExpression = !filterCurrentExpression ? {} : filterCurrentExpression;
    newExpression[fieldName] = "";

    this.setState({ filterCurrentExpression: newExpression });
  }

  onKeyDown(e: KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      this.addFilter();
    } else if (e.key === 'Escape') {
      this.setState({ showFilterBox: false });
    }
  }

  onRowClicked(row: any, event: MouseEvent<HTMLTableRowElement>) {
    if (this.props.resistDoubleClick) {
      if (this.lastRowClickedTime === null) {
        this.lastRowClickedTime = Date.now();
      } else {
        if (Date.now() - this.lastRowClickedTime < MINIMUM_SELECTION_INTERVAL) {
          return;
        }
        this.lastRowClickedTime = Date.now();
      }
    }

    this.setState({ showFilterBox: false });

    if (this.props.onRowClicked) {
      const result = this.props.onRowClicked(row, event);
      if (result) {
        return;
      }
    }

    if (this.dvm) {
      if (this.props.selectionPrecheck) {
        this.props.selectionPrecheck(() => this.processSelection(row));
      } else {
        this.processSelection(row);
      }
    }
  }

  onSort(columnName: string) {
    if (this.props.onSort) {
      this.props.onSort(columnName);
    } else if (this.dvm) {
      this.dvm.sort(columnName);
      this.refreshView();
    }
  }

  processSelection(row: any) {
    const { checkBoxSelection, multiSelect } = this.props;

    if (row._isSelected) {
      row._isSelected = false;
      row._isHighlighted = false;
    } else {
      const list = this.dvm._data;

      if (!checkBoxSelection || !multiSelect) {
        list.forEach((argRow: any) => {
          argRow._isSelected = false;
          argRow._isHighlighted = false;
        });
      }

      row._isSelected = true;
      row._isHighlighted = true;
    }

    this.refreshView();

    if (row._isSelected) {
      if (this.props.onRowSelected) {
        this.props.onRowSelected(row);
      }
    } else {
      if (this.props.onRowUnselected) {
        this.props.onRowUnselected(row);
      }
    }
  }

  refreshView() {
    if (this.dvm) {
      this.setState({ currentView: this.dvm.getCurrentView() });
    }
  }

  renderCell(r: any, x: Column) {
    if (x.dateformat) return this.fixDate(r[x.field], x.dateformat);
    if (x.renderFunction) return x.renderFunction(r[x.field], r);

    if (r._isUpdatedInfo && r._updatedColumn === x.field) {
      return <span style={{ backgroundColor: '#f290b1', padding: '5px', color: '#222222' }}>{r[x.field]}</span>;
    }

    return r[x.field];
  }

  scrollToSelectedRow() {
    if (this.selectedRow) {
      this.selectedRow.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }
  }

  setData() {
    const { rows, columns } = this.props;
    const { pageIndex, pageSize } = this.state;

    if (this.dvm && rows && columns) {
      this.dvm.setData(rows, columns);
      this.setState({ currentView: this.dvm.getCurrentView() });

      if (pageIndex > 0 && rows.length < (pageIndex * pageSize)) {
        this.setState({ pageIndex: 0 });
      }
    }
  }

  startDrag(ev: React.DragEvent<HTMLTableRowElement>, val: string) {
    ev.dataTransfer.setData("text", val);
  }

  // @ts-nocheck
  render() {
    const { filterCurrentExpression, filterHint, filterValues, pageIndex, pageSize, rememberFilter } = this.state;
    const { checkBoxSelection, colorMap, showHeader, showRememberFilter } = this.props;
    const currentView = this.props.currentView || this.state.currentView;

    if (!currentView || !currentView.filteredView) return null;

    let newRows = currentView.filteredView;
    newRows = newRows.slice(pageIndex * pageSize, pageIndex * pageSize + pageSize);
    const pageCount = Math.ceil(currentView.visibleRowCount / pageSize);

    //SKTODO: Removed this from iconbutton
    // ref={`filterButton${i}`}
    return (
        <div style={{ overflowX: 'hidden', overflowY: 'auto' }}>
          <div
              style={{
                position: 'absolute',
                color: 'white',
                top: this.state.filterButtonBottom + 4,
                left: Math.max(0, this.state.filterButtonLeft - 200),
                height: filterHint === 'freetext' ? '170px' : filterHint === 'date' ? '195px' : '300px',
                width: '250px',
                backgroundColor: '#292929',
                zIndex: 999,
                padding: '10px',
                textAlign: 'left',
                whiteSpace: 'nowrap',
                border: '1px solid #444444',
                display: this.state.showFilterBox ? '' : 'none',
              }}
          >
            {filterHint === 'date' && (
                <div>
                  <div className='flex'>
                    <div style={{ width: '100px' }}>From</div>
                    <StyledInput width="150px" type="date" onChange={(e: ChangeEvent<HTMLInputElement>) => this.onFilterDateChanged(e, "startDate")} value={filterCurrentExpression.startDate || ""} />
                    <Cancel onClick={() => this.onFilterDateCleared("startDate")} style={{ color: "var(--primary-border-color)", cursor: 'pointer', fontSize: '18pt', marginLeft: '10px', marginTop: '2px' }} />
                  </div>
                  <div className='flex pad10v'>
                    <div style={{ width: '100px' }}>To</div>
                    <StyledInput width="150px" type="date" onChange={(e: ChangeEvent<HTMLInputElement>) => this.onFilterDateChanged(e, "endDate")} value={filterCurrentExpression.endDate || ""} />
                    <Cancel onClick={() => this.onFilterDateCleared("endDate")} style={{ color: "var(--primary-border-color)", cursor: 'pointer', fontSize: '18pt', marginLeft: '10px', marginTop: '2px' }} />
                  </div>
                </div>
            )}

            {(filterHint === null || filterHint === 'list') && (
                <div style={{ height: '220px', overflowY: 'auto' }}>
                  <div>
                    <FormControlLabel style={{ paddingLeft: '10px', fontSize: '8pt' }} control={<Checkbox style={{ padding: '2px' }} checked={this.state.filterCurrentExpression === ''}
                                                                                                          onChange={(ev: ChangeEvent<HTMLInputElement>) => this.onFilterCheckboxSelectAllChanged(ev)} color="primary" />} label="Select All" />
                  </div>

                  {filterValues.map((x, i) => (
                      <div key={`fvl${i}`}>
                        <FormControlLabel style={{ paddingLeft: '10px', fontSize: '8pt' }} control={<Checkbox
                            style={{ padding: '2px' }}
                            checked={String(this.state.filterCurrentExpression).includes(x)}
                            onChange={(ev: ChangeEvent<HTMLInputElement>) => this.onFilterCheckboxChanged(ev, x)} color="primary" />} label={x} />
                      </div>
                  ))}
                </div>
            )}

            <div style={{ display: filterHint === 'freetext' ? '' : 'none' }}>
              Filter by<br />
              <input autoFocus style={{ marginTop: '10px', width: '100%', backgroundColor: "var(--body-color)", color: "var(--text-color)", border: "1px solid var(--primary-border-color)", borderRadius: "4px", height: "30px", padding: "5px" }}
                     ref={(obj) => this.filterInputBox = obj}
                     type="text" value={this.state.filterCurrentExpression}
                     onChange={(ev: ChangeEvent<HTMLInputElement>) => this.onFilterExpressionChanged(ev)}
                     onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => this.onKeyDown(e)} />
            </div>

            {showRememberFilter && (
                <div style={{ position: 'absolute', bottom: '25px', left: '15px' }}>
                  <FormControlLabel control={<Checkbox color="primary" checked={rememberFilter} onChange={(e: ChangeEvent<HTMLInputElement>) => this.onChangeRememberFilterSetting(e)} />} label="Remember filter" />
                </div>
            )}

            <div style={{ textAlign: 'center', width: '100%', position: 'absolute', bottom: '0px' }}>
              <IconButton onClick={() => this.setState({ showFilterBox: false })}><ClearIcon color="secondary" fontSize="small" /></IconButton>
              <IconButton onClick={() => this.addFilter()}><CheckIcon color="primary" fontSize="small" /></IconButton>
            </div>
          </div>

          {this.props.columns && this.props.columns.length > 0 && (
              <table cellSpacing="0" style={{ width: '100%' }}>
                {showHeader && (
                    <thead>
                    <tr>
                      {checkBoxSelection && (
                          <th style={{ width: '50px' }} key={0} />
                      )}
                      {this.props.columns.map((x, i) => (
                          <th className={x.className || ""} style={{ width: x.width ? x.width : '' }} key={i + 1}>
                            <span onClick={() => this.onSort(x.field)}>{x.headerName}</span>
                            {x.field === currentView.currentSortColumn && (
                                currentView.currentSortDirection === 'A' ? (
                                    <ArrowDropUpIcon />
                                ) : (
                                    <ArrowDropDownIcon />
                                )
                            )}
                            {(x.filterHint === null || x.filterHint !== 'nofilter') && (
                                <span style={{ marginLeft: x.field === this.state.sortColumn ? '0px' : '25px' }}>
                          <IconButton onClick={() => this.filterButtonClicked(i)}>
                            <FilterAltIcon fontSize="small"
                                           color={this.state.columnFilters[x.field] && this.state.columnFilters[x.field] !== '' ? "primary" : "inherit"} />
                          </IconButton>
                        </span>
                            )}
                          </th>
                      ))}
                    </tr>
                    </thead>
                )}

                {newRows && newRows.length > 0 && (
                    <tbody>
                    {newRows.map((r: any, i: number) => {
                      return (
                          <tr
                              draggable={this.props.draggable ? "true" : undefined}
                              onDragStart={this.props.draggable && this.props.dragDataField ? (ev) => {
                                this.startDrag(ev, r[this.props.dragDataField ? this.props.dragDataField : 0]);
                              } : undefined}
                              onDragOver={this.props.droppable ? (ev) => ev.preventDefault() : undefined}
                              onDrop={this.props.droppable ? (ev) => {
                                this.onDropped(r, ev);
                              } : undefined}
                              key={`tr${i}`}
                              className={r._isSelected === 1 || r._isHighlighted === 1 ? 'selected' : ''}
                              ref={r._isSelected === 1 ? (obj) => this.selectedRow = obj : null}
                              onClick={(e) => {
                                this.onRowClicked(r, e);
                              }}
                          >
                            {checkBoxSelection && (
                                <td>
                                  <Checkbox
                                      checked={r._isSelected === 1}
                                      color="primary"
                                      style={{padding: '0px', marginLeft: '-6px'}}
                                      disabled={this.props.isRowSelectable ? !this.props.isRowSelectable(r) : undefined}
                                  />
                                </td>
                            )}
                            {this.props.columns.map((x, ci) => (
                                x.setHTML ? (
                                    <td className={x.className || ""} key={`td${r['_id']}-${ci}`}
                                        style={{maxWidth: x.width ? x.width : ''}}
                                        dangerouslySetInnerHTML={{__html: this.renderCell(r, x) as string}}/>
                                ) : (
                                    <td className={x.className || ""} key={`td${r['_id']}-${ci}`} style={{
                                      maxWidth: x.width ? x.width : '',
                                      color: colorMap ? this.getRowColor(r) : ''
                                    }}>{this.renderCell(r, x)}</td>
                                )
                            ))}
                          </tr>
                      );
                    })}
                    </tbody>
                )}
              </table>
          )}

          {pageCount > 1 && (
              <div style={{ color: 'white', fontSize: 12, padding: '25px 0px 0px' }}>
                {[...Array(pageCount)].map((_, ndx) => (
                    <div
                        className={ndx === pageIndex ? "pageButtonCurrent" : "pageButton"}
                        key={`pb${ndx}`}
                        onClick={() => this.gotoPage(ndx)}
                    >
                      {ndx + 1}
                    </div>
                ))}
              </div>
          )}

          {!this.props.hideFooter && (
              <div style={{ padding: '25px 0px' }}>
                {this.getRowCountDescription(pageCount, pageIndex, pageSize, currentView)}
              </div>
          )}
        </div>
    );
  }
}

export default AppDataGrid;
