import React from "react";
import useState from 'react-usestateref';

const UploadFile = (props) => {
  const [files, setFiles, filesRef] = useState("");
  //state for checking file size
  const [fileSize, setFileSize] = useState(true);
  // for file upload progress message
  const [fileUploadProgress, setFileUploadProgress] = useState(false);
  //for displaying response message
  const [fileUploadResponse, setFileUploadResponse] = useState(null);
  //base end point url
  const FILE_UPLOAD_BASE_ENDPOINT = "";

  const uploadFileHandler = (event) => {

    setFiles(event.target.files);
    console.log("Received FIles:", filesRef.current.length, filesRef.current);
    let recFilesList = [];
    for (let i = 0; i < event.target.files.length; i++) {
        let curFile = {
            fileName : event.target.files[i].name,
            fileSize : event.target.files[i].size,
            fileType : event.target.files[i].type,
            fileData : event.target.files[i]
        }
        recFilesList.push(curFile);
        console.log("File:", i);
    }
    console.log("Files Data:", recFilesList);
    props.getUploadedFiles(recFilesList);

    //fileSubmitHandler(event);
  };

  const fileSubmitHandler = (event) => {
    event.preventDefault();

    console.log(
      "READY TO SUBMIT START: ",
      FILE_UPLOAD_BASE_ENDPOINT + "/upload", filesRef.current.length
    );

    // setFileSize(true);
    // setFileUploadProgress(true);
    // setFileUploadResponse(null);

    const formData = new FormData();

    for (let i = 0; i < filesRef.current.length; i++) {
      console.log("File:", i);

        if ((filesRef.current)[i].size > 10240000) {
        setFileSize(false);
        setFileUploadProgress(false);
        setFileUploadResponse(null);
        return;
      }

      formData.append(`files`, files[i]);
    }

    const requestOptions = {
      method: "POST",
      body: formData,
    };

    console.log(
      "READY TO SUBMIT: ",
      FILE_UPLOAD_BASE_ENDPOINT + "/upload",
      requestOptions
    );

    // fetch(FILE_UPLOAD_BASE_ENDPOINT + "/upload", requestOptions)
    //   .then(async (response) => {
    //     const isJson = response.headers
    //       .get("content-type")
    //       ?.includes("application/json");
    //     const data = isJson && (await response.json());

    //     // check for error response
    //     if (!response.ok) {
    //       // get error message
    //       const error = (data && data.message) || response.status;
    //       setFileUploadResponse(data.message);
    //       return Promise.reject(error);
    //     }

    //     console.log(data.message);
    //     setFileUploadResponse(data.message);
    //   })
    //   .catch((error) => {
    //     console.error("Error while uploading file!", error);
    //   });
    // setFileUploadProgress(false);
  };

  return (
    <span>
      <input type="file" multiple onChange={uploadFileHandler} />
      {!fileSize && <p style={{ color: "red" }}>File size exceeded!!</p>}
      {fileUploadProgress && <p style={{ color: "red" }}>Uploading File(s)</p>}
      {fileUploadResponse != null && (
        <p style={{ color: "green" }}>{fileUploadResponse}</p>
      )}
    </span>
  );
};
export default UploadFile;
