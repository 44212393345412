
import React, { useEffect } from "react";
import useState from 'react-usestateref';
import apiConfig from '../apiConfig';
import axios from "axios";

import {
    Flex,
    Text,
    FormControl,
    HStack,
    FormLabel,
    Container,
} from "@chakra-ui/react";

import { Select } from "chakra-react-select";

export function SelectPOS({ selectedPOS, setSelectedPOS }) {

    // select * from rc.apex_billing_POS

    let apiURL=apiConfig.REACT_APEX_BASE_API;
    const [selectedOption, setselectedOption] = useState();

    const POSArray = [
        { label: "11-Office", value: "11" },
        { label: "21-Inpatient", value: "21" },
        { label: "22-Outpatient", value: "22" },
        { label: "23-ER", value: "23" },
        { label: "24-ASC", value: "24" },
        { label: "31-SkilledNF", value: "31" },
    ]

    useEffect(()=> {
        console.log("CurrentSelectedOption", selectedOption, selectedPOS);
        if (selectedPOS > 0) {
          let newOptionElement = POSArray.filter(row=> {return row['value'] == selectedPOS});
          console.log("Setting POS to ", newOptionElement[0]);
          setselectedOption(newOptionElement[0]);
          return;
        }
  
      }, [selectedPOS]);

      const onPOSChange = (val) => {

        console.log("Setting selected value to :", val);
        setselectedOption(val);

        if (val) {
        let selectedPOSItem = POSArray && POSArray.filter(doctype=> doctype.value === val.value);
        console.log("Selected POS ",val,selectedPOSItem, selectedPOSItem[0]);
        setSelectedPOS(selectedPOSItem[0]);
        }
      }

      let POSs = POSArray?.map(({value, label})=>{ return ({value: value, label: label}) }); // return ({staff.uid, staff.name}));c

      return(
        <Container >
        <FormControl w='280px' >
          <HStack>
            <FormLabel marginLeft={'-70px'}>POS</FormLabel>
          <Select
            isClearable={true}
            options={POSs}
            value={selectedOption}
            placeholder="Select POS..."
            closeMenuOnSelect={true}
            onChange={onPOSChange}
          />
           </HStack>
        </FormControl>
      </Container>
      )

    }