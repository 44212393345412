import { Container, Flex, Spinner, Stack } from "@chakra-ui/react";
import React from "react";
import { Route, Routes } from "react-router-dom";
import Sidebar from "../Components/Sidebar";
import Menubar from "../Components/Menubar";
import PrivateRoute from "../PrivateRoute/PrivateR";
import Editpage from "./Editpage";
import DocumentViewerpage from "./DocumentViewer";
import Homepage from "./Homepage";
import ApexPatientList from "../Components/ApexPatientList";
import ApexDocumentManager from "../Components/ApexDocsMgr";
import Deposits from "../Features/deposits/App";
import ClaimPaymentsReview from "./ClaimPaymentsReview";
//import DepositsPage from "../Pages/Deposits";
import PersonalFinance from  '../Features/perfin/Dashboard';
import { ApexKeyViewer } from "../Components/ApexKeyViewer";
import ActionsBrowser from "../Pages/ActionsBrowser";
import Billing from "../Features/billing/App";
import PaymentsPage from "../Features/deposits/PaymentsPage";
import BillingReview from "../Features/billing/Review";
import TempClaimsViwer from "./TempClaimsViewer";
import EcwPatientEncounter from "../Modals/SelectEcwPatientEncounter";
import PatientManagementComponent from "../Modals/PatientManagementComponent";
import PatientFacesheets from "./PatientFacesheetsViewer";
import ClaimListReview from "./ClaimListReview";
import ClaimsReviewPage from "./ClaimsReviewPage";
import ClaimsQueueViewPage from "./ClaimsQueueViewPage";
import Login from "./Login";
import Signup from "./Signup";
import { useSelector } from "react-redux";
import { useSearchParams, useLocation } from "react-router-dom";
import ApexIframeViewer from "../Components/ApexIframeViewer";
import {ApexEcwEchos} from "../Features/apexechos/App";
import ScannedPaymentPage from "../Features/deposits/ScannedPaymentPage";
import echoClaimEntryPage from "../Features/deposits/EchoClaimEntryPage";

//import  Dashboard  from "../Features/dashboard/layouts/admin/";


const MainRoutes = () => {
  const loading = useSelector((state) => state?.auth?.isLoading);
  const loadings = useSelector((state) => state?.auth?.isLoading);
  let [searchParams, setSearchParams] = useSearchParams();

  const callbackFunction = (callbackarg) => {
    console.log("CALL BACK FUNCTION CALLED:", callbackarg);
  }

  const location = useLocation();
  console.log("State value:", loading, loadings, location);
  const filePath = searchParams.get("docpath");
  console.log("Search params:", searchParams, filePath);

    let curUser = {
        lanID : 'slk02'
    };
    let modelserv = undefined;
    return (

    <Routes>
      <Route
        path="/"
        element={
          <PrivateRoute>
            {!loading ? (
               <Stack direction="column">
                <Menubar  callbackFunction = {callbackFunction}/>
                  <Deposits />
              </Stack>
            ) : (
              loader()
            )}
          </PrivateRoute>
        }
      />


      <Route
        path="/task/:id"
        element={
          <>
            {!loading ? (
                <Stack direction="column">
                  <Menubar callbackFunction = {callbackFunction}/>
                  <Stack direction="row">
                    <Sidebar />
                    <Editpage />
                  </Stack>
                </Stack>
            ) : (
              loader()
            )}
          </>
        }
      />


<Route
        path="/viewkey/:sourcepath/:keyid"
        element={
          <>
            {!loading ? (
                <Stack direction="column">
                  <Stack direction="row">
                    <ApexKeyViewer  />
                  </Stack>
                </Stack>
            ) : (
              loader()
            )}
          </>
        }

      />

        <Route
            path="/browse/:url"
            element={
                <>
                    {!loading ? (
                        <Stack direction="column">
                            <Stack direction="row">
                                <ApexIframeViewer />
                            </Stack>
                        </Stack>
                    ) : (
                        loader()
                    )}
                </>
            }

        />


<Route
        path="/viewdoc/:docpath/:pagenum"
        element={
          <>
            {!loading ? (
                <Stack direction="column">
                  <Stack direction="row">
                    <DocumentViewerpage  />
                  </Stack>
                </Stack>
            ) : (
              loader()
            )}
          </>
        }

      />

        <Route
            path="/claimrqueuespage"
            element={
                <PrivateRoute>
                    {!loading ? (
                        <Stack direction="column">
                            <Menubar callbackFunction = {callbackFunction}/>
                            <Stack direction="row">
                                <ClaimsQueueViewPage />
                            </Stack>
                        </Stack>
                    ) : (
                        loader()
                    )}
                </PrivateRoute>
            }
        />

        <Route
            path="/claimreviewpage"
            element={
                <PrivateRoute>
                    {!loading ? (
                        <Stack direction="column">
                            <Menubar callbackFunction = {callbackFunction}/>
                            <Stack direction="row">
                                <ClaimsReviewPage />
                            </Stack>
                        </Stack>
                    ) : (
                        loader()
                    )}
                </PrivateRoute>
            }
        />

        <Route
            path="/echos"
            element={

                    !loading ? (
                        <Stack direction="column">

                            <Stack direction="row">
                                <ApexEcwEchos />
                            </Stack>
                        </Stack>
                    ) : (
                        loader()
                    )

            }
        />

        <Route
        path="/deposits"
        element={
          <PrivateRoute>
            {!loading ? (
                <Stack direction="column">
                <Menubar callbackFunction = {callbackFunction}/>
                <Stack direction="row">
                <Deposits />
                </Stack>
              </Stack>
            ) : (
              loader()
            )}
          </PrivateRoute>
        }
      />

<Route
        path="/temppatients"
        element={
          <PrivateRoute>
            {!loading ? (
                <Stack direction="column">
                <Menubar callbackFunction = {callbackFunction}/>
                <Stack direction="row">
                <PatientManagementComponent />
                </Stack>
              </Stack>
            ) : (
              loader()
            )}
          </PrivateRoute>
        }
      />



      <Route
        path="/perfin"
        element={
          <PrivateRoute>
            {!loading ? (
                <Stack direction="column">
                <Menubar callbackFunction = {callbackFunction}/>
                <Stack direction="row">
                  <PersonalFinance />
                </Stack>
              </Stack>
            ) : (
              loader()
            )}
          </PrivateRoute>
        }
      />

        <Route
            path="/scannedpayments"
            element={
                <PrivateRoute>
                    {!loading ? (
                        <Stack direction="column">
                            <Menubar callbackFunction = {callbackFunction}/>
                            <Stack direction="row">
                                <ScannedPaymentPage />
                            </Stack>
                        </Stack>
                    ) : (
                        loader()
                    )}
                </PrivateRoute>
            }
        />

<Route
        path="/hospsheets"
        element={
          <PrivateRoute>
            {!loading ? (
                <Stack direction="column">
                <Menubar callbackFunction = {callbackFunction}/>
                <Stack direction="row">
                  <Billing />
                </Stack>
              </Stack>
            ) : (
              loader()
            )}
          </PrivateRoute>
        }
      />

        <Route
            path="/hospsheetsreview"
            element={
                <PrivateRoute>
                    {!loading ? (
                        <Stack direction="column">
                            <Menubar callbackFunction = {callbackFunction}/>
                            <Stack direction="row">
                                <BillingReview />
                            </Stack>
                        </Stack>
                    ) : (
                        loader()
                    )}
                </PrivateRoute>
            }
        />

<Route
        path="/patentry"
        element={
          <PrivateRoute>
            {!loading ? (
                  <PatientFacesheets />
            ) : (
              loader()
            )}
          </PrivateRoute>
        }
      />


<Route
        path="/claimsqueue"
        element={
          <PrivateRoute>
            {!loading ? (
                <Stack direction="column">
                <Menubar callbackFunction = {callbackFunction}/>
                <Stack direction="row">
                  <ClaimListReview />
                </Stack>
              </Stack>
            ) : (
              loader()
            )}
          </PrivateRoute>
        }
      />

<Route
        path="/hospclaims"
        element={
          <PrivateRoute>
            {!loading ? (
                <Stack direction="column">
                <Menubar callbackFunction = {callbackFunction}/>
                <Stack direction="row">
                  <TempClaimsViwer />
                </Stack>
              </Stack>
            ) : (
              loader()
            )}
          </PrivateRoute>
        }
      />

<Route
        path="/encounters"
        element={
          <PrivateRoute>
            {!loading ? (
                <Stack direction="column">
                <Menubar callbackFunction = {callbackFunction}/>
                <Stack direction="row">
                  <EcwPatientEncounter />
                </Stack>
              </Stack>
            ) : (
              loader()
            )}
          </PrivateRoute>
        }
      />

        <Route
            path="/paymentspage"
            element={
                <PrivateRoute>
                    {!loading ? (
                        <Stack direction="column">
                            <Menubar callbackFunction = {callbackFunction}/>
                            <Stack direction="row">
                                <PaymentsPage />
                            </Stack>
                        </Stack>
                    ) : (
                        loader()
                    )}
                </PrivateRoute>
            }
        />
        <Route
            path="/echoentrypage"
            element={
                <PrivateRoute>
                    {!loading ? (
                        <Stack direction="column">
                            <Menubar callbackFunction = {callbackFunction}/>
                            <Stack direction="row">
                                <echoClaimEntryPage />
                            </Stack>
                        </Stack>
                    ) : (
                        loader()
                    )}
                </PrivateRoute>
            }
        />





<Route
        path="/projects"
        element={
          <PrivateRoute>
            {!loading ? (
                <Stack direction="column">
                <Menubar callbackFunction = {callbackFunction}/>
                <Stack direction="row">
                  <ActionsBrowser src={'https://app.apexheartcare.com'} />
                </Stack>
              </Stack>
            ) : (
              loader()
            )}
          </PrivateRoute>
        }
      />

        <Route
        path="/action"
        element={
          <PrivateRoute>
            {!loading ? (
               <Stack direction="column">
                <Menubar callbackFunction = {callbackFunction}/>
                <Stack direction="row">
                  <Sidebar />
                  <Homepage />
                </Stack>
              </Stack>
            ) : (
              loader()
            )}
          </PrivateRoute>
        }
      />
        <Route
        path="/documgr/:context"
        element={
          <>
            {!loading ? (
                <Stack direction="column">
                  <Menubar callbackFunction = {callbackFunction}/>
                  <Stack direction="row">
                    <ApexDocumentManager />
                  </Stack>
                </Stack>
            ) : (
              loader()
            )}
          </>
        }
      />

        <Route
        path="/account"
        element={
          <>
            {!loading ? (
                <Stack direction="column">
                  <Menubar callbackFunction = {callbackFunction}/>
                  <Stack direction="row">
                    <ApexPatientList />
                  </Stack>
                </Stack>
            ) : (
              loader()
            )}
          </>
        }
      />
      <Route path="/login" element={<>{!loadings ? <Login /> : loader()}</>} />
      <Route
        path="/signup"
        element={<>{!loadings ? <Signup /> : loader()}</>}
      />
    </Routes>
  );
};

export default MainRoutes;

export const loader = () => {
  return (
    <Container
      as={Flex}
      alignContent={"center"}
      alignItems={"center"}
      height={"100vh"}
      w={"100%"}
    >
      <Spinner thickness="5rem" margin={"auto"} size={"25xl"} speed="0.65s" />
    </Container>
  );
};
