import React, { useState, useEffect } from 'react';
import { Popper, Paper, Typography, Box, Button, TextField, IconButton } from '@mui/material';
import Draggable from 'react-draggable';
import CloseIcon from '@mui/icons-material/Close';

interface RowData {
    ecwdoc_id?: number;
    pageno?: number;
    checknumber?: string;
    pagedetail?: string;
    checkamount?: number;
    checkdate?: string;
    cardamount?: number;
    otheramount?: number;
    otheramount2?: number;
    ispaymentcached?: number;
    expiredpayment?: number;
    replacementremarks?: string;
    paymentnotes?: string;
    reference1?: string;
}

interface AddNewScannedPaymentProps {
    open: boolean;
    anchorEl: HTMLElement | null;
    onClose: () => void;
    onSave: (newRow: RowData) => void;
    initialData?: RowData;
    pageNum: number | null | undefined;
    ecwDocId: number | null | undefined;
}

function DraggablePaperComponent(props: any) {
    return (
        <Draggable handle="#draggable-popper-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

const AddNewScannedPayment: React.FC<AddNewScannedPaymentProps> = ({ open, anchorEl, onClose, onSave, initialData, pageNum, ecwDocId }) => {
    const defaultData: RowData = {
        checkdate: new Date().toISOString().split('T')[0],
        pageno : pageNum ? pageNum : 0,
        ecwdoc_id : ecwDocId? ecwDocId : 0
    };

    const [formData, setFormData] = useState<RowData>(initialData || defaultData);

    useEffect(() => {
        if (initialData) {
            setFormData(initialData);
        } else {
            setFormData(defaultData);
        }
    }, [initialData]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSave = () => {
        console.log("Save new patyment called", formData);
        onSave({ ...formData, pageno: pageNum ? pageNum : -1, ecwdoc_id: ecwDocId ? ecwDocId : -1 });
        onClose();
    };

    return (

            <Popper open={open} anchorEl={anchorEl} placement="top" disablePortal style={{ zIndex: 1300 }}>
                <DraggablePaperComponent style={{ width: 700, position: 'fixed', top: 150, left: 10, transform: 'translate(-50%, -50%)' }}>
                    <Box p={2} display="flex" justifyContent="space-between" alignItems="center" style={{ cursor: 'move' }}>
                        <Typography variant="h6" id="draggable-popper-title">Add New Scanned Payment</Typography>
                        <IconButton size="small" onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Box p={2}>
                        <form id="add-new-scanned-payment-form" onSubmit={(e) => { e.preventDefault(); handleSave(); }}>
                            <Box display="flex" flexDirection="row" flexWrap="wrap" gap={2}>
                                <Box flexBasis="calc(33.3333% - 16px)">
                                    <TextField
                                        label="ECW Doc ID"
                                        name="ecwdoc_id"
                                        variant="outlined"
                                        fullWidth
                                        value={formData.ecwdoc_id || ecwDocId}
                                        onChange={handleChange}
                                    />
                                </Box>
                                <Box flexBasis="calc(33.3333% - 16px)">
                                    <TextField
                                        label="Page No"
                                        name="pageno"
                                        variant="outlined"
                                        fullWidth
                                        type="number"
                                        value={formData.pageno || pageNum}
                                        onChange={handleChange}
                                    />
                                </Box>
                                <Box flexBasis="calc(33.3333% - 16px)">
                                    <TextField
                                        label="Check Number"
                                        name="checknumber"
                                        variant="outlined"
                                        fullWidth
                                        value={formData.checknumber || ''}
                                        onChange={handleChange}
                                    />
                                </Box>
                                <Box flexBasis="calc(33.3333% - 16px)">
                                    <TextField
                                        label="Page Detail"
                                        name="pagedetail"
                                        variant="outlined"
                                        fullWidth
                                        value={formData.pagedetail || ''}
                                        onChange={handleChange}
                                    />
                                </Box>
                                <Box flexBasis="calc(33.3333% - 16px)">
                                    <TextField
                                        label="Check Amount"
                                        name="checkamount"
                                        variant="outlined"
                                        fullWidth
                                        type="number"
                                        value={formData.checkamount || ''}
                                        onChange={handleChange}
                                    />
                                </Box>
                                <Box flexBasis="calc(33.3333% - 16px)">
                                    <TextField
                                        label="Check Date"
                                        name="checkdate"
                                        variant="outlined"
                                        fullWidth
                                        type="date"
                                        InputLabelProps={{ shrink: true }}
                                        value={formData.checkdate || ''}
                                        onChange={handleChange}
                                    />
                                </Box>
                                <Box flexBasis="calc(33.3333% - 16px)">
                                    <TextField
                                        label="Card Amount"
                                        name="cardamount"
                                        variant="outlined"
                                        fullWidth
                                        type="number"
                                        value={formData.cardamount || ''}
                                        onChange={handleChange}
                                    />
                                </Box>
                                <Box flexBasis="calc(33.3333% - 16px)">
                                    <TextField
                                        label="Other Amount"
                                        name="otheramount"
                                        variant="outlined"
                                        fullWidth
                                        type="number"
                                        value={formData.otheramount || ''}
                                        onChange={handleChange}
                                    />
                                </Box>
                                <Box flexBasis="calc(33.3333% - 16px)">
                                    <TextField
                                        label="Other Amount 2"
                                        name="otheramount2"
                                        variant="outlined"
                                        fullWidth
                                        type="number"
                                        value={formData.otheramount2 || ''}
                                        onChange={handleChange}
                                    />
                                </Box>
                                <Box flexBasis="calc(33.3333% - 16px)">
                                    <TextField
                                        label="Is Payment Cached"
                                        name="ispaymentcached"
                                        variant="outlined"
                                        fullWidth
                                        type="number"
                                        value={formData.ispaymentcached || ''}
                                        onChange={handleChange}
                                    />
                                </Box>
                                <Box flexBasis="calc(33.3333% - 16px)">
                                    <TextField
                                        label="Expired Payment"
                                        name="expiredpayment"
                                        variant="outlined"
                                        fullWidth
                                        type="number"
                                        value={formData.expiredpayment || ''}
                                        onChange={handleChange}
                                    />
                                </Box>
                                <Box flexBasis="calc(33.3333% - 16px)">
                                    <TextField
                                        label="Replacement Remarks"
                                        name="replacementremarks"
                                        variant="outlined"
                                        fullWidth
                                        value={formData.replacementremarks || ''}
                                        onChange={handleChange}
                                    />
                                </Box>
                                <Box flexBasis="calc(33.3333% - 16px)">
                                    <TextField
                                        label="Payment Notes"
                                        name="paymentnotes"
                                        variant="outlined"
                                        fullWidth
                                        value={formData.paymentnotes || ''}
                                        onChange={handleChange}
                                    />
                                </Box>
                                <Box flexBasis="calc(33.3333% - 16px)">
                                    <TextField
                                        label="Reference 1"
                                        name="reference1"
                                        variant="outlined"
                                        fullWidth
                                        value={formData.reference1 || ''}
                                        onChange={handleChange}
                                    />
                                </Box>
                            </Box>
                        </form>
                    </Box>
                    <Box display="flex" justifyContent="flex-end" p={2}>
                        <Button onClick={onClose} color="primary">Cancel</Button>
                        <Button type="submit" form="add-new-scanned-payment-form" color="primary">Save</Button>
                    </Box>
                </DraggablePaperComponent>
            </Popper>

    );
};

export default AddNewScannedPayment;
