import React from 'react';
import AppDataGrid from '../crmdatagrid/AppDataGrid';
import DataViewManager from '../crmdatagrid/dataviewmanager';
import { bodyTheme } from "../css/appthememanager";

const columns = [
    { field: 'id', headerName: 'ID', width: 100 },
    { field: 'name', headerName: 'Name', width: 200 },
    { field: 'age', headerName: 'Age', width: 100, filterHint: 'numeric' },
    { field: 'email', headerName: 'Email', width: 250, filterHint: 'freetext' },
];

const rows = [
    { id: 1, name: 'John Doe', age: 30, email: 'john.doe@example.com' },
    { id: 2, name: 'Jane Smith', age: 25, email: 'jane.smith@example.com' },
    { id: 3, name: 'Michael Johnson', age: 40, email: 'michael.johnson@example.com' },
    { id: 4, name: 'Emily Davis', age: 35, email: 'emily.davis@example.com' },
];

const colorMap = [
    { filter: { age: 30 }, color: 'lightblue' },
    { filter: { age: 25 }, color: 'lightgreen' },
];

class SampleDataGrid extends React.Component {
    constructor(props) {
        super(props);
        this.dataViewManager = new DataViewManager();
        this.dataViewManager.setData(rows, columns);
    }

    handleRowClick = (row, event) => {
        console.log('Row clicked:', row);
    }

    handleAddFilter = (field, expression) => {
        console.log('Filter added:', field, expression);
    }

    handleSort = (columnName) => {
        console.log('Sorting by:', columnName);
    }

    render() {
        return (
            <div>
                <h1>Sample App Data Grid</h1>
                <AppDataGrid
                    columns={columns}
                    rows={rows}
                    showHeader={false}
                    colorMap={colorMap}
                    dataviewManager={this.dataViewManager}
                    useExternalDataManager={true}
                    checkBoxSelection={true}
                    onRowClicked={this.handleRowClick}
                    onAddFilter={this.handleAddFilter}
                    onSort={this.handleSort}
                />
                <h1>Grind Rendered</h1>
            </div>
        );
    }
}

export default SampleDataGrid;

// Uncomment the line below to render the component in an HTML element with id 'root'
// ReactDOM.render(<SampleDataGrid />, document.getElementById('root'));
