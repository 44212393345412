
import React, { useEffect } from "react";
import useState from 'react-usestateref';
import apiConfig from '../apiConfig';
import axios from "axios";

import {
    Flex,
    Text,
    FormControl,
    HStack,
    FormLabel,
    Box,
    Button,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverArrow,
    PopoverCloseButton,
    PopoverBody,
    Input

} from "@chakra-ui/react";

import { AgGridReact } from 'ag-grid-react';

import {
    AutoComplete,
    AutoCompleteInput,
    AutoCompleteItem,
    AutoCompleteList,
  } from "@choc-ui/chakra-autocomplete";

  import 'ag-grid-community/styles/ag-grid.css';
  import 'ag-grid-community/styles/ag-theme-alpine.css';

export function SelectEcwInsurance({ selectedEcwInsurance, setSelectedEcwInsurance }) {

    let apiURL=apiConfig.REACT_APEX_BASE_API;
    const gridRef = React.useRef();
    const [quickInsuranceFilter, setQuickInsuranceFilter] = useState('');
    const [ecwinsurnaces, setecwinsurances] = useState([]);
    

    const [insuranceColumnDefs, setinsuranceColumnDefs] = useState(
        [
            { field: 'insid', filter: true , width:60 ,  sortable: true,cellStyle: {fontSize: '15px'}, headerName: 'ID' , 
            resizable: true,editable: true},
            { field: 'payorid', width: 100 , sortable: true,cellStyle: {fontSize: '15px'},headerName: 'PayorID',
            resizable: true,},
            { field: 'insurancename', filter: true, width: 300, sortable: true,cellStyle: {fontSize: '15px'},headerName: 'Name',
            resizable: true, },
            { field: 'fulladdress', filter: true , width:350 ,  sortable: true,cellStyle: {fontSize: '15px'}, headerName: 'Address' , 
            resizable: true,editable: true},
        
  
        ]
    );

    useEffect(()=> {

        const getData = async () => {
  
            const res2 = await axios.get(`${apiURL}/ecw/getinsurances`);
            if (res2.data) {
              console.log("GOT Insurances:", res2.data);
              setecwinsurances(res2.data);
            }
    

        };
      
        getData(); 
      
        return () => {
          // this now gets called when the component unmounts
        };
  
  
      }, []);

    const handleQuickInsuranceFilter = (event) => {
        console.log("Quick Patient Filter:",event.target, event.target.value, gridRef.current);
        setQuickInsuranceFilter(event.target.value );
        gridRef.current.api.setQuickFilter(event.target.value);
    };
      
    const handleGridRowSelected = (event) => {

        //console.log("handleGridRowSelected:",event);
        const selectedNodes = event.api
          .getSelectedNodes()
          .filter((node) => node.selected);
        //console.log(selectedNodes);
    
        const datarow = selectedNodes[0].data;
        console.log('AG Row selected', datarow);
        setSelectedEcwInsurance(datarow);
        setQuickInsuranceFilter(datarow.Name);

      };

      //use selectedEcwFolder to se currently selected value

      return(

        <Popover isLazy>
  <PopoverTrigger>
    <Input as='input' value={selectedEcwInsurance?.insurancename}  onChange={handleQuickInsuranceFilter}  
          placeholder="Select Insurance" variant="filled" fontSize='sm' aria-multiline 
          w='450px' />
  </PopoverTrigger>
  <PopoverContent bg='tomato' color='white' w='800px'>
    <PopoverHeader fontWeight='semibold'>Popover placement</PopoverHeader>
    <PopoverArrow />
    <PopoverCloseButton />
    <PopoverBody>
    <Input as='input' value={quickInsuranceFilter}  onChange={handleQuickInsuranceFilter}  
          placeholder="Select Insurance" variant="filled" fontSize='sm' aria-multiline />
    <Box className="ag-theme-alpine maxWidth" style={{ paddingLeft: '-200px', width: '100%', height: '450px', overflow: 'hidden' }}>
                <AgGridReact 
                ref={gridRef} 
                rowData={ecwinsurnaces} 
                rowHeight={20}
                columnDefs={insuranceColumnDefs} 
                animateRows={true} 
                resizable={true} 
                rowSelection="single"
                rowDragManaged={true}
                suppressMoveWhenRowDragging={true}
                applyColumnDefOrder={true}
                onSelectionChanged={handleGridRowSelected}
                />
     </Box>
    </PopoverBody>
  </PopoverContent>
</Popover>


      )

    }