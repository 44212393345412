
import React, {  useRef, useEffect, useMemo, useCallback } from 'react';
import useState from 'react-usestateref';
//import { Typography, TextField, Button, Container, Stack, Divider } from "@mui/material";
import { Box, Button, Flex, Text, useDisclosure, Container, Stack, Divider , Grid, Center, VStack, HStack} from "@chakra-ui/react";
import {
  Icon, Modal, ModalBody, 
  ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, FormControl, FormLabel, Input, Spacer,  useClipboard
} from '@chakra-ui/react';
import { useNavigate, useParams } from "react-router-dom";

import { SelectEcwPatient } from '../Modals/SelectApexPatient';
import {
  Avatar,
  FormHelperText,
  useRadioGroup,
  useRadio,
  Tabs,
 TabList,
 Tab,
 TabPanels,
 TabPanel,
 Editable,
 EditableInput,
 EditablePreview,
 EditableTextarea,
 Checkbox,
 Switch,
 Textarea,

} from "@chakra-ui/react";

import { Select } from "chakra-react-select";

import ApexPdfjsViewer from "../Components/ApexPdfjsViewer";
import { AgGridReact } from 'ag-grid-react';
import UploadFile from "./UploadFile";
import { AttachDoc } from './AttachDoc_model_v1';
import axios from "axios";
import apiConfig from '../apiConfig';
import { AttachDocument } from './AttachDocument';


import {
    loadGridData,
    DATA_PATIENT_LIST,
    DATA_PATIENT_LIST_BY_IDS,
    DATA_APEX_PT_DOCS

} from '../utils/ecw/EcwData';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import SplitPanel from "./splitpage/splitpanel";

function ApexDocumentManager(props) {

    let apiURL=apiConfig.REACT_APEX_BASE_API;
    const gridRef = useRef();
    const docgridRef = useRef();
    const docPtgridRef = useRef();

    const [rowData, setRowData] = useState();
    const [rowDocsData, setRowDocsData] = useState();
    const [rowPtDocsData, setrowPtDocsData] = useState();

    const [sizes, setSizes] = useState([100, '30%', 'auto']);

    const layoutCSS = {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    };

    const [data, setFileData] = useState(    {
      fileName: '',
      fileData: null
    })


    const [selectedEcwPtDocsPatient, setSelectedEcwPtDocsPatient] = useState();
    const [folderContext, setFolderContext] = useState('');
    const [folderType, setFolderType] = useState('');

    const [documentURL, setdocumentURL] = useState('')
    const [startingPageNum, setstartingPageNum] = useState(1)
    const [pdfToDisplayURL, setpdfToDisplayURL] = useState('')
    const [currentPageNum, setCurrentPageNum] = useState(1);

    const [quickPatientFilter, setQuickPatientFilter] = useState('');
    const [currentPatientName, setcurrentPatientName] = useState('');

    const [currentFileData, setcurrentFileData] = useState();

    const [uploadFileAttributes, setuploadFileAttributes] = useState({});

    const [quickDocsFilter, setQuickDocsFilter] = useState('');
    const [quickPtDocsFilter, setQuickPtDocsFilter] = useState('');
    const [isDocumentReady, setIsDocumentReady, isDocumentReadyRef ] = useState(false);
    const viewer = useRef(null);
    const [pdfViewerInstance, setpdfViewerInstance, pdfViewerRef] = useState(null)
    const [pdfViewerCurrentPage, setpdfViewerCurrentPage] = useState(1)

    const { isOpen, onOpen, onClose } = useDisclosure()
  
    const initialRef = React.useRef()
    const finalRef = React.useRef()

    const setCurrentPageInfo = (pageNumber) => {
        setpdfViewerCurrentPage( pageNumber);
        console.log(`SETTING Page number to: ${pageNumber}  == ${pdfViewerCurrentPage}`, pdfViewerCurrentPage);
    }

    const pageParams = useParams(); // props.match.params.docpath;

    const docContext = pageParams.context;

    //const {onRowSelected, onViewStatement, onViewDocuments } = props;

    const buttonListener = useCallback( e=> {
        gridRef.current.api.deselectAll();
    }, []);

    const handleGridRowButtonClick = (data, cmdtype) => {
        if (typeof cmdtype === 'string' || cmdtype instanceof String) {
            console.log("RowButton", cmdtype, data);
        }

        if (cmdtype.toString().includes("Statement")) {
            handleAgStatement(data);
            setcurrentPatientName(data.Name);
            loadGridData(DATA_APEX_PT_DOCS, {patient_id: data.PatientId}, recvGridData);
            return;
        }

        if (cmdtype.toString().includes("PtDocs")) {
            //handleAgStatement(data);
            console.log("Will Retrieve Documents", data);
            setcurrentPatientName(data.Name);
            loadGridData(DATA_APEX_PT_DOCS, {patient_id: data.PatientId}, recvGridData);
            return;
        }

        if (cmdtype.toString().includes("View")) {
            handleAgDocument(data);
            return;
        }

        if (cmdtype.toString().includes("Upload")) {
            console.log("Will Upload this document:", data);

            //uploadDocumentToServer(data);
            return;
        }
       
        //console.log("handleGridRowButtonClick:",data, cmdtype);
    };

    const handleModelButtonClick = (data, cmdtype) => {
      if (typeof cmdtype === 'string' || cmdtype instanceof String) {
          console.log(" Model RowButton", cmdtype, data);
      }

    
      if (cmdtype.toString().includes("Attach")) {
          console.log("Attach Button clicked:", data);

          //uploadDocumentToServer(data);
          return;
      }
     
      //console.log("handleGridRowButtonClick:",data, cmdtype);
  };

    const uploadDocumentToServer = (data) => {

        let target_name = '';
        target_name += uploadFileAttributes.filePrefixDate;
        console.log('Change to target name1: ', target_name);
        target_name += uploadFileAttributes.docuTypeName == null ? '' : ` ${uploadFileAttributes.docuTypeName}`;
        console.log('Change to target name2: ', target_name);
        target_name +=
        uploadFileAttributes.newFileNameSelected == ''
            ? ''
            : ` ${uploadFileAttributes.newFileNameSelected}`;
        console.log('Change to target name3: ', target_name);
        target_name +=
        uploadFileAttributes.RenameToFilename == ''
            ? ''
            : ` ${uploadFileAttributes.RenameToFilename}`;
        console.log('Change to target name4: ', target_name);
    
        let arg_file_operation = 'rename';
    
        if (uploadFileAttributes.docuContext == 'EMR') {
          arg_file_operation = 'uploademr';
        } else {
          arg_file_operation = 'rename';
        }
    
        // this.funcProcessDocument(
        //   this.state.docuContext,
        //   arg_file_operation,
        //   this.state.filetodisplay,
        //   target_name
        // );
    
        const context_data = {
          fileop: arg_file_operation,
          patientid: uploadFileAttributes.patientid,
          patientname: uploadFileAttributes.patientname,
          filecategory: uploadFileAttributes.docuType,
          filename: uploadFileAttributes.filetodisplay,
          context: uploadFileAttributes.docuContext,
          renameto: target_name,
          filedate: uploadFileAttributes.recentdate,
          pagenum: uploadFileAttributes.filepagenum,
          docutype: uploadFileAttributes.docuType,
          docupages: uploadFileAttributes.docuPages,
          actiondesc: uploadFileAttributes.actiondesc,
          depaccount: uploadFileAttributes.depositAccount,
          fileData: data.fileData

        };
    
        console.log('Starting process Document File: ');
        // cli_processfile_py(
        //   this.recvProcessFileOutput,
        //   JSON.stringify(context_data)
        // );

        const form = new FormData();
        form.append('fileName', data.fileName);
        form.append('fileSize', data.fileSize);
        form.append('fileData', data.fileData);

  
        axios
        .post(`${apiURL}/uploadFileWithData`, form, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
        .then((res) => {
          console.log(res.statusText);
          console.log('Called funcProcessDocument Successfully');
        });
    
        console.log('Called funcProcessDocument', context_data);
      };

    const handleGridRowSelected = (event) => {

        //console.log("handleGridRowSelected:",event);
        const selectedNodes = event.api
          .getSelectedNodes()
          .filter((node) => node.selected);
        //console.log(selectedNodes);
    
        const datarow = selectedNodes[0].data;
        console.log('AG Row selected', datarow);
        let filePath = datarow.fileName;
        filePath = datarow.dirpath ?  datarow.dirpath + '/' + filePath : filePath;
        setFileData({fileName : filePath, fileData: datarow.fileData})

        setcurrentFileData(datarow);

        // console.log("Selected patient:", datarow, currentPatientName);
        // if (currentPatientName !== "" && datarow.Name !== currentPatientName) {
        //     setcurrentPatientName("");  
        //     setRowDocsData([]);
        // }
        //setcurrentPatientName('');
        //this.handleChangePatient(datarow);

      };

      const getUploadedFiles = (filedata) => {
        console.log("Parent Received:", filedata);
        setRowDocsData(filedata);
      }
    

    const [patientColumnDefs, setPatientColumnDefs] = useState(
        [
            { field: 'Name', filter: true , width: 250,   sortable: true,
            resizable: true,editable: true},
            { field: 'PatientId', filter: true, width: 100, sortable: true,
            resizable: true, },
            { field: 'DOB', width: 150 , sortable: true,
            resizable: true,},
            { field: '',  cellRenderer: BtnCellRenderer,
                                cellRendererParams: {
                                    clicked: handleGridRowButtonClick,
                                    buttonText: 'Statement'
                                }, width: 100 ,
             },
             { field: '',  cellRenderer: BtnCellRenderer,
             cellRendererParams: {
                 clicked: handleGridRowButtonClick,
                 buttonText: 'PtDocs'
             }, width: 100 ,
},


        ]
    );

    const [docsColumnDefs, setdocsColumnDefs] = useState(
        [
            { field: 'fileName', filter: true , width: 200,   sortable: true,
            resizable: true,editable: true},
            { field: 'customName', filter: true , width: 200,   sortable: true,
            resizable: true,editable: true},
            { field: 'fileSize', filter: true, width: 100, sortable: true,
            resizable: true, },
             { field: '',  cellRenderer: BtnCellRenderer,
             cellRendererParams: {
                 clicked: handleGridRowButtonClick,
                 buttonText: 'View'
             }, width: 50 ,
            },
          //   { field: '',  cellRenderer: BtnCellRenderer,
          //   cellRendererParams: {
          //       clicked: handleGridRowButtonClick,
          //       buttonText: 'Upload'
          //   }, width: 50 ,
          //  },
          //  { field: '',  cellRenderer: AttachDoc,
          //  cellRendererParams: {
          //      clicked: handleModelButtonClick,
          //      buttonText: 'Attach'
          //  }, width: 100 ,
          // },

        ]
    );

    

    const defaultColDef = useMemo( () => ({

    }))

    
    useEffect(() => {
        loadGridData(DATA_PATIENT_LIST, {}, recvGridData);
    }, []);

    const recvGridData = (gridName, args, gridData) => {
        console.log('ReceivedData for :', gridName, args, gridData);
       
        switch (gridName) {
        
          case DATA_PATIENT_LIST:
          case DATA_PATIENT_LIST_BY_IDS:
            setRowData(gridData);
            break;
          case DATA_APEX_PT_DOCS:
              setrowPtDocsData(gridData);
            break;
          default:
        }
      };



    const cellClickedListener = useCallback( event => {
        console.log('cellClicked', event);
    }, [] );

    const handleQuickPatientFilter = (event) => {
        console.log("Quick Patient Filter:",event.target, event.target.value, gridRef.current);
        setQuickPatientFilter(event.target.value );
        gridRef.current.api.setQuickFilter(event.target.value);
    };

    const handleQuickDocsFilter = (event) => {
        console.log("Quick Docs Filter:",event.target, event.target.value, docgridRef.current);
        setQuickDocsFilter(event.target.value );
        docgridRef.current.api.setQuickFilter(event.target.value);
    };

    const handleQuickPtDocsFilter = (event) => {
        console.log("Quick PtDocs Filter:",event.target, event.target.value, docPtgridRef.current);
        setQuickPtDocsFilter(event.target.value );
        docPtgridRef.current.api.setQuickFilter(event.target.value);
    };

    const handleAgStatement = (datarow) => {
        console.log('AG Statement selected', datarow);
        console.log("Will display to pdf viewer:", viewer.current);

        //fetch(`${apiURL}/getstatementjava?patid=${datarow.PatientId.toString()}&full=yes`, {
        fetch(`${apiURL}/getecwstatement?patid=${datarow.PatientId.toString()}&incqrcode=portal&startdos=2016-01-01`, {
            headers: {
              "Access-Control-Allow-Origin": "*",
            },})
            .then((r) => r.blob())
            .then((blob) => {
      
              //this.showFilePdf(blob);
              let url = URL.createObjectURL(blob);
              setpdfToDisplayURL(url);
              setstartingPageNum(1);
      
              // pdfViewerRef.current.UI.loadDocument(url, {
              //   extension: 'pdf'
              // });
        
            });
      };

      const handleEcwDocument = (data) => {

        let documentFilePath = `${data.dirpath}/${data.fileName}` ;
       
        let full_file_path = `\\\\192.168.1.17\\d$\\eClinicalWorks\\ftp\\${documentFilePath}`;
        full_file_path = full_file_path.replace('/', '\\');

        full_file_path = `${apiURL}/downloadFile/${full_file_path.replaceAll("\\", "~").replaceAll("/", "~")}`;
    
        console.log('Starting to get Linq File', full_file_path);
    
        fetch(
              encodeURI(full_file_path), {
                  headers: {
                      "Access-Control-Allow-Origin": "*",
                  },}
          )
              // .then(this.handleErrors)
              .then((r) => r.blob())
              .then((blob) => {

                  let url = URL.createObjectURL(blob);

                  setpdfToDisplayURL(url);
                  setstartingPageNum(1);
                  // pdfViewerRef.current.UI.loadDocument(url, {
                  //   extension: 'pdf'
                  // });

                  // pdfViewerRef.current.UI.loadDocument(url, {
                  //   extension: 'pdf'
                  // });

              });
      
      };


      const handleFolderDocument = (documentFilePath) => {

       // let documentFilePath = `${data.dirpath}/${data.fileName}` ;
       
        let full_file_path = `${documentFilePath}`;
        full_file_path = full_file_path.replace('/', '\\');

        full_file_path = `${apiURL}/downloadFile/${full_file_path.replaceAll("\\", "~").replaceAll("/", "~")}`;
    
        console.log('Starting to get Linq File', full_file_path);
    
        fetch(
          encodeURI(full_file_path), {
            headers: {
              "Access-Control-Allow-Origin": "*",
            },}
        )
         // .then(this.handleErrors)
          .then((r) => r.blob())
          .then((blob) => {
    
            let url = URL.createObjectURL(blob);

            setpdfToDisplayURL(url);
            setstartingPageNum(1);
            // pdfViewerRef.current.UI.loadDocument(url, {
            //   extension: 'pdf'
            // });
         
            // pdfViewerRef.current.UI.loadDocument(url, {
            //   extension: 'pdf'
            // });
        
          });
      
      };

      const handleAgDocument = (data) => {
        console.log("HandleAgDocument", data);

        if (!data.fileData) {

          if (data.fileName.includes('ecwupload') || data.fileName.includes('ecwsrv')) {
            handleFolderDocument(data.fileName);
            return;
          }
          handleEcwDocument(data);
          return;
        }

      const reader = new FileReader();

    reader.addEventListener(
    "load",
    () => {
      // convert image file to base64 string
      //preview.src = reader.result;

      setpdfToDisplayURL(reader.result);
      setstartingPageNum(1);
      // pdfViewerRef.current.UI.loadDocument(reader.result, {
      //   extension: 'pdf'
      // });

    },
    false
  );

  if (data.fileData) {
    reader.readAsDataURL(data.fileData);
  }


      };
    
    const countLines = 100;

    const onSelectedEcwPtDocsPatient = (data) => {
      console.log("select ptDoc patient in documanager", data);
      if (data && data.Name) {
          setSelectedEcwPtDocsPatient(data);
          setcurrentPatientName(data.Name);

          loadGridData(DATA_APEX_PT_DOCS, {patient_id: data.PatientId}, recvGridData);
      }
    }

    const handleShowDocuments = () => {
      console.log("select ptDoc patient", selectedEcwPtDocsPatient);
      if (selectedEcwPtDocsPatient) {
          setcurrentPatientName(selectedEcwPtDocsPatient.Name);
          loadGridData(DATA_APEX_PT_DOCS, {patient_id: selectedEcwPtDocsPatient.PatientId}, recvGridData);
      }
    }

    const handleShowStatement = () => {
      handleAgStatement(selectedEcwPtDocsPatient);
    }

    const folderPaths = [
      { value: 'scanhome', label: 'Scan Home' },
      { value: 'scansnap', label: 'ScanSnap' },
      { value: 'scansnaplocal', label: 'ScanSnapLocal' },
      { value: 'echohome', label: 'Echo Home' },
      { value: 'ccmdhome', label: 'CCMD Home' },
      { value: 'uploads', label: 'Upload Folder' },
      { value: 'apexdoc', label: 'Apex Documents' },
      { value: 'skscan', label: 'SK Scan Folder' },
      { value: 'local', label: 'PC Local Work Folder' },
      { value: 'checks5555', label: 'checks5555' },
      { value: 'pcodelocalwa', label: 'NAS Local Work Folder' },
    ];

    const handleRefreshFiles = (fldrPath) => {
      console.log('Getting files from: ', fldrPath, folderContext);
      //ipcRenderer.send('show-folder-list', this.state.folderContext);
  
      recevieRefreshFiles(fldrPath);
  
    };

    const recevieRefreshFiles = async (fldrPath) => {
      fldrPath = folderContext ? folderContext : fldrPath;
      let json_data =  await fetch(
        `${apiURL}/getfiles?folderpath=${fldrPath.replaceAll("/","~").replaceAll("\\","~")}`, 
       ).then((response) => {
           if (response.status !== 200) {
            console.log('Something went wrong in getting data');
           }
           return response.json();
         })
         .then((data) => {
           console.log("setting file list", data);
           data = data.map(row=> {row["Name"] = row["key"]; row["fileName"] = row["key"]; return row});
           setRowDocsData(data);
   
         });
     };
   

    const handleFolderChange = (selected) => {
      selected ? setFolderType(selected.value) : setFolderType('');
      //setFolderType(selected.value)
      console.log(`Selected folder: ${selected.value}`);
      let folderpath = '\\\\192.168.1.17\\SCANHOME';
      switch (selected.value) {
        case 'scanhome':
          folderpath = '\\\\192.168.1.17\\SCANHOME';
          break;
        case 'echohome':
          folderpath = '\\\\192.168.21.54\\ECHO_REPORTS';
          break;
        case 'ccmdhome':
          folderpath = '\\\\192.168.1.20\\CCMD_REPORTS';
          break;
        case 'skscan':
          folderpath = '\\\\pcode-nas1\\skshare\\skscan';
          break;
        case 'uploads':
          folderpath = '\\\\192.168.1.17\\uploadhome';
          break;
        case 'apexdoc':
          folderpath = '\\\\192.168.1.17\\d$\\ApexDocs\\EMR';
          break;
        case 'local':
          folderpath = 'c:\\mydocs';
          break;
        case 'pcodelocalwa':
          folderpath = '\\\\192.168.21.59\\skshare\\skscan\\mydocwa';
          break;
        case 'scansnaplocal':
          folderpath = 'C:\\scanhome\\scansnap';
          break;
        case 'scansnap':
          folderpath = '\\\\pcode-nas1\\skshare\\skscan\\scansnap';
          break;
        case 'checks5555':
          folderpath =
            '\\\\pcode-nas1\\skshare\\AcctDocs\\Banks\\Apex\\BBVA\\Checking5555\\CheckImages';
          break;
        default:
          folderpath = '\\\\192.168.1.17\\uploadhome';
          break;
      }
      console.log(`Will get files from folder: ${folderpath}`);
      setFolderContext(folderpath);
      handleRefreshFiles(folderpath);

    };

    let fileScope =  docContext? docContext : 'attach';

    return(
     
    <SplitPanel split="horizontal">
      <div style={{width: '100%'}}>

          <VStack>
              <HStack>


                  {fileScope.includes('action') && (
                      <SelectEcwPatient selectedEcwPatient={selectedEcwPtDocsPatient}
                                        setSelectedEcwPatient={onSelectedEcwPtDocsPatient}/>
                  )}

                  {fileScope.includes('attach') && (
                      <UploadFile getUploadedFiles={getUploadedFiles} width={'30px'}/>
                  )}
                  <Button colorScheme="blue" mr={2} onClick={handleShowStatement}>Stmt</Button>
                  <Button colorScheme="blue" mr={2} onClick={handleShowDocuments}>PtDocs</Button>
              </HStack>
              <Flex>
                  <div className="d-inline">
                      <Select width={'300px'}
                              isClearable={true}
                              options={folderPaths}
                              placeholder="Select Type..."
                              closeMenuOnSelect={true}
                              onChange={handleFolderChange}
                      />
                      <Text>{currentPatientName}</Text>
                  </div>
              </Flex>

              <Box width='100%'>
                  <AttachDocument fileScope={fileScope} data={data} currentPageNum={currentPageNum}
                                  rowDocsData={rowDocsData} setRowDocsData={handleAgDocument}
                                  setSelectedEcwDocsPatient={onSelectedEcwPtDocsPatient}/>
              </Box>
              <input
                  type="text"
                  placeholder="Quick Filter"
                  value={quickDocsFilter}
                  onChange={handleQuickDocsFilter}
              />

              <div className="ag-theme-alpine " style={{width: '100%', height: '25vh', overflow: 'hidden'}}>

                  <AgGridReact
                      ref={docgridRef}
                      rowData={rowDocsData}
                      columnDefs={[
                          {
                              field: 'fileName', filter: true, width: 650, sortable: true,
                              resizable: true, editable: true
                          },

                          {
                              field: '', cellRenderer: BtnCellRenderer,
                              cellRendererParams: {
                                  clicked: handleGridRowButtonClick,
                                  buttonText: 'View'
                              }, width: 100,
                          },]}
                      defaultColDef={defaultColDef}
                      animateRows={true}
                      resizable={true}
                      rowSelection="single"
                      rowDragManaged={true}
                      suppressMoveWhenRowDragging={true}
                      applyColumnDefOrder={true}
                      onSelectionChanged={handleGridRowSelected}
                  />
              </div>
              <input
                  type="text"
                  placeholder="Quick Filter"
                  value={quickPtDocsFilter}
                  onChange={handleQuickPtDocsFilter}
              />

              <div className="ag-theme-alpine " style={{width: '100%', height: '35vh', overflow: 'hidden'}}>

                  <AgGridReact
                      ref={docPtgridRef}
                      rowData={rowPtDocsData}
                      columnDefs={[
                          {
                              field: 'customName', filter: true, width: 250, sortable: true,
                              resizable: true, editable: true
                          },
                          {
                              field: 'scanDate', filter: true, width: 200, sortable: true,
                              resizable: true, editable: true
                          },
                          {
                              field: 'ScannedBy', filter: true, width: 150, sortable: true,
                              resizable: true, editable: true
                          },

                          {
                              field: '', cellRenderer: BtnCellRenderer,
                              cellRendererParams: {
                                  clicked: handleGridRowButtonClick,
                                  buttonText: 'View'
                              }, width: 100,
                          },]}


                      defaultColDef={defaultColDef}
                      animateRows={true}
                      resizable={true}
                      rowSelection="single"
                      rowDragManaged={true}
                      suppressMoveWhenRowDragging={true}
                      applyColumnDefOrder={true}
                      onSelectionChanged={handleGridRowSelected}
                  />
              </div>
          </VStack>

      </div>

        <div width='100%'><Box bg='green.500'>
            <ApexPdfjsViewer pdfUrl={pdfToDisplayURL} startingPageNum={startingPageNum}
                             setCurrentPageNum={setCurrentPageNum}/>
        </Box>
        </div>
    </SplitPanel>


    );
}

// BtnCellRenderer.jsx

function BtnCellRenderer(props) {
    //let cellformattedValue = ` ${props.data.price}`;

    const btnClickedHandler = () => {
        props.clicked(props.data, props.buttonText);
    }
    return (
        <>
            <div>
                <button onClick={btnClickedHandler}>{props.buttonText}</button>
            </div>

        </>
    )
}

function ModelCellRenderer(props) {
    //let cellformattedValue = ` ${props.data.price}`;

    const btnClickedHandler = () => {
        props.clicked(props.data, props.buttonText);
    }
    console.log("MODEL PARAMS INPUT", props.data);
    return (
        <>
            <AttachDoc buttonText="{props.buttonText}" data={props.data}>
                Put in whatever text you want here
            </AttachDoc>
        </>

    )
  }
export default ApexDocumentManager;
