
import React, {  useRef, useEffect, useMemo, useCallback } from 'react';
import useState from 'react-usestateref';

import { Box, Button, Flex, Text, useDisclosure, Container, Stack, Divider , Grid, Center, VStack, HStack} from "@chakra-ui/react";
import { AgGridReact } from 'ag-grid-react';

import apiConfig from '../apiConfig';
import ApexPdfjsViewer from './ApexPdfjsViewer';
import SplitPanel from "./splitpage/splitpanel";

import {
    loadGridData,
    DATA_PATIENT_LIST,
    DATA_PATIENT_LIST_BY_IDS,
    DATA_APEX_PT_DOCS

} from '../utils/ecw/EcwData';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

function ApexPatientList(props) {

    let apiURL=apiConfig.REACT_APEX_BASE_API;

    const gridRef = useRef();
    const docgridRef = useRef();
    const [rowData, setRowData] = useState();
    const [rowDocsData, setRowDocsData] = useState();
    const [quickPatientFilter, setQuickPatientFilter] = useState('');
    const [currentPatientName, setcurrentPatientName] = useState('');
    const [quickDocsFilter, setQuickDocsFilter] = useState('');
    const [isDocumentReady, setIsDocumentReady, isDocumentReadyRef ] = useState(false);
    const viewer = useRef(null);
    const [pdfViewerInstance, setpdfViewerInstance, pdfViewerRef] = useState(null)
    const [pdfViewerCurrentPage, setpdfViewerCurrentPage] = useState(1)
    const [pdfToDisplayURL, setpdfToDisplayURL] = useState('')

    const [activePatientRow, setActivePatient, activePatientRowRef ] = useState({});

    const setCurrentPageInfo = (pageNumber) => {
        setpdfViewerCurrentPage( pageNumber);
        console.log(`SETTING Page number to: ${pageNumber}  == ${pdfViewerCurrentPage}`, pdfViewerCurrentPage);
    }

    const buttonListener = useCallback( e=> {
        gridRef.current.api.deselectAll();
    }, []);

    const handleGridRowButtonClick = (data, cmdtype) => {
        if (typeof cmdtype === 'string' || cmdtype instanceof String) {
            console.log("RowButton", cmdtype, data);
        }

        if (cmdtype.toString().includes("LastStmt")) {
            handleAgStatement(data, true);
            setcurrentPatientName(data.Name);
            loadGridData(DATA_APEX_PT_DOCS, {patient_id: data.PatientId}, recvGridData);
            return;
        }

        if (cmdtype.toString().includes("StmtNow")) {
            handleAgStatement(data, false);
            setcurrentPatientName(data.Name);
            loadGridData(DATA_APEX_PT_DOCS, {patient_id: data.PatientId}, recvGridData);
            return;
        }

        if (cmdtype.toString().includes("PtDocs")) {
            //handleAgStatement(data);
            console.log("Will Retrieve Documents", data);
            setcurrentPatientName(data.Name);
            loadGridData(DATA_APEX_PT_DOCS, {patient_id: data.PatientId}, recvGridData);
            return;
        }

        if (cmdtype.toString().includes("View")) {
            handleAgDocument(data);
            return;
        }
       
        //console.log("handleGridRowButtonClick:",data, cmdtype);
    };

    const handleGridRowSelected = (event) => {

        //console.log("handleGridRowSelected:",event);
        const selectedNodes = event.api
          .getSelectedNodes()
          .filter((node) => node.selected);
        //console.log(selectedNodes);
    
        const datarow = selectedNodes[0].data;
        console.log('AG Row selected', datarow);

        // console.log("Selected patient:", datarow, currentPatientName);
        // if (currentPatientName !== "" && datarow.Name !== currentPatientName) {
        //     setcurrentPatientName("");  
        //     setRowDocsData([]);
        // }
        //setcurrentPatientName('');
        //this.handleChangePatient(datarow);

      };
    

    const [patientColumnDefs, setPatientColumnDefs] = useState(
        [
            { field: 'Name', filter: true , width: 200,   sortable: true,
            resizable: true,editable: true},
            { field: 'PatientId', filter: true, width: 80, sortable: true,
            resizable: true, },
            { field: 'DOB', width: 120 , sortable: true,
            resizable: true,},
            { field: 'RecentDOS', width: 120 , sortable: true,
                resizable: true,},
            { field: 'Balance', width: 130 , sortable: true,
                resizable: true,},
            { field: '',  cellRenderer: BtnCellRenderer,
                                cellRendererParams: {
                                    clicked: handleGridRowButtonClick,
                                    buttonText: 'LastStmt'
                                }, width: 100 ,
             },
            { field: '',  cellRenderer: BtnCellRenderer,
                cellRendererParams: {
                    clicked: handleGridRowButtonClick,
                    buttonText: 'StmtNow'
                }, width: 100 ,
            },
             { field: '',  cellRenderer: BtnCellRenderer,
             cellRendererParams: {
                 clicked: handleGridRowButtonClick,
                 buttonText: 'PtDocs'
             }, width: 100 ,
},

        ]
    );

    const [docsColumnDefs, setdocsColumnDefs] = useState(
        [
            { field: 'customName', filter: true , width: 200,   sortable: true,
            resizable: true,editable: true},
            { field: 'scanDate', filter: true, width: 150, sortable: true,
            resizable: true, },
            { field: 'docID', filter: true, width: 100, sortable: true,
            resizable: true, },
            { field: 'ReviewerName', width: 150 , sortable: true,
            resizable: true,},
            { field: 'ScannedBy', width: 100 , sortable: true,
            resizable: true,},
             { field: '',  cellRenderer: BtnCellRenderer,
             cellRendererParams: {
                 clicked: handleGridRowButtonClick,
                 buttonText: 'View'
             }, width: 50 ,
},

        ]
    );


    const defaultColDef = useMemo( () => ({

    }))

    useEffect(() => {
        loadGridData(DATA_PATIENT_LIST, {}, recvGridData);
    }, []);

    const recvGridData = (gridName, args, gridData) => {
        console.log('ReceivedData for :', gridName, args, gridData);
       
        switch (gridName) {
        
          case DATA_PATIENT_LIST:
          case DATA_PATIENT_LIST_BY_IDS:
            setRowData(gridData);
            break;
          case DATA_APEX_PT_DOCS:
            setRowDocsData(gridData);
            break;
          default:
        }
      };



    const cellClickedListener = useCallback( event => {
        console.log('cellClicked', event);
    }, [] );

    const handleQuickPatientFilter = (event) => {
        console.log("Quick Patient Filter:",event.target, event.target.value, gridRef.current);
        setQuickPatientFilter(event.target.value );
        gridRef.current.api.setQuickFilter(event.target.value);
    };

    const handleQuickDocsFilter = (event) => {
        console.log("Quick Docs Filter:",event.target, event.target.value, docgridRef.current);
        setQuickDocsFilter(event.target.value );
        docgridRef.current.api.setQuickFilter(event.target.value);
    };

    const handleAgStatement = (datarow, printLastStatement) => {
        console.log('AG Statement selected', datarow);
        console.log("Will display to pdf viewer:", viewer.current);

        setActivePatient(datarow);

        let stmtRePrint = printLastStatement ? "&stmt=yes" : "&stmt=no" ;
        //fetch(`${apiURL}/getstatementjava?patid=${datarow.PatientId.toString()}&full=yes`, {
        fetch(`${apiURL}/getecwstatement?patid=${datarow.PatientId.toString()}&incqrcode=portal&startdos=2016-01-01${stmtRePrint}`, {
            headers: {
              "Access-Control-Allow-Origin": "*",
            },})
            .then((r) => r.blob())
            .then((blob) => {
      
              //this.showFilePdf(blob);
              let url = URL.createObjectURL(blob);
      
              setpdfToDisplayURL(url);
              // pdfViewerRef.current.UI.loadDocument(url, {
              //   extension: 'pdf'
              // });
        
            });
      };

      const handleAgDocument = (data) => {

        let documentFilePath = `${data.dirpath}/${data.fileName}` ;
       
        let full_file_path = `\\\\192.168.1.17\\d$\\eClinicalWorks\\ftp\\${documentFilePath}`;
        full_file_path = full_file_path.replace('/', '\\');

        full_file_path = `${apiURL}/downloadFile/${full_file_path.replaceAll("\\", "~").replaceAll("/", "~")}`;
    
        console.log('Starting to get Linq File', full_file_path);
    
        fetch(
          encodeURI(full_file_path), {
            headers: {
              "Access-Control-Allow-Origin": "*",
            },}
        )
         // .then(this.handleErrors)
          .then((r) => r.blob())
          .then((blob) => {
    
            let url = URL.createObjectURL(blob);
            setpdfToDisplayURL(url);
            // pdfViewerRef.current.UI.loadDocument(url, {
            //   extension: 'pdf'
            // });
        
          });
      
      };

      const pdfFileNameCreator = () => {

          if (activePatientRowRef.current) {
              console.log(activePatientRowRef.current, `${activePatientRowRef.current.PatientId}`);
              return `stmt_${activePatientRowRef.current.PatientId}_${activePatientRowRef.current.Name.toString().trim().replace(/[\W]+/g,"_")}.pdf`;
          }

          return "next_file_name.pdf";


      }

    return(
        <SplitPanel split="horizontal">
            <div style={{width: '100%'}}>
                <VStack>
                    <HStack>
                        <input
                            type="text"
                            placeholder="Quick Filter"
                            value={quickPatientFilter}
                            onChange={handleQuickPatientFilter}
                        />
                    </HStack>

                    <div className="ag-theme-alpine" style={{width: '100%', height: '35vh'}}>
                        <AgGridReact
                            ref={gridRef}
                            rowData={rowData}
                            columnDefs={patientColumnDefs}
                            defaultColDef={defaultColDef}
                            animateRows={true}
                            resizable={true}
                            rowSelection="single"
                            rowDragManaged={true}
                            suppressMoveWhenRowDragging={true}
                            applyColumnDefOrder={true}
                            onSelectionChanged={handleGridRowSelected}
                        />
                    </div>

                    <HStack>
                        <Text>{currentPatientName}</Text>
                        <input
                            type="text"
                            placeholder="Quick Filter"
                            value={quickDocsFilter}
                            onChange={handleQuickDocsFilter}
                        />
                    </HStack>

                    <div className="ag-theme-alpine" style={{width: '100%', height: '35vh'}}>
                        <AgGridReact
                            ref={docgridRef}
                            rowData={rowDocsData}
                            columnDefs={docsColumnDefs}
                            defaultColDef={defaultColDef}
                            animateRows={true}
                            resizable={true}
                            rowSelection="single"
                            rowDragManaged={true}
                            suppressMoveWhenRowDragging={true}
                            applyColumnDefOrder={true}
                            onSelectionChanged={handleGridRowSelected}
                        />
                    </div>
                </VStack>
            </div>

            <div style={{width: '100%'}}>
                <ApexPdfjsViewer pdfUrl={pdfToDisplayURL} getTargetFileName={pdfFileNameCreator}/>
            </div>
        </SplitPanel>
);
}

// BtnCellRenderer.jsx

function  BtnCellRenderer (props) {
    //let cellformattedValue = ` ${props.data.price}`;

    const btnClickedHandler = () => {
        props.clicked(props.data, props.buttonText);
    }
      return (
        <>
        <div><button onClick={btnClickedHandler}>{props.buttonText}</button> </div>
        
        </>
      )
  }

export default ApexPatientList;
