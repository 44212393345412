// FullScreenModal.js
import React , {useState} from "react";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, Box } from "@chakra-ui/react";
import Draggable, { DraggableData, DraggableEvent }  from 'react-draggable';


const FullScreenModal = ({ isOpen, onClose, title, children , modelSize, maxW, winH}) => {

  const [currentPosition, setCurrentPosition] = useState({
    xRate: 0,
    yRate: 0
  });

  const onDrag = (e, data) => {
    console.log(`Moving to new position : ${data.lastX} ${data.lastY} `)
    setCurrentPosition({ xRate: data.lastX, yRate: data.lastY });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={modelSize ? modelSize : 'full'} blockScrollOnMount={false} closeOnOverlayClick={false}  scrollBehavior={'inside'}>
      <ModalOverlay />
      <Draggable handle=".modal-drag-handle" 
            position={{
              x: currentPosition.xRate,
              y: currentPosition.yRate
            }}
            onDrag={onDrag}
      >
      <ModalContent maxW={maxW ? maxW : "100%"}  h={winH ? winH : "100%"} style={{ position: "relative", x: currentPosition.xRate, y: currentPosition.yRate }} >
        <ModalHeader>
            <Box className="modal-drag-handle" cursor="grab">
              {title}
            </Box>
          </ModalHeader>
        <ModalCloseButton />
        <ModalBody>{children}</ModalBody>
      </ModalContent>
      </Draggable>
    </Modal>
  );
};

export default FullScreenModal;
