
import React, { useEffect } from "react";
import useState from 'react-usestateref';
import apiConfig from '../apiConfig';
import axios from "axios";

import {
    Flex,
    Text,
    FormControl,
    HStack,
    FormLabel,
    Container
} from "@chakra-ui/react";

import { Select } from "chakra-react-select";
import {
    AutoComplete,
    AutoCompleteInput,
    AutoCompleteItem,
    AutoCompleteList,
  } from "@choc-ui/chakra-autocomplete";

export function SelectEcwFolder({ selectedEcwFolder, setSelectedEcwFolder }) {

    let apiURL=apiConfig.REACT_APEX_BASE_API;
    const [apexfolders, setapexfolders] = useState([]);

    useEffect(()=> {

        const getData = async () => {
  
            const res2 = await axios.get(`${apiURL}/ecw/getdocfolders`);
            if (res2.data) {
              console.log("GOT Folders:", res2.data);
              setapexfolders(res2.data);
            }
    

        };
      
        getData(); 
      
        return () => {
          // this now gets called when the component unmounts
        };
  
  
      }, []);

      const onFolderSelectionChange = (value) => {
        console.log("Folder selection change:", value);
        let selectedfolderitem = apexfolders.filter(fldr=> fldr.id === value.value);
        console.log("Selected File Folder Category", selectedfolderitem[0]);
        setSelectedEcwFolder(selectedfolderitem[0]);
      }

      //use selectedEcwFolder to se currently selected value

  let ecwFolders = apexfolders?.map(({id, label})=>{ return ({value: id, label: label}) }); // return ({staff.uid, staff.name}));c

      return(
        <Container mb={1}>
        <FormControl  w='700px'>
            <HStack>
          <FormLabel>Folder</FormLabel>
          <Select
            isClearable={true}
            options={ecwFolders}
            placeholder="Select Folder..."
            closeMenuOnSelect={true}
            onChange={onFolderSelectionChange}
          />
          </HStack>
        </FormControl>
      </Container>
      )

    }