
import React, { useEffect } from "react";
import useState from 'react-usestateref';
import apiConfig from '../apiConfig';
import axios from "axios";

import {
    Flex,
    Text,
    FormControl,
    HStack,
    FormLabel,
    Container
} from "@chakra-ui/react";

import { Select } from "chakra-react-select";

import {
    AutoComplete,
    AutoCompleteInput,
    AutoCompleteItem,
    AutoCompleteList,
  } from "@choc-ui/chakra-autocomplete";

export function SelectOpProject({ selectedProject, setSelectedProject }) {

    let apiURL=apiConfig.REACT_APEX_BASE_API;
    const [opProjects, setopProjects] = useState([]);

    useEffect(()=> {

        const getData = async () => {
  
          console.log(`${apiURL}/proj/getprojects`);
          const res = await axios.get(`${apiURL}/proj/getprojects`);
          if (res.data) {
            if (res.data.includes("ERROR")) {
              console.log("GOT Projects:", res.data);
              setopProjects([]);
            }else {
            console.log("GOT Projects:", res.data);
            setopProjects(res.data);
            }
          }else {
            setopProjects([]);
          }

        };
      
        getData(); 
      
        return () => {
          // this now gets called when the component unmounts
        };
  
  
      }, []);

      const onProjectSelectionChange = (val) => {
        console.log("Project selection change:", val);
        let selectedProjectitem = opProjects.filter(proj=> proj.id === val.value);
        console.log("Selected Project", selectedProjectitem[0]);
        setSelectedProject(selectedProjectitem[0]);
      }

      //use setSelectedStaff to se currently selected value

      let opProjectsJson = opProjects?.map(({id, name})=>{ return ({value: id, label: name}) }); // return ({staff.uid, staff.name}));c

      return(
        <Container mb={1}>
        <FormControl p={1} w='500px'>
          <HStack>
          <FormLabel>Project</FormLabel>
          <Select
            isClearable={true}
            options={opProjectsJson}
            placeholder="Select project..."
            closeMenuOnSelect={true}
            onChange={onProjectSelectionChange}
          />
          </HStack>
        </FormControl>
      </Container>
      )


    }