import React, { useState, useEffect } from 'react';
import { Popper, Paper, Typography, Box, Button, TextField, CircularProgress } from '@mui/material';
import Draggable from 'react-draggable';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import axios from 'axios';
import apiConfig from "../../apiConfig";

interface RowData {
    batch_id: number;
    batch_code: string;
    batch_source: string;
    itemcount: number;
    batch_total: number;
    bankdeposit_id: number;
    bankdeposit_date: string;
    reconcilestatus: string;
    username: string;
    notes: string;
    lockedflag: number;
}

interface SelectDepositBatchProps {
    open: boolean;
    anchorEl: HTMLElement | null;
    onClose: () => void;
    onSelect: (selectedBatch: RowData) => void;
}

function DraggablePaperComponent(props: any) {
    return (
        <Draggable handle="#draggable-popper-title">
            <Paper elevation={3} {...props} />
        </Draggable>
    );
}

const SelectDepositBatch: React.FC<SelectDepositBatchProps> = ({ open, anchorEl, onClose, onSelect }) => {
    const [rowData, setRowData] = useState<RowData[]>([]);
    const [loading, setLoading] = useState(false);
    const gridRef = React.useRef<any>(null);

    useEffect(() => {
        fetchDepositBatches()
    }, []);

    const fetchDepositBatches = async () => {
        setLoading(true);
        const sqlText = 'select  batch_id, batch_code, batch_source, itemcount, batch_total, lockedflag from apex.rc.ApexDepositBatch order by batch_id desc';
        const authHeader = JSON.parse(localStorage.getItem('authHeader') || '{}');
        const urlPrefix = `${apiConfig.REACT_APEX_BASE_API}/exsql?dbserver=apex&sqltype=customSQL&sqltext=`;

        const response = await axios.get(`${urlPrefix}${sqlText}`, { headers: authHeader });

        if (response.data) {
            const dframe: string = response.data.frame0;
            const myObj = JSON.parse(dframe);
            const gridData = myObj.rows;
            setRowData(gridData);
        }
        setLoading(false);
    };

    const handleGridRowSelected = (event: any) => {
        const selectedNodes = event.api.getSelectedNodes().filter((node: any) => node.selected);
        const datarow = selectedNodes[0].data;
        onSelect(datarow);
        onClose();
    };

    const handleRefresh = () => {
        fetchDepositBatches();
    };

    return (
        <Popper open={open} anchorEl={anchorEl} placement="top" disablePortal style={{ zIndex: 1300 }}>
            <DraggablePaperComponent style={{ width: 850, position: 'fixed', top: 10, left: '20%' }}>
                <Box p={1} display="flex" justifyContent="space-between" alignItems="center" style={{ cursor: 'move' }}>
                    <Typography id="draggable-popper-title">Select Deposit Batch</Typography>
                    <Button size="small" color="primary" onClick={handleRefresh} style={{ marginBottom: '1px' }}>
                        Refresh
                    </Button>
                    <Button size="small" onClick={onClose}>X</Button>
                </Box>
                <Box >
                    {loading ? (
                        <CircularProgress />
                    ) : (
                        <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
                            <AgGridReact
                                ref={gridRef}
                                rowData={rowData}
                                columnDefs={[
                                    { field: 'batch_id', headerName: 'Batch ID', width: 100 },
                                    { field: 'batch_code', headerName: 'Batch Code', width: 150 },
                                    { field: 'batch_source', headerName: 'Batch Source', width: 150 },
                                    { field: 'itemcount', headerName: 'Item Count', width: 100 },
                                    { field: 'batch_total', headerName: 'Batch Total', width: 100 },
                                    { field: 'bankdeposit_id', headerName: 'Bank Deposit ID', width: 150 },
                                    { field: 'bankdeposit_date', headerName: 'Bank Deposit Date', width: 150 },
                                    { field: 'reconcilestatus', headerName: 'Reconcile Status', width: 150 },
                                    { field: 'username', headerName: 'Username', width: 150 },
                                    { field: 'notes', headerName: 'Notes', width: 200 },
                                    { field: 'lockedflag', headerName: 'Locked', width: 100 },
                                ]}
                                rowSelection="single"
                                onSelectionChanged={handleGridRowSelected}
                            />
                        </div>
                    )}
                </Box>
            </DraggablePaperComponent>
        </Popper>
    );
};

export default SelectDepositBatch;
