import React, { useState } from 'react';
import {Box, IconButton, Text, VStack, RadioGroup, Radio, Stack, HStack} from '@chakra-ui/react';
import { CheckIcon, CloseIcon, EditIcon } from '@chakra-ui/icons';
import { Editor } from '@tinymce/tinymce-react';
import apiConfig from "../../apiConfig";
import axios from 'axios';

interface Comment {
    detailId: number;
    patid: number;
    claimid: number;
    note: string;
    notePlainText: string;
    noteType: number; // 0 => claim, 1 => patient, 2 => payment
    assignedToName: string;
    assignedByName: string;
    enteredbyName: string;
    modifieddate: string;
}

interface CommentCardProps {
    comment?: Comment;
    isNew?: boolean;
    onSave: (comment: Comment) => void;
    onCancel: () => void;
    detailId?: number; // Optional detailId for new comments
    note?: string; // Optional note for new comments
    noteType?: number; // Optional noteType for new comments
}

const backgroundColors = ["lightblue", "lightgreen", "lightcoral"];

const CommentCard: React.FC<CommentCardProps> = ({ comment, isNew, onSave, onCancel, detailId, note, noteType: initialNoteType }) => {
    const [isEditing, setIsEditing] = useState(isNew || false);
    const [content, setContent] = useState(comment?.note || note || '');
    const [noteType, setNoteType] = useState(comment?.noteType?.toString() || initialNoteType?.toString() || '0');
    let apiURL=apiConfig.REACT_APEX_BASE_API;
    const handleEditorChange = (content: string) => {
        setContent(content);
    };

    const stripHtmlTags = (html: string) => {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || "";
    };

    const handleCancel = async () => {
        if (onCancel) onCancel();
        setIsEditing(false);
    }

    const handleSave = async () => {
        const plainTextContent = stripHtmlTags(content);
        console.log("Saving Comment", comment);
        try {
            const response = await axios.post<Comment>(`${apiURL}/api/comments`, {
                detailId: comment?.detailId || detailId,
                patid: comment?.patid || 0,
                claimid: comment?.claimid || 0,
                note: content,
                notePlainText: plainTextContent,
                noteType: parseInt(noteType)
            });
            if (response.data.detailId) {
                onSave(response.data);
                setIsEditing(false);
            } else {
                console.error('Failed to save comment');
                setIsEditing(false);
            }
        } catch (error) {
            setIsEditing(false);
            console.error('Error saving comment:', error);
        }
    };

    console.log("This comment", detailId, content, comment);
    return (
        <Box width={"100%"} borderWidth="1px" borderRadius="lg" overflow="hidden" p={0} mb={0} bg={backgroundColors[parseInt(noteType)]}>
            {isEditing ? (
                <>

                    <HStack display="flex" justifyContent="flex-end" width="100%" margin={0} padding={0}>
                        <RadioGroup onChange={(value) => setNoteType(value)} value={noteType}>
                            <Stack direction="row">
                                <Radio value="0">Claim</Radio>
                                <Radio value="1">Patient</Radio>
                                <Radio value="2">Payment</Radio>
                            </Stack>
                        </RadioGroup>
                        <IconButton onClick={handleSave} colorScheme="teal" icon={<CheckIcon />} aria-label="Save" mr={1} />
                        <IconButton onClick={handleCancel} colorScheme="red" icon={<CloseIcon />} aria-label="Cancel" />
                    </HStack>
                </>
            ): ( <Box display="flex" justifyContent="flex-end" width="100%">
                <HStack>
                    <Text> Created by: {comment && comment.enteredbyName}</Text>
                    <Text> Assigned By: {comment && comment.assignedByName}</Text>
                    <Text style={{ backgroundColor: 'lightgreen'}}> Assigned To: {comment && comment.assignedToName}</Text>
                    <Text style={{ backgroundColor: 'white'}}> Last Modified : {comment && comment.modifieddate}</Text>
                    <IconButton onClick={() => setIsEditing(true)} colorScheme="teal" icon={<EditIcon />} aria-label="Edit" />
                </HStack>
            </Box>) }

            {isEditing ? (
                    <Editor
                        apiKey="98k3h93kvtel4j0vrllhyogvi1v0nbvrglgysq7y6axxp5sm"
                        value={content}
                        init={{
                            height: 150,
                            menubar: false,
                            plugins: [
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount'
                            ],
                            toolbar:
                                'undo redo | formatselect | bold italic backcolor | ' +
                                'alignleft aligncenter alignright alignjustify | ' +
                                'bullist numlist outdent indent | removeformat | help',
                            content_style: `body { background-color: ${backgroundColors[parseInt(noteType)]}; }`
                        }}
                        onEditorChange={handleEditorChange}
                    />
            ) : (
                <HStack  spacing={1}>
                    <Box display="flex"  width="100%" mr={4} ml={4}>
                    <Text dangerouslySetInnerHTML={{ __html: content }} />

                    </Box>
                </HStack>
            )}



        </Box>
    );
};

export default CommentCard;
