import React, { Component} from 'react';
import { Checkbox } from '@chakra-ui/react';

const CheckboxRenderer = ({ value, api }) => {
  console.log("Checkbox Render called ", value, api);
  const handleChange = () => {
    const newValue = value === 1 ? 0 : 1;
    api.setValue(newValue);
  };

  return (
    <Checkbox
      isChecked={value === 1}
      onChange={handleChange}
      colorScheme={value === 1 ? 'green' : 'gray'}
    />
  );
};

export default CheckboxRenderer;


// export default class ChexboxRenderer extends Component {
//   constructor(props) {
//     super(props);
//     this.checkedHandler = this.checkedHandler.bind(this);
//   }
//   checkedHandler(event) {
//     let checked = event.target.checked;
//     let colId = this.props.column.colId;
//     this.props.node.setDataValue(colId, checked);
//   }
//   render() {
//     return (
//       <input 
//         type="checkbox" 
//         onClick={this.checkedHandler}
//         checked={this.props.value}
//       />
//     )
//   }
// }