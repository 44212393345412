import React, { useEffect } from 'react';
import useState from 'react-usestateref';

import axios from 'axios';

import { Box, Button, HStack } from '@chakra-ui/react';

import { AgGridReact } from 'ag-grid-react';

import apiConfig from "../../apiConfig";

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';


export function ApexEcwEchos({ selectedEchos, setEchos }) {
    const apiURL = apiConfig.REACT_APEX_BASE_API;
    const gridEchosRef = React.useRef();
    const gridusbEchosRef = React.useRef();
    const [ecwUsbEchoMatch, setEcwUsbEchoMatch] = useState([]);
    const [ecwEchos, setEcwEchos] = useState([]);
    const [usbEchos, setusbEchos] = useState([]);

    const authHeader = {};

    useEffect(() => {
        //${apiURL}
        const sqlEchos = `https://localhost:8090/matchStudiesAndExams`;

        fetch(sqlEchos, authHeader)
            .then((response) => {
                if (response.status !== 200) {
                    console.log("Error:Something went wrong in getting data" );
                } else {
                    return response.json();
                }
            })
            .then((data) => {
                console.log(data);

                console.log('Got data', data);
                data = data.map((row, indx)=> {
                    row.id = indx + 1;
                    return row;
                });

                setEcwUsbEchoMatch(data);
            });

        return () => {

        };
    }, []);

    const fixCounters = () => {
        const sqlEchos = `${apiURL}/epacs/fix_tapecounter`;

        fetch(sqlEchos, authHeader)
            .then((response) => {
                if (response.status !== 200) {
                    console.log("Error updating tapecounter");
                } else {
                    return response.json();
                }
            })
            .then((data) => {
                console.log(data);

                console.log('Got data from Fix Counters', data);
            });
    };

    const updateNewTags = () => {
        const sqlEchos = `${apiURL}/epacs/updatenew`;

        fetch(sqlEchos, authHeader)
            .then((response) => {
                if (response.status !== 200) {
                    console.log("Error updating tags");
                } else {
                    return response.json();
                }
            })
            .then((data) => {
                console.log(data);

                console.log('Got data from Fix Counters', data);
            });
    };

    const handleGridRowSelected = (event) => {
        // console.log("handleGridRowSelected:",event);
        const selectedNodes = event.api
            .getSelectedNodes()
            .filter((node) => node.selected);
        // console.log(selectedNodes);

        const datarow = selectedNodes[0].data;
        console.log('AG Row selected', datarow);
        //setSelectedEcwPatient(datarow);
        //setQuickPatientFilter(datarow.Name);
    };

    const handleReadUSB = () => {
        // ipcRenderer.send('readUSB');
        window.electron.ipcRenderer.sendMessage('readUSB');
    };

    const handleUpdateNew = () => {
        // ipcRenderer.send('readUSB');
        updateNewTags();
    };

    const handleFixCounters = () => {
        // ipcRenderer.send('readUSB');
        fixCounters();
    };

    // { headerName: 'PACS Accession Number', field: 'pacs_accessionNumber' },
    // { headerName: 'PACS Study ID', field: 'pacs_studyID' },
    // { headerName: 'PACS Study Description', field: 'pacs_studyDescription' },
//
//     { headerName: 'PACS Last Name', field: 'pacs_lastName' },
//     { headerName: 'PACS First Name', field: 'pacs_firstName' },
//     { headerName: 'PACS Middle Name', field: 'pacs_middleName' },
    // use selectedEcwFolder to se currently selected value
    //         { headerName: 'ECW Patient Name', field: 'ecw_patientName' },
    //        { headerName: 'ECW Study Date', field: 'ecw_studyDate' },
    //      { headerName: 'PACS Study Time', field: 'pacs_studyTime' },
    const columnDefs = [
        { headerName: 'ECW Status', field: 'ecw_encStatus', width: 150 },

        { headerName: 'PACS Study Date', field: 'pacs_studyDate' , width: 140},
        { headerName: 'ECW Study Date', field: 'ecw_studyDateTime' , width: 170},
        { headerName: 'ECW Visit Type', field: 'ecw_visitType' , width: 100},
        { headerName: 'ECW Reason', field: 'ecw_reason' , width: 100},

        { headerName: 'Pat ID', field: 'pacs_patientID' , width: 100},
        { headerName: 'ECW ID', field: 'ecw_patientId' , width: 100},

        { headerName: 'PACS Name', field: 'pacs_patientName' , width: 150},
        { headerName: 'ECWLast ', field: 'ecw_lastName' , width: 120},
        { headerName: 'ECWFirst', field: 'ecw_firstName' , width: 120},

        { headerName: 'P DOB', field: 'pacs_patientBirthDate' , width: 110},
        { headerName: 'E DOB', field: 'ecw_birthDate' , width: 160},
        { headerName: 'Sex', field: 'pacs_patientSex', width: 100 },






        { headerName: 'StudyUID', field: 'pacs_studyInstanceUID' , width: 150},


        { headerName: 'Report', field: 'ecw_reportDoc' , width: 100},
        { headerName: 'Locked', field: 'ecw_encLock' , width: 100},
        { headerName: 'InvID', field: 'ecw_invoiceId', width: 100 },
        { headerName: 'EncID', field: 'ecw_encounterId' , width: 100},
    ];

    const getRowStyle = params => {
        const { data } = params;
        if (data.pacs_studyInstanceUID && data.ecw_encounterId) {
            return { background: 'lightgreen' };
        }else if (!data.ecw_encounterId && data.pacs_studyInstanceUID) {
            return { background: 'lightblue' };
        }else if (!data.pacs_studyInstanceUID) {
            return { background: 'lightyellow' };
        }

        return null;
    };

    return (
        <div>
            {/*<HStack>*/}
            {/*    <Button onClick={handleReadUSB}>Read DicomDIR </Button>*/}
            {/*    <Button onClick={handleUpdateNew}>Update New </Button>*/}
            {/*    <Button onClick={handleFixCounters}>Fix Counters </Button>*/}
            {/*</HStack>*/}
            <HStack>
                <Box
                    className="ag-theme-alpine maxWidth"
                    style={{
                        paddingLeft: '10px',
                        width: '95vw',
                        height: '90vh',
                        overflow: 'auto',
                    }}
                >
                    <AgGridReact
                        ref={gridEchosRef}
                        rowData={ecwUsbEchoMatch}
                        columnDefs={columnDefs}
                        animateRows
                        resizable
                        rowSelection="single"
                        rowDragManaged
                        suppressMoveWhenRowDragging
                        applyColumnDefOrder
                        onSelectionChanged={handleGridRowSelected}
                        getRowStyle={getRowStyle}
                    />
                </Box>
            </HStack>
        </div>
    );
}
