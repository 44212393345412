
import React, { useEffect } from "react";
import useState from 'react-usestateref';
import apiConfig from '../apiConfig';
import axios from "axios";

import {
    Flex,
    Text,
    FormControl,
    HStack,
    FormLabel,
    Container,
} from "@chakra-ui/react";

import { Select } from "chakra-react-select";
import {
    AutoComplete,
    AutoCompleteInput,
    AutoCompleteItem,
    AutoCompleteList,
  } from "@choc-ui/chakra-autocomplete";

export function SelectDocumentType({ selectedDocumentType, setSelectedDocumentType }) {

    let apiURL=apiConfig.REACT_APEX_BASE_API;

    const docTypes = [
        { name: "ECHO", value: "echo" },
        { name: "CAROTID", value: "Carotid" },
        { name: "LED", value: "Led" },
        { name: "Nuclear", value: "Nuclear" },
        { name: "Treadmill", value: "Treadmill" },
        { name: "EKG", value: "EKG" },
        { name: "Mcot Daily", value: "McCOT Daily" },
        { name: "Mcot Report", value: "MCOT Report" },            
        { name: "LINQ Report", value: "LINQ Report" },
        { name: "LINQ QL", value: "LINQ QL" },
        { name: "PM Remote QL", value: "PM Remote QL" },
        { name: "PM Interog IH", value: "PM Intrg IH" },
        { name: "Event Report", value: "Event Report" },
        { name: "PB", value: "PB" },
        { name: "OPMT", value: "OPMT" },
        { name: "HOSP", value: "HOSP" },
        { name: "FACESHEET", value: "FACESHEET" },
        { name: "DEPOSIT", value: "Deposit" },
        { name: "OTHER", value: "Other" },
    ]

    useEffect(()=> {

        const getData = async () => {

        };
      
        getData(); 
      
        return () => {
          // this now gets called when the component unmounts
        };
  
  
      }, []);

      const onDocuTypeChange = (val) => {
        let selectedDocumentTypeItem = docTypes.filter(doctype=> doctype.value === val.value);
        console.log("Selected Document TYpe ",val,selectedDocumentTypeItem, selectedDocumentTypeItem[0]);
        setSelectedDocumentType(selectedDocumentTypeItem[0]);
      }

      let documentTypes = docTypes?.map(({value, name})=>{ return ({value: value, label: name}) }); // return ({staff.uid, staff.name}));c

      return(
        <Container mb={2}>
        <FormControl w='300px'>
          <HStack>
            <FormLabel>Type</FormLabel>
          <Select
            isClearable={true}
            options={documentTypes}
            placeholder="Select Type..."
            closeMenuOnSelect={true}
            onChange={onDocuTypeChange}
          />
           </HStack>
        </FormControl>
      </Container>
      )

    }