import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Typography,
    Popper,
    Paper,
    IconButton,
} from '@mui/material';
import axios from 'axios';
import Draggable from 'react-draggable';
import RefreshIcon from '@mui/icons-material/Refresh';
import CloseIcon from '@mui/icons-material/Close';
import apiConfig from "../../apiConfig";

const ERAParseComponent: React.FC = () => {
    const [eraCount, setEraCount] = useState<number>(0);
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    let apiURL = apiConfig.REACT_APEX_BASE_API;

    const fetchERACount = async () => {
        const sqlText = `select  count(*) as neweracount from  rc.ecw_eraxml erxml where not exists (select 'x' from  rc.billing_eraclaims clm where clm.erafileid = erxml.fileid) `;
        const authHeader = JSON.parse(localStorage.getItem('authHeader') || '{}');
        const urlPrefix = `${apiURL}/exsql?dbserver=apex&sqltype=customSQL&sqltext=`;

        try {
            console.log("Getting ERAstoParse count:", sqlText);
            const response = await axios.get(`${urlPrefix}${sqlText}`, { headers: authHeader });

            if (response.data) {
                const dframe: string = response.data.frame0;
                const myObj = JSON.parse(dframe);
                const gridData = myObj.rows;
                setEraCount(gridData[0].neweracount);
            }
        } catch (error) {
            console.error('Error fetching ERA count:', error);
        }
    };

    const parseERAClaims = async () => {
        try {
            await axios.get(`${apiURL}/parse_era_claims`);
            fetchERACount();
        } catch (error) {
            console.error('Error parsing ERA claims:', error);
        }
    };

    useEffect(() => {
        fetchERACount();
        const interval = setInterval(fetchERACount, 300000); // Refresh every 5 minutes
        return () => clearInterval(interval);
    }, []);

    const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setOpen((prev) => !prev);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Button
                onClick={handleButtonClick}
                style={{ backgroundColor: eraCount > 0 ? 'lightorange' : 'inherit' }}
            >
                ERA Count: {eraCount}
            </Button>
            <Popper open={open} anchorEl={anchorEl} placement="bottom-start">
                <Draggable>
                    <Paper style={{ padding: '16px', maxWidth: '300px' }}>
                        <Box display="flex" flexDirection="column" alignItems="center">
                            <Box display="flex" justifyContent="flex-end" width="100%">
                                <IconButton onClick={handleClose}>
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                            <Typography variant="h6">ERAs to be Parsed</Typography>
                            <Typography variant="subtitle1">{eraCount}</Typography>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={parseERAClaims}
                                disabled={eraCount === 0}
                                style={{ marginTop: '16px' }}
                            >
                                Parse ERA Claims
                            </Button>
                            <IconButton onClick={fetchERACount} style={{ marginTop: '16px' }}>
                                <RefreshIcon />
                            </IconButton>
                        </Box>
                    </Paper>
                </Draggable>
            </Popper>
        </>
    );
};

export default ERAParseComponent;
