import React, { useState, useRef, useEffect } from 'react';
import {
    Button,
    Box,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    TextField,
    Collapse,
    Typography,
    Switch
} from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import SplitPanel from "../../Components/splitpage/splitpanel";
import ApexPdfjsViewer from "../../Components/ApexPdfjsViewer";
import apiConfig from "../../apiConfig";
import axios from "axios";
import { handleAgDocument } from "../../utils/ecw/hadleDocuments";
import CreateDepositBatch from "./CreateDepositBatch";
import SelectDepositBatch from "./SelectDepositBatch";


interface RowData {
    spmt_id?: string;
    ecwdoc_id?: number;
    docpath?: string;
    docpage?: number;
    docname?: string;
    pageno?: number;
    checknumber?: string;
    checkamount?: number;
    checkdate?: string;
    cardamount?: number;
    otheramount?: number;
    otheramount2?: number;
    ecwpayment_id?: string;
    bankdeposit_id?: string;
    pmtpatientid?: string;
    pmtpatientname?: string;
    reconcilestatus?: string;
    customFilename?: string;
    ecwFileName?: string;
    depositbatch?: string;
    ispaymentcached?: number;
    expiredpayment?: number;
    replacementremarks?: string;
    paymentnotes?: string;
    reference1?: string;
    reference2?: string;
    enteredby?: string;
    deleteflag?: number;
    status?: string;
}

const PaymentDepositsPage: React.FC = () => {
    const [batchPayments, setBatchPayments] = useState<RowData[]>([]);
    const [payments, setPayments] = useState<RowData[]>([]);
    const [filteredPayments, setFilteredPayments] = useState<RowData[]>([]);
    const [selectedPayment, setSelectedPayment] = useState<RowData | null>(null);
    const [currentPageNum, setCurrentPageNum] = useState<number>();
    const [pdfUrl, setPdfUrl] = useState('');
    const [criteria, setCriteria] = useState('all');
    const [depositBatchId, setDepositBatchId] = useState('');
    const [collapse, setCollapse] = useState(true);
    const [showPdfViewer, setShowPdfViewer] = useState(true);

    const gridBatchRef = useRef<any>(null);
    const gridRef = useRef<any>(null);

    const apiURL = apiConfig.REACT_APEX_BASE_API;
    const [selectedDepositBatch, setSelectedDepositBatch] = useState<any>(null);
    const [selectBatchOpen, setSelectBatchOpen] = useState(false);
    const [createBatchOpen, setCreateBatchOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handleSelectBatchClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setSelectBatchOpen(true);
    };

    const handleCreateBatchClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setCreateBatchOpen(true);
    };

    const handleSelectBatchClose = () => {
        setSelectBatchOpen(false);
        setAnchorEl(null);
    };

    const handleCreateBatchClose = () => {
        setCreateBatchOpen(false);
        setAnchorEl(null);
    };

    const handleDepositBatchSelected = async (batch: any) => {
        setSelectedDepositBatch(batch);
        await fetchBatchPayments(batch);
        handleSelectBatchClose();
    };

    const handleDepositBatchCreated = async (batch: any) => {
        await fetchBatchPayments(batch);
        setSelectedDepositBatch(batch);
        handleCreateBatchClose();
    };

    useEffect(() => {
        fetchPayments();
    }, []);

    const fetchBatchPayments = async (batchRow: { batch_code: any; }) => {
        const sqlText = `select top 2000 apex.dbo.EcwDocumentPath(ecwdoc_id) as docpath, pageno as docpage, apex.dbo.EcwDocumentName(ecwdoc_id) as docname,* from rc.ApexScannedPayment where depositbatch = '${batchRow.batch_code}' order by spmt_id desc`;
        const authHeader = JSON.parse(localStorage.getItem('authHeader') || '{}');
        const urlPrefix = `${apiConfig.REACT_APEX_BASE_API}/exsql?dbserver=apex&sqltype=customSQL&sqltext=`;

        const response = await axios.get(`${urlPrefix}${sqlText}`, { headers: authHeader });

        if (response.data) {
            const dframe: string = response.data.frame0;
            const myObj = JSON.parse(dframe);
            const gridData = myObj.rows;
            setBatchPayments(gridData);
        }
    };

    const fetchPayments = async () => {
        const sqlText = `select top 2000 apex.dbo.EcwDocumentPath(ecwdoc_id) as docpath, pageno as docpage, apex.dbo.EcwDocumentName(ecwdoc_id) as docname,* from rc.ApexScannedPayment where isnull(expiredpayment,0)  != 50 and  isnull(depositbatch,'') = '' order by spmt_id desc`;
        const authHeader = JSON.parse(localStorage.getItem('authHeader') || '{}');
        const urlPrefix = `${apiConfig.REACT_APEX_BASE_API}/exsql?dbserver=apex&sqltype=customSQL&sqltext=`;

        const response = await axios.get(`${urlPrefix}${sqlText}`, { headers: authHeader });

        if (response.data) {
            const dframe: string = response.data.frame0;
            const myObj = JSON.parse(dframe);
            const gridData = myObj.rows;
            setPayments(gridData);
            setFilteredPayments(gridData);
        }
    };

    const handleSelectionChange = (params: any) => {
        const selectedNode = params.api.getSelectedNodes()[0];
        setSelectedPayment(selectedNode ? selectedNode.data : null);
    };

    const handleViewPage = (row: RowData) => {
        if (row) {
            ShowDocument(row.docpage ? row.docpage : 1, row.docpath ? row.docpath : '');
        }
    };

    const handleCriteriaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCriteria(event.target.value);
        applyFilter(event.target.value);
    };

    const applyFilter = (criteria: string) => {
        if (criteria === 'checks') {
            setFilteredPayments(payments.filter(payment => payment.checkamount && payment.checkamount > 0));
        } else if (criteria === 'creditcards') {
            setFilteredPayments(payments.filter(payment => payment.cardamount && payment.cardamount > 0));
        } else {
            setFilteredPayments(payments);
        }
    };

    const handleSelectAll = () => {
        gridRef.current.api.selectAll();
    };

    const handleSelectNone = () => {
        gridRef.current.api.deselectAll();
    };

    const onSetCurrentPageNum = (pageNum: number) => {
        setCurrentPageNum(pageNum);
    };

    const onSetpdfToDisplayURL = (args: React.SetStateAction<string>) => {
        setPdfUrl(args);
    };

    const ShowDocument = (pagenum: number, docpath: string) => {
        handleAgDocument({
            filename: docpath,
            dirpath: '',
            startpage: pagenum
        }, apiURL, onSetpdfToDisplayURL, setCurrentPageNum);
    };

    const dateFormatter = (params: { value: string }) => {
        const date = new Date(params.value);
        return date.toISOString().split('T')[0];
    };



    const handleRemoveSelectedFromBatchClick = async () => {
        if (!selectedDepositBatch) {
            alert("Please select a deposit batch first.");
            return;
        }

        const selectedNodes = gridBatchRef.current.api.getSelectedNodes();
        const selectedIds = selectedNodes.map((node: any) => node.data.spmt_id);

        if (selectedIds.length === 0) {
            alert("Please select at least one payment in the selected batch.");
            return;
        }

        const batchCode = selectedDepositBatch.batch_code;
        const authHeader = JSON.parse(localStorage.getItem('authHeader') || '{}');
        const urlPrefix = `${apiConfig.REACT_APEX_BASE_API}/exsql?dbserver=apex&sqltype=customSQL&sqltext=`;

        const idsString = selectedIds.map((id: any) => `'${id}'`).join(',');

        const sqlText = `UPDATE rc.ApexScannedPayment SET depositbatch = null WHERE spmt_id IN (${idsString})`;

        try {
            await axios.get(`${urlPrefix}${sqlText}`, { headers: authHeader });
            await fetchPayments(); // Refresh the payments grid after updating
            await fetchBatchPayments(selectedDepositBatch);
        } catch (error) {
            console.error('Error updating deposit batch:', error);
        }
    };


    const handleAddSelectedToBatchClick = async () => {
        if (!selectedDepositBatch) {
            alert("Please select a deposit batch first.");
            return;
        }

        const selectedNodes = gridRef.current.api.getSelectedNodes();
        const selectedIds = selectedNodes.map((node: any) => node.data.spmt_id);

        if (selectedIds.length === 0) {
            alert("Please select at least one payment.");
            return;
        }

        const batchCode = selectedDepositBatch.batch_code;
        const authHeader = JSON.parse(localStorage.getItem('authHeader') || '{}');
        const urlPrefix = `${apiConfig.REACT_APEX_BASE_API}/exsql?dbserver=apex&sqltype=customSQL&sqltext=`;

        const idsString = selectedIds.map((id: any) => `'${id}'`).join(',');

        const sqlText = `UPDATE rc.ApexScannedPayment SET depositbatch = '${batchCode}' WHERE spmt_id IN (${idsString})`;

        try {
            await axios.get(`${urlPrefix}${sqlText}`, { headers: authHeader });
            await fetchPayments(); // Refresh the payments grid after updating
            await fetchBatchPayments(selectedDepositBatch);
        } catch (error) {
            console.error('Error updating deposit batch:', error);
        }
    };

    function formatDate(dateString: string | undefined) {
        if (!dateString) return;

        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const handleExportPDF = () => {
        const doc = new jsPDF();

        const tableColumn = [
            "ID",
            "Page",
            "Check Number",
            "Check Amount",
            "Card Amount",
            "Date",
            "Name",
            "Other Amount"
        ];

        const tableRows: (string | number | undefined)[][] = [];

        let total_checks = 0
        let total_amount = 0.0

        batchPayments.forEach(payment => {
            const paymentData = [
                payment.spmt_id,
                payment.docpage,
                payment.checknumber,
                payment.checkamount,
                payment.cardamount,
                formatDate(payment.checkdate),
                payment.docname,
                payment.otheramount
            ];
            total_amount +=Number( payment.checkamount)
            total_checks += 1;
            tableRows.push(paymentData);
        });


        tableRows.push([
            '','',
            'Checks Count: ' + total_checks,
            'Total Checks Amt: ',
            Number(total_amount).toFixed(2),
            '', '', ''
        ])


        // @ts-ignore
        doc.autoTable(tableColumn, tableRows, { startY: 20 });
        doc.text("Batch Payments Report", 14, 15);
        //doc.save("batch_payments_report.pdf");
        //let base = doc.output('datauri')
        //console.log("Got URI for the PDF");

        // const pdfBlob = doc.output('blob');
        // // Create a Blob URL
        // const pdfBlobUrl = URL.createObjectURL(pdfBlob);
        // // Set the PDF URL to the generated Blob URL
        // setPdfUrl(pdfBlobUrl);


        const pdfBlob = doc.output('blob');
        let url = URL.createObjectURL(pdfBlob);

        setPdfUrl(url);

       // setpdfToDisplayURL(url);

       // let base = doc.output('datauri')
       // setpdfToDisplayURL(base);

        // setPdfUrl(base);
    };


    return (
        <SplitPanel split="horizontal">
            <>
                <Box display={"flex"} flexDirection={"row"} alignItems="center" gap={2}>
                    <Button onClick={() => setCollapse(!collapse)}>
                        {collapse ? 'Hide Criteria' : 'Show Criteria'}
                    </Button>
                    <Button size={"small"} color="primary" onClick={handleSelectBatchClick} style={{ marginRight: '10px' }}>
                        Select Batch
                    </Button>
                    <Typography>{selectedDepositBatch ? selectedDepositBatch.batch_code : 'No batch selected'}</Typography>
                    <Typography>{selectedDepositBatch && batchPayments ? batchPayments.length : ''}</Typography>
                    <Button size={"small"} color="secondary" onClick={handleCreateBatchClick}>
                        Create Batch
                    </Button>
                    <Button size={"small"} color="primary" onClick={handleRemoveSelectedFromBatchClick} style={{ marginRight: '10px' }}>
                        Removed Selected From Batch
                    </Button>
                    <Button onClick={handleExportPDF}>Export to PDF</Button>
                </Box>
                <SelectDepositBatch
                    open={selectBatchOpen}
                    anchorEl={anchorEl}
                    onClose={handleSelectBatchClose}
                    onSelect={handleDepositBatchSelected}
                />
                <CreateDepositBatch
                    open={createBatchOpen}
                    anchorEl={anchorEl}
                    onClose={handleCreateBatchClose}
                    onSave={handleDepositBatchCreated}
                />
                <Collapse in={collapse}>
                    <Box display={"flex"} flexDirection={"row"} alignItems="center" gap={2}>
                        <FormLabel>Criteria</FormLabel>
                        <RadioGroup value={criteria} onChange={handleCriteriaChange}>
                            <FormControlLabel value="checks" control={<Radio />} label="Checks Only" />
                            <FormControlLabel value="creditcards" control={<Radio />} label="Credit Cards Only" />
                            <FormControlLabel value="all" control={<Radio />} label="All Payments" />
                        </RadioGroup>
                        <Button onClick={handleSelectAll}>Select All</Button>
                        <Button onClick={handleSelectNone}>Select None</Button>
                        <Button onClick={handleExportPDF}>Export to PDF</Button>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={showPdfViewer}
                                    onChange={() => setShowPdfViewer(!showPdfViewer)}
                                    name="showPdfViewer"
                                    color="primary"
                                />
                            }
                            label="Show PDF Viewer"
                        />
                    </Box>
                </Collapse>
                <SplitPanel split={'vertical'}>
                    <div className="ag-theme-alpine" style={{ height: 200 }}>
                        <AgGridReact
                            ref={gridBatchRef}
                            rowData={batchPayments}
                            rowSelection="multiple"
                            defaultColDef={{sortable: true, filter: true,  resizable: true}}
                            columnDefs={[
                                { headerName: 'ID', field: 'spmt_id', checkboxSelection: true, width: 100 },
                                {
                                    headerName: '',
                                    width: 70,
                                    cellRenderer: (params: any) => (
                                        <>
                                            <Button onClick={() => handleViewPage(params.data)}>View</Button>
                                        </>
                                    ),
                                },
                                { headerName: 'pg', field: 'docpage', width: 80 },
                                { headerName: 'Last4', field: 'checknumber', width: 100 },
                                { headerName: 'Check', field: 'checkamount', width: 120 },
                                { headerName: 'Card', field: 'cardamount', width: 120 },
                                { headerName: 'Date', field: 'checkdate', width: 150, valueFormatter: dateFormatter },
                                { headerName: 'Name', field: 'docname', width: 200 },
                                { headerName: 'Amt2', field: 'otheramount', width: 120 },
                            ]}
                        />
                    </div>
                    <div className="ag-theme-alpine" style={{ height: 500 }}>
                        <Box display={"flex"} flexDirection={"row"} alignItems="center" gap={2}>
                            <Button size={"small"} color="primary" onClick={handleAddSelectedToBatchClick} style={{ marginRight: '10px' }}>
                                Add Selected To Batch
                            </Button>
                        </Box>
                        <AgGridReact
                            ref={gridRef}
                            rowData={filteredPayments}
                            rowSelection="multiple"
                            defaultColDef={{sortable: true, filter: true,  resizable: true}}
                            onSelectionChanged={handleSelectionChange}
                            columnDefs={[
                                { headerName: 'ID', field: 'spmt_id', checkboxSelection: true, width: 100 },
                                {
                                    headerName: '',
                                    width: 70,
                                    cellRenderer: (params: any) => (
                                        <>
                                            <Button onClick={() => handleViewPage(params.data)}>View</Button>
                                        </>
                                    ),
                                },
                                { headerName: 'pg', field: 'docpage', width: 80 },
                                { headerName: 'Last4', field: 'checknumber', width: 100 },
                                { headerName: 'Check', field: 'checkamount', width: 120 },
                                { headerName: 'Card', field: 'cardamount', width: 120 },
                                { headerName: 'Date', field: 'checkdate', width: 150, valueFormatter: dateFormatter },
                                { headerName: 'Name', field: 'docname', width: 200 },
                                { headerName: 'Amt2', field: 'otheramount', width: 120 },
                            ]}
                        />
                    </div>
                </SplitPanel>

            </>
            {showPdfViewer && (
            <div style={{ height: '100%' }}>
                <Box>
                    <FormLabel>{selectedPayment && selectedPayment.docname}</FormLabel>
                    <ApexPdfjsViewer pdfUrl={pdfUrl} startingPageNum={currentPageNum} setCurrentPageNum={onSetCurrentPageNum} />
                </Box>
            </div>
            )}
        </SplitPanel>
    );
};

export default PaymentDepositsPage;
