import React, { useState } from 'react';
import {
    Box, Button, TextField, Typography, FormControl, FormControlLabel,
    FormLabel, Popper, Paper, Radio, RadioGroup, Card, CardHeader, CardContent, Collapse, IconButton
} from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import Draggable from 'react-draggable';
import axios, { AxiosResponse } from 'axios';
import apiConfig from "../../apiConfig";


interface PatientData {
    uid: number | null;
    ulname: string;
    ufname: string;
    uminitial: string;
    suffix?: string;
    prefix?: string;
    dob: string;
    sex: string;
    upPhone: string;
    umobileno: string;
    uemail?: string;
    maritalstatus?: string;
    race?: string;
    ethnicity?: string;
    upaddress?: string;
    upaddress2?: string;
    upcity?: string;
    upstate?: string;
    zipcode?: string;
    doctorId: number;
    refPrId: number;
    rendPrId: number;
    nostatements: number;
    service_date: string;
    admit_date: string;
    discharge_date: string;
}


interface EcwAddNewPatientProps {
    open: boolean;
    anchorEl: HTMLElement | null;
    onClose: () => void;
}

function DraggablePaperComponent(props: any) {
    return (
        <Draggable handle="#draggable-popper-title">
            <Paper elevation={3} {...props} />
        </Draggable>
    );
}


const EcwAddNewPatient: React.FC<EcwAddNewPatientProps> = ({ open, anchorEl, onClose }) => {
    const [formData, setFormData] = useState<PatientData>({
        uid: null,
        ulname: '',
        ufname: '',
        uminitial: '',
        suffix: '',
        prefix: '',
        dob: '',
        sex: '',
        upPhone: '',
        umobileno: '',
        uemail: '',
        maritalstatus: 'Unknown',
        race: 'ASKU',
        ethnicity: 'Declined to Specify',
        upaddress: '',
        upaddress2: '',
        upcity: '',
        upstate: '',
        zipcode: '',
        doctorId: 32320,
        refPrId: 0,
        rendPrId: 122,
        nostatements: 0,
        service_date: '',
        admit_date: '',
        discharge_date: ''
    });

    const [npi, setNpi] = useState('');
    const [npiData, setNpiData] = useState<any>(null);
    const [responseMessage, setResponseMessage] = useState<string | null>(null);
    const [responseStatus, setResponseStatus] = useState<'success' | 'error' | null>(null);
    const [expanded, setExpanded] = useState<boolean>(false);

    const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const handleNpiChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNpi(event.target.value);
    };

    const handleNpiSubmit = async () => {
        try {
            const response = await axios.get(`https://app.apexheartcare.com:8044/ecwAddPcp?npi=${npi}`);
            setNpiData(response.data);
            setFormData((prevData) => ({
                ...prevData,
                doctorId: response.data.doctorid ? parseInt(response.data.doctorid, 10) : 122,
            }));
        } catch (error) {
            console.error('Error fetching NPI data', error);
            setNpiData(null);
        }
    };

    const getScalarFromResultSet = (response: AxiosResponse<any, any>) => {
        if (response.data) {
            const dframe: string = response.data.frame0;
            const myObj: any = dframe && JSON.parse(dframe);
            const gridData: any[] = myObj && myObj.rows;
            return gridData && gridData.length > 0 ? gridData[0] : null;
        }
        return null;
    };

    const handleSavePatient = async () => {
        try {
            const apiURL = apiConfig.REACT_APEX_BASE_API;
            const authHeader = JSON.parse(localStorage.getItem('authHeader') || '{}');
            const urlPrefix = `${apiURL}/exsql?dbserver=`;
            const dataURL = `apex&sqltype=customSQL&sqltext= declare @patid int set @patid = null exec apex.rc.mnt_new_patient 
        @uid = @patid output ,
        @ulname = '${formData.ulname}',
        @ufname = '${formData.ufname}',
        @uminitial = '${formData.uminitial}',
        @suffix = '${formData.suffix}',
        @prefix = '${formData.prefix}',
        @dob = '${formData.dob}',
        @sex = '${formData.sex}',
        @upPhone = '${formData.upPhone}',
        @umobileno = '${formData.umobileno}',
        @uemail = '${formData.uemail}',
        @maritalstatus = '${formData.maritalstatus}',
        @race = '${formData.race}',
        @ethnicity = '${formData.ethnicity}',
        @upaddress = '${formData.upaddress}',
        @upaddress2 = '${formData.upaddress2}',
        @upcity = '${formData.upcity}',
        @upstate = '${formData.upstate}',
        @zipcode = '${formData.zipcode}',
        @doctorId = ${formData.doctorId},
        @refPrId = ${formData.refPrId},
        @rendPrId = ${formData.rendPrId},
        @nostatements = ${formData.nostatements},
        @service_date = '${formData.service_date}',
        @admit_date = '${formData.admit_date}',
        @discharge_date = '${formData.discharge_date}';   

        if exists (select 'x' from mobiledoc..users usr where uid = @patid) begin 
        exec rc.billsp_new_baylor_ekg_ecw_outoff_enc 
        @patient_id = @patid ,
        @service_date = '${formData.service_date}',
        @cptunits = 1,
        @ekg_mod1 = '',
        @argrefprid = ${formData.doctorId || 122},
        @admit_date = '${formData.admit_date}',
        @discharge_date = '${formData.discharge_date}'

        select @patid as patid
        end`;

            console.log("SP Call:", dataURL);

            const response = await axios.get(urlPrefix + dataURL, { headers: authHeader });

            if (!response.data) {
                setResponseMessage('Failed to save patient.');
                setResponseStatus('error');
            } else {
                const result = getScalarFromResultSet(response);
                const patid = result?.patid ?? null;

                if (patid === null) {
                    setResponseMessage('Failed to save patient.');
                    setResponseStatus('error');
                } else {
                    setResponseMessage(`Patient ID: ${patid}`);
                    setResponseStatus('success');
                    setFormData((prevData) => ({
                        ...prevData,
                        uid: patid // Store the UID for subsequent updates
                    }));
                }
            }
        } catch (error: any) {
            setResponseMessage(error.message || 'Failed to save patient.');
            setResponseStatus('error');
        }
    };

    return (
        <Popper open={open} anchorEl={anchorEl} placement="top" disablePortal style={{ zIndex: 1300 }}>
            <DraggablePaperComponent style={{ width: 850, position: 'fixed', top: 10, left: '20%' }}>
                <Box p={2} display="flex" justifyContent="space-between" alignItems="center" style={{ cursor: 'move' }}>
                    <Typography variant="h6" id="draggable-popper-title">Add New Patient</Typography>
                    <Button size="small" onClick={onClose}>X</Button>
                </Box>
                <Box p={2}>
                    <form id="new-patient-form" style={{ width: '100%' }}>
                        <Box display="flex" flexDirection="row" flexWrap="wrap" gap={2}>
                            <Box flexBasis="calc(33.3333% - 16px)">
                                <TextField
                                    label="NPI Number"
                                    name="npi"
                                    variant="outlined"
                                    fullWidth
                                    value={npi}
                                    onChange={handleNpiChange}
                                />
                                <Button onClick={handleNpiSubmit} color="primary">Add2eCW</Button>
                            </Box>
                            {npiData && (
                                <>
                                    <Box flexBasis="calc(33.3333% - 16px)">
                                        <TextField
                                            label="Last Name"
                                            name="ulname"
                                            variant="outlined"
                                            fullWidth
                                            value={formData.ulname}
                                            onChange={handleTextFieldChange}
                                        />
                                    </Box>
                                    <Box flexBasis="calc(33.3333% - 16px)">
                                        <TextField
                                            label="First Name"
                                            name="ufname"
                                            variant="outlined"
                                            fullWidth
                                            value={formData.ufname}
                                            onChange={handleTextFieldChange}
                                        />
                                    </Box>
                                    <Box flexBasis="calc(33.3333% - 16px)">
                                        <TextField
                                            label="Middle Initial"
                                            name="uminitial"
                                            variant="outlined"
                                            fullWidth
                                            value={formData.uminitial}
                                            onChange={handleTextFieldChange}
                                        />
                                    </Box>
                                    <Box flexBasis="calc(33.3333% - 16px)">
                                        <TextField
                                            label="Suffix"
                                            name="suffix"
                                            variant="outlined"
                                            fullWidth
                                            value={formData.suffix}
                                            onChange={handleTextFieldChange}
                                        />
                                    </Box>
                                    <Box flexBasis="calc(33.3333% - 16px)">
                                        <TextField
                                            label="Prefix"
                                            name="prefix"
                                            variant="outlined"
                                            fullWidth
                                            value={formData.prefix}
                                            onChange={handleTextFieldChange}
                                        />
                                    </Box>
                                    <Box flexBasis="calc(33.3333% - 16px)">
                                        <TextField
                                            label="Date of Birth"
                                            name="dob"
                                            type="date"
                                            variant="outlined"
                                            fullWidth
                                            value={formData.dob}
                                            onChange={handleTextFieldChange}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Box>
                                    <Box flexBasis="calc(33.3333% - 16px)">
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">Sex</FormLabel>
                                            <RadioGroup
                                                name="sex"
                                                value={formData.sex}
                                                onChange={handleRadioChange}
                                            >
                                                <FormControlLabel value="M" control={<Radio />} label="Male" />
                                                <FormControlLabel value="F" control={<Radio />} label="Female" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Box>
                                    <Box flexBasis="calc(33.3333% - 16px)">
                                        <TextField
                                            label="Phone"
                                            name="upPhone"
                                            variant="outlined"
                                            fullWidth
                                            value={formData.upPhone}
                                            onChange={handleTextFieldChange}
                                        />
                                    </Box>
                                    <Box flexBasis="calc(33.3333% - 16px)">
                                        <TextField
                                            label="Mobile Number"
                                            name="umobileno"
                                            variant="outlined"
                                            fullWidth
                                            value={formData.umobileno}
                                            onChange={handleTextFieldChange}
                                        />
                                    </Box>
                                    <Box flexBasis="calc(33.3333% - 16px)">
                                        <TextField
                                            label="Email"
                                            name="uemail"
                                            variant="outlined"
                                            fullWidth
                                            value={formData.uemail}
                                            onChange={handleTextFieldChange}
                                        />
                                    </Box>
                                    <Box flexBasis="calc(33.3333% - 16px)">
                                        <TextField
                                            label="Address"
                                            name="upaddress"
                                            variant="outlined"
                                            fullWidth
                                            value={formData.upaddress}
                                            onChange={handleTextFieldChange}
                                        />
                                    </Box>
                                    <Box flexBasis="calc(33.3333% - 16px)">
                                        <TextField
                                            label="Address Line 2"
                                            name="upaddress2"
                                            variant="outlined"
                                            fullWidth
                                            value={formData.upaddress2}
                                            onChange={handleTextFieldChange}
                                        />
                                    </Box>
                                    <Box flexBasis="calc(33.3333% - 16px)">
                                        <TextField
                                            label="City"
                                            name="upcity"
                                            variant="outlined"
                                            fullWidth
                                            value={formData.upcity}
                                            onChange={handleTextFieldChange}
                                        />
                                    </Box>
                                    <Box flexBasis="calc(33.3333% - 16px)">
                                        <TextField
                                            label="State"
                                            name="upstate"
                                            variant="outlined"
                                            fullWidth
                                            value={formData.upstate}
                                            onChange={handleTextFieldChange}
                                        />
                                    </Box>
                                    <Box flexBasis="calc(33.3333% - 16px)">
                                        <TextField
                                            label="Zip Code"
                                            name="zipcode"
                                            variant="outlined"
                                            fullWidth
                                            value={formData.zipcode}
                                            onChange={handleTextFieldChange}
                                        />
                                    </Box>
                                    <Box flexBasis="calc(33.3333% - 16px)">
                                        <TextField
                                            label="Service Date"
                                            name="service_date"
                                            type="date"
                                            variant="outlined"
                                            fullWidth
                                            value={formData.service_date}
                                            onChange={handleTextFieldChange}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Box>
                                    <Box flexBasis="calc(33.3333% - 16px)">
                                        <TextField
                                            label="Admit Date"
                                            name="admit_date"
                                            type="date"
                                            variant="outlined"
                                            fullWidth
                                            value={formData.admit_date}
                                            onChange={handleTextFieldChange}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Box>
                                    <Box flexBasis="calc(33.3333% - 16px)">
                                        <TextField
                                            label="Discharge Date"
                                            name="discharge_date"
                                            type="date"
                                            variant="outlined"
                                            fullWidth
                                            value={formData.discharge_date}
                                            onChange={handleTextFieldChange}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Box>
                                </>
                            )}
                            <Box flexBasis="100%">
                                <Button variant="contained" color="primary" onClick={handleSavePatient}>
                                    Save Patient
                                </Button>
                                {responseMessage && (
                                    <Typography variant="body2" color={responseStatus === 'success' ? 'green' : 'red'}>
                                        {responseMessage}
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                    </form>
                </Box>
            </DraggablePaperComponent>
        </Popper>
    );

};

export default EcwAddNewPatient;
