import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { fetchCount } from './perfinAPI';
import  apiConfig, { useLocalStorage }  from '../../apiConfig'
import { getLocalData, saveLocalData } from "../../utils/localStorage";


const initialState = {
  status: 'idle',
  error: '',
  PlaidEnvironment: localStorage.getItem('PlaidEnvironment'),
  public_token: '',
  linkToken: {
    link_token: '',
  },
  linktokenStatus: '',
  accounts: [],
  accountsLoadingStatus: '',
  transactions: [],
  accountsLoading: false,
  transactionssLoadingStatus: '',
  transactionsLoading: false,
};

let apiURL=apiConfig.REACT_APP_AUTHENTICATION;
let authToken = getLocalData("token") ;
const Headers = {
  "Access-Control-Allow-Origin": "*",
  'Authorization': `Bearer ${authToken ? authToken : "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c"}`,
};

export const getLinkToken = createAsyncThunk(
  'perfin/getLinkToken',
  async (payload) => {
    console.log('Got into asyncthunk for SelectLinkToken', payload);

    const accessToken = localStorage.getItem('accessToken');
    // axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    // axios.defaults.headers.common.PlaidEnvironment = `${payload.plaidenv}`;
    console.log('Getting link token with accesstoken: ',  `${apiConfig.API_URL}/get_link_token`, `${payload.plaidenv}`, Headers.Authorization);

    return axios.get(`${apiURL}/get_link_token`, Headers).then((res) => {
      console.log('GetLINKToken Result', res);
      //state.PlaidEnvironment = (res.data.payload.link_token.substring(5,res.data.link_token.indexOf("-", 6)) || 'sandbox');
      localStorage.setItem('PlaidEnvironment', (res.data.link_token.substring(5,res.data.link_token.indexOf("-", 6)) || 'sandbox'));
      console.log("SETTING Local Storage Paid Environment: ", localStorage.getItem('PlaidEnvironment'), (res.data.link_token.substring(5,res.data.link_token.indexOf("-", 6)) || 'sandbox'));
      return res.data;
    });
  }
);

export const addAccount = createAsyncThunk(
  'perfin/addAccount',
  async (payload) => {
    console.log('Calling addAccount', payload.plaidData);
    const accessToken = localStorage.getItem('accessToken');
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    axios.defaults.headers.common.PlaidEnvironment = `${payload.plaidenv}`;
    console.log(axios.defaults.headers.common.Authorization);

    return axios.post(`${apiConfig.API_URL}/api/plaid/accounts/add/`, payload.plaidData).then((res) => {
      console.log('addAccount Result', res);
      return res.data;
    });
  }
);

export const refreshAccount = createAsyncThunk(
  'perfin/refreshAccount',
  async (payload) => {
    console.log('Calling refreshAccount', payload.plaidData);
    const accessToken = localStorage.getItem('accessToken');
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    axios.defaults.headers.common.PlaidEnvironment = `${payload.plaidenv}`;
    console.log('Refresh Account', axios.defaults.headers.common.PlaidEnvironment, axios.defaults.headers.common.Authorization);

    return axios.post(`${apiConfig.API_URL}/api/plaid/accounts/refresh/`, payload.plaidData).then((res) => {
      console.log('refreshAccount Result', res);
      return res.data;
    });
  }
);

export const deleteAccount = createAsyncThunk(
  'perfin/deleteAccount',
  async (payload) => {
    console.log('Calling deleteAccount');
    const accessToken = localStorage.getItem('accessToken');
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    console.log(axios.defaults.headers.common.Authorization);
    axios.defaults.headers.common.PlaidEnvironment = `${payload.plaidenv}`;

    return axios.get(`${apiConfig.API_URL}/api/plaid/account/${payload.id}`).then((res) => {
      console.log('deleteAccount Result', res);
      return res.data;
    });
  }
);

export const getAccounts = createAsyncThunk('perfin/getAccounts', async (payload) => {
  console.log('Got into asyncthunk for getAccounts');
  console.log('Calling get accounts');
  const accessToken = localStorage.getItem('accessToken');
  axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  console.log(axios.defaults.headers.common.Authorization);
  axios.defaults.headers.common.PlaidEnvironment = `${payload.plaidenv}`;

  return axios.get(`${apiConfig.API_URL}/api/plaid/accounts`).then((res) => {
    console.log('GetAccounts Result', res);
    return res.data;
  });
});

export const getTransactions = createAsyncThunk(
  'perfin/getTransactions',
  async (payload) => {
    console.log("Calling allhxtrn", payload);
    const accessToken = localStorage.getItem('accessToken');
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    console.log(`${payload.plaidenv}`, axios.defaults.headers.common.Authorization);
    axios.defaults.headers.common.PlaidEnvironment = `${payload.plaidenv}`;


    return axios
      .post(`${apiConfig.API_URL}/api/plaid/accounts/allhxtransactions/`, {
        penv: `${payload.plaidenv}`,
        id: `${payload.id}`,
        op: `${payload.txnscope}`,
      })
      .then((res) => {
        // console.log("Received from Get Transactions", res.data);
        if (res.data.status == 'refresh') {
          alert(`Refrsh Token:${res.data.statusmessage}`);
        } else {
          console.log(
            `Received Transactions:${res.data.status}(${res.data.transactions.length})`
          );
          return res.data;
        }
      });
  }
);


export const getCombinedTransactions = createAsyncThunk(
  'perfin/getCombinedTransactions',
  async (payload) => {
    console.log("Calling combined trn", payload);
    const accessToken = localStorage.getItem('accessToken');
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    console.log(axios.defaults.headers.common.Authorization);
    axios.defaults.headers.common.PlaidEnvironment = `${payload.plaidenv}`;


    return axios
      .post(`${apiConfig.API_URL}/api/plaid/tranlist`, {
        penv: `${payload.plaidenv}`,
        op: `${payload.txnscope}`,
      })
      .then((res) => {
        console.log("Received Combined  Transactions", res.data);
        //state.transactions = res.data;
        return res.data;
        // if (res.data.status == 'refresh') {
        //   alert(`Refrsh Token:${res.data.statusmessage}`);
        // } else {
        //   console.log(
        //     `Received Combined Transactions:${res.data.status}(${res.data.transactions.length})`
        //   );
          
        // }
      });
  }
);

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const incrementAsync = createAsyncThunk(
  'perfin/fetchCount',
  async (payload) => {
    axios.defaults.headers.common.PlaidEnvironment = `${payload.plaidenv}`;
    const response = await fetchCount(payload.amount);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const setPlaidEnvironment = createAsyncThunk(
  'perfin/setPlaidEnvironment',
  async (payload) => {
    //const response = await fetchCount(amount);
    // The value we return becomes the `fulfilled` action payload
    return payload;
  }
);

//console.log("perfin Slice Inital State: ", initialState);
//console.log("perfin Slice local storage value: ",  localStorage.getItem('PlaidEnvironment'));
export const perfinSlice = createSlice({
  name: 'perfin',
  initialState,
  reducers: {
  },

  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.

  extraReducers: (builder) => {
    builder
      .addCase(getLinkToken.pending, (state) => {
        state.linktokenStatus = 'loading';
      })
      .addCase(getLinkToken.fulfilled, (state, action) => {
        state.linktokenStatus = 'succeeded';
        // Add any fetched posts to the array
        state.linkToken = action.payload;
        console.log("IN SET_LINK_TOKEN", action);
        state.PlaidEnvironment = (action.payload.link_token.substring(5,action.payload.link_token.indexOf("-", 6)) || 'sandbox');
        localStorage.setItem('PlaidEnvironment', (action.payload.link_token.substring(5,action.payload.link_token.indexOf("-", 6)) || 'sandbox'));
        console.log("SETTING Local Storage Paid Environment: ", localStorage.getItem('PlaidEnvironment'), (action.payload.link_token.substring(5,action.payload.link_token.indexOf("-", 6)) || 'sandbox'));
      })
      .addCase(getAccounts.pending, (state) => {
        state.accountsLoadingStatus = 'loading';
      })
      .addCase(getAccounts.fulfilled, (state, action) => {
        state.accountsLoadingStatus = 'succeeded';
        // Add any fetched posts to the array
        state.accounts = action.payload;
        console.log('RECEIVED accounts:', action.payload, state.accounts);
      })
      .addCase(getTransactions.pending, (state) => {
        state.transactionssLoadingStatus = 'loading';
      })
      .addCase(getTransactions.fulfilled, (state, action) => {
        state.transactionssLoadingStatus = 'succeeded';
        // Add any fetched posts to the array
        state.transactions = action.payload;
        console.log(
          'RECEIVED getTransactions:',
          action.payload,
          state.transactions
        );
      })
      .addCase(getCombinedTransactions.pending, (state) => {
        state.transactionssLoadingStatus = 'loading';
      })
      .addCase(getCombinedTransactions.fulfilled, (state, action) => {
        state.transactionssLoadingStatus = 'succeeded';
        console.log("IN Finsihed Load Combined Transactions", action);
        // Add any fetched posts to the array
        state.transactions = action.payload;
        console.log(
          'RECEIVED getCombinedTransactions:',
          action.payload,
          state.transactions
        );
      })
      .addCase(setPlaidEnvironment.fulfilled, (state, action) => {
        state.PlaidEnvironment = action.payload;
        localStorage.setItem('PlaidEnvironment', (action.payload) || 'sandbox');

        // Add any fetched posts to the array
        console.log(
          'Set Plaid Environment:',
          action.payload,
          state.PlaidEnvironment
        );
        window.location.reload();
      });;
  },
});

// export const {
//   SET_LINK_TOKEN,
// } = perfinSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.perfin.value)`
// export const selectLinkToken = (state: PerfinState) => state.linkToken;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd = (amount: number): AppThunk => (
//   dispatch,
//   getState
// ) => {
//   const currentValue = selectCount(getState());
//   if (currentValue % 2 === 1) {
//     dispatch(incrementByAmount(amount));
//   }
// };

export default perfinSlice.reducer;

// export const fetchPosts = createAsyncThunk('posts/fetchPosts', async () => {
//   const response = await client.get('/fakeApi/posts');
//   return response.data;
// });
