
 // ClaimContext.js
 import React, { useState, useContext , useEffect} from 'react';
 import {
    loadGridData,
    DATA_CLAIM_LIST,
    DATA_CLAIM_LIST_BY_IDS, DATA_PATIENT_FULL_LIST,
} from '../utils/ecw/EcwData';
 
 const ClaimContext = React.createContext();
 
 const ClaimProvider = ({ children }) => {
 
     const [ClaimList, setClaimList] = useState();
 
   
   useEffect(() => {
     loadGridData(DATA_CLAIM_LIST, {}, recvGridData);
 }, []);
 
 const recvGridData = (gridName, args, gridData) => {
   console.log('ReceivedData for :', gridName, args, gridData);
  
   switch (gridName) {
     case DATA_CLAIM_LIST:
     case DATA_CLAIM_LIST_BY_IDS:
         setClaimList(gridData);
       break;
     default:
   }
 };

     const refreshClaims = () => {

         loadGridData(DATA_CLAIM_LIST, {}, recvGridData);

         //return null;

     }
 
   const addClaimRow = (newClaim) => {
     console.log("WILL ADD Claim ROW", newClaim);
 
     //const newClaim = { ...newClaim };
     // Use the spread operator (...) to create a new array with the existing ClaimList and add the newClaim object
     const updatedClaimList = [...ClaimList, newClaim];
   
     // Update the state with the new array
     setClaimList(updatedClaimList);
 
     //setDocinfo(newDocinfo);
   };
 
 //   const updateDocPageinfo = (Claimpage) => {
 //     setDocinfo({...docinfo, Claimpage: Claimpage});
 //   };
 
   return (
     <ClaimContext.Provider value={{ ClaimList, addClaimRow, refreshClaims }}>
       {children}
     </ClaimContext.Provider>
   );
 };
 
 const useClaim = () => {
   const context = useContext(ClaimContext);
   if (!context) {
     throw new Error('useClaim must be used within a ClaimProvider');
   }
   return context;
 };
 
 export { ClaimProvider, useClaim };
 
 