import React, {  createRef, useEffect, useRef } from 'react';
import useState from 'react-usestateref';
import WebViewer from '@pdftron/pdfjs-express-viewer';
import apiConfig from '../apiConfig';
import axios from 'axios';

import { Box, Button, Flex, Text, useDisclosure, Container, Stack, Divider , Grid, Center, VStack, HStack} from "@chakra-ui/react";


const ApexPdfjsViewer = (props) => {

    let apiURL=apiConfig.REACT_APEX_BASE_API;
    let apiPdfJsExpressKey=apiConfig.PDFJS_KEY;
    let pdfurl = props?.pdfUrl;
    let startingPageNum = props.startingPageNum?props.startingPageNum:1;
    

    const [isDocumentReady, setIsDocumentReady, isDocumentReadyRef ] = useState(false);
    const viewer = useRef(null);
    const [pdfViewerInstance, setpdfViewerInstance, pdfViewerRef] = useState(null)
    const [pdfStartingPage, setpdfStartingPage, pdfStartingPageRef] = useState(1)

    const [currentPageNumber, setcurrentPageNumber, currentPageNumberRef] = useState(0)
    const [totalPages, settotalPages, totalPagesRef] = useState(0)



    const setCurrentPageInfo = (pageNumber) => {
        setcurrentPageNumber(pageNumber);
        props.setCurrentPageNum(pageNumber, 'loc3');
    }

    //console.log("Got call to disppdf", startingPageNum, pdfurl);

      useEffect(() => {
      
        let slider = null;
        let currentPage = 1;

        if (!pdfViewerInstance) {
          console.log("Executing useEffect 1 ", startingPageNum, pdfurl);
        WebViewer(
          {
            path: '/webviewer/lib',
            licenseKey: apiPdfJsExpressKey ,   //apex 2N6et92vB1D9mHMrHaPJ  //Local apiPdfJsExpressKey 
            initialDoc: pdfurl ? pdfurl : null,
          },
          viewer.current,
        ).then((instance) => {
          // now you can access APIs through the WebViewer instance
          const { Core, UI } = instance;
          setpdfViewerInstance(instance);

          console.log("Set Init for Webviewer:", Core, UI, instance);
    
          // adding an event listener for when a document is loaded
          Core.documentViewer.addEventListener('documentLoaded', () => {
            console.log('document loaded', pdfStartingPageRef.current, Core.documentViewer.getCurrentPage(),
            Core.documentViewer.getPageCount());
            setIsDocumentReady(true);
            settotalPages(Core.documentViewer.getPageCount());
            //if (!startingPageNum) setpdfStartingPage(1) ;
            if ((pdfStartingPageRef.current <=Core.documentViewer.getPageCount())) {

                if ( pdfStartingPageRef.current) {
                    console.log("DOC loaded, setting page to:",pdfStartingPageRef.current, startingPageNum );
                    setcurrentPageNumber(pdfStartingPageRef.current );
                    Core.documentViewer.setCurrentPage(pdfStartingPageRef.current );
                    if ( props.setCurrentPageNum) props.setCurrentPageNum(pdfStartingPageRef.current , 'loc5');
                    slider.value = pdfStartingPageRef.current;
                }
                // setcurrentPageNumber(startingPageNum);
                //startingPageNum = Core.documentViewer.getCurrentPage();



            }else {
                setcurrentPageNumber(Core.documentViewer.getCurrentPage());
                slider.value = Core.documentViewer.getCurrentPage();
            }



             // currentPage = Core.documentViewer.getCurrentPage();

            // Removing Count Button as it is duplicating

          //   const countButton = {
          //   type: 'statefulButton',
          //   initialState: 'Count',
          //   states: {
          //     Count: {
          //       number: 1,
          //       getContent: activeState => {
          //         return instance.Core.documentViewer.getPageCount();
          //       },
          //       onClick: (update, activeState) => {
          //
          //                   }
          //     }
          //   },
          //   dataElement: 'countButton'
          // };
          //
          //   instance.UI.setHeaderItems(header => {
          //       header.push( countButton  );
          //     });


          });
    
          // adding an event listener for when the page number has changed
          Core.documentViewer.addEventListener('pageNumberUpdated', (pageNumber) => {
            console.log("CHANGEPAGE", Core.documentViewer.getCurrentPage());
            setcurrentPageNumber( Core.documentViewer.getCurrentPage());
            if (props.setCurrentPageNum) {
            props.setCurrentPageNum(Core.documentViewer.getCurrentPage(), 'loc4');
            }
                slider.value = pageNumber;
          });


          // Core.documentViewer.setWatermark({
          //   // Draw diagonal watermark in middle of the document
          //   diagonal: {
          //     fontSize: 25, // or even smaller size
          //     fontFamily: 'sans-serif',
          //     color: 'red',
          //     opacity: 50, // from 0 to 100
          //     text: ''
          //   },
          //
          //   // Draw header watermark
          //   header: {
          //     fontSize: 6,
          //     fontFamily: 'sans-serif',
          //     color: 'red',
          //     opacity: 30,
          //     left: '',
          //     center: 'For Apex Heart Care of North Texas use only.  Business-confidential.  Unauthorized Access Prohibited.',
          //     right: ''
          //   }
          // });
          //
         // UI.setHeaderItems()
          // adds a button to the header that on click sets the page to the next page

        //   instance.UI.setHeaderItems(function(header) {
        //     // get the tools overlay
        //     const toolsOverlay = header.getHeader('toolbarGroup-Annotate').get('toolsOverlay');
        //     header.getHeader('toolbarGroup-Annotate').delete('toolsOverlay');
        //     // add the line tool to the top header
        //     header.getHeader('default').push({
        //       type: 'toolGroupButton',
        //       toolGroup: 'lineTools',
        //       dataElement: 'lineToolGroupButton',
        //       title: 'annotation.line',
        //     });
        //     // add the tools overlay to the top header
        //     header.push(toolsOverlay);
        //   });
        //   // hide the ribbons and second header
        //   instance.UI.disableElements(['ribbons']);
        //   instance.UI.disableElements(['toolsHeader']);
        // });

        // const countButton = {
        //     type: 'statefulButton',
        //     initialState: 'Count',
        //     states: {
        //       Count: {
        //         number: 1,
        //         getContent: activeState => {
        //           return activeState.number;
        //         },
        //         onClick: (update, activeState) => {
        //           activeState.number += 1;
        //           update();
        //         }
        //       }
        //     },
        //     dataElement: 'countButton'
        //   };



        // UI.setHeaderItems(header => {
        //     header.delete(9);
        //     header.unshift({
        //       type: 'customElement',
        //       render: () => {
        //         const logo = document.createElement('img');
        //         logo.src = 'https://www.pdftron.com/downloads/logo.svg';
        //         logo.style.width = '200px';
        //         logo.style.marginLeft = '10px';
        //         logo.style.cursor = 'pointer';
        //         logo.onclick = () => {
        //           window.open('https://www.pdftron.com', '_blank');
        //         }
        //         return logo;
        //       }
        //     }, {
        //       type: 'spacer'
        //     });
        // });


          UI.setHeaderItems(header => {

            header.push({
                type: 'actionButton',
                img: 'https://icons.getbootstrap.com/assets/icons/caret-left-fill.svg',
                onClick: () => {
                  const currentPage = Core.documentViewer.getCurrentPage();
                  const atFirstPage = currentPage === 1;
      
                  if (atFirstPage) {
                    Core.documentViewer.setCurrentPage(1);
                  } else {
                    Core.documentViewer.setCurrentPage(currentPage - 1);
                  }
                }
              });

             // header.push(countButton);

              
              header.push({

                type: 'customElement',
            
                title:'Page',
            
                render: function() {
            
                  slider = document.createElement('input');
            
                  slider.type = 'text';
            
                  slider.value = currentPageNumberRef.current;
            
                  slider.tabindex="-1";
            
                  slider.style="width: 25px; border:none; font-size: small; margin-top: -1px;";
            
                  slider.oninput = function() {
            
                    console.log('---oninput---');
            
                  };
            
                  slider.addEventListener("focusout", function(event) {
            
                    console.log('---onfocusout---');
            
                    if (slider.value == '' || (slider.value > totalPages) || (slider.value != Core.documentViewer.getCurrentPage())){
            
                      slider.value = Core.documentViewer.getCurrentPage();
            
                    }
            
                  });
            

            
                //   var pageNum = document.createElement('input');
            
                //   pageNum.type = 'text';


            
                //   pageNum.value = " /  " + Core.documentViewer.getPageCount();;
            
                //   pageNum.tabindex="-1";
            
                //   pageNum.style="width: 30px; border:none; font-size: small; margin-top: -1px;background-color: white;";
            
                //   pageNum.disabled = true;

                //   console.log("Page num:", Core.documentViewer.getPageCount(), totalPages);
            
            
            
                  var form = document.createElement('div');
            
                  form.style="display: flex; border-radius: 10px;padding: 2px;cursor: pointer;";
            
                  form.appendChild(slider);
            
                  //form.appendChild(pageNum);

                  slider.addEventListener("keyup", function(event) {
            
                    console.log('---keyup---', slider.value,event.key);
            
                    if (event.key === 'Enter'){
            
                      if(slider.value !== '' && (slider.value <= totalPages)){
                        console.log("Setting on Enter, input value to current page");
                        setcurrentPageNumber( slider.value);
                        Core.documentViewer.setCurrentPage(slider.value);
                        
            
                      }
            
                      else{
            
                        slider.value = Core.documentViewer.getCurrentPage();
            
                      }
            
                    }
            
                  });
            
                  return form;
            
            
            
                  // return slider;
            
                }
            
              });

            header.push({
              type: 'actionButton',
              img: 'https://icons.getbootstrap.com/assets/icons/caret-right-fill.svg',
              onClick: () => {
                const currentPage = Core.documentViewer.getCurrentPage();
                const totalPages = Core.documentViewer.getPageCount();
                const atLastPage = currentPage === totalPages;
    
                if (atLastPage) {
                  Core.documentViewer.setCurrentPage(1);
                } else {
                  Core.documentViewer.setCurrentPage(currentPage + 1);
                }
              }
            });

            instance.UI.disableElements(['fullscreenButton'])

            header.push({
                type: 'actionButton',
                img: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm-5 16c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm3-10H5V5h10v4z"/></svg>',
                onClick: () => {
                  // save the annotations
                  console.log("Save button called on PDF:");

                    Core.documentViewer.getDocument().getFileData({})
                        .then(docstream => {

                            const documentBlob = new Blob([docstream], { type: 'application/pdf' });
                            console.log("Saving Doc Bob ", documentBlob);
                            //saveBlobToServer(documentBlob);

                            let docFileName = "patient_statement.pdf";
                            if (props.getTargetFileName) {
                                docFileName = props.getTargetFileName();
                            }
                            saveFileAsPDF  (documentBlob, docFileName);

                        });
                    //

                    // Example function that saves a blob to the server


                }
              });


  // instance.UI.setHeaderItems((header) => {
  //   header.getHeader('default').push({
  //     img: "icon-header-full-screen",
  //     index: -1,
  //     type: "actionButton",
  //     element: 'fullScreenButton',
  //     onClick: () => {
  //       instance.UI.toggleFullScreen()
  //     }
  //   });
  // });
          

            //instance.UI.disableElements(['downloadButton'])
           // instance.UI.disableElements(['printButton'])
            //instance.UI.disableElements(['ribbons']);
            //instance.UI.disableElements(['toolsHeader']);
          });
    
        });
      }
    
      }, [     ]);

    const saveFileAsPDF = (blobPDF, fileName) => {

        if (window.navigator.msSaveOrOpenBlob) // IE10+
            window.navigator.msSaveOrOpenBlob(blobPDF, fileName);
        else { // Others
            console.log("Use Else to save file");
            var a = document.createElement("a"),
                url = URL.createObjectURL(blobPDF);
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            setTimeout(function() {
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
            }, 0);
        }
    }

      useEffect(() => {
        if (pdfViewerInstance && pdfurl) {
          console.log("Executing useEffect 2 ", pdfurl);
           // setpdfStartingPage(startingPageNum);
            viewPdfDocument(pdfurl);
        }
    
      }, [pdfurl]);

      useEffect(() => {
        if (pdfViewerInstance && pdfurl) {
          console.log("Executing useEffect 3 ", startingPageNum);
            setpdfStartingPage(startingPageNum);
            pdfViewerRef.current.Core.documentViewer.setCurrentPage(startingPageNum);
            //viewPdfDocument(pdfurl);
        }
    
      }, [startingPageNum]);

      useEffect(() => {
        if (pdfViewerInstance && pdfurl) {
          //console.log("Current page Number Changed again in useeffect", currentPageNumber);
          //props.setCurrentPageNum(currentPageNumber, 'loc1');
        }
    
      }, [currentPageNumber]);


    const viewPdfDocument = (pdfurl) => {
        pdfViewerRef.current.UI.loadDocument(pdfurl, {
            extension: 'pdf'
        });  
    };


   const gotoPrevPDFjsPage = () => {
    console.log('Called Prev Page');

    const currentPage = pdfViewerRef.current.Core.documentViewer.getCurrentPage();
    //const totalPages = pdfViewerRef.current.Core.documentViewer.getPageCount();

    if (currentPage === 1) return;
    pdfViewerRef.current.Core.documentViewer.setCurrentPage(currentPage - 1);

    setcurrentPageNumber(currentPage - 1, 'loc2');
    settotalPages(totalPages);

    // const iframePdf = this.state.iframeRef.current.contentWindow;
    // console.log(iframePdf);
    // console.log(iframePdf.PDFViewerApplication.pdfViewer.currentPageNumber);
    // if (this.iframePdf !== undefined) {
    //   const iframePdf = this.iframePdf.contentWindow;
    //   iframePdf.print();
    // }
  };

  const gotoNextPDFjsPage = () => {
    console.log('Called Next Page');

    const currentPage = pdfViewerRef.current.Core.documentViewer.getCurrentPage();
    const totalPages = pdfViewerRef.current.Core.documentViewer.getPageCount();


    if (currentPage === totalPages) return;
    pdfViewerRef.current.Core.documentViewer.setCurrentPage(currentPage + 1);

    
    setcurrentPageNumber(currentPage + 1);
    settotalPages(totalPages);

    // if (pdfViewerRef.current.documentViewer.getCurrentPage() === pdfViewerRef.current.documentViewer.getPageCount()) return;
    // pdfViewerRef.current.documentViewer.setCurrentPage(pdfViewerRef.current.documentViewer.getCurrentPage() + 1);

    // const iframePdf = this.state.iframeRef.current.contentWindow;
    // console.log(iframePdf);
    // console.log(iframePdf.PDFViewerApplication.pdfViewer.currentPageNumber);
    // if (this.iframePdf !== undefined) {
    //   const iframePdf = this.iframePdf.contentWindow;
    //   iframePdf.print();
    // }
  };

    const gotoPrevPDFjsPageNumber = (pagenum) => {
    console.log('Called goto Page:', pagenum);

    // const iframePdf = this.state.iframeRef.current.contentWindow;
    // console.log(iframePdf);
    // console.log(iframePdf.PDFViewerApplication.pdfViewer.currentPageNumber);
    // if (this.iframePdf !== undefined) {
    //   const iframePdf = this.iframePdf.contentWindow;
    //   iframePdf.print();
    // }
  };

//   nextPDFPage = () => {
//     console.log('Called Next Page Number');

//     const iframePdf = this.state.iframeRef.current.contentWindow;
//     console.log(iframePdf);
//     console.log(
//       (iframePdf.PDFViewerApplication.pdfViewer.currentPageNumber += 1)
//     );

//     iframePdf.PDFViewerApplication.pdfDocument.getPage(1).then((pdfPage) => {
//       pdfPage.getTextContent().then((data) => {
//         console.log(data);
//       });
//     });
//   };

    return (

        <div className="webviewer" ref={viewer} style={{ width: '100%', height: '90vh', backgroundColor: 'lightsalmon' }}>
        </div>
       

    );
}

export default ApexPdfjsViewer;