// UserContext.tsx
import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';

interface User {
    firstName: string;
    lastName: string;
    email: string;
}

interface UserContextType {
    user: User | null;
    setUser: (user: User) => void;
    isLoggedIn: () => boolean;
    loginUserName: () => string | null;
    loginUserEmail: () => string | null;
    logout: () => void;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

const UserProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [user, setUserState] = useState<User | null>(null);

    useEffect(() => {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            setUserState(JSON.parse(storedUser));
        }
    }, []);

    const setUser = (user: User) => {
        setUserState(user);
        localStorage.setItem('user', JSON.stringify(user));
    };

    const isLoggedIn = () => {
        return user !== null;
    };

    const loginUserName = () => {
        return user ? `${user.firstName} ${user.lastName}` : null;
    };

    const loginUserEmail = () => {
        return user ? user.email : null;
    };

    const logout = () => {
        setUserState(null);
        localStorage.removeItem('user');
    };

    return (
        <UserContext.Provider value={{ user, setUser, isLoggedIn, loginUserName, loginUserEmail, logout }}>
            {children}
        </UserContext.Provider>
    );
};

const useUserContext = () => {
    const context = useContext(UserContext);
    if (context === undefined) {
        throw new Error('useUserContext must be used within a UserProvider');
    }
    return context;
};

export { UserProvider, useUserContext };
