import React from "react";
import { Button, CircularProgress } from "@mui/material";

interface SubmitButtonProps {
  buttonText?: string;
  onClick: () => void;
  disabled?: boolean;
  isSaving: boolean;
  style?: React.CSSProperties;
}

// A material-ui derived button that shows
// text or circularprogress

const SubmitButton: React.FC<SubmitButtonProps> = ({ buttonText, onClick, disabled, isSaving, style }) => {
  return (
      <Button
          disabled={disabled || isSaving}
          color="primary"
          size="small"
          variant="contained"
          onClick={onClick}
          style={style || { width: '100px', height: '30px' }}
      >
        {isSaving ? (<CircularProgress size="12pt" />) : (<>{buttonText || "OK"}</>)}
      </Button>
  );
};

export default SubmitButton;
