import { createTheme, Theme } from '@mui/material/styles';

interface ThemeConfig {
  name: string;
  header_color: string;
  header_text_color: string;
  title_bar_color: string;
  title_bar_text_color: string;
  left_panel_color: string;
  body_color: string;
  text_color: string;
  secondary_text_color: string;
  primary_accent_color: string;
  secondary_accent_color: string;
  list_header_color: string;
  list_selection_color: string;
  list_selection_text_color?: string;
  list_hover_color: string;
  primary_border_color?: string;
}

const themes: ThemeConfig[] = [
  {
    name: "Default",
    header_color: "#515151",
    header_text_color: "white",
    title_bar_color: "#202020",
    title_bar_text_color: "#92cbf7",
    left_panel_color: "#202020",
    body_color: "#333333",
    text_color: "white",
    secondary_text_color: "silver",
    primary_accent_color: "#92cbf7",
    secondary_accent_color: "#f290b1",
    list_header_color: "#292929",
    list_selection_color: "#434c53",
    list_selection_text_color: "white",
    list_hover_color: "#414141",
  },
  {
    name: "Mayo Blue",
    header_color: "#0057B8",
    header_text_color: "white",
    title_bar_color: "#0070BE",
    title_bar_text_color: "white",
    left_panel_color: "white",
    body_color: "white",
    text_color: "black",
    secondary_text_color: "silver",
    primary_accent_color: "#0070BE",
    secondary_accent_color: "#f290b1",
    list_header_color: "#292929",
    list_selection_color: "#0057B8",
    list_selection_text_color: "white",
    list_hover_color: "#0070BE",
  },
  {
    name: "Pumpkin",
    header_color: "#1a2639",
    header_text_color: "#c24d2c",
    title_bar_color: "#3e4a61",
    title_bar_text_color: "white",
    left_panel_color: "#c24d2c",
    body_color: "#d9dad7",
    text_color: "#1a2639",
    secondary_text_color: "silver",
    primary_accent_color: "#3e4a61",
    secondary_accent_color: "#c24d2c",
    list_header_color: "#292929",
    list_selection_color: "#434c53",
    list_hover_color: "#414141",
  },
  {
    name: "Brown",
    header_color: "#596e79",
    header_text_color: "#ffffff",
    title_bar_color: "#c7b198",
    title_bar_text_color: "white",
    left_panel_color: "#dfd3c3",
    body_color: "#f0ece2",
    text_color: "#596e79",
    secondary_text_color: "silver",
    primary_accent_color: "#92cbf7",
    secondary_accent_color: "#f290b1",
    list_header_color: "#292929",
    list_selection_color: "#434c53",
    list_hover_color: "#414141",
  },
  {
    name: "My Little Pony",
    header_color: "#6c5b7c",
    header_text_color: "#ffffff",
    title_bar_color: "#c06c84",
    title_bar_text_color: "white",
    left_panel_color: "#f67280",
    body_color: "#f8b595",
    text_color: "#2c1b3c",
    secondary_text_color: "silver",
    primary_accent_color: "#92cbf7",
    secondary_accent_color: "#f290b1",
    list_header_color: "#292929",
    list_selection_color: "#434c53",
    list_hover_color: "#414141",
  },
  {
    name: "Olive",
    header_color: "#757a79",
    header_text_color: "#ffffff",
    title_bar_color: "#9ba6a5",
    title_bar_text_color: "white",
    left_panel_color: "#aeccc6",
    body_color: "#bbe9db",
    text_color: "black",
    secondary_text_color: "silver",
    primary_accent_color: "#92cbf7",
    secondary_accent_color: "#f290b1",
    list_header_color: "#292929",
    list_selection_color: "#434c53",
    list_hover_color: "#414141",
  },
  {
    name: "Cadbury",
    header_color: "#25161b",
    header_text_color: "#f3cba5",
    title_bar_color: "#453953",
    title_bar_text_color: "#f3cba5",
    left_panel_color: "#975a5e",
    body_color: "#f3cba5",
    text_color: "#25161b",
    secondary_text_color: "silver",
    primary_accent_color: "#975a5e",
    secondary_accent_color: "#f290b1",
    list_header_color: "#292929",
    list_selection_color: "#453953",
    list_hover_color: "#453953",
  },
  {
    name: "Dublin",
    header_color: "#392f2f",
    header_text_color: "#59a985",
    title_bar_color: "#3a7563",
    title_bar_text_color: "white",
    left_panel_color: "#59a985",
    body_color: "#e6d3a7",
    text_color: "#25161b",
    secondary_text_color: "silver",
    primary_accent_color: "#92cbf7",
    secondary_accent_color: "#f290b1",
    list_header_color: "#292929",
    list_selection_color: "#434c53",
    list_hover_color: "#414141",
  },
  {
    name: "Serenity",
    header_color: "#0C7E7A",
    header_text_color: "#00163F",
    title_bar_color: "#2A9885",
    title_bar_text_color: "#00163F",
    left_panel_color: "#5AB795",
    body_color: "#8DD9AA",
    text_color: "#00163F",
    secondary_text_color: "#10264F",
    primary_border_color: "#00163F",
    primary_accent_color: "#0C7E7A",
    secondary_accent_color: "#2A9885",
    list_header_color: "#74C79F",
    list_selection_color: "#00163F",
    list_selection_text_color: "white",
    list_hover_color: "#0C7E7A",
  },
  {
    name: "Serenity Blue",
    header_color: "#131944",
    header_text_color: "#5F87B9",
    title_bar_color: "#1D3252",
    title_bar_text_color: "#5F87B9",
    left_panel_color: "#314F67",
    body_color: "#457081",
    text_color: "white",
    secondary_text_color: "gray",
    primary_border_color: "#131944",
    primary_accent_color: "#5F87B9",
    secondary_accent_color: "#2A9885",
    list_header_color: "#3C5F73",
    list_selection_color: "#00163F",
    list_selection_text_color: "white",
    list_hover_color: "#3C5F73",
  },
  {
    name: "Steel",
    header_color: "#455a64",
    header_text_color: "white",
    title_bar_color: "#78909c",
    title_bar_text_color: "white",
    left_panel_color: "#b0bec5",
    body_color: "#eceff1",
    text_color: "black",
    secondary_text_color: "#607d8b",
    primary_accent_color: "#455a64",
    secondary_accent_color: "#f290b1",
    list_header_color: "#78909c",
    list_selection_color: "#434c53",
    list_hover_color: "#414141",
  },
];

function getMUITheme(theme: ThemeConfig): Theme {
  return createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: theme.primary_accent_color,
      },
      secondary: {
        main: theme.secondary_accent_color,
      },
    },
    typography: {
      button: {
        fontSize: '11pt',
      },
    },
    components: {
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: theme.text_color,
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            fontSize: '11pt',
            color: theme.text_color,
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          sizeMedium: {
            color: theme.text_color,
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontSize: '11pt',
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            color: theme.text_color,
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.secondary_text_color,
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.primary_accent_color,
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.primary_accent_color,
            },
            '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.secondary_text_color,
            },
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            color: theme.text_color,
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: '11pt',
          },
        },
      },
    },
  });
}

const bodyTheme = getMUITheme(themes[0]);
const blueTheme =  getMUITheme(themes[0]);


let callbackFunction: ((theme: Theme) => void) | null = null;

export default class AppThemeManager {
  static setThemeChangeCallback(func: (theme: Theme) => void) {
    callbackFunction = func;
  }

  static changeTheme(themeName: string) {
    const theme = themes.find((x) => x.name === themeName);

    if (!theme) return;

    const r = document.documentElement;

    Object.keys(theme).forEach((key) => {
      if (key !== 'name') {
        r.style.setProperty('--' + key.replaceAll('_', '-'), (theme as any)[key]);
      }
    });

    if (callbackFunction) callbackFunction(getMUITheme(theme));
  }
}

export { bodyTheme, blueTheme, themes };
export type { ThemeConfig };
