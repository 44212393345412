import React from 'react';
import LoadingScreen from '../loadingscreen/loadingscreen';

const MOBILE_DEVICE_MEDIUM = 800;

interface PageProps {
  actionButtons?: React.ReactNode;
  content?: React.ReactNode;
  fullScreen?: boolean;
  isLoading?: boolean;
  loadingMsg?: string;
  showWarningTitlebar?: boolean;
  title?: React.ReactNode;
  toolButtons?: React.ReactNode;
}

interface PageState {
  windowHeight: number;
  windowWidth: number;
}

export default class Page extends React.Component<PageProps, PageState> {
  static defaultProps: Partial<PageProps> = {
    fullScreen: false,
    isLoading: false,
    loadingMsg: '',
    showWarningTitlebar: false,
  };

  isChildWindow: boolean;

  constructor(props: PageProps) {
    super(props);
    this.isChildWindow = window.parent !== window;

    this.state = {
      windowHeight: 0,
      windowWidth: 0,
    };

    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount(): void {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount(): void {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize(): void {
    this.setState({
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
    });
  }

  render() {
    const {
      actionButtons,
      content,
      fullScreen,
      isLoading,
      loadingMsg,
      showWarningTitlebar,
      title,
      toolButtons,
    } = this.props;

    const { windowHeight, windowWidth } = this.state;

    return (
        <>
          {isLoading ? (
              <LoadingScreen msg={loadingMsg} />
          ) : (
              <div
                  className="omv-pageroot noselect"
                  style={{ height: windowHeight - (this.isChildWindow || fullScreen ? 0 : 60) }}
              >
                {title && (
                    <div className={showWarningTitlebar ? 'omv-pageheader-warning' : 'omv-pageheader'}>
                      <div style={{ marginTop: -1 }}>
                        <h4>{title}</h4>
                      </div>
                      <div className="md-lg" style={{ flexGrow: 2, paddingLeft: '10px', marginTop: '-1px' }}>
                        {toolButtons}
                      </div>
                      <div style={{ marginTop: '5px' }}>
                        {actionButtons}
                      </div>
                    </div>
                )}

                <div
                    style={{
                      height: windowHeight - (this.isChildWindow || fullScreen ? 52 : 112),
                      overflow: 'auto',
                    }}
                >
                  {isLoading ? (
                      <LoadingScreen msg="Loading data" />
                  ) : (
                      <>
                        {content}
                      </>
                  )}
                </div>
              </div>
          )}
        </>
    );
  }
}
