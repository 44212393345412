
import React, { useEffect } from "react";
import useState from 'react-usestateref';
import apiConfig from '../apiConfig';
import axios from "axios";

import {
    Flex,
    Text,
    FormControl,
    HStack,
    FormLabel,
    Box,
    Button,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverArrow,
    PopoverCloseButton,
    PopoverBody,
    Input

} from "@chakra-ui/react";

import { AgGridReact } from 'ag-grid-react';

import {
    AutoComplete,
    AutoCompleteInput,
    AutoCompleteItem,
    AutoCompleteList,
  } from "@choc-ui/chakra-autocomplete";

  import 'ag-grid-community/styles/ag-grid.css';
  import 'ag-grid-community/styles/ag-theme-alpine.css';

export function SelectEcwCPT({ selectedEcwCPT, setSelectedEcwCPT }) {

    let apiURL=apiConfig.REACT_APEX_BASE_API;
    const gridRef = React.useRef();
    const initFocusRef = React.useRef();
    const [quickCPTFilter, setQuickCPTFilter] = useState('');
    const [ecwCPTs, setecwCPTs] = useState([]);
    

    const [CPTColumnDefs, setCPTColumnDefs] = useState(
        [
            { field: 'cptcode', filter: true , width:160 ,  sortable: true,cellStyle: {fontSize: '15px'}, headerName: 'CPT' , 
            resizable: true,editable: true},
            { field: 'cptdesc', width: 300 , sortable: true,cellStyle: {fontSize: '15px'},headerName: 'Desc',
            resizable: true,},
            { field: 'tcount', filter: true, width: 100, sortable: true,cellStyle: {fontSize: '15px'},headerName: 'Name',
            resizable: true, },       
  
        ]
    );

    useEffect(()=> {

        const getData = async () => {

            let authHeader = {};

            const authH = JSON.parse(localStorage.getItem('authHeader'));
            if (authH) {
              authHeader =  authH;
            }

            let urlPrefix = apiURL + '/exsql?dbserver=';
            let dataURL = "apex&sqltype=customSQL&sqltext=set rowcount 0 select * from apex.rc.billing_cpt_list order by tcount desc ";
  
            const res2 = await axios.get(urlPrefix + dataURL, authHeader);
            if (res2.data) {
              console.log("GOT Billing Files:", res2.data);
              let dframe = res2.data['frame0'];
              //console.log(dframe);
              let myObj = JSON.parse(dframe);
              console.log(myObj);
              let gridData = myObj['rows'];

              setecwCPTs(gridData);
            }
    

        };
      
        getData(); 
      
        return () => {
          // this now gets called when the component unmounts
        };
  
  
      }, []);

    const handleQuickCPTFilter = (event) => {
        console.log("Quick Patient Filter:",event.target, event.target.value, gridRef.current);
        setQuickCPTFilter(event.target.value );
        gridRef.current.api.setQuickFilter(event.target.value);
    };
      
    const handleGridRowSelected = (event) => {

        //console.log("handleGridRowSelected:",event);
        const selectedNodes = event.api
          .getSelectedNodes()
          .filter((node) => node.selected);
        //console.log(selectedNodes);
    
        const datarow = selectedNodes[0].data;
        console.log('AG Row selected', datarow);
        setSelectedEcwCPT(datarow);

      };

      //use selectedEcwFolder to se currently selected value

      return(

        <Popover isLazy initialFocusRef={initFocusRef}>
  <PopoverTrigger>
    <Text as='input' value={selectedEcwCPT? `${selectedEcwCPT.cptcode} ${selectedEcwCPT.cptdesc}` : ''}  onChange={handleQuickCPTFilter}  
          placeholder="Select CPT" variant="filled" fontSize='sm'  
          w='450px' />
  </PopoverTrigger>
  <PopoverContent bg='tomato' color='white' w='800px'>
    <PopoverHeader fontWeight='semibold'>Popover placement</PopoverHeader>
    <PopoverArrow />
    <PopoverCloseButton />
    <PopoverBody>
    <Input as='input' value={quickCPTFilter}  onChange={handleQuickCPTFilter}  ref={initFocusRef}
          placeholder="Select CPT" variant="filled" fontSize='sm' aria-multiline />
  
    <Box className="ag-theme-alpine maxWidth" style={{ paddingLeft: '-200px', width: '100%', height: '450px', overflow: 'hidden' }}>
                <AgGridReact 
                ref={gridRef} 
                rowData={ecwCPTs} 
                rowHeight={20}
                columnDefs={CPTColumnDefs} 
                animateRows={true} 
                resizable={true} 
                rowSelection="single"
                rowDragManaged={true}
                suppressMoveWhenRowDragging={true}
                applyColumnDefOrder={true}
                onSelectionChanged={handleGridRowSelected}
                />
     </Box>
    </PopoverBody>
  </PopoverContent>
</Popover>


      )

    }