import React from "react";
import { OutlinedInput, Select, SelectProps } from "@mui/material";

interface StyledSelectProps extends Omit<SelectProps, 'input'> {
    children: React.ReactNode;
}

const StyledSelect: React.FC<StyledSelectProps> = (props) => {
    return (
        <div>
            <Select
                input={<OutlinedInput />}
                sx={{ color: "var(--text-color)", fontSize: '11pt', height: '30px' }}
                {...props}
            >
                {props.children}
            </Select>
        </div>
    );
}

export default StyledSelect;
