import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { getLocalData } from "../utils/localStorage";

const PrivateRoute = ({ children }) => {
  const location = useLocation();
  console.log("In Private route check.", location);
  let auth = getLocalData("token") ? true : false;
  //auth = true;
  console.log("In Private route check. auth: ", auth);
  if (!auth) {
    return <Navigate to="/login" replace state={{ from: location }} />;
  }
  return children;
};
export default PrivateRoute;
