
import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
    Avatar,
    Flex,
    FormControl,
    FormHelperText,
    FormLabel,
    Box,
    Text,
    Heading,
    StackDivider,
    Stack,
    Container,
    Textarea 

  } from "@chakra-ui/react";

  import { Card, CardHeader, CardBody, CardFooter } from '@chakra-ui/card'

  import {  useParams } from "react-router-dom";


  // import { CUIAutoComplete } from 'chakra-ui-autocomplete'

  import React, { useEffect, useState } from "react";
  import axios from "axios";
  import apiConfig from '../apiConfig';
import { MdLaptopWindows } from "react-icons/md";
  
  export function ApexKeyViewer({ children, buttonText , data}) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [keyInfo, setKeyInfo] = useState({});
  
    const pageParams = useParams(); // props.match.params.docpath;
    const sourcepath = pageParams.sourcepath;
    const keyid = pageParams.keyid;

    let apiURL=apiConfig.REACT_APEX_BASE_API;

    useEffect(()=> {

      const getecwData = async () => {

        console.log(sourcepath, keyid);
        const res = await axios.get(`${apiURL}/apexapi/getentry?id=${keyid}&source=${sourcepath}&isinternal`);
        if (res.data) {
          console.log("GOT Stuff:", res.data);
          setKeyInfo(res.data);
        }
       
      };
    
      getecwData(); // run it, run it
    
      return () => {
        // this now gets called when the component unmounts
      };


    }, []);

    if (isOpen) {
    console.log("Received props in model :", pageParams);

    }

    const handleOpenEntry = (event) => {
        let url = keyInfo.editURL;
        window.open(url);
      }

      const handleOpenSiteEntry = (event) => {
        let url = keyInfo.Url;
        window.open(url);
      }

    const handleSavePassword = (event) => {
      console.log("Ready to Save: ", pageParams);
      onClose();
    }

    let notesHtml =keyInfo.Notes?.replaceAll('\n', '<br/>');
  
    return (

        <>
            <Box flex='1' bg='green.500' >
            <Card>
  <CardHeader>
    <Heading size='md'>Key Entry Information</Heading>
  </CardHeader>

  <CardBody>
    <Stack divider={<StackDivider />} spacing='4'>
      <Box>
        <Heading size='xs' textTransform='uppercase'>
          Summary
        </Heading>
        <Text pt='2' fontSize='sm'>
          Name: {keyInfo.Name}
        </Text>
        <Text pt='2' fontSize='sm'>
          User ID: {keyInfo.Username}
        </Text>
        <Text pt='2' fontSize='sm'>
          Password: {keyInfo.password}
        </Text>
        <Button colorScheme="blue" mr={3} onClick={handleOpenSiteEntry}>
                Open Site
        </Button>
      </Box>
      <Box>
        <div dangerouslySetInnerHTML={{ __html:  `${notesHtml}` }}></div>
      </Box>
      <Box>
        <Heading size='xs' textTransform='uppercase'>
          Action Area
        </Heading>
        <Button colorScheme="blue" mr={3} onClick={handleOpenEntry}>
                Edit Entry
        </Button>
      </Box>
    </Stack>
  </CardBody>
</Card>
            </Box>
           
          
            </>

    );
  }
  
