import React, { useState, useEffect, useRef } from 'react';
import {
    Box, Button, TextField, Typography, FormControl, FormControlLabel,
    FormLabel, Popper, Paper, Radio, RadioGroup, Card, CardHeader, CardContent, Collapse, IconButton, AppBar
} from '@mui/material';
import { ExpandMore, ExpandLess, Refresh } from '@mui/icons-material';
import Draggable from 'react-draggable';
import axios, { AxiosResponse } from 'axios';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import SplitPanel from "../../Components/splitpage/splitpanel";
import SelectEcwPatient from "../../Modals/SelectApexPatient";
import apiConfig from "../../apiConfig";
import ClearIcon from '@mui/icons-material/Clear';
import { makeStyles } from '@mui/styles';
import { Check as CheckIcon } from '@mui/icons-material';
import SelectEcwPcp from "../../Modals/SelectEcwPcp";


interface PatientData {
    uid: number | null;
    ulname: string;
    ufname: string;
    uminitial: string;
    suffix?: string;
    prefix?: string;
    dob: string;
    sex: string;
    upPhone: string;
    umobileno: string;
    uemail?: string;
    maritalstatus?: string;
    race?: string;
    ethnicity?: string;
    upaddress?: string;
    upaddress2?: string;
    upcity?: string;
    upstate?: string;
    zipcode?: string;
    doctorId: number;
    refPrId: number;
    rendPrId: number;
    nostatements: number;
    service_date: string;
    admit_date: string;
    discharge_date: string;
}

interface CPTRowData {
    claim_id?: number;
    cpt_id?: number;
    cpt_diaginfo?: string;
    dos?: string;
    cptdos?: string;
    patientname?: string;
    facilityname?: string;
    cptdesc?: string;
    mods?: string;
    ClaimStatus?: string;
    queueCode?: string;
    queueDesc?: string;
    queueIndex?: number;
    last_action_time?: string;
    claimwith?: string;
    billedto?: string;
    invPriIns?: string;
    invSecIns?: string;
    EncounterId?: number;
    PatientId?: number;
    InvoiceDt?: string;
    ServiceDt?: string;
    InvoiceAmount?: number;
    inv_payment?: number;
    inv_copay?: number;
    uncoveredAmount?: number;
    inv_ptresp?: number;
    inv_ptpayment?: number;
    inv_ptbalance?: number;
    inv_balance?: number;
    inv_filestatus?: string;
    BilledToId?: number;
    SubmissionId?: number;
    BilledToIdType?: number;
    InvFacilityId?: number;
    inv_netpayment?: number;
    inv_allowedfee?: number;
    invpos?: number;
    assignedTo?: string;
    assignedToId?: number;
    PrimaryInsId?: number;
    SecondaryInsId?: number;
    invcptid?: number;
    itemid?: number;
    code?: string;
    mod1?: string;
    mod2?: string;
    mod3?: string;
    mod4?: string;
    sdos?: string;
    edos?: string;
    tos?: string;
    cptpos?: string;
    cptBilledUnitFee?: number;
    cptUnits?: number;
    cptBilledFee?: number;
    icd1?: string;
    icd2?: string;
    icd3?: string;
    icd4?: string;
    inv_icds?: string;
    cptAllowedFee?: number;
    cptAllowedUnitFee?: number;
    cptDisplayIndex?: number;
    cptBalance?: number;
    billedfee?: number;
    Paid?: number;
    Deduct?: number;
    Coins?: number;
    Memresp?: number;
    Adjustment?: number;
    Adjustment2?: number;
    Withheld?: number;
    CptPaid?: number;
}
const useStyles = makeStyles(() => ({
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        backgroundColor: '#f4f6f8',
        padding: '16px',
        borderRadius: '8px',
    },
    formField: {
        display: 'flex',
        flexDirection: 'row',
        gap: '8px',
        '& .MuiInputBase-root': {
            fontSize: '0.875rem',
        },
        '& .MuiFormLabel-root': {
            fontSize: '0.875rem',
        },
    },
    formFieldRow: {
        display: 'flex',
        flexDirection: 'row',
        gap: '8px',
        '& > *': {
            flex: 1,
        },
    },
    card: {
        marginTop: '16px',
    },
    cardHeader: {
        backgroundColor: '#1976d2', // custom blue color
        color: '#ffffff', // white color
    },
    cardContent: {
        backgroundColor: '#ffffff', // white color
    },
    responseMessage: {
        color: '#4caf50', // green color
    },
    errorMessage: {
        color: '#f44336', // red color
    },
    saveButton: {
        marginTop: '16px',
        display: 'flex',
        justifyContent: 'flex-end',
    },
}));


const BaylorEKGEntryPage: React.FC = () => {
    const [formData, setFormData] = useState<PatientData>({
        uid: null,
        ulname: '',
        ufname: '',
        uminitial: '',
        suffix: '',
        prefix: '',
        dob: '',
        sex: '',
        upPhone: '',
        umobileno: '',
        uemail: '',
        maritalstatus: 'Unknown',
        race: 'Declined to Specify',
        ethnicity: 'ASKU',
        upaddress: '',
        upaddress2: '',
        upcity: '',
        upstate: 'TX',
        zipcode: '',
        doctorId: 32320,
        refPrId: 0,
        rendPrId: 122,
        nostatements: 0,
        service_date: '',
        admit_date: '',
        discharge_date: ''
    });

    const [npiResponseData, setNpiResponseData] = useState<any>(null);


    const classes = useStyles();
    const [npi, setNpi] = useState('');
    const [npiData, setNpiData] = useState<any>(null);
    const [responseMessage, setResponseMessage] = useState<string | null>(null);
    const [responseStatus, setResponseStatus] = useState<'success' | 'error' | null>(null);
    const [expanded, setExpanded] = useState<boolean>(false);
    const [cptsByPatient, setCptsByPatient] = useState<CPTRowData[]>([]);
    const [selectedPatientId, setSelectedPatientId] = useState<any>(null);
    const gridCptsRef = useRef<any>(null);
    const [patientId, setPatientId] = useState<number | null>(null);
    const [encounterId, setEncounterId] = useState<number | null>(null);
    const [selectedPcp, setSelectedPcp] = useState<any>(null);

    const handleTextFieldChange00 = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };


    const getLocationByZipCode = async (zipCode: number) => {
        const url = `https://api.zippopotam.us/us/${zipCode}`;

        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            const city = data.places[0]['place name'];
            const state = data.places[0]['state abbreviation'];
            return { city, state };
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            return { city: '', state: '' };
        }
    };

    const formatDateString = (date: Date | string): string => {
        if (!date) return '';

        const d = new Date(date);
        if (isNaN(d.getTime())) return '';

        const month = (`0${d.getMonth() + 1}`).slice(-2);
        const day = (`0${d.getDate()}`).slice(-2);
        const year = d.getFullYear();
        return `${month}-${day}-${year}`;
    };

    const handleTextFieldChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;

        // Date validation regex
        const datePattern = /^\d{4}-\d{2}-\d{2}$/;

        setFormData((prevData) => ({
            ...prevData,
            [name]: name.includes('date') && !datePattern.test(value) ? '' : value,
        }));

        if (name === 'zipcode' && value.length === 5) {
            let upcity: any, upstate: any;
            ({upcity, upstate} = formData);

            //if (!upcity || !upcity) {
                const locationData = await getLocationByZipCode(Number(value));
                setFormData((prevData) => ({
                    ...prevData,
                    upcity:  locationData.city || upcity,
                    upstate: locationData.state || upstate
                }));
           // }
        }

        if (name === 'dob') {
            const formattedDate = formatDateString(value);
            setFormData((prevData) => ({
                ...prevData,
                dob: formattedDate,
            }));
        }
    };

    // upcity: upcity || locationData.city, upstate: upstate || locationData.state

    const handleCopyDate = () => {
        setFormData((prevData) => {
            const serviceDate = prevData.service_date;

            // Date validation regex
            const datePattern = /^\d{4}-\d{2}-\d{2}$/;

            // Validate the service date before copying
            if (!datePattern.test(serviceDate)) {
                return prevData;
            }

            return {
                ...prevData,
                admit_date: serviceDate,
                discharge_date: serviceDate,
            };
        });
    };

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const handleNpiChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNpi(event.target.value);
    };

    const handleNpiSubmit = async () => {
        try {
            const response = await axios.get(`https://app.apexheartcare.com:8044/ecwAddPcp?npi=${npi}`);
            setNpiData(response.data);
            setNpiResponseData(response.data); // Set the NPI response data
            setFormData((prevData) => ({
                ...prevData,
                doctorId: response.data.doctorid ? parseInt(response.data.doctorid, 10) : 122,
            }));
        } catch (error) {
            console.error('Error fetching NPI data', error);
            setNpiData(null);
        }
    };

    const getScalarFromResultSet = (response: AxiosResponse<any, any>) => {
        if (response.data) {
            const dframe0: string = response.data.frame0;
            const myObj0: any = dframe0 && JSON.parse(dframe0);
            const gridData0: any[] = myObj0 && myObj0.rows;

            const dframe1: string = response.data.frame1;
            const myObj1: any = dframe1 && JSON.parse(dframe1);
            const gridData1: any[] = myObj1 && myObj1.rows;

            const apexEncounterId = gridData0 && gridData0.length > 0 ? gridData0[0].apex_encounter_id : null;
            const patid = gridData1 && gridData1.length > 0 ? gridData1[0].patid : null;

            return { apexEncounterId, patid };
        }
        return null;
    };


    const getScalarFromResultSetOrig = (response: AxiosResponse<any, any>) => {
        if (response.data) {
            const dframe: string = response.data.frame0;
            const myObj: any = dframe && JSON.parse(dframe);
            const gridData: any[] = myObj && myObj.rows;
            return gridData && gridData.length > 0 ? gridData[0] : null;
        }
        return null;
    };

    const clearForm = () => {
        setFormData({
            uid: null,
            ulname: '',
            ufname: '',
            uminitial: '',
            suffix: '',
            prefix: '',
            dob: '',
            sex: '',
            upPhone: '',
            umobileno: '',
            uemail: '',
            maritalstatus: 'Unknown',
            race: 'Declined to Specify',
            ethnicity: 'ASKU',
            upaddress: '',
            upaddress2: '',
            upcity: '',
            upstate: 'TX',
            zipcode: '',
            doctorId: 32320,
            refPrId: 0,
            rendPrId: 122,
            nostatements: 0,
            service_date: '',
            admit_date: '',
            discharge_date: ''
        });
        setPatientId(null);
        setEncounterId(null);
        setResponseMessage(null);
        setResponseStatus(null);
    };


    const handleSavePatient = async () => {
        try {
            const apiURL = apiConfig.REACT_APEX_BASE_API;
            const authHeader = JSON.parse(localStorage.getItem('authHeader') || '{}');
            const urlPrefix = `${apiURL}/exsql?dbserver=`;
            const dataURL = `apex&sqltype=customSQL&sqltext= declare @patid int set @patid = null exec apex.rc.mnt_new_patient 
            @uid = @patid output ,
            @ulname = '${formData.ulname}',
            @ufname = '${formData.ufname}',
            @uminitial = '${formData.uminitial}',
            @suffix = '${formData.suffix}',
            @prefix = '${formData.prefix}',
            @dob = '${formData.dob}',
            @sex = '${formData.sex}',
            @upPhone = '${formData.upPhone}',
            @umobileno = '${formData.umobileno}',
            @uemail = '${formData.uemail}',
            @maritalstatus = '${formData.maritalstatus}',
            @race = '${formData.race}',
            @ethnicity = '${formData.ethnicity}',
            @upaddress = '${formData.upaddress}',
            @upaddress2 = '${formData.upaddress2}',
            @upcity = '${formData.upcity}',
            @upstate = '${formData.upstate}',
            @zipcode = '${formData.zipcode}',
            @doctorId = ${formData.doctorId},
            @refPrId = ${formData.refPrId},
            @rendPrId = ${formData.rendPrId},
            @nostatements = ${formData.nostatements};


            if exists (select 'x' from mobiledoc..users usr where uid = @patid) begin 
            exec rc.billsp_new_baylor_ekg_ecw_outoff_enc 
            @patient_id = @patid ,
            @service_date = '${formData.service_date}',
            @cptunits = 1,
            @ekg_mod1 = '',
            @argrefprid = ${formData.doctorId || 122},
            @admit_date = '${formData.admit_date}',
            @discharge_date = '${formData.discharge_date}'

            select @patid as patid
            end`;

            const response = await axios.get(urlPrefix + dataURL, {headers: authHeader});

            if (!response.data) {
                setResponseMessage('Failed to save patient.');
                setResponseStatus('error');
            } else {
                const result = getScalarFromResultSet(response);
                if (result) {
                    setPatientId(result.patid);
                    setEncounterId(result.apexEncounterId);
                    setResponseMessage(`Patient ID: ${result.patid}, Encounter ID: ${result.apexEncounterId}`);
                    setResponseStatus('success');
                } else {
                    setResponseMessage('Failed to save patient.');
                    setResponseStatus('error');
                }
            }
        } catch (error: any) {
            setResponseMessage(error.message || 'Failed to save patient.');
            setResponseStatus('error');
        }
    };

    const handlePatientSelection = (patient: any) => {
        setSelectedPatientId(patient ? patient : null);
        fetchCptsByPatient(patient?.PatientId);
    };

    const fetchCptsByPatient = async (patientId: number) => {
        const sqlText = `SELECT * FROM rc.ecw_cpts WHERE PatientId = ${patientId} ORDER BY dos DESC`;
        const authHeader = JSON.parse(localStorage.getItem('authHeader') || '{}');
        const urlPrefix = `${apiConfig.REACT_APEX_BASE_API}/exsql?dbserver=apex&sqltype=customSQL&sqltext=`;

        try {
            const response = await axios.get(`${urlPrefix}${sqlText}`, {headers: authHeader});

            if (response.data) {
                const dframe: string = response.data.frame0;
                const myObj = JSON.parse(dframe);
                const gridData = myObj.rows;
                setCptsByPatient(gridData);
            }
        } catch (error) {
            console.error('Error fetching CPTs by patient:', error);
        }
    };

    const dateFormatter = (params: { value: string }) => {
        const date = new Date(params.value);
        return date.toISOString().split('T')[0];
    };

    const clearSelectedPatient = () => {
        setSelectedPatientId(null);
    };

    const refreshEKG = async () => {
        const sqlText = `SELECT * FROM rc.ecw_cpts WHERE code = '93010' AND dos >= DATEADD(year, -1, GETDATE()) ORDER BY dos DESC`;
        const authHeader = JSON.parse(localStorage.getItem('authHeader') || '{}');
        const urlPrefix = `${apiConfig.REACT_APEX_BASE_API}/exsql?dbserver=apex&sqltype=customSQL&sqltext=`;

        try {
            const response = await axios.get(`${urlPrefix}${sqlText}`, { headers: authHeader });

            if (response.data) {
                const dframe: string = response.data.frame0;
                const myObj = JSON.parse(dframe);
                const gridData = myObj.rows;
                setCptsByPatient(gridData);
            }
        } catch (error) {
            console.error('Error fetching CPTs for EKG:', error);
        }
    };

    const getCityNameByZipCode = (zipCode: number) => {
        const url = `https://api.zippopotam.us/us/${zipCode}`;

        fetch(url)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                const city = data.places[0]['place name'];
                console.log(data.places[0], `City: ${city}`);
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error);
            });
    }

    const handleDateChange = (date: Date | null) => {
        if (date) {
            const formattedDate = date.toISOString().split('T')[0]; // Format the date as YYYY-MM-DD
            setFormData((prevData) => ({
                ...prevData,
                dob: formattedDate,
            }));
        }
    };


    return (
            <SplitPanel split="horizontal">
                <Box style={{ flex: 1, height: '100%', overflowY: 'auto' }}>
                    <SelectEcwPatient selectedEcwPatient={selectedPatientId} setSelectedEcwPatient={handlePatientSelection} />
                    {selectedPatientId && (
                        <IconButton onClick={clearSelectedPatient}>
                            <ClearIcon />
                        </IconButton>
                    )}
                    <AppBar position="static" style={{ marginBottom: 8 }}>
                        <Box display="flex" justifyContent="space-between" alignItems="center" p={1}>
                            <Box display="flex" alignItems="center">
                                <Button color="inherit" onClick={refreshEKG}>
                                    Refresh EKG
                                </Button>
                                <Button color="inherit" onClick={() => fetchCptsByPatient(selectedPatientId?.PatientId)}>
                                    Refresh
                                </Button>
                            </Box>
                            <Typography>Patient CPTs</Typography>
                        </Box>
                    </AppBar>
                    <div className="ag-theme-alpine" style={{ height: '100%' }}>
                        <AgGridReact
                            ref={gridCptsRef}
                            rowData={cptsByPatient}
                            rowSelection="single"
                            defaultColDef={{ sortable: true, filter: true, resizable: true }}
                            columnDefs={[
                                { headerName: 'CPT ID', field: 'cpt_id', width: 120 },
                                { headerName: 'patient', field: 'patientname', width: 120 },
                                { headerName: 'patid', field: 'PatientId', width: 120 },
                                { headerName: 'Service Date', field: 'dos', width: 150, valueFormatter: dateFormatter },
                                { headerName: 'Paid', field: 'Paid', width: 150 },
                                { headerName: 'Balance', field: 'cptBalance', width: 150 },
                                { headerName: 'Invoice Amount', field: 'InvoiceAmount', width: 150 },
                                { headerName: 'Allowed Fee', field: 'cptAllowedFee', width: 150 },
                                { headerName: 'CPT Description', field: 'cptdesc', width: 200 },
                            ]}
                            gridOptions={{
                                suppressRowHoverHighlight: true,
                                suppressColumnVirtualisation: true,
                                rowHeight: 25,
                                headerHeight: 20,
                            }}
                        />
                    </div>
                </Box>
                <Box style={{ flex: 1, height: '100%', overflowY: 'auto', padding: 16 }}>
                    <form id="new-patient-form" className={classes.formContainer}>
                        {/*<Box className={classes.formField}>*/}
                        {/*    <TextField*/}
                        {/*        label="NPI Number"*/}
                        {/*        name="npi"*/}
                        {/*        variant="outlined"*/}
                        {/*        fullWidth*/}
                        {/*        value={npi}*/}
                        {/*        onChange={handleNpiChange}*/}
                        {/*    />*/}
                        {/*    <Button onClick={handleNpiSubmit} color="primary" variant="contained">Add2eCW</Button>*/}
                        {/*</Box>*/}
                        <Box className={classes.formField}>
                            <FormLabel>PCP: </FormLabel>
                            <SelectEcwPcp selectedPcp={selectedPcp} setSelectedPcp={setSelectedPcp} />
                            {selectedPcp && (
                                <IconButton onClick={() => setSelectedPcp(null)}>
                                    <ClearIcon />
                                </IconButton>
                            )}
                        </Box>
                        {npiResponseData && (
                            <Box style={{ marginTop: '1px', padding: '1px', backgroundColor: '#e0f7fa', borderRadius: '2px', display: 'flex', flexWrap: 'wrap', gap: '1px' }}>
                                {Object.entries(npiResponseData).map(([key, value]) => (
                                    <Box key={key} style={{ display: 'flex', flexDirection: 'column', minWidth: '00px' }}>
                                        <Typography variant="body2" style={{ fontWeight: 'bold', color: '#00796b' }}>
                                            {key}:
                                        </Typography>
                                        <Typography variant="body2" style={{ color: '#00796b' }}>
                                            {String(value)}
                                        </Typography>
                                    </Box>
                                ))}
                            </Box>
                        )}

                        { (
                            <>
                                <Box className={classes.formFieldRow}>
                                    <TextField
                                        label="Last Name"
                                        name="ulname"
                                        variant="outlined"
                                        fullWidth
                                        value={formData.ulname}
                                        onChange={handleTextFieldChange}
                                        size="small"
                                    />
                                    <TextField
                                        label="First Name"
                                        name="ufname"
                                        variant="outlined"
                                        fullWidth
                                        value={formData.ufname}
                                        onChange={handleTextFieldChange}
                                        size="small"
                                    />
                                    <TextField
                                        label="Middle Initial"
                                        name="uminitial"
                                        variant="outlined"
                                        fullWidth
                                        value={formData.uminitial}
                                        onChange={handleTextFieldChange}
                                        size="small"
                                    />
                                    <Box className={classes.formFieldRow}>
                                        <FormControl component="fieldset" style={{ flex: 1 }}>
                                            <RadioGroup
                                                name="sex"
                                                value={formData.sex}
                                                onChange={handleRadioChange}
                                                row
                                            >
                                                <FormControlLabel value="male" control={<Radio />} label="M" />
                                                <FormControlLabel value="female" control={<Radio />} label="F" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Box>
                                    <TextField
                                        label="Date of Birth"
                                        name="dob"
                                        variant="outlined"
                                        fullWidth
                                        value={formData.dob}
                                        onChange={handleTextFieldChange}
                                        placeholder="MM-DD-YYYY"
                                        size="small"
                                    />
                                </Box>
                                <Box className={classes.formFieldRow}>
                                    <TextField
                                        label="Phone"
                                        name="upPhone"
                                        variant="outlined"
                                        fullWidth
                                        value={formData.upPhone}
                                        onChange={handleTextFieldChange}
                                        size="small"
                                    />
                                    <TextField
                                        label="Mobile Number"
                                        name="umobileno"
                                        variant="outlined"
                                        fullWidth
                                        value={formData.umobileno}
                                        onChange={handleTextFieldChange}
                                        size="small"
                                    />
                                    <TextField
                                        label="Email"
                                        name="uemail"
                                        variant="outlined"
                                        fullWidth
                                        value={formData.uemail}
                                        onChange={handleTextFieldChange}
                                        size="small"
                                    />
                                </Box>
                                <Box className={classes.formFieldRow}>
                                    <TextField
                                        label="Address"
                                        name="upaddress"
                                        variant="outlined"
                                        fullWidth
                                        value={formData.upaddress}
                                        onChange={handleTextFieldChange}
                                        size="small"
                                    />
                                    <TextField
                                        label="Zip Code"
                                        name="zipcode"
                                        variant="outlined"
                                        fullWidth
                                        value={formData.zipcode}
                                        onChange={handleTextFieldChange}
                                        size="small"
                                        style={{ maxWidth: '120px', padding: '0 2px' }}
                                    />
                                    <TextField
                                        label="City"
                                        name="upcity"
                                        variant="outlined"
                                        fullWidth
                                        value={formData.upcity}
                                        onChange={handleTextFieldChange}
                                        size="small"
                                        style={{ maxWidth: '180px', padding: '0 2px' }}
                                    />
                                    <TextField
                                        label="State"
                                        name="upstate"
                                        variant="outlined"
                                        fullWidth
                                        value={formData.upstate}
                                        onChange={handleTextFieldChange}
                                        size="small"
                                        style={{ maxWidth: '50px', padding: '0 2px' }}
                                    />
                                    <TextField
                                        label="Addr Line2"
                                        name="upaddress2"
                                        variant="outlined"
                                        fullWidth
                                        value={formData.upaddress2}
                                        onChange={handleTextFieldChange}
                                        size="small"
                                        style={{ maxWidth: '120px', padding: '0 2px' }}
                                    />
                                </Box>
                                <Box className={classes.formFieldRow}>
                                    <TextField
                                        label="Service Date"
                                        name="service_date"
                                        type="date"
                                        variant="outlined"
                                        fullWidth
                                        value={formData.service_date}
                                        onChange={handleTextFieldChange}
                                        InputLabelProps={{ shrink: true }}
                                        size="small"
                                    />
                                    <Button
                                        onClick={handleCopyDate}
                                        color="primary"
                                        variant="contained"
                                        style={{ maxWidth: '4px', padding: '0 2px' }}
                                    >
                                        ==
                                    </Button>
                                    <TextField
                                        label="Admit Date"
                                        name="admit_date"
                                        type="date"
                                        variant="outlined"
                                        fullWidth
                                        value={formData.admit_date}
                                        onChange={handleTextFieldChange}
                                        InputLabelProps={{ shrink: true }}
                                        size="small"
                                    />
                                    <TextField
                                        label="Discharge Date"
                                        name="discharge_date"
                                        type="date"
                                        variant="outlined"
                                        fullWidth
                                        value={formData.discharge_date}
                                        onChange={handleTextFieldChange}
                                        InputLabelProps={{ shrink: true }}
                                        size="small"
                                    />
                                </Box>
                            </>
                        )}

                        <Box display="flex" alignItems="center" className={classes.saveButton}>
                            <Box display="flex" alignItems="center" mr={2}>
                                {responseStatus === 'success' && (
                                    <>
                                        <CheckIcon style={{ color: 'green', marginRight: '8px' }} />
                                        <Typography variant="body1" style={{ color: 'green' }}>
                                            {`Patient ID: ${patientId}, Encounter ID: ${encounterId}`}
                                        </Typography>
                                    </>
                                )}
                            </Box>
                            <Button onClick={clearForm} color="secondary" variant="contained" style={{ marginRight: '8px' }}>
                                Clear Form
                            </Button>
                            <Button onClick={handleSavePatient} color="primary" variant="contained">
                                Save Patient
                            </Button>
                        </Box>

                        <Card className={classes.card}>
                            <CardHeader
                                title="Additional Information"
                                action={
                                    <IconButton onClick={handleExpandClick}>
                                        {expanded ? <ExpandLess /> : <ExpandMore />}
                                    </IconButton>
                                }
                                className={classes.cardHeader}
                                onClick={handleExpandClick}
                            />
                            <Collapse in={expanded} timeout="auto" unmountOnExit>
                                <CardContent className={classes.cardContent}>
                                    <Box className={classes.formFieldRow}>
                                        <FormControl component="fieldset" style={{ flex: 1 }}>
                                            <FormLabel component="legend">Marital Status</FormLabel>
                                            <RadioGroup
                                                name="maritalstatus"
                                                value={formData.maritalstatus || ''}
                                                onChange={handleRadioChange}
                                                row
                                            >
                                                {['Unknown', 'Married', 'Single', 'Widowed', 'Divorced', 'Partner'].map(option => (
                                                    <FormControlLabel key={option} value={option} control={<Radio />} label={option} />
                                                ))}
                                            </RadioGroup>
                                        </FormControl>
                                        <FormControl component="fieldset" style={{ flex: 1 }}>
                                            <FormLabel component="legend">Race</FormLabel>
                                            <RadioGroup
                                                name="race"
                                                value={formData.race || ''}
                                                onChange={handleRadioChange}
                                                row
                                            >
                                                {['Declined to Specify', 'White', 'Black', 'African American', 'American Indian or Alaska Native', 'Asian', 'Asian Indian', 'Black or African American', 'English', 'Hispanic', 'Native Hawaiian', 'Native Hawaiian or Other Pacific Islander', 'Other Pacific Islander', 'Other Race', 'Refused to Report', 'Spanish American Indian', 'Vietnamese'].map(option => (
                                                    <FormControlLabel key={option} value={option} control={<Radio />} label={option} />
                                                ))}
                                            </RadioGroup>
                                        </FormControl>
                                        <FormControl component="fieldset" style={{ flex: 1 }}>
                                            <FormLabel component="legend">Ethnicity</FormLabel>
                                            <RadioGroup
                                                name="ethnicity"
                                                value={formData.ethnicity || ''}
                                                onChange={handleRadioChange}
                                                row
                                            >
                                                <FormControlLabel value="ASKU" control={<Radio />} label="Declined to Specify" />
                                                <FormControlLabel value="2135-2" control={<Radio />} label="Hispanic or Latino" />
                                                <FormControlLabel value="2186-5" control={<Radio />} label="Not Hispanic or Latino" />
                                                <FormControlLabel value="2145-2" control={<Radio />} label="Refused to Report" />
                                            </RadioGroup>
                                        </FormControl>
                                        <Box className={classes.formFieldRow}>
                                            <TextField
                                                label="Suffix"
                                                name="suffix"
                                                variant="outlined"
                                                fullWidth
                                                value={formData.suffix}
                                                onChange={handleTextFieldChange}
                                                size="small"
                                            />
                                            <TextField
                                                label="Prefix"
                                                name="prefix"
                                                variant="outlined"
                                                fullWidth
                                                value={formData.prefix}
                                                onChange={handleTextFieldChange}
                                                size="small"
                                            />

                                        </Box>
                                    </Box>
                                </CardContent>
                            </Collapse>
                        </Card>


                        {/*{responseMessage && (*/}
                        {/*    <Box display="flex" justifyContent="center" alignItems="center" p={2}>*/}
                        {/*        <Typography*/}
                        {/*            variant="body1"*/}
                        {/*            className={responseStatus === 'success' ? classes.responseMessage : classes.errorMessage}*/}
                        {/*        >*/}
                        {/*            {responseMessage}*/}
                        {/*        </Typography>*/}
                        {/*    </Box>*/}
                        {/*)}*/}



                    </form>
                </Box>
            </SplitPanel>
        );
    }

    export default BaylorEKGEntryPage;

