import React from 'react';
import { CircularProgress } from '@mui/material';

// include stylesheets here as loadingscreen maybe displayed
// before themewrapper is injected
import '../css/variables.css';
import '../css/app.css';

interface LoadingScreenProps {
    determinate?: boolean;
    error?: boolean;
    msg?: string;
    percentComplete?: number;
}

const LoadingScreen: React.FC<LoadingScreenProps> = ({
                                                         determinate = false,
                                                         error = false,
                                                         msg = 'Loading ...',
                                                         percentComplete = 0,
                                                     }) => {
    return (
        <div className="omv-centered">
            <CircularProgress value={percentComplete} variant={determinate ? 'determinate' : 'indeterminate'} />
            {determinate && (
                <span style={{ position: 'relative', left: -31, top: -15, fontSize: '9pt' }}>
          {percentComplete}%
        </span>
            )}
            <br />
            {msg || 'Loading ...'}
        </div>
    );
};

export default LoadingScreen;
