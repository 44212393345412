'use strict';

import React, {
    useCallback,
    useMemo,
    useRef,
    useState,
    StrictMode,
} from 'react';
import { createRoot } from 'react-dom/client';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import axios from "axios";
import apiConfig from "../apiConfig";

const GridExample = () => {
    let apiURL = apiConfig.REACT_APEX_BASE_API;
    const gridRef = useRef();
    const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const [rowData, setRowData] = useState();
    const [claimsData, setClaimsData] = useState([]);
    const [columnDefs, setColumnDefs] = useState([
        // group cell renderer needed for expand / collapse icons
        { field: 'name', cellRenderer: 'agGroupCellRenderer' },
        { field: 'account' },
        { field: 'calls' },
        { field: 'minutes', valueFormatter: "x.toLocaleString() + 'm'" },
    ]);
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
        };
    }, []);
    const detailCellRendererParams = useMemo(() => {
        return {
            detailGridOptions: {
                rowSelection: 'multiple',
                suppressRowClickSelection: true,
                enableRangeSelection: true,
                pagination: true,
                paginationAutoPageSize: true,
                columnDefs: [
                    { field: 'callId', checkboxSelection: true },
                    { field: 'direction' },
                    { field: 'number', minWidth: 150 },
                    { field: 'duration', valueFormatter: "x.toLocaleString() + 's'" },
                    { field: 'switchCode', minWidth: 150 },
                ],
                defaultColDef: {
                    sortable: true,
                    flex: 1,
                },
            },
            getDetailRowData: (params) => {
                params.successCallback(params.data.callRecords);
            },
        };
    }, []);

    const onFirstDataRendered = useCallback((params) => {
        // arbitrarily expand a row for presentational purposes
        setTimeout(function () {
            gridRef.current.api.getDisplayedRowAtIndex(1).setExpanded(true);
        }, 0);
    }, []);

    const refreshClaimsData = async () => {
        let authHeader = {};

        const authH = JSON.parse(localStorage.getItem('authHeader'));
        if (authH) {
            authHeader =  authH;
        }

        let urlPrefix = apiURL + '/exsql?dbserver=';
        let dataURL = `apex&sqltype=customSQL&sqltext=set rowcount 0   select * from rc.billing_apex_enc_queue_current order by visit_dos  `;

        const res2 = await axios.get(urlPrefix + dataURL, authHeader);
        if (res2.data) {
            console.log("GOT Billing Files:", res2.data);
            let dframe = res2.data['frame0'];
            //console.log(dframe);
            let myObj = JSON.parse(dframe);
            console.log(myObj);
            let gridData = myObj['rows'];

            setClaimsData(gridData);
            console.log("got claims:", gridData);
        }

    };

    React.useEffect(() => {
        refreshClaimsData();
    }, []);

    return (
        <div style={containerStyle}>
            <div style={gridStyle} className="ag-theme-alpine">
                <AgGridReact
                    ref={gridRef}
                    rowData={claimsData}
                    columnDefs={{
                        field: 'column_id'
                    }}
                    defaultColDef={defaultColDef}
                    masterDetail={true}
                    detailCellRendererParams={detailCellRendererParams}
                    onFirstDataRendered={onFirstDataRendered}
                />
            </div>
        </div>
    );
};

export default GridExample;