import React, { useState, useEffect, useRef } from 'react';
import {
    Button,
    Box,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    Collapse,
    Typography,
    Switch,
    Checkbox,
    FormControl,
    FormGroup,
    TextField,
    Toolbar, AppBar, IconButton, Card, CardContent, CircularProgress
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import DescriptionIcon from '@mui/icons-material/Description';
import RefreshIcon from "@mui/icons-material/Refresh";
import CloseIcon from '@mui/icons-material/Close';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import SplitPanel from "../../Components/splitpage/splitpanel";
import ApexPdfjsViewer from "../../Components/ApexPdfjsViewer";
import apiConfig from "../../apiConfig";
import axios from "axios";
import { handleAgDocument } from "../../utils/ecw/hadleDocuments";

interface PaymentsRowData {
    paymentid?: number;
    payorname?: string;
    bank_tran_id?: number;
    macct_tran_id?: number;
    spmt_id?: number;
    ispaymentposted?: number;
    ispaymentcached?: number;
    pmtclosed?: number;
    verified_by?: string;
    verified_on?: string;
    payortype?: number;
    payorid?: number;
    amount?: number;
    checktotalamt?: number;
    unpostedamount?: number;
    checkno?: string;
    pmttype?: string;
    pmtdate?: string;
    checkdate?: string;
    pmtmemo?: string;
    postedby?: number;
    postedon?: string;
    modifieddate?: string;
    facilityid?: number;
    paymentlock?: number;
    lockedby?: number;
    deleteflag?: number;
    eradetailid?: number;
    encid?: number;
    pmtnotes?: string;
    scancheckamount: number;
    scancardamount: number;
}

interface BankRowData {
    tran_id?: number;
    importsource?: string;
    importsourceref?: string;
    importnotes?: string;
    sourceAccount?: string;
    destAccount?: string;
    sourceAccount_id?: number;
    destAccount_id?: number;
    trangrpparent_id?: number;
    trantype?: number;
    trandate?: string;
    tranamount?: number;
    debit_credit?: string;
    description?: string;
    trannotes?: string;
    currencyname?: string;
    reconcilestatus?: string;
    trancompleted?: string;
    ecwpayment_id?: number;
    payer_desc?: string;
    ecwpayer_id?: number;
    vendor_desc?: string;
    transource?: string;
    transource_id?: number;
    fireflyref?: string;
    fireflyreftj_id?: number;
    createdat?: string;
    updatedat?: string;
    deletedat?: string;
}

interface MerchantRowData {
    row_id?: number;
    sourceaccount?: string;
    TransactionID?: number;
    TransactionType?: string;
    status?: string;
    CardNumber?: string;
    Amount?: number;
    SubmittedTime?: string;
    InvoiceNumber?: string;
    PONumber?: string;
    ClerkID?: string;
    PaymentType?: string;
    CardBrand?: string;
    TerminalID?: number;
    TerminalName?: string;
    bankdeposit_id?: number;
    ecwpayment_id?: number;
    scannedpayment_id?: number;
    reconcilestatus?: string;
    created_at?: string;
    updated_at?: string;
}

interface ScannedRowData {
    spmt_id?: number;
    ecwdoc_id?: number;
    pageno?: number;
    docpath?: string;
    pagedetail?: string;
    checkamount?: number;
    checkdate?: string;
    checknumber?: string;
    cardamount?: number;
    otheramount?: number;
    otheramount2?: number;
    ecwpayment_id?: number;
    bankdeposit_id?: number;
    pmtpatientid?: number;
    pmtpatientname?: string;
    reconcilestatus?: string;
    customFilename?: string;
    ecwFileName?: string;
    depositbatch?: string;
    ispaymentcached?: number;
    expiredpayment?: number;
    replacementremarks?: string;
    paymentnotes?: string;
    reference1?: string;
    reference2?: string;
    enteredby?: string;
    created_at?: string;
    updated_at?: string;
    deleteflag?: number;
}

const ReviewByPayment: React.FC = () => {
    const [payments, setPayments] = useState<PaymentsRowData[]>([]);
    const [filteredPayments, setFilteredPayments] = useState<PaymentsRowData[]>([]);
    const [selectedPayment, setSelectedPayment] = useState<PaymentsRowData | null>(null);
    const [isCached, setIsCached] = useState(false);

    const [bankPayments, setBankPayments] = useState<BankRowData[]>([]);
    const [filteredBankPayments, setFilteredBankPayments] = useState<BankRowData[]>([]);
    const [selectedBankPayment, setSelectedBankPayment] = useState<BankRowData | null>(null);

    const [merchantPayments, setMerchantPayments] = useState<MerchantRowData[]>([]);
    const [filteredMerchantPayments, setFilteredMerchantPayments] = useState<MerchantRowData[]>([]);
    const [selectedMerchantPayment, setSelectedMerchantPayment] = useState<MerchantRowData | null>(null);

    const [scannedPayments, setScannedPayments] = useState<ScannedRowData[]>([]);
    const [filteredScannedPayments, setFilteredScannedPayments] = useState<ScannedRowData[]>([]);
    const [selectedScannedPayment, setSelectedScannedPayment] = useState<ScannedRowData | null>(null);

    const [currentPageNum, setCurrentPageNum] = useState<number>();
    const [pdfUrl, setPdfUrl] = useState('');
    const [criteria, setCriteria] = useState('all');
    const [collapse, setCollapse] = useState(false);
    const [showPdfViewer, setShowPdfViewer] = useState(false);
    const [showBank, setShowBank] = useState(true);
    const [showMerchant, setShowMerchant] = useState(true);
    const [showScanned, setShowScanned] = useState(true);
    const [showAll, setShowAll] = useState(true);
    const [showUnmatchedOnly, setShowUnmatchedOnly] = useState(true);
    const [startDate, setStartDate] = useState<string | null>(null);
    const [endDate, setEndDate] = useState<string | null>(null);
    const [viewMode, setViewMode] = useState<'grid' | 'suggestions'>('grid');
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(true);

    const gridPaymentsRef = useRef<any>(null);
    const gridBankRef = useRef<any>(null);
    const gridMerchRef = useRef<any>(null);
    const gridScanRef = useRef<any>(null);
    const apiURL = apiConfig.REACT_APEX_BASE_API;

    useEffect(() => {
        fetchPayments();
        fetchBankPayments();
        fetchMerchantPayments();
        fetchScannedPayments();
    }, [startDate, endDate]);

    useEffect(() => {
        applyFilterToGrids();
    }, [showUnmatchedOnly, payments, bankPayments, merchantPayments, scannedPayments]);

    useEffect(() => {
        if (viewMode === 'suggestions') {
            loadMoreSuggestions();
        }
    }, [page]);

    const fetchPayments = async (paymentId?: number) => {
        const sqlText = paymentId
            ? `SELECT * FROM rc.apexecwpayments WHERE paymentid = ${paymentId}`
            : generatePaymentSqlText();
        const authHeader = JSON.parse(localStorage.getItem('authHeader') || '{}');
        const urlPrefix = `${apiConfig.REACT_APEX_BASE_API}/exsql?dbserver=apex&sqltype=customSQL&sqltext=`;

        try {
            const response = await axios.get(`${urlPrefix}${sqlText}`, { headers: authHeader });

            if (response.data) {
                const dframe: string = response.data.frame0;
                const myObj = JSON.parse(dframe);
                const gridData = myObj.rows;
                if (paymentId) {
                    const updatedPayments = payments.map(payment => payment.paymentid === paymentId ? gridData[0] : payment);
                    setPayments(updatedPayments);
                    setFilteredPayments(updatedPayments);
                    gridPaymentsRef.current.api.applyTransaction({ update: [gridData[0]] });
                } else {
                    setPayments(gridData);
                    setFilteredPayments(gridData);
                }
            }
        } catch (error) {
            console.error('Error fetching payments:', error);
        }
    };

    const generatePaymentSqlText = () => {
        let sqlText = `SELECT * FROM rc.apexecwpayments WHERE pmtdate > DATEADD(yy, -2, GETDATE())`;

        if (startDate) {
            sqlText += ` AND pmtdate >= '${startDate}'`;
        }
        if (endDate) {
            sqlText += ` AND pmtdate <= '${endDate}'`;
        }
        sqlText += ` ORDER BY paymentid DESC`;
        return sqlText;
    };

    const fetchBankPayments = async (tranId?: number) => {
        const sqlText = tranId
            ? `SELECT * FROM rc.ApexBankTransaction WHERE tran_id = ${tranId}`
            : `SELECT * FROM rc.ApexBankTransaction ORDER BY tran_id DESC`;
        const authHeader = JSON.parse(localStorage.getItem('authHeader') || '{}');
        const urlPrefix = `${apiConfig.REACT_APEX_BASE_API}/exsql?dbserver=apex&sqltype=customSQL&sqltext=`;

        try {
            const response = await axios.get(`${urlPrefix}${sqlText}`, { headers: authHeader });

            if (response.data) {
                const dframe: string = response.data.frame0;
                const myObj = JSON.parse(dframe);
                const gridData = myObj.rows;
                if (tranId) {
                    const updatedBankPayments = bankPayments.map(payment => payment.tran_id === tranId ? gridData[0] : payment);
                    setBankPayments(updatedBankPayments);
                    setFilteredBankPayments(updatedBankPayments);
                    gridBankRef.current.api.applyTransaction({ update: [gridData[0]] });
                } else {
                    setBankPayments(gridData);
                    setFilteredBankPayments(gridData);
                }
            }
        } catch (error) {
            console.error('Error fetching bank payments:', error);
        }
    };

    const fetchMerchantPayments = async (rowId?: number) => {
        const sqlText = rowId
            ? `SELECT * FROM rc.MerchantTransactions WHERE row_id = ${rowId}`
            : `SELECT * FROM rc.MerchantTransactions ORDER BY row_id DESC`;
        const authHeader = JSON.parse(localStorage.getItem('authHeader') || '{}');
        const urlPrefix = `${apiConfig.REACT_APEX_BASE_API}/exsql?dbserver=apex&sqltype=customSQL&sqltext=`;

        try {
            const response = await axios.get(`${urlPrefix}${sqlText}`, { headers: authHeader });

            if (response.data) {
                const dframe: string = response.data.frame0;
                const myObj = JSON.parse(dframe);
                const gridData = myObj.rows;
                if (rowId) {
                    const updatedMerchantPayments = merchantPayments.map(payment => payment.row_id === rowId ? gridData[0] : payment);
                    setMerchantPayments(updatedMerchantPayments);
                    setFilteredMerchantPayments(updatedMerchantPayments);
                    gridMerchRef.current.api.applyTransaction({ update: [gridData[0]] });

                } else {
                    setMerchantPayments(gridData);
                    setFilteredMerchantPayments(gridData);
                }
            }
        } catch (error) {
            console.error('Error fetching merchant payments:', error);
        }
    };

    const fetchScannedPayments = async (spmtId?: number) => {
        const sqlText = spmtId
            ? `SELECT apex.dbo.ecwdocumentpath(ecwdoc_id) as docpath, * FROM rc.ApexScannedPayment WHERE spmt_id = ${spmtId}`
            : `SELECT apex.dbo.ecwdocumentpath(ecwdoc_id) as docpath, * FROM rc.ApexScannedPayment ORDER BY spmt_id DESC`;
        const authHeader = JSON.parse(localStorage.getItem('authHeader') || '{}');
        const urlPrefix = `${apiConfig.REACT_APEX_BASE_API}/exsql?dbserver=apex&sqltype=customSQL&sqltext=`;

        try {
            const response = await axios.get(`${urlPrefix}${sqlText}`, { headers: authHeader });

            if (response.data) {
                const dframe: string = response.data.frame0;
                const myObj = JSON.parse(dframe);
                const gridData = myObj.rows;
                if (spmtId) {
                    const updatedScannedPayments = scannedPayments.map(payment => payment.spmt_id === spmtId ? gridData[0] : payment);
                    setScannedPayments(updatedScannedPayments);
                    setFilteredScannedPayments(updatedScannedPayments);
                    gridScanRef.current.api.applyTransaction({ update: [gridData[0]] });
                } else {
                    setScannedPayments(gridData);
                    setFilteredScannedPayments(gridData);
                }
            }
        } catch (error) {
            console.error('Error fetching scanned payments:', error);
        }
    };

    const handleeCWPaymentSelectionChange = (params: any) => {
        const selectedNode = params.api.getSelectedNodes()[0];
        console.log("Selected payment row: ", selectedNode ? selectedNode.data : '');
        setSelectedPayment(selectedNode ? selectedNode.data : null);
        if (selectedNode) {
            filterOtherGrids(selectedNode.data.amount);
            setIsCached(selectedNode.data.ispaymentcached === 1);
        }
    };

    const handleScannedPmtSelectionChange = (params: any) => {
        const selectedNode = params.api.getSelectedNodes()[0];
        console.log("Selected payment row: ", selectedNode ? selectedNode.data : '');
        setSelectedScannedPayment(selectedNode ? selectedNode.data : null);
        if (selectedNode) {
            filterOtherGrids(selectedNode.data.amount);
        }
    };

    const filterOtherGrids = (amount: number | undefined) => {
        if (amount !== undefined) {
            setFilteredBankPayments(bankPayments.filter(payment => payment.tranamount === amount));
            setFilteredMerchantPayments(merchantPayments.filter(payment => payment.Amount === amount));
            setFilteredScannedPayments(scannedPayments.filter(payment => payment.checkamount === amount || payment.cardamount === amount));
        }
    };

    const handleCriteriaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCriteria(event.target.value);
        applyFilter(event.target.value);
    };

    const applyFilter = (criteria: string) => {
        // if (criteria === 'checks') {
        //     setFilteredPayments(payments.filter(payment => payment.checkamount && payment.checkamount > 0));
        // } else if (criteria === 'creditcards') {
        //     setFilteredPayments(payments.filter(payment => payment.cardamount && payment.cardamount > 0));
        // } else {
        //     setFilteredPayments(payments);
        // }
    };

    const handleSelectAll = () => {
        gridPaymentsRef.current.api.selectAll();
    };

    const handleSelectNone = () => {
        gridPaymentsRef.current.api.deselectAll();
    };

    const onSetCurrentPageNum = (pageNum: number) => {
        setCurrentPageNum(pageNum);
    };

    const onSetpdfToDisplayURL = (args: React.SetStateAction<string>) => {
        setPdfUrl(args);
    };

    const ShowDocument = (pagenum: number, docpath: string) => {
        handleAgDocument({
            filename: docpath,
            dirpath: '',
            startpage: pagenum
        }, apiURL, onSetpdfToDisplayURL, setCurrentPageNum);
    };

    const dateFormatter = (params: { value: string }) => {
        const date = new Date(params.value);
        return date.toISOString().split('T')[0];
    };

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;
        if (name === 'showBank') {
            setShowBank(checked);
        } else if (name === 'showMerchant') {
            setShowMerchant(checked);
        } else if (name === 'showScanned') {
            setShowScanned(checked);
        } else if (name === 'showAll') {
            setShowAll(checked);
            if (checked) {
                setShowBank(true);
                setShowMerchant(true);
                setShowScanned(true);
            } else {
                setShowBank(false);
                setShowMerchant(false);
                setShowScanned(false);
            }
        }
    };

    const handleMatch = async (paymentId: number, type: 'Bank' | 'Merchant' | 'Scanned', data: any, op: 'edit' | 'delete') => {
        const params = {
            ecwpmt_id: paymentId,
            bank_tran_id: type === 'Bank' ? data.tran_id : null,
            macct_tran_id: type === 'Merchant' ? data.row_id : null,
            spmt_id: type === 'Scanned' ? data.spmt_id : null,
            arg_op: op
        };

        try {
            const authHeader = JSON.parse(localStorage.getItem('authHeader') || '{}');
            const urlPrefix = `${apiURL}/exsql?dbserver=apex&sqltype=customSQL&sqltext=`;
            const sqlText = generateSqlText(params);
            const response = await axios.get(`${urlPrefix}${sqlText}`, { headers: authHeader });

            if (response.data) {
                updateRowsAfterMatch(paymentId, type, data, op);

                // Fetch specific rows after matching
                fetchPayments(paymentId);
                if (type === 'Bank') fetchBankPayments(data.tran_id);
                if (type === 'Merchant') fetchMerchantPayments(data.row_id);
                if (type === 'Scanned') fetchScannedPayments(data.spmt_id);
            }
        } catch (error) {
            console.error('Error matching payment:', error);
        }
    };

    const generateSqlText = (params: any) => {
        return `exec rc.mnt_ecwpayments
            @arg_op = '${params.arg_op}',
            @ecwpmt_id = ${params.ecwpmt_id},
            @bank_tran_id = ${params.bank_tran_id},
            @macct_tran_id = ${params.macct_tran_id},
            @spmt_id = ${params.spmt_id}`;
    };

    const updateRowsAfterMatch = (paymentId: number, type: 'Bank' | 'Merchant' | 'Scanned', data: any, op: 'edit' | 'delete') => {
        const updatedPayments = payments.map(payment => {
            if (payment.paymentid === paymentId) {
                if (type === 'Bank') payment.bank_tran_id = op === 'edit' ? data.tran_id : null;
                if (type === 'Merchant') payment.macct_tran_id = op === 'edit' ? data.row_id : null;
                if (type === 'Scanned') payment.spmt_id = op === 'edit' ? data.spmt_id : null;
            }
            return payment;
        });

        setPayments(updatedPayments);
        setFilteredPayments(updatedPayments);

        if (type === 'Bank') {
            const updatedBankPayments = bankPayments.map(payment => {
                if (payment.tran_id === data.tran_id && op === 'edit') {
                    payment.ecwpayment_id =  paymentId ;
                }
                return payment;
            });
            setBankPayments(updatedBankPayments);
            setFilteredBankPayments(updatedBankPayments);
        }

        if (type === 'Merchant') {
            const updatedMerchantPayments = merchantPayments.map(payment => {
                if (payment.row_id === data.row_id && op === 'edit') {
                    payment.ecwpayment_id =  paymentId ;
                }
                return payment;
            });
            setMerchantPayments(updatedMerchantPayments);
            setFilteredMerchantPayments(updatedMerchantPayments);
        }

        if (type === 'Scanned') {
            const updatedScannedPayments = scannedPayments.map(payment => {
                if (payment.spmt_id === data.spmt_id && op === 'edit') {
                    payment.ecwpayment_id =  paymentId ;
                }
                return payment;
            });
            setScannedPayments(updatedScannedPayments);
            setFilteredScannedPayments(updatedScannedPayments);
        }
    };

    const applyFilterToGrids = () => {
        if (showUnmatchedOnly) {
            setFilteredPayments(payments.filter(payment => !payment.bank_tran_id && !payment.macct_tran_id && !payment.spmt_id));
            setFilteredBankPayments(bankPayments.filter(payment => !payment.ecwpayment_id));
            setFilteredMerchantPayments(merchantPayments.filter(payment => !payment.ecwpayment_id));
            setFilteredScannedPayments(scannedPayments.filter(payment => !payment.ecwpayment_id));
        } else {
            setFilteredPayments(payments);
            setFilteredBankPayments(bankPayments);
            setFilteredMerchantPayments(merchantPayments);
            setFilteredScannedPayments(scannedPayments);
        }
    };

    const handleQuickFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const filterValue = event.target.value;
        gridPaymentsRef.current && gridPaymentsRef.current.api && gridPaymentsRef.current.api.setQuickFilter(filterValue);
        gridBankRef.current && gridBankRef.current.api && gridBankRef.current.api.setQuickFilter(filterValue);
        gridMerchRef.current && gridMerchRef.current.api && gridMerchRef.current.api.setQuickFilter(filterValue);
        gridScanRef.current && gridScanRef.current.api && gridScanRef.current.api.setQuickFilter(filterValue);
    };

    const handleScannedPaymentMatch = async (data: any, op: 'edit' | 'delete' = 'edit') => {
        console.log("Match scanned payment: ", data);

        if (!selectedPayment || !selectedPayment.paymentid) {
            alert("No payment row selected. Please select a payment row first.");
            return;
        }

        handleMatch(selectedPayment.paymentid, 'Scanned', data, op);
    };

    const handleMerchantAccountPaymentMatch = async (data: MerchantRowData, op: 'edit' | 'delete' = 'edit') => {
        console.log("Match Merchant Account payment: ", data);

        if (!selectedPayment || !selectedPayment.paymentid) {
            alert("No payment row selected. Please select a payment row first.");
            return;
        }

        handleMatch(selectedPayment.paymentid, 'Merchant', data, op);
    };

    const handleBankTransactionMatch = async (data: BankRowData, op: 'edit' | 'delete' = 'edit') => {
        console.log("Match Bank Transaction: ", data);

        if (!selectedPayment || !selectedPayment.paymentid) {
            alert("No payment row selected. Please select a payment row first.");
            return;
        }

        handleMatch(selectedPayment.paymentid, 'Bank', data, op);
    };

    const handleDateChange = (setter: React.Dispatch<React.SetStateAction<string | null>>) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setter(event.target.value);
    };

    const resetDateFilter = () => {
        setStartDate(null);
        setEndDate(null);
    };

    const handleViewPage = (row: ScannedRowData) => {
        if (row) {
            console.log("Willview", row.pageno, row.docpath);
            ShowDocument(row.pageno ? row.pageno : 1, row.docpath ? row.docpath : '');
        }
    };

    const handleScannedSelectionChange = (params: any) => {
        const selectedNode = params.api.getSelectedNodes()[0];
        setSelectedScannedPayment(selectedNode ? selectedNode.data : null);
        console.log("Scanned payment row selected", selectedNode ? selectedNode.data : null)
    };

    function refreshData() {
        fetchPayments();
        fetchBankPayments();
        fetchMerchantPayments();
        fetchScannedPayments();
    }

    function handleBankTranSelectionChange(params: any) {
        const selectedNode = params.api.getSelectedNodes()[0];
        console.log("Banktran row selected", selectedNode ? selectedNode.data : null)
    }

    const getPaymentERALinkforCheck = (params: any) => {
        const selectedNode = gridPaymentsRef.current.api.getSelectedNodes()[0];
        if (selectedNode.data.checkno) {
            return (`https://emr.apexheartcare.com/mobiledoc/jsp/edi/x12utils/ViewEraFile_Encode.jsp?TrUserId=9121&CheckNo=${selectedNode.data.checkno}&DetailId=0&PaymentId=${selectedNode.data.paymentid}`);
        }
    }

    const handleViewCheckERA = (params: any) => {
        console.log("Trying to view the check ER:", params);
        let url = getPaymentERALinkforCheck(params);
        url && window.open(url, "_ecwPaymentViewTab");
    }

    function handleMerchantRowSelectionChange(params: any) {
        const selectedNode = params.api.getSelectedNodes()[0];
        console.log("Merchant row selected", selectedNode ? selectedNode.data : null)
    }

    const switchView = () => {
        setViewMode(viewMode === 'grid' ? 'suggestions' : 'grid');
    };

    const handleBatchMatch = async () => {
        const selectedRows = document.querySelectorAll<HTMLInputElement>('input[name="matchCheckbox"]:checked');
        const selectedData: { type: 'Bank' | 'Merchant' | 'Scanned', data: any, paymentId: number }[] = [];

        selectedRows.forEach(row => {
            const { type, data } = JSON.parse(row.value);
            const paymentCardElement = row.closest('[data-paymentid]');
            if (paymentCardElement) {
                const paymentId = Number(paymentCardElement.getAttribute('data-paymentid'));
                selectedData.push({ type, data, paymentId });
            }
        });

        for (const { type, data, paymentId } of selectedData) {
            //console.log("Will handle each payment selection in batch loop",paymentId, data);
            console.log("Will send to handleMatch:", paymentId, type, data);
            await handleMatch(paymentId, type, data, 'edit');
        }

        // Refresh the data
        refreshData();
    };

    const fetchPotentialMatches = async (payment: PaymentsRowData) => {
        const authHeader = JSON.parse(localStorage.getItem('authHeader') || '{}');
        const urlPrefix = `${apiConfig.REACT_APEX_BASE_API}/exsql?dbserver=apex&sqltype=customSQL&sqltext=`;

        const bankSql = ` exec rc.ecw_get_payment_matches  @payment_id = ${payment.paymentid}, @matchtype = 'banktrn'`;
        const merchantSql = ` exec rc.ecw_get_payment_matches @payment_id = ${payment.paymentid}, @matchtype = 'merchtrn' `;
        const scannedSql = `exec rc.ecw_get_payment_matches @payment_id = ${payment.paymentid}, @matchtype = 'papercheck' `;

        try {
            const bankResponse = await axios.get(`${urlPrefix}${bankSql}`, { headers: authHeader });
            const merchantResponse = await axios.get(`${urlPrefix}${merchantSql}`, { headers: authHeader });
            const scannedResponse = await axios.get(`${urlPrefix}${scannedSql}`, { headers: authHeader });

            return {
                bankPayments: bankResponse.data.frame0 ? JSON.parse(bankResponse.data.frame0).rows : [],
                merchantPayments: merchantResponse.data.frame0 ? JSON.parse(merchantResponse.data.frame0).rows : [],
                scannedPayments: scannedResponse.data.frame0 ? JSON.parse(scannedResponse.data.frame0).rows : []
            };
        } catch (error) {
            console.error('Error fetching potential matches:', error);
            return { bankPayments: [], merchantPayments: [], scannedPayments: [] };
        }
    };

    const PaymentCard = ({ payment }: { payment: PaymentsRowData }) => {
        const [potentialMatches, setPotentialMatches] = useState<{ bankPayments: BankRowData[], merchantPayments: MerchantRowData[], scannedPayments: ScannedRowData[] }>({ bankPayments: [], merchantPayments: [], scannedPayments: [] });
        const [hasMatches, setHasMatches] = useState(false);
        const [matchStatus, setMatchStatus] = useState<'pending' | 'matched' | 'error' | ''>('');
        const [errorMessage, setErrorMessage] = useState('');

        useEffect(() => {
            const fetchMatches = async () => {
                const matches = await fetchPotentialMatches(payment);
                setPotentialMatches(matches);
                setHasMatches(matches.bankPayments.length > 0 || matches.merchantPayments.length > 0 || matches.scannedPayments.length > 0);
            };

            fetchMatches();
        }, [payment]);

        const handleIndividualMatch = async () => {
            const paymentId = payment.paymentid;
            const paymentCardElement = document.querySelector(`[data-paymentid="${paymentId}"]`);

            if (paymentCardElement) {
                const selectedCheckboxes = paymentCardElement.querySelectorAll<HTMLInputElement>('input[name="matchCheckbox"]:checked');
                const selectedData: { paymentid: any, type: 'Bank', data: any }[] = [];

                selectedCheckboxes.forEach(checkbox => {
                    const { type, data } = JSON.parse(checkbox.value);
                    selectedData.push({paymentid:  payment.paymentid, type, data });
                });

                try {
                    for (const { paymentid,  type, data } of selectedData) {
                        console.log("Will send to handleMatch:", paymentid, type, data);
                        await handleMatch(paymentid, type, data, 'edit');
                    }
                    setMatchStatus('matched');
                } catch (error) {
                    setMatchStatus('error');
                }
            } else {
                console.error("Payment card element not found for payment ID:", paymentId);
            }
        };

        const handleRemoveMatch = async (type: 'Bank' | 'Merchant' | 'Scanned', data: any) => {
            if (!payment.paymentid) {
                alert('No payment row selected. Please select a payment row first.');
                return;
            }

            await handleMatch(payment.paymentid, type, data, 'delete');
        };

        const formatDate = (date?: string) => {
            if (!date) return '';
            const d = new Date(date);
            const year = d.getFullYear();
            const month = (`0${d.getMonth() + 1}`).slice(-2);
            const day = (`0${d.getDate()}`).slice(-2);
            return `${year}-${month}-${day}`;
        };

        if (payment.ispaymentcached === 1) {
            return (
                <Card style={{ border: '1px solid black', marginBottom: '10px' }}>
                    <CardContent data-paymentid={payment.paymentid}>
                        <Box display="flex" justifyContent="space-between">
                            <Button onClick={handleIndividualMatch} style={{ backgroundColor: matchStatus === 'matched' ? 'green' : matchStatus === 'error' ? 'red' : 'gray' }}>
                                Match
                            </Button>
                            <IconButton onClick={() => setHasMatches(false)}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        <Box display="flex" flexWrap="wrap" alignItems="center">
                            <Typography variant="h6">Payment ID: {payment.paymentid}</Typography>
                            <Typography>{formatDate(payment.pmtdate)}</Typography>
                            <Typography>Amount: {payment.amount}</Typography>
                            <Typography>Date: {payment.checkno} on {formatDate(payment.checkdate)}</Typography>
                            <Typography>{payment.pmtmemo}</Typography>
                        </Box>
                        <Box>
                            {payment.bank_tran_id && (
                                <Box display="flex" flexWrap="wrap" alignItems="center">
                                    <Button onClick={() => handleRemoveMatch('Bank', { tran_id: payment.bank_tran_id })} style={{ backgroundColor: 'red' }}>Remove</Button>
                                    <Typography>Bank Transaction ID: {payment.bank_tran_id}</Typography>
                                </Box>
                            )}
                            {payment.macct_tran_id && (
                                <Box display="flex" flexWrap="wrap" alignItems="center">
                                    <Button onClick={() => handleRemoveMatch('Merchant', { row_id: payment.macct_tran_id })} style={{ backgroundColor: 'red' }}>Remove</Button>
                                    <Typography>Merchant Transaction ID: {payment.macct_tran_id}</Typography>
                                </Box>
                            )}
                            {payment.spmt_id && (
                                <Box display="flex" flexWrap="wrap" alignItems="center">
                                    <Button onClick={() => handleRemoveMatch('Scanned', { spmt_id: payment.spmt_id })} style={{ backgroundColor: 'red' }}>Remove</Button>
                                    <Typography>Scanned Payment ID: {payment.spmt_id}</Typography>
                                </Box>
                            )}
                        </Box>
                        {matchStatus === 'error' && <Typography color="error">{errorMessage}</Typography>}
                    </CardContent>
                </Card>
            );
        } else {
            if (!hasMatches) {
                return null;
            }

            return (
                <Card style={{ border: '1px solid black', marginBottom: '10px' }}>
                    <CardContent data-paymentid={payment.paymentid}>
                        <Box display="flex" justifyContent="space-between">
                            <Button onClick={handleIndividualMatch} style={{ backgroundColor: matchStatus === 'matched' ? 'green' : matchStatus === 'error' ? 'red' : 'gray' }}>
                                Match
                            </Button>
                            <IconButton onClick={() => setHasMatches(false)}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        <Box display="flex" flexWrap="wrap" alignItems="center">
                            <Typography variant="h6">Payment ID: {payment.paymentid}</Typography>
                            <Typography>{formatDate(payment.pmtdate)}</Typography>
                            <Typography>Amount: {payment.amount}</Typography>
                            <Typography>Date: {payment.checkno} on {formatDate(payment.checkdate)}</Typography>
                            <Typography>{payment.pmtmemo}</Typography>
                        </Box>
                        <Box>
                            <Typography variant="subtitle1">Potential Matches</Typography>
                            {potentialMatches.bankPayments.map(bank => (
                                <Box key={bank.tran_id} display="flex" flexWrap="wrap" alignItems="center">
                                    <Checkbox name="matchCheckbox" value={JSON.stringify({ type: 'Bank', data: bank })} />
                                    <Typography>Bank Transaction ID: {bank.tran_id}</Typography>
                                    <Typography>Source: {bank.importsource}</Typography>
                                    <Typography>Date: {bank.trandate}</Typography>
                                    <Typography>Description: {bank.description}</Typography>
                                </Box>
                            ))}
                            {potentialMatches.merchantPayments.map(merchant => (
                                <Box key={merchant.row_id} display="flex" flexWrap="wrap" alignItems="center">
                                    <Checkbox name="matchCheckbox" value={JSON.stringify({ type: 'Merchant', data: merchant })} />
                                    <Typography>Merchant Transaction ID: {merchant.row_id}</Typography>
                                    <Typography>Card Number: {merchant.CardNumber}</Typography>
                                    <Typography>Amount: {merchant.Amount}</Typography>
                                    <Typography>Status: {merchant.status}</Typography>
                                    <Typography>Date: {merchant.SubmittedTime}</Typography>
                                </Box>
                            ))}
                            {potentialMatches.scannedPayments.map(scanned => (
                                <Box key={scanned.spmt_id} display="flex" flexWrap="wrap" alignItems="center">
                                    <Checkbox name="matchCheckbox" value={JSON.stringify({ type: 'Scanned', data: scanned })} />
                                    <Typography>Scanned Payment ID: {scanned.spmt_id}</Typography>
                                    <Typography>Page No: {scanned.pageno}</Typography>
                                    <Typography>Doc ID: {scanned.ecwdoc_id}</Typography>
                                    <Typography>Check Amount: {scanned.checkamount}</Typography>
                                    <Typography>Card Amount: {scanned.cardamount}</Typography>
                                    <Typography>Date: {scanned.checkdate}</Typography>
                                    <Typography>Check Number: {scanned.checknumber}</Typography>
                                    <Button onClick={() => handleViewPage(scanned)}>View</Button>
                                </Box>
                            ))}
                        </Box>
                        {matchStatus === 'error' && <Typography color="error">{errorMessage}</Typography>}
                    </CardContent>
                </Card>
            );
        }
    };

    const loadMoreSuggestions = () => {
        if (loading || !hasMore) return;

        setLoading(true);
        setTimeout(() => {
            const unmatchedPayments = payments.filter(payment => !payment.bank_tran_id && !payment.macct_tran_id && !payment.spmt_id);
            const newPage = page + 1;
            const start = newPage * 10;
            const end = start + 10;
            const nextPayments = unmatchedPayments.slice(start, end);

            if (nextPayments.length > 0) {
                setPage(newPage);
                setFilteredPayments(prev => [...prev, ...nextPayments]);
            } else {
                setHasMore(false);
            }

            setLoading(false);
        }, 1000);
    };

    const SuggestionsView = () => {
        const [displayedPayments, setDisplayedPayments] = useState<PaymentsRowData[]>([]);
        const [hasMore, setHasMore] = useState(true);
        const [selectedMatchCount, setSelectedMatchCount] = useState(0);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            loadInitialSuggestions();
        }, []);

        useEffect(() => {
            updateSelectedMatchCount();
        }, [displayedPayments]);

        const fetchUnmatchedPayments = async (maxPaymentId: number): Promise<PaymentsRowData[]> => {
            const sqlText = `exec rc.billing_load_unmatched_payments @max_payment_id=${maxPaymentId}`;
            const authHeader = JSON.parse(localStorage.getItem('authHeader') || '{}');
            const urlPrefix = `${apiConfig.REACT_APEX_BASE_API}/exsql?dbserver=apex&sqltype=customSQL&sqltext=`;

            try {
                const response = await axios.get(`${urlPrefix}${sqlText}`, { headers: authHeader });
                if (response.data) {
                    const dframe: string = response.data.frame0;
                    const myObj = JSON.parse(dframe);
                    return myObj.rows;
                }
            } catch (error) {
                console.error('Error fetching unmatched payments:', error);
            }

            return [];
        };

        const loadInitialSuggestions = async () => {
            const unmatchedPayments = await fetchUnmatchedPayments(-1);
            const sortedUnmatchedPayments = unmatchedPayments.sort((a, b) => b.paymentid! - a.paymentid!);
            const initialPayments = sortedUnmatchedPayments.slice(0, 1000);
            setDisplayedPayments(initialPayments);
            setHasMore(initialPayments.length < sortedUnmatchedPayments.length);
        };

        const loadMoreSuggestions = async () => {
            const minPaymentId = Math.min(...displayedPayments.map(payment => payment.paymentid!));
            const unmatchedPayments = await fetchUnmatchedPayments(minPaymentId);
            const sortedUnmatchedPayments = unmatchedPayments.sort((a, b) => b.paymentid! - a.paymentid!);
            const newPayments = sortedUnmatchedPayments.slice(0, 10);
            setDisplayedPayments(prev => [...prev, ...newPayments]);
            setHasMore(displayedPayments.length + newPayments.length < sortedUnmatchedPayments.length);
        };

        const updateSelectedMatchCount = () => {
            const count = displayedPayments.reduce((acc, payment) => {
                const checkboxes = document.querySelectorAll<HTMLInputElement>(`input[name="matchCheckbox"][data-paymentid="${payment.paymentid}"]:checked`);
                return acc + (checkboxes.length > 0 ? 1 : 0);
            }, 0);
            setSelectedMatchCount(count);
        };

        return (
            <Box>
                <AppBar position="static" style={{ marginBottom: 0, marginTop: 0, backgroundColor: 'lightgray' }}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" p={1}>
                        <Typography>Suggest Matches</Typography>
                        <Box display="flex" alignItems="center">
                            <Typography>{`Displayed Payments: ${displayedPayments.length}`}</Typography>
                            <Button onClick={loadMoreSuggestions}>Load More</Button>
                            <Typography>{`Selected Matches: ${selectedMatchCount}`}</Typography>
                            <Button onClick={handleBatchMatch}>Batch Match</Button>
                        </Box>
                    </Box>
                </AppBar>
                <Box style={{ overflowY: 'auto', maxHeight: '80vh' }}>
                    {displayedPayments.map(payment => (
                        <PaymentCard key={payment.paymentid} payment={payment} />
                    ))}
                    {loading && <CircularProgress />}
                    {!hasMore && <Typography>END OF LIST</Typography>}
                </Box>
            </Box>
        );
    };

    return (
        <SplitPanel split="vertical">
            <>
                <Box display={"flex"} flexDirection={"row"} alignItems="center" gap={2}>
                    <Button onClick={refreshData}>Refresh</Button>
                    <Button onClick={() => setCollapse(!collapse)}>
                        {collapse ? 'Hide Criteria' : 'Show Criteria'}
                    </Button>
                    <Button onClick={switchView}>
                        {viewMode === 'grid' ? 'Show Suggestions' : 'Show Grid'}
                    </Button>
                    <Typography>{selectedPayment ? selectedPayment.paymentid : 'No payment selected'}</Typography>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={showPdfViewer}
                                onChange={() => setShowPdfViewer(!showPdfViewer)}
                                name="showPdfViewer"
                                color="primary"
                            />
                        }
                        label="Show PDF Viewer"
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={showUnmatchedOnly}
                                onChange={() => setShowUnmatchedOnly(!showUnmatchedOnly)}
                                name="showUnmatchedOnly"
                                color="primary"
                            />
                        }
                        label="Show Unmatched Only"
                    />
                    <Box display={"flex"} flexDirection={"row"} alignItems="center" gap={2} padding={0}>
                        <FormGroup row>
                            <FormControlLabel
                                control={<Checkbox checked={showAll} onChange={handleCheckboxChange} name="showAll" />}
                                label="Show All"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={showBank} onChange={handleCheckboxChange} name="showBank" />}
                                label="Bank"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={showMerchant} onChange={handleCheckboxChange} name="showMerchant" />}
                                label="Card"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={showScanned} onChange={handleCheckboxChange} name="showScanned" />}
                                label="Paper"
                            />
                        </FormGroup>
                    </Box>
                    <TextField label="Quick Filter" onChange={handleQuickFilterChange} />
                </Box>
                <Collapse in={collapse}>
                    <Box display={"flex"} flexDirection={"row"} alignItems="center" gap={2}>
                        <FormLabel>Criteria</FormLabel>
                        <RadioGroup value={criteria} onChange={handleCriteriaChange}>
                            <FormControlLabel value="checks" control={<Radio />} label="Checks Only" />
                            <FormControlLabel value="creditcards" control={<Radio />} label="Credit Cards Only" />
                            <FormControlLabel value="all" control={<Radio />} label="All Payments" />
                        </RadioGroup>
                        <TextField
                            label="Start Date"
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            value={startDate || ''}
                            onChange={handleDateChange(setStartDate)}
                        />
                        <TextField
                            label="End Date"
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            value={endDate || ''}
                            onChange={handleDateChange(setEndDate)}
                        />
                        <Button onClick={resetDateFilter}>Reset Date Filter</Button>
                        <Button onClick={handleSelectAll}>Select All</Button>
                        <Button onClick={handleSelectNone}>Select None</Button>
                    </Box>
                </Collapse>
                <SplitPanel split={'horizontal'}>
                    <Box style={{ flex: 1, height: '100%', overflowY: 'auto' }}>
                        {viewMode === 'grid' ? (
                            <>
                                <AppBar position="static" style={{ marginBottom: 0, marginTop: 0, marginRight: 10, paddingRight: '10px',  height: '30px' }}>
                                    <Box display="flex" justifyContent="space-between" alignItems="center" p={0.5}>
                                        <Box style={{ fontSize: '0.875rem', padding: '0 8px' }}>eCW Payments</Box>
                                        <IconButton edge="end" color="inherit" onClick={() => fetchPayments()} style={{ padding: '5px' }}>
                                            <RefreshIcon style={{ fontSize: '14px' }} />
                                        </IconButton>
                                    </Box>
                                </AppBar>
                                <div className="ag-theme-alpine" style={{ height: '30%' }}>
                                    <AgGridReact
                                        ref={gridPaymentsRef}
                                        rowData={filteredPayments}
                                        rowSelection="single"
                                        defaultColDef={{ sortable: true, filter: true, resizable: true }}
                                        onSelectionChanged={handleeCWPaymentSelectionChange}
                                        columnDefs={[
                                            { headerName: 'ID', field: 'paymentid', width: 125, checkboxSelection: true },
                                            {
                                                headerName: '',
                                                width: 100,
                                                cellRenderer: (params: any) => {
                                                    const { ispaymentcached, bank_tran_id, macct_tran_id, spmt_id, pmtclosed, ispaymentposted, scancheckamount, scancardamount } = params.data;

                                                    if (pmtclosed === 1) {
                                                        return <CheckCircleIcon style={{ color: 'blue' }} />;
                                                    } else {
                                                        return (
                                                            <>
                                                                {ispaymentposted === 1 && <CheckCircleIcon style={{ color: 'orange' }} />}
                                                                {ispaymentcached === 1 && (
                                                                    <>
                                                                        {bank_tran_id > 0 && <AccountBalanceIcon style={{ color: 'blue' }} />}
                                                                        {macct_tran_id > 0 && <CreditCardIcon style={{ color: 'orange' }} />}
                                                                        {spmt_id > 0 && scancheckamount > 0 && <DescriptionIcon style={{ color: 'orange' }} />}
                                                                        {spmt_id > 0 && scancardamount > 0 && <DescriptionIcon style={{ color: 'gray' }} />}
                                                                    </>
                                                                )}
                                                            </>
                                                        );
                                                    }
                                                }
                                            },
                                            { headerName: 'Amount', field: 'amount', width: 110 },
                                            {
                                                headerName: 'posted?',
                                                width: 110,
                                                cellRenderer: (params: any) => {
                                                    const { amount, unpostedamount } = params.data;

                                                    if (unpostedamount === 0.0) {
                                                        return <CheckCircleIcon style={{ color: 'blue' }} />;
                                                    } else {
                                                        return (<span>{unpostedamount}</span>)
                                                    }
                                                }
                                            },
                                            { headerName: 'Date', field: 'pmtdate', width: 150, valueFormatter: dateFormatter },
                                            { headerName: 'Num', field: 'checkno', width: 120 },
                                            {
                                                headerName: 'Num',
                                                width: 70,
                                                cellRenderer: (params: any) => (
                                                    <>
                                                        <Button onClick={() => handleViewCheckERA(params.data)} style={{ backgroundColor: 'white' }}>ERA</Button>
                                                    </>
                                                ),
                                            },
                                            { headerName: 'Chk', field: 'checkdate', width: 150, valueFormatter: dateFormatter },
                                            { headerName: 'By', field: 'payorname', width: 125, tooltipField: 'payorname' },
                                            { headerName: 'Bank', field: 'bank_tran_id', width: 90 },
                                            { headerName: 'Card', field: 'macct_tran_id', width: 90 },
                                            { headerName: 'Paper', field: 'spmt_id', width: 90 },
                                            { headerName: 'Description', field: 'pmtmemo', width: 300, tooltipField: 'pmtmemo' },
                                        ]}
                                        gridOptions={{
                                            suppressRowHoverHighlight: true,
                                            suppressColumnVirtualisation: true,
                                            rowHeight: 25,
                                            headerHeight: 20
                                        }}
                                    />
                                </div>

                                {isCached && selectedPayment ? (
                                    <PaymentCard payment={selectedPayment} />
                                ) : (
                                    <>
                                        {showBank && (
                                            <>
                                                <AppBar position="static" style={{ marginBottom: 0, marginTop: 0, marginRight: 10, paddingRight: '10px',  height: '30px' , backgroundColor: 'lightblue'}}>
                                                    <Box display="flex" justifyContent="space-between" alignItems="center" p={0.5}>
                                                        <Box style={{ fontSize: '0.875rem', padding: '0 8px' }}>Bank Payments</Box>
                                                        <IconButton edge="end" color="inherit" onClick={() => fetchBankPayments()} style={{ padding: '5px' }}>
                                                            <RefreshIcon style={{ fontSize: '14px' }} />
                                                        </IconButton>
                                                    </Box>
                                                </AppBar>
                                                <div className="ag-theme-alpine" style={{ height: '15%' }}>
                                                    <AgGridReact
                                                        ref={gridBankRef}
                                                        rowData={filteredBankPayments}
                                                        rowSelection="single"
                                                        defaultColDef={{ sortable: true, filter: true, resizable: true }}
                                                        onSelectionChanged={handleBankTranSelectionChange}
                                                        columnDefs={[
                                                            { headerName: 'ID', field: 'tran_id', width: 100, checkboxSelection: true },
                                                            {
                                                                headerName: '',
                                                                width: 70,
                                                                cellRenderer: (params: any) => (
                                                                    <>
                                                                        <Button onClick={() => handleBankTransactionMatch(params.data)} style={{ backgroundColor: 'gray' }}>Match</Button>
                                                                    </>
                                                                ),
                                                            },
                                                            { headerName: 'Source', field: 'importsource', width: 150, tooltipField: 'importsource' },
                                                            { headerName: 'Amount', field: 'tranamount', width: 120 },
                                                            { headerName: 'Date', field: 'trandate', width: 150, valueFormatter: dateFormatter },
                                                            { headerName: 'eCW', field: 'ecwpayment_id', width: 90 },
                                                            { headerName: 'Description', field: 'description', width: 300, tooltipField: 'description' },
                                                        ]}
                                                        gridOptions={{
                                                            suppressRowHoverHighlight: true,
                                                            suppressColumnVirtualisation: true,
                                                            rowHeight: 25,
                                                            headerHeight: 20
                                                        }}
                                                    />
                                                </div>
                                            </>
                                        )}
                                        {showMerchant && (
                                            <>
                                               <AppBar position="static" style={{ marginBottom: 0, marginTop: 0, marginRight: 10, paddingRight: '10px',  height: '30px' , backgroundColor: 'orange'}}>
                                                    <Box display="flex" justifyContent="space-between" alignItems="center" p={0.5}>
                                                        <Box style={{ fontSize: '0.875rem', padding: '0 8px' }}>Credit Card Transactions</Box>
                                                        <IconButton edge="end" color="inherit" onClick={() => fetchMerchantPayments()} style={{ padding: '5px' }}>
                                                            <RefreshIcon style={{ fontSize: '14px' }} />
                                                        </IconButton>
                                                    </Box>
                                                </AppBar>
                                                <div className="ag-theme-alpine" style={{ height: '15%' }}>
                                                    <AgGridReact
                                                        ref={gridMerchRef}
                                                        rowData={filteredMerchantPayments}
                                                        rowSelection="single"
                                                        defaultColDef={{ sortable: true, filter: true, resizable: true }}
                                                        onSelectionChanged={handleMerchantRowSelectionChange}
                                                        columnDefs={[
                                                            { headerName: 'ID', field: 'row_id', width: 100, checkboxSelection: true },
                                                            {
                                                                headerName: '',
                                                                width: 70,
                                                                cellRenderer: (params: any) => (
                                                                    <>
                                                                        <Button onClick={() => handleMerchantAccountPaymentMatch(params.data)} style={{ backgroundColor: 'gray' }}>Match</Button>
                                                                    </>
                                                                ),
                                                            },
                                                            { headerName: 'Card Number', field: 'CardNumber', width: 200 },
                                                            { headerName: 'Amount', field: 'Amount', width: 120 },
                                                            { headerName: 'Status', field: 'status', width: 120 },
                                                            { headerName: 'Invoice', field: 'InvoiceNumber', width: 120 },
                                                            { headerName: 'Date', field: 'SubmittedTime', width: 150, valueFormatter: dateFormatter },
                                                            { headerName: 'eCW', field: 'ecwpayment_id', width: 90 },
                                                            { headerName: 'Bank', field: 'bankdeposit_id', width: 90 },
                                                            { headerName: 'Paper', field: 'scannedpayment_id', width: 90 },
                                                            { headerName: 'Description', field: 'TransactionType', width: 200, tooltipField: 'TransactionType' },
                                                        ]}
                                                        gridOptions={{
                                                            suppressRowHoverHighlight: true,
                                                            suppressColumnVirtualisation: true,
                                                            rowHeight: 25,
                                                            headerHeight: 20
                                                        }}
                                                    />
                                                </div>
                                            </>
                                        )}
                                        {showScanned && (
                                            <>
                                                <AppBar position="static" style={{ marginBottom: 0, marginTop: 0, marginRight: 10, paddingRight: '10px',  height: '30px' , backgroundColor: 'gray'}}>
                                                    <Box display="flex" justifyContent="space-between" alignItems="center" p={0.5}>
                                                        <Box style={{ fontSize: '0.875rem', padding: '0 8px' }}>Paper EOBs scanned</Box>
                                                        <IconButton edge="end" color="inherit" onClick={() => fetchScannedPayments()} style={{ padding: '5px' }}>
                                                            <RefreshIcon style={{ fontSize: '14px' }} />
                                                        </IconButton>
                                                    </Box>
                                                </AppBar>
                                                <div className="ag-theme-alpine" style={{ height: '30%', }}>
                                                    <AgGridReact
                                                        ref={gridScanRef}
                                                        rowData={filteredScannedPayments}
                                                        rowSelection="single"
                                                        defaultColDef={{ sortable: true, filter: true, resizable: true }}
                                                        onSelectionChanged={handleScannedSelectionChange}
                                                        columnDefs={[
                                                            { headerName: 'ID', field: 'spmt_id', width: 100, checkboxSelection: true },
                                                            {
                                                                headerName: '',
                                                                width: 180,
                                                                cellRenderer: (params: any) => (
                                                                    <>
                                                                        <Button onClick={() => handleScannedPaymentMatch(params.data)} style={{ backgroundColor: 'gray' }}>Match</Button>
                                                                        <Button onClick={() => handleViewPage(params.data)} style={{ backgroundColor: 'gray' }}>View</Button>
                                                                    </>
                                                                ),
                                                            },
                                                            { headerName: 'pg', field: 'pageno', width: 90 },
                                                            { headerName: 'Doc ID', field: 'ecwdoc_id', width: 150 },
                                                            { headerName: 'Chk Amt', field: 'checkamount', width: 120 },
                                                            { headerName: 'CardAmt', field: 'cardamount', width: 120 },
                                                            { headerName: 'Date', field: 'checkdate', width: 150, valueFormatter: dateFormatter },
                                                            { headerName: 'Check Number', field: 'checknumber', width: 100 },
                                                            { headerName: 'eCW', field: 'ecwpayment_id', width: 90 },
                                                            { headerName: 'Bank', field: 'bankdeposit_id', width: 90 },
                                                        ]}
                                                        gridOptions={{
                                                            suppressRowHoverHighlight: true,
                                                            suppressColumnVirtualisation: true,
                                                            rowHeight: 25,
                                                            headerHeight: 20
                                                        }}
                                                    />
                                                </div>
                                            </>
                                        )}
                                    </>
                                )}
                            </>
                        ) : (
                            <SuggestionsView />
                        )}
                    </Box>
                    {showPdfViewer && (
                        <div style={{ height: '100%' }}>
                            <Box>
                                <FormLabel>{selectedPayment && selectedPayment.pmttype}</FormLabel>
                                <ApexPdfjsViewer pdfUrl={pdfUrl} startingPageNum={currentPageNum} setCurrentPageNum={onSetCurrentPageNum} />
                            </Box>
                        </div>
                    )}
                </SplitPanel>
            </>
        </SplitPanel>
    );

};

export default ReviewByPayment;
