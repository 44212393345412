export const handleEcwDocument = (data, apiURL, setpdfToDisplayURL, setstartingPageNum) => {

    if (!data.filename) {
        console.log("Cant diplay file: " , data);
        return
    }
    let documentFilePath = '';
    if (data.dirpath != ''){
        documentFilePath = `${data.dirpath}/${data.filename}`;
    }else {
        documentFilePath = `${data.filename}`;
    }
    let startingpagenum = 1;

    if (data.startpage) {
        startingpagenum = data.startpage;
    }

    let full_file_path = `\\\\192.168.1.17\\d$\\eClinicalWorks\\ftp\\${documentFilePath}`;
    full_file_path = full_file_path.replace('/', '\\');

    full_file_path = `${apiURL}/downloadFile/${full_file_path.replaceAll("\\", "~").replaceAll("/", "~")}`;

    console.log('Starting to get page, File', startingpagenum, full_file_path);

    fetch(
        encodeURI(full_file_path), {
        headers: {
            "Access-Control-Allow-Origin": "*",
        },
    }
    )
        // .then(this.handleErrors)
        .then((r) => r.blob())
        .then((blob) => {

            let url = URL.createObjectURL(blob);

            setpdfToDisplayURL(url);
            setstartingPageNum(startingpagenum);
            // pdfViewerRef.current.UI.loadDocument(url, {
            //   extension: 'pdf'
            // });

            // pdfViewerRef.current.UI.loadDocument(url, {
            //   extension: 'pdf'
            // });

        });

};


export const handleFolderDocument = (documentFilePath, apiURL, setpdfToDisplayURL, setstartingPageNum) => {

    // let documentFilePath = `${data.dirpath}/${data.fileName}` ;

    let full_file_path = `${documentFilePath}`;
    full_file_path = full_file_path.replace('/', '\\');

    full_file_path = `${apiURL}/downloadFile/${full_file_path.replaceAll("\\", "~").replaceAll("/", "~")}`;

    console.log('Starting to get Linq File', full_file_path);

    fetch(
        encodeURI(full_file_path), {
        headers: {
            "Access-Control-Allow-Origin": "*",
        },
    }
    )
        // .then(this.handleErrors)
        .then((r) => r.blob())
        .then((blob) => {

            let url = URL.createObjectURL(blob);

            setpdfToDisplayURL(url);
            setstartingPageNum(1);
            // pdfViewerRef.current.UI.loadDocument(url, {
            //   extension: 'pdf'
            // });

            // pdfViewerRef.current.UI.loadDocument(url, {
            //   extension: 'pdf'
            // });

        });

};

export const handleAgDocument = (data, apiURL, setpdfToDisplayURL, setstartingPageNum) => {
    console.log("HandleAgDocument", apiURL, data);
    if (data.filename && (data.filename.includes('ecwupload') || data.filename.includes('ecwsrv'))) {
            handleFolderDocument(data.fileName, apiURL, setpdfToDisplayURL, setstartingPageNum);
            return;
        }
        handleEcwDocument(data, apiURL, setpdfToDisplayURL, setstartingPageNum);
        return;

};


