import React, { Component, useState, useEffect } from 'react';
// import PlaidLinkButton from "react-plaid-link-button";
import { PlaidLink } from 'react-plaid-link';
//import { useSelector, useDispatch } from 'react-redux';

import jwtDecode from 'jwt-decode';
import { useAppSelector, useAppDispatch } from '../../Redux/hooks';

import {
  Box,
  Button,
  Text,
  Modal,
  MenuItem,
  InputLabel,
  FormControl,

} from '@chakra-ui/react';

import { Select } from "chakra-react-select";

import SpredsheetView from './spreadsheet';

import {
  setPlaidEnvironment,
  getLinkToken,
  getAccounts,
} from './perfinSlice';

import Accounts from './Accounts';
import Spinner from './Spinner';


const style = {
  position: 'relative',
  top: '100px',
  left: '200px',
  width: '80%',
  height: '80%',
  overflow: 'scroll',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


export function Dashboard() {
  // componentDidMount() {
  //   // this.props.getLinkToken();
  //   // this.props.getAccounts();
  // }

  // Logout
  // onLogoutClick = e => {
  //   e.preventDefault();
  //   this.props.logoutUser();
  // };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const dispatch = useAppDispatch();

 // console.log('Dispatching SelectLinkToken');
 // dispatch(getLinkToken());
 // dispatch(getAccounts());


  let PlaidEnvironment = useAppSelector((state) => {
    let plaidenv =state.perfin.PlaidEnvironment ? state.perfin.PlaidEnvironment :  'development' ;
    console.log('Got PlaidEnvironment:', plaidenv);
    return plaidenv;
  });

  useEffect(() => {
   
    if (PlaidEnvironment != null) {
      console.log('Dispatching SelectLinkToken2', PlaidEnvironment);
      dispatch(getLinkToken({ plaidenv: PlaidEnvironment }));
      dispatch(getAccounts({ plaidenv: PlaidEnvironment }));
    }
  }, [PlaidEnvironment]);

  // Add account
  const handleOnSuccess = (token, metadata) => {
    const plaidData = {
      public_token: token,
      metadata,
    };

    // this.props.addAccount(plaidData);
  };

  // Add account
  const handleOnLoad = () => {
    console.log('Link on load called');
  };

  // Logout
  // onLogoutClick = e => {
  //   e.preventDefault();
  //   this.props.logoutUser();
  // };

  // const { user } = this.props.auth;

  // console.log(this.props.auth);

  console.log('In Dashboard.');
  // const accessToken = localStorage.getItem('accessToken');

  // const decodedToken = jwtDecode(accessToken);
  // console.log(decodedToken);

  // const user = {
  //   id: decodedToken.user_id,
  //   avatar: '',
  //   email: decodedToken.email,
  //   name: decodedToken.username,
  //   username: decodedToken.username,
  //   role: '',
  // };

  const user = {
    id: 1,
    avatar: '',
    email: 'siva@korlakunta.com',
    name: 'Siva',
    username: 'Siva',
    role: '',
  };



  const linkToken = useAppSelector((state) => {
    let linktoken = state.perfin && state.perfin.linkToken ? state.perfin.linkToken : '';
    console.log('Got LinkToken:', linktoken);
    return linktoken;
  });

  const accounts = useAppSelector((state) => {
    let accts = state.perfin && state.perfin.accounts ? state.perfin.accounts : '';
    console.log('Got Accounts:', accts);
    return accts;
  });

  const accountsLoading = useAppSelector((state) => {
    let accountsLoading = state.perfin && state.perfin.accountsLoading ? state.perfin.accountsLoading : false;
    return accountsLoading;
  });

  console.log('Accounts Status', linkToken, accountsLoading, accounts);

  const handleEnvironmentChange = (event) => {
    console.log("SELECTED ENVIRONMENT: ", (event.target.value));
    dispatch(setPlaidEnvironment(event.target.value));
  };

  // if (linkToken.length > 0) {
  //   if (PlaidEnvironment !== linkToken.substring(5, 15)) {
  //     setPlaidEnvironment ( linkToken.substring(5, 15));
  //   }
  // }

  let dashboardContent;
  console.log(`Link Token using in Dashboard ${linkToken}`);

  if (accounts === null && accountsLoading) {
    dashboardContent = <Spinner />;

    console.log('DBC1', dashboardContent);
  } else if (accounts != null && accounts.length > 0) {
    // User has accounts linked
    dashboardContent = (
      <div>
        <Accounts user={user} accounts={accounts} linkToken={linkToken} />
      </div>
    );
    console.log('DBC2', dashboardContent);
  } else {
    // User has no accounts linked
    dashboardContent = (
      <div className="row">
        <div className="col s12 center-align">
          <h4>
            <b>Welcome,</b>{' '}
            <span>
              {' '}
              {user.username} {PlaidEnvironment}{' '}
            </span>
          </h4>
          <p className="flow-text grey-text text-darken-1">
            To get started, link your first bank account below
          </p>

          {/* buttonProps={{
                  className:
                    "btn btn-large waves-effect waves-light hoverable blue accent-3 main-btn"
                }}

                                onScriptLoad={() => this.setState({ loaded: true })}

                                */}

          <div>
            {linkToken ? (
              <div>
                <FormControl size="small" >
                  <Select
                    id="demo-simple-select"
                    value={PlaidEnvironment}
                    label="Environment"
                    onChange={handleEnvironmentChange}
                  >
                    <MenuItem value={'sandbox'}>Sandbox</MenuItem>
                    <MenuItem value={'development'}>Development</MenuItem>
                  </Select>
                </FormControl>
                <PlaidLink
                  token={linkToken}
                  onLoad={handleOnLoad}
                  onSuccess={handleOnSuccess}
                  onExit={function (err, metadata) {
                    // The user exited the Link flow.
                    if (err != null) {
                      // The user encountered a Plaid API error prior to exiting.
                      console.log(err);
                      console.log('plaid link error');
                    }

                    console.log('plaid link exited');
                    // metadata contains information about the institution
                    // that the user selected and the most recent API request IDs.
                    // Storing this information can be helpful for support.
                  }}
                  onEvent={function (eventName, metadata) {
                    // Optionally capture Link flow events, streamed through
                    // this callback as your users connect an Item to Plaid.
                    // For example:
                    // eventName = "TRANSITION_VIEW"
                    // metadata  = {
                    //   link_session_id: "123-abc",
                    //   mfa_type:        "questions",
                    //   timestamp:       "2017-09-14T14:42:19.350Z",
                    //   view_name:       "MFA",
                    // }
                    console.log(`plaid link event: ${eventName}`);
                  }}
                >
                  Link Account
                </PlaidLink>
                <div>
                  <Button onClick={handleOpen}>Open modal</Button>
                  <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <div>
                      <Box sx={style}>
                        <Text id="modal-modal-title" variant="h6" component="h2">
                          Text in a modal
                        </Text>
                        <Text id="modal-modal-description" sx={{ mt: 2 }}>
                          Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
                        </Text>
                      </Box>
                      <SpredsheetView />
                    </div>
                  </Modal>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    );

    //console.log('DBC3', dashboardContent);
  }
  console.log("In Dashboard:", dashboardContent);
  return <div className="container-fluid">{dashboardContent}</div>;
}

export default Dashboard;
