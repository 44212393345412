import React, { useState, useEffect } from 'react';
import { Popper, Paper, Typography, Box, Button, TextField } from '@mui/material';
import Draggable from 'react-draggable';

interface RowData {
    spmt_id?: string;
    ecwdoc_id?: number;
    pageno?: number;
    checknumber?: string;
    pagedetail?: string;
    checkamount?: number;
    checkdate?: string;
    cardamount?: number;
    otheramount?: number;
    otheramount2?: number;
    ecwpayment_id?: string;
    bankdeposit_id?: string;
    pmtpatientid?: string;
    pmtpatientname?: string;
    reconcilestatus?: string;
    customFilename?: string;
    ecwFileName?: string;
    depositbatch?: string;
    ispaymentcached?: number;
    expiredpayment?: number;
    replacementremarks?: string;
    paymentnotes?: string;
    reference1?: string;
    reference2?: string;
    enteredby?: string;
    deleteflag?: number;
}

interface EditScannedPaymentProps {
    open: boolean;
    anchorEl: HTMLElement | null;
    onClose: () => void;
    rowData: RowData | null;
    onSave: (updatedRow: RowData) => void;
    onDelete: (rowId: string) => void;
    pageNum: number | null | undefined;
    ecwDocId: number | null | undefined;
}

function DraggablePaperComponent(props: any) {
    return (
        <Draggable handle="#draggable-popper-title">
            <Paper elevation={3} {...props} />
        </Draggable>
    );
}

const EditScannedPayment: React.FC<EditScannedPaymentProps> = ({ open, anchorEl, onClose, rowData, onSave, onDelete , pageNum, ecwDocId}) => {
    const [formData, setFormData] = useState<RowData>({});

    useEffect(() => {
        setFormData(rowData || {});
    }, [rowData]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSave = () => {
        onSave(formData);
        onClose();
    };

    const handleDelete = () => {
        if (formData.spmt_id) {
            onDelete(formData.spmt_id);
            onClose();
        }
    };

    return (
        <Popper open={open} anchorEl={anchorEl} placement="top" disablePortal style={{ zIndex: 1300 }}>
            <DraggablePaperComponent style={{ width: 850, position: 'fixed', top: 10, left: '20%', }}>
                <Box p={2} display="flex" justifyContent="space-between" alignItems="center" style={{ cursor: 'move' }}>
                    <Typography variant="h6" id="draggable-popper-title">Edit Scanned Payment</Typography>
                    <Button size="small" onClick={onClose}>X</Button>
                </Box>
                <Box p={2}>
                    <form id="edit-scanned-payment-form" style={{ width: '100%' }}>
                        <Box display="flex" flexDirection="row" flexWrap="wrap" gap={2}>
                            {rowData && Object.keys(rowData).map((key) => (
                                <Box key={key} flexBasis="calc(33.3333% - 16px)">
                                    <TextField
                                        label={key}
                                        name={key}
                                        variant="outlined"
                                        fullWidth
                                        value={formData[key as keyof RowData] || ''}
                                        onChange={handleChange}
                                        required
                                    />
                                </Box>
                            ))}
                        </Box>
                    </form>
                </Box>
                <Box display="flex" justifyContent="flex-end" p={2}>
                    <Button onClick={onClose} color="primary">Cancel</Button>
                    <Button onClick={handleSave} color="primary">Save</Button>
                    <Button onClick={handleDelete} color="secondary">Delete</Button>
                </Box>
            </DraggablePaperComponent>
        </Popper>
    );
};

export default EditScannedPayment;
