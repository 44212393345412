
 // PatientContext.js
import React, { useState, useContext , useEffect} from 'react';
import {
    loadGridData,
    DATA_PATIENT_LIST,
    DATA_PATIENT_FULL_LIST,
    DATA_PATIENT_LIST_BY_IDS,
  } from '../utils/ecw/EcwData';

const PatientContext = React.createContext();

const PatientProvider = ({ children }) => {

    const [patientList, setPatientList] = useState([]);

  
  useEffect(() => {
    loadGridData(DATA_PATIENT_FULL_LIST, {}, recvGridData);
}, []);

const recvGridData = (gridName, args, gridData) => {
  console.log('ReceivedData for :', gridName, args, gridData);
 
  switch (gridName) {
    case DATA_PATIENT_LIST:
      case DATA_PATIENT_FULL_LIST:
    case DATA_PATIENT_LIST_BY_IDS:
        setPatientList(gridData.map(row=> { row['PatientId'] = Number(row['PatientId']); return row;}));
      break;
    default:
  }
};


const getNewPatients = () => {

  loadGridData(DATA_PATIENT_FULL_LIST, {}, recvGridData);

  //return null;

}

const getPatientRow = (patientid) => {

  console.log("LOOKING UP patient id :", patientid);
  let ptrows = patientList.filter(pt=> { return pt.PatientId == patientid});



  if (ptrows) {
    console.log("Found patient ", patientid,  ptrows[0]);
    return ptrows[0];
  }else{
    console.log("Patient not found for :", patientid);
  }

  return null;

}
  const addPatientRow = (newpt) => {
    console.log("WILL ADD PT ROW", newpt);

   // const newPatient = { PatientId: newpt.tpat_id, Name: `${newpt.firstname} ${newpt.lastname}` , DOB: newpt.dob };
    // Use the spread operator (...) to create a new array with the existing patientList and add the newPatient object
    const updatedPatientList = [  newpt, ...patientList];
  
    // Update the state with the new array
    setPatientList(updatedPatientList);

    //setDocinfo(newDocinfo);
  };

//   const updateDocPageinfo = (Patientpage) => {
//     setDocinfo({...docinfo, Patientpage: Patientpage});
//   };

  return (
    <PatientContext.Provider value={{ patientList, addPatientRow, getPatientRow, getNewPatients }}>
      {children}
    </PatientContext.Provider>
  );
};

const usePatient = () => {
  const context = useContext(PatientContext);
  if (!context) {
    throw new Error('usePatient must be used within a PatientProvider');
  }
  return context;
};

export { PatientProvider, usePatient };

