import React, { useState, useEffect, useRef } from 'react';
import {
    Button,
    Box,
    FormLabel,
    Typography,
    Switch,
    Checkbox,
    FormControlLabel,
    FormGroup,
    TextField,
    Collapse
} from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import SplitPanel from "../../Components/splitpage/splitpanel";
import ApexPdfjsViewer from "../../Components/ApexPdfjsViewer";
import apiConfig from "../../apiConfig";
import axios from "axios";
import { handleAgDocument } from "../../utils/ecw/hadleDocuments";

interface BankRowData {
    tran_id?: number;
    importsource?: string;
    importsourceref?: string;
    importnotes?: string;
    sourceAccount?: string;
    destAccount?: string;
    sourceAccount_id?: number;
    destAccount_id?: number;
    trangrpparent_id?: number;
    trantype?: number;
    trandate?: string;
    tranamount?: number;
    debit_credit?: string;
    description?: string;
    trannotes?: string;
    currencyname?: string;
    reconcilestatus?: string;
    trancompleted?: string;
    ecwpayment_id?: number;
    payer_desc?: string;
    ecwpayer_id?: number;
    vendor_desc?: string;
    transource?: string;
    transource_id?: number;
    fireflyref?: string;
    fireflyreftj_id?: number;
    createdat?: string;
    updatedat?: string;
    deletedat?: string;
}

interface MerchantRowData {
    row_id?: number;
    sourceaccount?: string;
    TransactionID?: number;
    TransactionType?: string;
    status?: string;
    CardNumber?: string;
    Amount?: number;
    SubmittedTime?: string;
    InvoiceNumber?: string;
    PONumber?: string;
    ClerkID?: string;
    PaymentType?: string;
    CardBrand?: string;
    TerminalID?: number;
    TerminalName?: string;
    bankdeposit_id?: number;
    ecwpayment_id?: number;
    scannedpayment_id?: number;
    reconcilestatus?: string;
    created_at?: string;
    updated_at?: string;
}

interface ScannedRowData {
    spmt_id?: number;
    ecwdoc_id?: number;
    pageno?: number;
    docpath?: string;
    pagedetail?: string;
    checkamount?: number;
    checkdate?: string;
    checknumber?: string;
    cardamount?: number;
    otheramount?: number;
    otheramount2?: number;
    ecwpayment_id?: number;
    bankdeposit_id?: number;
    pmtpatientid?: number;
    pmtpatientname?: string;
    reconcilestatus?: string;
    customFilename?: string;
    ecwFileName?: string;
    depositbatch?: string;
    ispaymentcached?: number;
    expiredpayment?: number;
    replacementremarks?: string;
    paymentnotes?: string;
    reference1?: string;
    reference2?: string;
    enteredby?: string;
    created_at?: string;
    updated_at?: string;
    deleteflag?: number;
}

const ReviewVirtualCards: React.FC = () => {
    const [scannedPayments, setScannedPayments] = useState<ScannedRowData[]>([]);
    const [filteredScannedPayments, setFilteredScannedPayments] = useState<ScannedRowData[]>([]);
    const [selectedScannedPayment, setSelectedScannedPayment] = useState<ScannedRowData | null>(null);

    const [bankPayments, setBankPayments] = useState<BankRowData[]>([]);
    const [filteredBankPayments, setFilteredBankPayments] = useState<BankRowData[]>([]);
    const [selectedBankPayment, setSelectedBankPayment] = useState<BankRowData | null>(null);

    const [merchantPayments, setMerchantPayments] = useState<MerchantRowData[]>([]);
    const [filteredMerchantPayments, setFilteredMerchantPayments] = useState<MerchantRowData[]>([]);
    const [selectedMerchantPayment, setSelectedMerchantPayment] = useState<MerchantRowData | null>(null);

    const [currentPageNum, setCurrentPageNum] = useState<number>();
    const [pdfUrl, setPdfUrl] = useState('');
    const [collapse, setCollapse] = useState(false);
    const [showPdfViewer, setShowPdfViewer] = useState(false);
    const [showBank, setShowBank] = useState(true);
    const [showMerchant, setShowMerchant] = useState(true);
    const [showScanned, setShowScanned] = useState(true);
    const [showAll, setShowAll] = useState(false);
    const [showUnmatchedOnly, setShowUnmatchedOnly] = useState(true);

    const gridScanRef = useRef<any>(null);
    const gridMerchRef = useRef<any>(null);
    const gridBankRef = useRef<any>(null);
    const apiURL = apiConfig.REACT_APEX_BASE_API;

    useEffect(() => {
        fetchScannedPayments();
        fetchMerchantPayments();
        fetchBankPayments();
    }, []);

    useEffect(() => {
        applyFilterToGrids();
    }, [showUnmatchedOnly, scannedPayments, merchantPayments, bankPayments]);

    const fetchScannedPayments = async () => {
        const sqlText = `SELECT apex.dbo.ecwdocumentpath(ecwdoc_id) as docpath, * FROM rc.ApexScannedPayment WHERE cardamount > 0 ORDER BY spmt_id DESC`;
        const authHeader = JSON.parse(localStorage.getItem('authHeader') || '{}');
        const urlPrefix = `${apiConfig.REACT_APEX_BASE_API}/exsql?dbserver=apex&sqltype=customSQL&sqltext=`;

        try {
            const response = await axios.get(`${urlPrefix}${sqlText}`, { headers: authHeader });

            if (response.data) {
                const dframe: string = response.data.frame0;
                const myObj = JSON.parse(dframe);
                const gridData = myObj.rows;
                setScannedPayments(gridData);
                setFilteredScannedPayments(gridData);
            }
        } catch (error) {
            console.error('Error fetching scanned payments:', error);
        }
    };

    const fetchMerchantPayments = async () => {
        const sqlText = `SELECT * FROM rc.MerchantTransactions ORDER BY row_id DESC`;
        const authHeader = JSON.parse(localStorage.getItem('authHeader') || '{}');
        const urlPrefix = `${apiConfig.REACT_APEX_BASE_API}/exsql?dbserver=apex&sqltype=customSQL&sqltext=`;

        try {
            const response = await axios.get(`${urlPrefix}${sqlText}`, { headers: authHeader });

            if (response.data) {
                const dframe: string = response.data.frame0;
                const myObj = JSON.parse(dframe);
                const gridData = myObj.rows;
                setMerchantPayments(gridData);
                setFilteredMerchantPayments(gridData);
            }
        } catch (error) {
            console.error('Error fetching merchant payments:', error);
        }
    };

    const fetchBankPayments = async () => {
        const sqlText = `SELECT * from rc.ApexBankTransaction order by tran_id desc`;
        const authHeader = JSON.parse(localStorage.getItem('authHeader') || '{}');
        const urlPrefix = `${apiConfig.REACT_APEX_BASE_API}/exsql?dbserver=apex&sqltype=customSQL&sqltext=`;

        try {
            const response = await axios.get(`${urlPrefix}${sqlText}`, { headers: authHeader });

            if (response.data) {
                console.log("Received:", response.data);
                const dframe: string = response.data.frame0;
                const myObj = JSON.parse(dframe);
                const gridData = myObj.rows;
                setBankPayments(gridData);
                setFilteredBankPayments(gridData);
            }
        } catch (error) {
            console.error('Error fetching bank payments:', error);
        }
    };

    const handleSelectionChange = (params: any) => {
        const selectedNode = params.api.getSelectedNodes()[0];
        console.log("Selected payment row: ", selectedNode ? selectedNode.data : '');
        setSelectedScannedPayment(selectedNode ? selectedNode.data : null);
        if (selectedNode) {
            filterOtherGrids(selectedNode.data);
        }
    };

    const filterOtherGrids = (selectedPayment: ScannedRowData) => {
        if (selectedPayment) {
            const last4CheckNumber = selectedPayment.checknumber?.slice(-4);
            setFilteredMerchantPayments(
                merchantPayments.filter(payment =>
                    payment.Amount === selectedPayment.cardamount ||
                    payment.CardNumber?.slice(-4) === last4CheckNumber
                )
            );
            setFilteredBankPayments(bankPayments.filter(payment => payment.tranamount === selectedPayment.cardamount));
        }
    };

    const handleScannedPmtSelectionChange = (params: any) => {
        const selectedNode = params.api.getSelectedNodes()[0];
        console.log("Selected payment row: ", selectedNode ? selectedNode.data : '');
        setSelectedScannedPayment(selectedNode ? selectedNode.data : null);
        if (selectedNode) {
            filterOtherGrids(selectedNode.data);
        }
    };

    const handleCriteriaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        applyFilter(event.target.value);
    };

    const applyFilter = (criteria: string) => {
        // Apply any additional filtering logic here
    };

    const handleSelectAll = () => {
        gridScanRef.current.api.selectAll();
    };

    const handleSelectNone = () => {
        gridScanRef.current.api.deselectAll();
    };

    const onSetCurrentPageNum = (pageNum: number) => {
        setCurrentPageNum(pageNum);
    };

    const onSetpdfToDisplayURL = (args: React.SetStateAction<string>) => {
        setPdfUrl(args);
    };

    const ShowDocument = (pagenum: number, docpath: string) => {
        handleAgDocument({
            filename: docpath,
            dirpath: '',
            startpage: pagenum
        }, apiURL, onSetpdfToDisplayURL, setCurrentPageNum);
    };

    const dateFormatter = (params: { value: string }) => {
        const date = new Date(params.value);
        return date.toISOString().split('T')[0];
    };

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;
        if (name === 'showBank') {
            setShowBank(checked);
        } else if (name === 'showMerchant') {
            setShowMerchant(checked);
        } else if (name === 'showScanned') {
            setShowScanned(checked);
        } else if (name === 'showAll') {
            setShowAll(checked);
            if (checked) {
                setShowBank(true);
                setShowMerchant(true);
                setShowScanned(true);
            } else {
                setShowBank(false);
                setShowMerchant(false);
                setShowScanned(false);
            }
        }
    };

    const handleMatch = async (type: 'Bank' | 'Merchant', data: any) => {
        if (!selectedScannedPayment) {
            alert('Please select a scanned payment first.');
            return;
        }

        const selectedScannedNodes = gridScanRef.current.api.getSelectedNodes();
        const selectedScannedPaymentID = selectedScannedNodes.length > 0 ? selectedScannedNodes[0].data.spmt_id : null;

        const params = {
            ecwpmt_id: selectedScannedPayment.spmt_id,
            bank_tran_id: type === 'Bank' ? data.tran_id : null,
            macct_tran_id: type === 'Merchant' ? data.row_id : null,
            spmt_id: selectedScannedPaymentID,
            ispaymentcached: null,
            pmtnotes: null,
            pmtclosed: null,
            verified_by: null,
            verified_on: null,
        };

        try {
            const authHeader = JSON.parse(localStorage.getItem('authHeader') || '{}');
            const urlPrefix = `${apiURL}/exsql?dbserver=apex&sqltype=customSQL&sqltext=`;
            const sqlText = generateSqlText(params);
            const response = await axios.get(`${urlPrefix}${sqlText}`, { headers: authHeader });

            if (response.data) {
                updateRowsAfterMatch(type, data, selectedScannedPayment);
            }
        } catch (error) {
            console.error('Error matching payment:', error);
        }
    };

    const generateSqlText = (params: any) => {
        return `exec rc.mnt_ecwpayments
            @ecwpmt_id = ${params.ecwpmt_id},
            @bank_tran_id = ${params.bank_tran_id},
            @macct_tran_id = ${params.macct_tran_id},
            @spmt_id = ${params.spmt_id},
            @ispaymentcached = ${params.ispaymentcached},
            @pmtnotes = ${params.pmtnotes},
            @pmtclosed = ${params.pmtclosed},
            @verified_by = ${params.verified_by},
            @verified_on = ${params.verified_on}`;
    };

    const updateRowsAfterMatch = (type: 'Bank' | 'Merchant', data: any, selectedScannedPayment: ScannedRowData) => {
        const updatedScannedPayments = scannedPayments.map(payment => {
            if (payment.spmt_id === selectedScannedPayment.spmt_id) {
                // Update logic as needed
            }
            return payment;
        });

        setScannedPayments(updatedScannedPayments);
        setFilteredScannedPayments(updatedScannedPayments);

        if (type === 'Bank') {
            const updatedBankPayments = bankPayments.map(payment => {
                if (payment.tran_id === data.tran_id) {
                    payment.ecwpayment_id = selectedScannedPayment.spmt_id;
                }
                return payment;
            });
            setBankPayments(updatedBankPayments);
            setFilteredBankPayments(updatedBankPayments);
        }

        if (type === 'Merchant') {
            const updatedMerchantPayments = merchantPayments.map(payment => {
                if (payment.row_id === data.row_id) {
                    payment.ecwpayment_id = selectedScannedPayment.spmt_id;
                }
                return payment;
            });
            setMerchantPayments(updatedMerchantPayments);
            setFilteredMerchantPayments(updatedMerchantPayments);
        }
    };

    const applyFilterToGrids = () => {
        if (showUnmatchedOnly) {
            setFilteredScannedPayments(scannedPayments.filter(payment => !payment.ecwpayment_id));
            setFilteredBankPayments(bankPayments.filter(payment => !payment.ecwpayment_id));
            setFilteredMerchantPayments(merchantPayments.filter(payment => !payment.ecwpayment_id));
        } else {
            setFilteredScannedPayments(scannedPayments);
            setFilteredBankPayments(bankPayments);
            setFilteredMerchantPayments(merchantPayments);
        }
    };

    const handleQuickFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const filterValue = event.target.value;
        gridScanRef.current.api.setQuickFilter(filterValue);
        gridBankRef.current.api.setQuickFilter(filterValue);
        gridMerchRef.current.api.setQuickFilter(filterValue);
    };

    const handleScannedPaymentMatch = async (data: any) => {
        //handleMatch('Scanned', data);
    };

    const handleMerchantAccountPaymentMatch = async (data: any) => {
        //handleMatch('Merchant', data);
    };

    const handleBankTransactionMatch = async (data: any) => {
        //handleMatch('Bank', data);
    };

    const handleViewPage = (row: any) => {
        if (row) {
            ShowDocument(row.pageno ? row.pageno : 1, row.docpath ? row.docpath : '');
        }
    };

    return (
        <SplitPanel split="horizontal">
            <>
                <Box display={"flex"} flexDirection={"row"} alignItems="center" gap={2}>
                    <Button onClick={() => setCollapse(!collapse)}>
                        {collapse ? 'Hide Criteria' : 'Show Criteria'}
                    </Button>
                    <Typography>{selectedScannedPayment ? selectedScannedPayment.spmt_id : 'No scanned payment selected'}</Typography>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={showPdfViewer}
                                onChange={() => setShowPdfViewer(!showPdfViewer)}
                                name="showPdfViewer"
                                color="primary"
                            />
                        }
                        label="Show PDF Viewer"
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={showUnmatchedOnly}
                                onChange={() => setShowUnmatchedOnly(!showUnmatchedOnly)}
                                name="showUnmatchedOnly"
                                color="primary"
                            />
                        }
                        label="Show Unmatched Only"
                    />
                    <Box display={"flex"} flexDirection={"row"} alignItems="center" gap={2} padding={0}>
                        <FormGroup row>
                            <FormControlLabel
                                control={<Checkbox checked={showAll} onChange={handleCheckboxChange} name="showAll" />}
                                label="Show All"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={showBank} onChange={handleCheckboxChange} name="showBank" />}
                                label="Bank Transactions"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={showMerchant} onChange={handleCheckboxChange} name="showMerchant" />}
                                label="Merchant Transactions"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={showScanned} onChange={handleCheckboxChange} name="showScanned" />}
                                label="Scanned Payments"
                            />
                        </FormGroup>
                    </Box>
                    <TextField label="Quick Filter" onChange={handleQuickFilterChange} />
                </Box>
                <Collapse in={collapse}>
                    <Box display={"flex"} flexDirection={"row"} alignItems="center" gap={2}>
                        <Button onClick={() => setShowPdfViewer(!showPdfViewer)}>Toggle PDF Viewer</Button>
                        <Button onClick={() => setShowUnmatchedOnly(!showUnmatchedOnly)}>Toggle Unmatched Only</Button>
                        <Button onClick={() => setShowAll(!showAll)}>Toggle Show All</Button>
                    </Box>
                </Collapse>

                {showScanned && (
                    <div className="ag-theme-alpine" style={{ height: '30%' }}>
                        <AgGridReact
                            ref={gridScanRef}
                            rowData={filteredScannedPayments}
                            rowSelection="single"
                            defaultColDef={{ sortable: true, filter: true, resizable: true }}
                            onSelectionChanged={handleScannedPmtSelectionChange}
                            columnDefs={[
                                { headerName: 'ID', field: 'spmt_id', width: 100, checkboxSelection: true },
                                {
                                    headerName: '',
                                    width: 180,
                                    cellRenderer: (params: any) => (
                                        <>
                                            <Button onClick={() => handleScannedPaymentMatch(params.data)}>Match</Button>
                                            <Button onClick={() => handleViewPage(params.data)}>View</Button>
                                        </>
                                    ),
                                },
                                { headerName: 'pg', field: 'pageno', width: 90 },
                                { headerName: 'Doc ID', field: 'ecwdoc_id', width: 150 },
                                { headerName: 'Chk Amt', field: 'checkamount', width: 120 },
                                { headerName: 'CardAmt', field: 'cardamount', width: 120 },
                                { headerName: 'Date', field: 'checkdate', width: 150, valueFormatter: dateFormatter },
                                { headerName: 'Check Number', field: 'checknumber', width: 200 },
                            ]}
                            gridOptions={{
                                suppressRowHoverHighlight: true,
                                suppressColumnVirtualisation: true,
                                rowHeight: 25,
                                headerHeight: 20
                            }}
                        />
                    </div>
                )}

                {showMerchant && (
                    <div className="ag-theme-alpine" style={{ height: '30%' }}>
                        <AgGridReact
                            ref={gridMerchRef}
                            rowData={filteredMerchantPayments}
                            rowSelection="single"
                            defaultColDef={{ sortable: true, filter: true, resizable: true }}
                            columnDefs={[
                                { headerName: 'ID', field: 'row_id', width: 100, checkboxSelection: true },
                                {
                                    headerName: '',
                                    width: 70,
                                    cellRenderer: (params: any) => (
                                        <>
                                            <Button onClick={() => handleMerchantAccountPaymentMatch(params.data)}>Match</Button>
                                        </>
                                    ),
                                },
                                { headerName: 'Card Number', field: 'CardNumber', width: 200 },
                                { headerName: 'Amount', field: 'Amount', width: 120 },
                                { headerName: 'Status', field: 'status', width: 120 },
                                { headerName: 'Date', field: 'SubmittedTime', width: 150, valueFormatter: dateFormatter },
                                { headerName: 'Description', field: 'TransactionType', width: 200 },
                            ]}
                            gridOptions={{
                                suppressRowHoverHighlight: true,
                                suppressColumnVirtualisation: true,
                                rowHeight: 25,
                                headerHeight: 20
                            }}
                        />
                    </div>
                )}

                {showBank && (
                    <div className="ag-theme-alpine" style={{ height: '30%' }}>
                        <AgGridReact
                            ref={gridBankRef}
                            rowData={filteredBankPayments}
                            rowSelection="single"
                            defaultColDef={{ sortable: true, filter: true, resizable: true }}
                            columnDefs={[
                                { headerName: 'ID', field: 'tran_id', width: 100, checkboxSelection: true },
                                {
                                    headerName: '',
                                    width: 70,
                                    cellRenderer: (params: any) => (
                                        <>
                                            <Button onClick={() => handleBankTransactionMatch(params.data)}>Match</Button>
                                        </>
                                    ),
                                },
                                { headerName: 'Source', field: 'importsource', width: 150 },
                                { headerName: 'Amount', field: 'tranamount', width: 120 },
                                { headerName: 'Date', field: 'trandate', width: 150, valueFormatter: dateFormatter },
                                { headerName: 'Description', field: 'description', width: 300 },
                            ]}
                            gridOptions={{
                                suppressRowHoverHighlight: true,
                                suppressColumnVirtualisation: true,
                                rowHeight: 25,
                                headerHeight: 20
                            }}
                        />
                    </div>
                )}
            </>
            {showPdfViewer && (
                <div style={{ height: '100%' }}>
                    <Box>
                        <FormLabel>{selectedScannedPayment && selectedScannedPayment.customFilename}</FormLabel>
                        <ApexPdfjsViewer pdfUrl={pdfUrl} startingPageNum={currentPageNum} setCurrentPageNum={onSetCurrentPageNum} />
                    </Box>
                </div>
            )}
        </SplitPanel>
    );
};

export default ReviewVirtualCards;
